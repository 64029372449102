<template>
  <div class="bg-white shadow-sm w-100 p-1">
    <div class="p-2 d-flex justify-content-between">
      <div class="" style="color: #09140f; font-size: 15px">
        {{ this.$route.name }}
      </div>
      <div class="d-flex justify-content-around">
        <!--
        <span
          class="material-icons mr-2 cursor-pointer"
          style="color: #238f53; cursor: pointer"
        >
          notifications
        </span>
        -->

        <div class="d-flex ml-2 cursor-pointer">
          <div style="width: 25px; height: 25px">
            <img
              class="rounded-circle shadow-4-strong w-100 h-100"
              alt="avatar2"
              :src="`${user.profileImage.url}`"
            />
          </div>
          <span
            style="
              font-size: 14px;
              line-height: 25.6px;
              margin-left: 10px;
              cursor: pointer;
              text-transform: capitalize;
            "
            >{{ user.userRole }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "Header-Vue",

  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
  },
  mounted() {
    console.log("User:", this.user);
  },
};
</script>
<style></style>
