import { createRouter, createWebHistory } from "vue-router";
import Login from "../auth/login.vue";
import axios from "axios";
import Dashboard from "../admin/dashboard.vue";
import Alert from "../alert/alert.vue";
import Report from "../emergency/report.vue";
import Map from "../noauth/map.vue";
import MapRedirect from "../redirect/redirect.vue";
import Download from "../apps.vue";
import Downloads from "../downloadapp.vue";

import docMap from "../doc/map.vue";
import EventMap from "../doc/bigmap.vue";
import myPrivacy from "../privacy/privacy.vue";
import Management from "../user/management.vue";
import Settings from "../settings/settings.vue";
import Faq from "../faq/faq.vue";
import AlertPublic from "../doc/alert.vue";
import AlertIframe from "../doc/alertifram.vue";
import ReportPublic from "../doc/report.vue";
import ReportIframe from "../doc/reportiframe.vue";
import Codes from "../codes/home.vue";
import PrivacyAccessibilty from "../privacy/privacy-accessibility.vue";
import PrivacyDelete from "../privacy/delete-privacy.vue";
import DeleteRequest from "../settings/delete-request.vue";

import store from "@/store";

// import store  from '@/store'

axios.defaults.baseURL = "https://api.inec-sans.com/";

const routes = [
  {
    path: "/",
    name: "Sign In",
    component: Login,
    beforeEnter: (to, from, next) => {
      if (store.getters["auth/authenticated"]) {
        return next({ name: "Dashboard" });
      }
      next();
    },
  },
  {
    path: "/redirect/:id",
    component: MapRedirect,
  },
  {
    path: "/noauth/redirect/:id",
    name: "Map View",
    component: Map,
  },

  {
    path: "/faq/faq",
    name: "Faq",
    component: Faq,
  },
  {
    path: "/apps",
    name: "App ",
    component: Download,
  },
  {
    path: "/downloadapp",
    name: "Apps ",
    component: Downloads,
  },
  {
    path: "/privacy/privacy",
    name: "Privacy ",
    component: myPrivacy,
  },
  {
    path: "/privacy/privacy-accessibity",
    name: "Privacy Accessibity ",
    component: PrivacyAccessibilty,
  },
  {
    path: "/privacy/delete",
    name: "Delete ",
    component: PrivacyDelete,
  },
  {
    path: "/doc/map",
    name: "Event Map  ",
    component: docMap,
  },
  {
    path: "/doc/alertframe",
    name: "Alert Details Map  ",
    component: AlertIframe,
  },
  {
    path: "/doc/reportframe",
    name: "Report Details  ",
    component: ReportIframe,
  },
  {
    path: "/doc/alert",
    name: "Alert   ",
    component: AlertPublic,
  },
  {
    path: "/doc/report",
    name: "Report   ",
    component: ReportPublic,
  },

  {
    path: "/doc/bigmap",
    name: "Event Maps  ",
    component: EventMap,
  },
  {
    path: "/admin/dashboard",
    name: "Dashboard",
    component: Dashboard,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({ name: "Sign In" });
      }
      next();
    },
  },
  {
    path: "/alert/alert",
    name: "Alert",
    component: Alert,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({ name: "Sign In" });
      }
      next();
    },
  },
  {
    path: "/emergency/report",
    name: "Emergency Report",
    component: Report,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({ name: "Sign In" });
      }
      next();
    },
  },
  {
    path: "/delete-request",
    name: "Delete Request",
    component: DeleteRequest,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({ name: "Sign In" });
      }
      next();
    },
  },
  {
    path: "/code",
    name: "Generate Codes",
    component: Codes,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({ name: "Sign In" });
      }
      next();
    },
  },
  {
    path: "/user/management",
    name: "User Management",
    component: Management,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({ name: "Sign In" });
      }
      next();
    },
  },
  {
    path: "/settings/settings",
    name: "Settings",
    component: Settings,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({ name: "Sign In" });
      }
      next();
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  mode: history,
});

router.beforeEach((to, from, next) => {
  document.title = `${to.name}`;
  next();

  // to and from are both route objects. must call `next`.
});

export default router;
