<template>
  <div style="width: 100wv; height: 100vh">
    <iframe
      src="https://www.inec-sans.com/doc/report"
      style="width: 100%; height: 100vh"
    ></iframe>
  </div>
</template>
<script>
export default {
  name: "ReportIframe",
};
</script>
