<template>
  <div
    class="container bg-white shadow-sm"
    style="margin: 30px auto; max-width: 800px; width: 100%"
  >
    <h6 style="padding: 10px">How to permanently delete your account</h6>
    <ul>
      <li>Login to your account</li>
      <li>Go to your Profile</li>
      <li>Click on delete account</li>
      <li>Confirm delete account</li>
    </ul>

    <p>All the history of your account will be deleted on INEC SANS server, including</p>
    <ol>
      <li>Your personal details you submitted while registratering</li>
      <li>All alerts, SOS, and emergency report form you have submitted</li>
      <li>All your chats history will also be deleted</li>
    </ol>
    <form @submit.prevent="deleteAccount">
      <div class="form-group">
        <h5>Do you want to delete your account? Fill</h5>
        <label for="exampleInputEmail1">Name</label>
        <input
          type="name"
          class="form-control"
          id="exampleInputEmail1"
          aria-describedby="emailHelp"
          placeholder="Enter Name"
          v-model="name"
        />
      </div>
      <div class="form-group">
        <label for="exampleInputPassword1">Email</label>
        <input
          type="email"
          class="form-control"
          id="exampleInputPassword1"
          placeholder="email"
          v-model="email"
        />
      </div>
      <div class="form-group">
        <label for="exampleInputPassword1">Reason</label>
        <textarea
          type="text"
          class="form-control"
          id="exampleInputPassword1"
          placeholder="Enter reason"
          v-model="reason"
        >
        </textarea>
      </div>

      <button
        type="submit"
        class="btn btn-primary"
        style="background: crimson; border: 1px solid crimson"
      >
        Delete my account
      </button>
    </form>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "myPrivacy",
  data() {
    return {
      name: "",
      email: "",
      reason: "",
    };
  },

  methods: {
    async deleteAccount() {
      const data = {
        name: this.name,
        reason: this.reason,
        email: this.email,
      };
      axios
        .post(`/v1/user/request_for_delete`, data)
        .then((res) => {
          this.$swal({
            title: "<span style='font-size:13px !important;color:#238f53'>Success</span>",
            text: res.data.result.message,

            width: "300px",
            type: "success",
            icon: "success",
            confirmButtonColor: "#238f53",
          }).then(() => {
            (this.name = ""), (this.email = "");
            this.reason = "";
          });
        })
        .catch((e) => {
          console.log(e);
          this.$swal({
            title: "<span style='font-size:13px !important;color:#238f53'>Error</span>",
            text: e.response?.data
              ? e.response?.data.errors[0].message
              : "An error occur",
            type: "error",
            icon: "error",

            width: "300px",
            confirmButtonColor: "#238f53",
          }).then(() => {});
        });
    },
  },
};
</script>
<style scoped>
h6 {
  line-height: 30px;
}
p {
  line-height: 40px;
  line-break: auto;
  text-align: left;
  padding: 10px;
}
li {
  line-height: 40px;
}
</style>
