<template>
  <div
    class="contaners"
    style="display: flex; align-items: center; justify-content: center"
  >
    <div class="card">
      <div class="card-body">
        <div class="w-100 d-flex justify-content-center">
          <img src="@/assets/images/logo.svg" alt="" width="32px" height="32px" />
        </div>
        <h4
          style="
            text-align: center;
            margin-top: 20px;
            color: #233149;
            font-size: 20px;
            line-height: 32px;
          "
        >
          Welcome Back
        </h4>
        <div
          style="
            margin-top: 8px;
            color: #7993b1;
            text-align: center;
            font-size: 14px;
            line-height: 16.6px;
          "
        >
          Please enter your details

          <div :class="error ? 'text-danger' : null">
            {{ error ? errMessage : null }}
          </div>
        </div>
        <form @submit.prevent="loginHandle">
          <div class="form-group">
            <label for="exampleInputEmail1">Email </label>
            <input
              type="email"
              class="form-control"
              id="exampleInputEmail1"
              placeholder="Enter email"
              v-model="email"
            />
          </div>
          <div class="form-group">
            <label for="exampleInputPassword1">Password</label>
            <input
              type="password"
              class="form-control"
              id="exampleInputPassword1"
              placeholder="Password"
              v-model="password"
            />
          </div>

          <button
            :disabled="disabled"
            type="submit"
            class="btn w-100"
            style="background: #238f53; color: #fff"
          >
            Sign In
          </button>
          <h4
            style="
              color: #238f53;
              text-align: center;
              font-size: 12px;
              line-height: 15px;
              margin-top: 18px;
              text-transform: uppercase;
            "
          >
            Inec Security Alert And Notification system
          </h4>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Login-app",
  data() {
    return {
      email: "",
      password: "",
      success: false,
      error: false,
      loading: false,
      errMessage: "",
      disabled: false,
    };
  },
  methods: {
    ...mapActions({
      signIn: "auth/signIn",
    }),
    loginHandle() {
      this.disabled = true;
      const data = {
        email: this.email,
        password: this.password,
        fcmToken: "iamnecadmin",
      };
      this.signIn(data)
        .then(() => {
          this.$router.push("/admin/dashboard");
        })
        .catch((e) => {
          this.$swal({
            title: "<span style='font-size:13px !important;color:crimson'>Failed</span>",
            text: e.response.data.errors[0].message,
            width: "300px",
            confirmButtonColor: "#238f53",
          });
          this.disabled = false;
        });
    },
  },

  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
  },
};
</script>
<style>
.contaners {
  background-image: url("../assets/images/bgss.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}
.card {
  width: 100%;
  max-width: 303px;
  margin: 0 auto;
  top: 0px;
  bottom: 0px;
  border-radius: 5px !important;
}
.card-body {
  padding: 24px 32px 24px 32px !important;
}
label {
  color: #7993b1 !important;
  font-size: 12px;
  line-height: 19.2px;
}
</style>
