import axios from "axios";
export default {
  namespaced: true,
  state: {
    token: null,
    user: null,
  },
  getters: {
    authenticated(state) {
      return state.token && state.user;
    },
    user(state) {
      return state.user;
    },
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_USER(state, user) {
      state.user = user;
    },
  },
  actions: {
    async signUp({ dispatch }, payload) {
      let response = await axios.post("v1/user/register", payload);

      return dispatch("attempt", response.data.result.data.token);
    },
    async signAdmin({ dispatch }, payload) {
      let response = await axios.post("v1/admin/register_admin", payload);

      return dispatch("attempt", response.data.result.data.token);
    },
    async signIn({ dispatch }, payload) {
      let response = await axios.post("v1/admin/login", payload);

      return dispatch("attempt", response.data.result.data.token);
    },
    async attempt({ commit, state }, token) {
      if (token) {
        commit("SET_TOKEN", token);
      }
      if (!state.token) {
        return;
      }

      try {
        let response = await axios.get("v1/user/get_user_profile");
        commit("SET_USER", response.data.result.data);
      } catch (e) {
        console.log(e);
        commit("SET_TOKEN", null), commit("SET_USER", null);
      }
    },
    async signOut({ commit }) {
      commit("SET_TOKEN", null);
      commit("SET_USER", null);
    },
  },
};
