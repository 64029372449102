<template>
  <div class="w-100 app" v-if="loading == false">
    <div class="w-100">
      <div
        class="modal"
        style="display: block !important; z-index: 1000000"
        v-show="status"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          class="modal-dialog"
          role="document"
          style="max-width: 460px !important; border-radius: 30px"
        >
          <div
            class="modal-content"
            style="padding: 10px !important; border-radius: 10px; max-width: 400px"
          >
            <div
              class="modal-header"
              style="border-bottom: none !important ; display: flex; align-items: center"
            >
              <h5
                class="modal-title"
                id="exampleModalLabel"
                style="
                  font-size: 12px;
                  color: #334563;
                  margin: 0px !important ;
                  padding: 0px !important;
                "
                v-if="selectedMarker.incidenceType"
              >
                Alert Details
              </h5>
              <h5
                class="modal-title"
                id="exampleModalLabel"
                style="
                  font-size: 12px;
                  color: #334563;
                  margin: 0px !important ;
                  padding: 0px !important;
                "
                v-else
              >
                SOS Details
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                id="closeme"
              >
                <span aria-hidden="true" @click="closeModalOpen" style="font-size: 20px"
                  >&times;</span
                >
              </button>
            </div>
            <div
              class="modal-body"
              style="
                padding: 5px !important;
                border-top: none !important;
                border-bottom: none !important;
              "
            >
              <div class="d-flex justify-content-between mb-3">
                <div
                  style="
                    background: #f2d5d5;
                    border-radius: 5px;
                    align-items: center;
                    display: flex;
                  "
                  class="pr-2 pl-2 pt-2 pb-2"
                >
                  <span style="color: #9d1f1f; font-size: 12px">{{
                    selectedMarker.status
                  }}</span>
                </div>
                <!--
                <button
                  :disabled="isdiabled"
                  style="
                    background: #238f53;
                    border-radius: 5px;
                    align-items: center;
                    display: flex;
                    border: 1px solid #238f53;
                  "
                  class="pr-2 pl-2"
                  @click="MarkAsResolved"
                  v-if="selectedMarker.incidenceType"
                >
                  <span style="color: #ffff; font-size: 12px">Mark as resolved</span>
                </button>
                <button
                  :disabled="isdiabled"
                  style="
                    background: #238f53;
                    border-radius: 5px;
                    align-items: center;
                    display: flex;
                    border: 1px solid #238f53;
                  "
                  class="pr-2 pl-2"
                  @click="MarkAsResolvedSOS"
                  v-else
                >
                  <span style="color: #ffff; font-size: 12px">Mark as resolved</span>
                </button>
                -->
              </div>
              <div class="w-100" style="height: 180px">
                <Map :centers="center" :current="current" />
              </div>

              <div class="d-flex justify-content-between mt-3">
                <h5
                  style="color: #161f2e; font-size: 12px"
                  v-for="itemme in selectedMarker.alertTypes"
                  :key="itemme"
                >
                  {{ itemme.type }} ({{ itemme.level }})
                </h5>
                <span style="font-size: 12px; color: #e2b93b">{{
                  selectedMarker.status
                }}</span>
              </div>
              <div class="list-group mdil">
                <div
                  class="d-flex justify-content"
                  style="align-items: center"
                  v-if="selectedMarker.incidenceType"
                >
                  <h6>Sender</h6>
                  <span v-for="name in selectedMarker.user" :key="name">
                    {{ name.firstName }} {{ name.lastName }}
                  </span>
                </div>
                <div class="d-flex justify-content" style="align-items: center">
                  <h6>Date/Time</h6>
                  <span>{{
                    moment(selectedMarker.updatedAt).format("DD-MM-YYYY h:sa")
                  }}</span>
                </div>
                <div
                  class="d-flex justify-content"
                  style="align-items: center"
                  v-if="selectedMarker.incidenceType"
                >
                  <h6>Polling Unit</h6>
                  <span>{{ selectedMarker.pollingUnit }}</span>
                </div>
                <div class="d-flex justify-content" style="align-items: center">
                  <h6>Address</h6>
                  <span>{{ selectedMarker.address }}</span>
                </div>
                <div class="d-flex justify-content" style="align-items: center">
                  <h6>ID</h6>
                  <span>{{ selectedMarker._id }}</span>
                </div>
                <div class="d-flex justify-content" style="align-items: center">
                  <h6>Description</h6>
                  <span>No description</span>
                </div>
              </div>
              <div class="d-flex justify-content-between mt-2 mb-2">
                <div class="d-grid" style="line-height: 15px">
                  <h6
                    style="
                      color: #c9d9e5;
                      font-size: 11px;
                      padding: 2px !important;
                      margin: 2px !important;
                    "
                  >
                    Incidence has not been assigned
                  </h6>
                  <span style="color: #161f2e; font-size: 12px"
                    >Assign Response Team</span
                  >
                </div>
                <!--
                <button
                  type="button"
                  class="btn"
                  style="background: #238f53; color: #fff; font-size: 13px"
                  data-toggle="modal"
                  data-target="#exampleModalLong"
                  @click="closeModalOpen"
                  v-if="selectedMarker.incidenceType"
                >
                  Assign Response Team
                </button>
                -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        style="z-index: 900000"
        id="exampleModalLong"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLongTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content" style="padding: 10px !important">
            <div
              class="modal-header"
              style="
                margin-top: 2px !important;
                margin-bottom: 2px !important;
                padding: 10px !important;
              "
            >
              <h5
                class="modal-title"
                id="exampleModalLongTitle"
                style="
                  margin-top: 0px !important;
                  margin-bottom: 0px !important;
                  font-size: 14px;
                "
              >
                Incidence Details
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                id="closeme5"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form @submit.prevent="assignSubmit">
                <div class="form-group">
                  <label for="exampleInputEmail1">Response Name</label>
                  <div
                    class="btntab d-flex"
                    style="border: 1px solid #f5f6f7; justigy-content: left; width: 100%"
                  >
                    <input
                      type="text"
                      style="
                        width: 100%;
                        border: none !important;
                        outline: none;
                        color: #7993b1;
                        padding: 8px;
                        font-size: 12px;
                      "
                      placeholder="Enter a Team name"
                      v-model="searchres"
                      @focus="hidegetres"
                    />
                    <span
                      class="material-icons"
                      style="
                        display: flex;
                        align-self: center;
                        margin-right: 10px;
                        font-size: 14px;
                        color: #7993b1;
                      "
                      >search</span
                    >
                  </div>
                  <ul class="list-group" v-show="getres">
                    <!---  <li
                        class="list-group-item"
                        style="font-size: 12px"
                        v-for="item in filterName"
                        :key="item"
                        @click="getvalres(item)"
                      >
                        {{ item.firstName }} {{ item.lastName }}
                      </li>-->

                    <li
                      class="list-group-item"
                      style="font-size: 12px; diplay: flex; align-items: center"
                      v-for="item in filterName"
                      :key="item"
                    >
                      <input
                        class="form-check-input me-1"
                        type="checkbox"
                        :value="item._id"
                        aria-label="..."
                        v-model="respId"
                      />
                      {{ item.firstName }} {{ item.lastName }}
                    </li>
                  </ul>
                </div>

                <div class="d-flex justify-content-between">
                  <button
                    type="submit"
                    class="btn w-50 mr-2"
                    style="border: 2px solid #238f53; font-size: 12px"
                    data-toggle="modal"
                    data-target="#exampleModalLong"
                  >
                    Go Back
                  </button>
                  <button
                    :disabled="isdisabled"
                    type="submit"
                    class="btn w-50 ml-2"
                    style="background: #238f53; color: #fff; font-size: 12px"
                  >
                    Assign
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!--- End Assign Modal -->

      <!--- Modal End -->

      <main class="d-flex">
        <div class="rowsm">
          <h5 style="color: #334563; font-size: 13px; line-height: 20px">
            Realtime flashpoint -
            {{
              totalpage +
              parseInt(filternopending != "" ? filternopending[0].count : 0) +
              parseInt(filternoresolve != "" ? filternoresolve[0].count : 0) +
              parseInt(filternoprogress != "" ? filternoprogress[0].count : 0)
            }}
          </h5>

          <ul class="list-group mt-0 mb-0 bg-none">
            <li class="list-group-item d-flex justify-content-between bg-none">
              <div style="font-size: 12px; font-weight: 500; color: #adf802">Low</div>
              <span>{{ filternofLL != "" ? filternofLL[0].count : 0 }}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between bg-none">
              <div class="" style="font-size: 12px; font-weight: 500; color: yellow">
                Medium
              </div>
              <span>{{ filternofML != "" ? filternofML[0].count : 0 }}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between bg-none">
              <div class="" style="font-size: 12px; font-weight: 500; color: orange">
                High
              </div>
              <span>{{ filternofHL != "" ? filternofHL[0].count : 0 }}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between bg-none">
              <div style="font-size: 14px; font-weight: 400; color: red">Critical</div>
              <span>{{ filternofCL != "" ? filternofCL[0].count : 0 }}</span>
            </li>
          </ul>
          <h5 style="color: #334563; font-size: 12px; line-height: 30px">Analytics</h5>
          <div class="bb">
            <div class="container" style="background: #b8ebcd">
              <h6>Total no. of alerts</h6>
              <span>{{
                parseInt(filternopending != "" ? filternopending[0].count : 0) +
                parseInt(filternoresolve != "" ? filternoresolve[0].count : 0) +
                parseInt(filternoprogress != "" ? filternoprogress[0].count : 0)
              }}</span>
            </div>
            <div class="container" style="background: #c9d9e5">
              <h6>Total no. of unassigned alert</h6>
              <span>{{ filternopending != "" ? filternopending[0].count : 0 }}</span>
            </div>
            <div class="container" style="background: #f5e5b4; margin-bottom: 20px">
              <h6>Total no. of unresolved alerts</h6>
              <span>{{ filternopending != "" ? filternopending[0].count : 0 }}</span>
            </div>
            <div class="container" style="background: #e5bdf5; margin-bottom: 20px">
              <h6>Total no. of SOS</h6>
              <span>{{ totalpage }}</span>
            </div>
          </div>
          <div>
            <h5
              class="mb-2"
              style="
                color: #334563;
                font-size: 12px;
                margin: 0px !important;
                padding: 0px !important;
                line-height: 40px;
              "
            >
              Location with the most alerts
            </h5>

            <div class="col" v-for="(item, index) in moststate.slice(0, 9)" :key="item">
              <div
                :class="index == clickindex ? 'active' : null"
                class="border rounded region"
                style="float: left; cursor: pointer"
                @click="updatestate(item, index)"
              >
                {{ item.state }}
              </div>
            </div>
            <button
              v-if="clickindex != null"
              class="btn w-100 active mt-2"
              @click="resetallalert"
              style="font-size: 12px"
            >
              All State
            </button>
          </div>
        </div>
        <div class="rowsm2 p-2">
          <div class="w-100 p-2 d-flex justify-content-between">
            <h6
              style="
                margin: 0px !important ;
                padding: 0px !important;
                font-size: 14px;
                flex: 3;
                align-items: center;
                display: flex;
              "
            >
              Filter By:
            </h6>
            <div class="d-flex" style="flex: 7; justify-content: right">
              <div class="dropdown">
                <button
                  class="btn dropdown-toggle mr-2"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  style="background: #fff"
                >
                  State
                </button>
                <div
                  class="dropdown-menu dpr overflow-auto position-absolute left-0 right-auto"
                  aria-labelledby="dropdownMenuButton"
                  style="max-height: 500px; min-height: 500px"
                >
                  <div class="w-100 d-flex justify-content-center bgmtt">
                    <input
                      type="search"
                      v-model="search"
                      class="search border-none p-1 searcg"
                      placeholder="Search State"
                    />
                    <span class="material-icons">search</span>
                  </div>
                  <a
                    class="dropdown-item"
                    href="#"
                    style="font-size: 13px"
                    type="button"
                    v-for="item in filterStates"
                    :key="item.id"
                    @click="getlgas(item)"
                    >{{ item.name }}
                  </a>
                </div>
              </div>
              <div class="btn-group">
                <button
                  type="button"
                  class="btn dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  style="background: #fff"
                >
                  LGA
                </button>
                <div
                  class="dropdown-menu dropdown-menu-right"
                  style="max-height: 500px; min-height: 500px"
                >
                  <button
                    class="dropdown-item"
                    type="button"
                    v-for="item in selectedstates"
                    :key="item.id"
                    style="font-size: 12px"
                    @click="getmyalert(item)"
                  >
                    {{ item.name }}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            style="height: calc(100vh - 100px); width: 100%; box-sizing: border-box"
            class="m-2 border rounded overflow-auto"
          >
            <l-map
              :zoom="zoom"
              :max-zoom="maxzoom"
              :center="{ lat: lat, lng: lag }"
              :bound="bound"
              style="height: 100% !important; width: 100%"
            >
              <l-tile-layer :url="url" :attribution="attribution" />
              <!-- Use default icon -->

              <!-- <marker-cluster
                  :options="{ showCoverageOnHover: false, chunkedLoading: true }"
                >-->
              <l-marker
                v-for="item in filterSOS"
                :key="item"
                :lat-lng="[item.location.coordinates[0], item.location.coordinates[1]]"
                v-model="zoom"
                @click="showModalOpen(item, 1)"
              >
                <div>
                  <l-icon
                    :icon-anchor="staticAnchor"
                    class-name="someExtraClass"
                    v-model="zoom"
                  >
                    <div class="glow">
                      <div
                        style="
                          width: 10px;
                          height: 10px;
                          background: #9d1f1f !important;
                          border-radius: 100%;
                        "
                      ></div>
                    </div>
                    <div
                      style="
                        font-size: 12px;
                        color: black;
                        font-weight: 600;
                        text-align: center;
                      "
                    >
                      sos
                    </div>
                  </l-icon>
                </div>
              </l-marker>

              <!-- Alert Marker -->
              <div v-for="(notication, index) in filterAllAlert" :key="index">
                <l-marker
                  :lat-lng="[
                    notication.location.coordinates[1],
                    notication.location.coordinates[0],
                  ]"
                  v-model="zoom"
                  @click="showModalOpen(notication, 2)"
                  v-if="notication.alertTypes.length > 0"
                >
                  <div v-for="(type, index) in notication.alertTypes" :key="index">
                    <l-icon
                      :icon-anchor="staticAnchor"
                      class-name="someExtraClass"
                      v-if="type.level == 'CL'"
                    >
                      <div class="glow">
                        <div
                          style="
                            width: 10px;
                            height: 10px;
                            background: #9d1f1f;
                            border-radius: 100%;
                          "
                        ></div>
                      </div>
                    </l-icon>
                    <l-icon
                      :icon-anchor="staticAnchor"
                      class-name="someExtraClass"
                      v-if="type.level == 'HL'"
                    >
                      <div
                        class="hl"
                        v-if="type.level == 'HL'"
                        style="border: 1px solid orange"
                      >
                        <div
                          style="
                            width: 10px;
                            height: 10px;
                            background: orange;
                            border-radius: 100%;
                          "
                        ></div>
                      </div>
                    </l-icon>
                    <l-icon
                      :icon-anchor="staticAnchor"
                      class-name="someExtraClass"
                      v-if="type.level == 'ML'"
                    >
                      <div
                        class="ml"
                        v-if="type.level == 'ML'"
                        style="border: 1px solid orange"
                      >
                        <div
                          style="
                            width: 10px;
                            height: 10px;
                            background: yellow;
                            border-radius: 100%;
                          "
                        ></div>
                      </div>
                    </l-icon>
                    <l-icon
                      :icon-anchor="staticAnchor"
                      class-name="someExtraClass"
                      v-if="type.level == 'LL'"
                    >
                      <div
                        class="ll"
                        v-if="type.level == 'LL'"
                        style="border: 1px solid #adf802"
                      >
                        <div
                          style="
                            width: 10px;
                            height: 10px;
                            background: #adf802;
                            border-radius: 100%;
                          "
                        ></div>
                      </div>
                    </l-icon>
                  </div>
                </l-marker>
              </div>
              <!--
                </marker-cluster>
                -->
            </l-map>
          </div>
        </div>
      </main>
    </div>
  </div>
  <div v-else class="center">
    <vue-loaders-ball-scale-multiple
      color="#238f53"
      scale="1"
    ></vue-loaders-ball-scale-multiple>
  </div>
</template>
<script>
import Map from "../components/Map.vue";
import { mapGetters } from "vuex";
import { latLng } from "leaflet/dist/leaflet-src.esm";
import { LMap, LTileLayer, LMarker, LIcon } from "@vue-leaflet/vue-leaflet/src/lib";
import axios from "axios";
import "leaflet/dist/leaflet.css";
//import MarkerCluster from "../components/MarkerCluster.vue";
import moment from "moment";
//import StatesLgas from "vue-nigeria-states-lgas/dist/vue-naija-states-lgas.esm.js";

export default {
  name: "Dashboard-App",
  components: {
    //   MarkerCluster,
    LMap,
    Map,
    LTileLayer,
    LMarker,

    LIcon,
  },
  data() {
    return {
      states: [],
      alertid: "",
      selectedMarker: [],
      selectedstates: [],
      notification: [],
      responseTeam: [],
      loading: true,
      pending: 0,
      cancelled: 0,
      resolved: 0,
      adminrole: "",
      adminstate: "",
      bound: 100,
      progress: 0,
      maxzoom: 40,
      clickindex: null,
      cl: 0,
      hl: 0,
      ll: 0,
      ml: 0,
      alerts: [],
      analytics: [],
      exactstate: "",
      getres: false,
      slectedcoordslat: 0,
      slectedcoordslag: 0,
      selectedlevel: "",
      choosenstate: "",
      searchres: "",
      status: false,
      current: [],
      search: "",
      lga: [],
      lat: 9.0765,
      lag: 7.3986,
      exactlga: "",
      markers: [],
      respId: [],
      moststate: [],
      noofAlert: [],
      noofalertlevel: [],
      mystate: "",
      minZoom: -2,
      moment: moment,
      isdisabled: false,
      isdiabled: false,

      zoom: 7,
      center: latLng(6.465422, 3.406448),
      url: "https://{s}.tile.osm.org/{z}/{x}/{y}.png",

      customText: "Foobar",
      iconSize: 64,
    };
  },
  methods: {
    async resetallalert() {
      this.mystate = "";
      this.clickindex = null;
      try {
        let resp = await axios.get(`v1/admin/alert_analytics`);
        this.analytics = resp.data.result.data;
        this.moststate = this.analytics.alertsWithMostState;
        this.noofAlert = this.analytics.noOfAlert;
        this.noofalertlevel = this.analytics.noOfAlertLevel;
        this.lat = 9.0765;
        this.lag = 7.3986;
        this.zoom = 7;
        this.pending = this.noofAlert[0] != undefined ? this.noofAlert[0].count : 0;
        this.cancelled = this.noofAlert[1] != undefined ? this.noofAlert[1].count : 0;
        this.progress = this.noofAlert[2] != undefined ? this.noofAlert[2].count : 0;
        this.resolved = this.noofAlert[3] != undefined ? this.noofAlert[3].count : 0;
      } catch (e) {
        console.log(e);
      }
      try {
        let response = await axios.get(`v1/admin/get_all_sos`);
        // console.log(response);

        this.totalpage = response.data.result.data.length;
      } catch (e) {
        console.log(e);
      }
    },
    async updatestate(item, id) {
      this.clickindex = id;

      this.choosenstate = item.state;
      this.mystate = item.state;

      for (var p = 0; p <= this.states.length; p++) {
        if (this.states[p] && this.states[p].name == item.state) {
          this.zoom = 8;

          this.lat = this.states[p].coordinates.lat;
          this.lag = this.states[p].coordinates.lng;
        }
      }

      try {
        let resp = await axios.get(`v1/admin/alert_analytics?state=${item.state}`);
        this.analytics = resp.data.result.data;
        // this.moststate = this.analytics.alertsWithMostState;
        this.noofAlert = this.analytics.noOfAlert;
        this.noofalertlevel = this.analytics.noOfAlertLevel;

        this.pending = this.noofAlert[0] != undefined ? this.noofAlert[0].count : 0;
        this.cancelled = this.noofAlert[1] != undefined ? this.noofAlert[1].count : 0;
        this.progress = this.noofAlert[2] != undefined ? this.noofAlert[2].count : 0;
        this.resolved = this.noofAlert[3] != undefined ? this.noofAlert[3].count : 0;
      } catch (e) {
        console.log(e);
      }
      try {
        let response = await axios.get(`v1/admin/get_all_sos?state=${item.state}`);
        // console.log(response);

        this.totalpage = response.data.result.data.length;
      } catch (e) {
        console.log(e);
      }
      /*  try {
          let response = await axios.get(
            `v1/admin/get_all_sos?status=pending&state=${item.state}`
          );
  
          this.notification = response.data.result.data;
        } catch (e) {
          console.log(e);
        }
        */
    },
    async getmyalert(item) {
      this.exactlga = item.name;
      const response = await axios.get(
        `v1/admin/get_all_alert?state=${this.exactstate}&lga=${this.exactlga}`
      );
      this.alerts = response.data.result.data.alerts;
      try {
        let response = await axios.get(
          `v1/admin/get_all_sos?state=${this.exactstate}&lga=${this.exactlga}`
        );
        // console.log(response);

        this.totalpage = response.data.result.data.length;
      } catch (e) {
        console.log(e);
      }
    },
    async MarkAsResolvedSOS() {
      this.isdiabled = true;
      try {
        const data = {
          sosId: this.alertid,
        };
        const res = await axios.post(`v1/admin/resolve_sos`, data);
        var button = document.getElementById("closeme");
        button.click();
        this.status = false;
        this.$swal({
          title: "<span style='font-size:13px !important;color:#238f53'>Success</span>",
          text: res.data.result.message,

          width: "300px",
          confirmButtonColor: "#238f53",
        }).then((res) => {
          if (res.isConfirmed) {
            this.$router.go();
          }
        });
      } catch (e) {
        if (e.response.status == 400) {
          this.isdiabled = false;
          this.$swal({
            title: "<span style='font-size:13px !important;color:red'>Failed</span>",
            text: "Alert has not been resolved",

            width: "300px",
            confirmButtonColor: "#238f53",
          }).then();
        } else if (e.response.status == 500) {
          this.isdiabled = false;
          this.$swal({
            title: "<span style='font-size:13px !important;color:red'>Failed</span>",
            text: "An error occur",

            width: "300px",
            confirmButtonColor: "#238f53",
          }).then();
        }
      }
    },
    async MarkAsResolved() {
      this.isdiabled = true;
      try {
        const data = {
          alertId: this.alertid,
        };
        const res = await axios.post(`v1/admin/resolve_alert`, data);
        var button = document.getElementById("closeme");
        button.click();
        this.status = false;
        this.$swal({
          title: "<span style='font-size:13px !important;color:#238f53'>Success</span>",
          text: res.data.result.message,

          width: "300px",
          confirmButtonColor: "#238f53",
        }).then((res) => {
          if (res.isConfirmed) {
            this.$router.go();
          }
        });
      } catch (e) {
        if (e.response.status == 400) {
          this.isdiabled = false;
          this.$swal({
            title: "<span style='font-size:13px !important;color:red'>Failed</span>",
            text: "Alert has not been resolved",

            width: "300px",
            confirmButtonColor: "#238f53",
          }).then();
        } else if (e.response.status == 500) {
          this.isdiabled = false;
          this.$swal({
            title: "<span style='font-size:13px !important;color:red'>Failed</span>",
            text: "An error occur",

            width: "300px",
            confirmButtonColor: "#238f53",
          }).then();
        }
      }
    },
    async assignSubmit() {
      this.isdisabled = true;
      const data = {
        responseTeam: this.respId,
        alertId: this.alertid,
      };
      try {
        const response = await axios.post("v1/admin/assign_response_team_alert", data);
        this.status = false;
        var button = document.getElementById("closeme5");
        button.click();
        this.$swal({
          title: "<span style='font-size:13px !important;color:#238f53'>Success</span>",
          text: response.data.result.message,

          width: "300px",
          confirmButtonColor: "#238f53",
        }).then((res) => {
          if (res.isConfirmed) {
            this.$router.go();
          }
        });
      } catch (e) {
        if (e.response.status == 400) {
          var buttons = document.getElementById("closeme5");
          buttons.click();
          this.$swal({
            title: "<span style='font-size:13px !important;color:red'>Failed</span>",
            text: "User has been assigned for an alert",

            width: "300px",
            confirmButtonColor: "#238f53",
          });
          this.isdisabled = false;
        } else if (e.response.status == 500) {
          var buttonss = document.getElementById("closeme5");
          buttonss.click();
          this.$swal({
            title: "<span style='font-size:13px !important;color:red'>Failed</span>",
            text: "An error occur",

            width: "300px",
            confirmButtonColor: "#238f53",
          });
          this.isdisabled = false;
        }
      }
    },
    getvalres(item) {
      this.searchres = item.firstName + " " + item.lastName;

      this.getres = false;
    },
    hidegetres() {
      this.getres = !this.getres;
    },
    closeModalOpen() {
      this.status = false;
    },
    showModalOpen(item, id) {
      this.slectedcoordslat = item.location.coordinates[1];
      this.slectedcoordslag = item.location.coordinates[0];
      if (id == 2) {
        this.current = { lat: this.slectedcoordslat, lng: this.slectedcoordslag };
      } else {
        this.current = { lat: this.slectedcoordslag, lng: this.slectedcoordslat };
      }
      if (id == 2) {
        this.selectedlevel = item.alertTypes[0].level;
      }

      this.selectedMarker = item;
      this.status = true;
      this.alertid = item._id;
    },
    async getlgas(state) {
      (this.lat = state.coordinates.lat), (this.lag = state.coordinates.lng);
      this.selectedstates = this.lga[state.id];
      this.exactstate = state.name;
      this.choosenstate = state.name;
      this.mystate = state.name;
      try {
        let response = await axios.get(`v1/admin/get_all_sos?state=${state.name}`);
        // console.log(response);

        this.totalpage = response.data.result.data.length;
      } catch (e) {
        console.log(e);
      }
      try {
        let resp = await axios.get(`v1/admin/alert_analytics?state=${state.name}`);

        this.analytics = resp.data.result.data;
        this.moststate = this.analytics.alertsWithMostState;
        this.noofAlert = this.analytics.noOfAlert;
        this.noofalertlevel = this.analytics.noOfAlertLevel;
        this.pending = this.noofAlert[0] != undefined ? this.noofAlert[0].count : 0;
        this.cancelled = this.noofAlert[1] != undefined ? this.noofAlert[1].count : 0;
        this.progress = this.noofAlert[2] != undefined ? this.noofAlert[2].count : 0;
        this.resolved = this.noofAlert[3] != undefined ? this.noofAlert[3].count : 0;
      } catch (e) {
        console.log(e);
      }
    },
    selectedstate(stt) {
      this.state = stt;
    },
  },

  async created() {
    try {
      let response = await axios.get(`v1/admin/get_all_sos `);
      // console.log(response);

      this.totalpage = response.data.result.data.length;
    } catch (e) {
      console.log(e);
    }
    try {
      let response = await axios.get(`v1/admin/get_all_alert?status=pending `);

      this.alerts = response.data.result.data.data;
      console.log(response);
    } catch (e) {
      console.log(e);
    }

    try {
      let response = await axios.get(`v1/admin/get_all_sos?status=pending`);
      this.notification = response.data.result.data;
    } catch (e) {
      console.log(e);
    }

    try {
      let response = await axios.get(`v1/get_states`);

      this.lga = response.data.result.data.lgas;
      this.states = response.data.result.data.state;
    } catch (e) {
      console.log(e);
    }
    try {
      let response = await axios.get(
        `v1/admin/get_all_sos${
          this.adminrole == "admin" ? `?state=${this.adminstate}` : ""
        } `
      );
      // console.log(response);

      this.totalpage = response.data.result.data.length;
    } catch (e) {
      console.log(e);
    }

    /* try {
      let resp = await axios.get(
        `v1/user/get_all_response_team${
          this.adminrole == "admin" ? `?state=${this.adminstate}` : ""
        }`
      );
      this.responseTeam = resp.data.result.data;
     
    } catch (e) {
      this.$swal({
        text: e.message,

        width: "300px",
        confirmButtonColor: "#238f53",
      });
    }
    */

    try {
      let resp = await axios.get(`v1/admin/alert_analytics`);

      this.analytics = resp.data.result.data;
      this.moststate = this.analytics.alertsWithMostState;
      this.noofAlert = this.analytics.noOfAlert;
      this.noofalertlevel = this.analytics.noOfAlertLevel;
      this.pending = this.noofAlert[0] != undefined ? this.noofAlert[0].count : 0;
      this.cancelled = this.noofAlert[1] != undefined ? this.noofAlert[1].count : 0;
      this.progress = this.noofAlert[2] != undefined ? this.noofAlert[2].count : 0;
      this.resolved = this.noofAlert[3] != undefined ? this.noofAlert[3].count : 0;
      console.log(this.noofAlert);
      this.loading = false;
    } catch (e) {
      console.log(e);
    }
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),

    filterStates: function () {
      return this.states.filter((item) => {
        return item.name.match(this.search.toUpperCase());
      });
    },
    filterSOS: function () {
      return this.notification.filter((item) => {
        return item.state.match(this.mystate);
      });
    },
    filternofHL: function () {
      return this.noofalertlevel.filter((item) => {
        return item.level.match("HL");
      });
    },
    filternofLL: function () {
      return this.noofalertlevel.filter((item) => {
        return item.level.match("LL");
      });
    },
    filternofML: function () {
      return this.noofalertlevel.filter((item) => {
        return item.level.match("ML");
      });
    },
    filternofCL: function () {
      return this.noofalertlevel.filter((item) => {
        return item.level.match("CL");
      });
    },

    filternoresolve: function () {
      return this.noofAlert.filter((item) => {
        return item.status.match("resolve");
      });
    },
    filternopending: function () {
      return this.noofAlert.filter((item) => {
        return item.status.match("pending");
      });
    },
    filternoprogress: function () {
      return this.noofAlert.filter((item) => {
        return item.status.match("in-progress");
      });
    },
    filterProgress: function () {
      return this.alerts.filter((item) => {
        return item.status.match("in-progress");
      });
    },
    filterResolved: function () {
      return this.alerts.filter((item) => {
        return item.status.match("resolved");
      });
    },
    filterAllAlert: function () {
      return this.alerts.filter((item) => {
        return item.state.match("");
      });
    },
    filterName: function () {
      return this.responseTeam.filter((item) => {
        return item.firstName.match(this.searchres);
      });
    },
  },
};
</script>
<style scoped>
main {
  background: #f5f6f7;
  height: 100vh;
  overflow: auto;
}
.rowsm {
  flex: 2;
  border-right: 1px solid #c9d9e5;
  padding: 15px;
}
.rowsm2 {
  flex: 8;
}
ul {
  padding: 0px !important;
  border-bottom: 1px solid #c9d9e5;
}
ul li {
  background: none !important;
  border: none;
  font-weight: 500 !important;
  margin-left: -18px;
}
.container {
  border-radius: 10px;
  padding: 10px;
  line-height: 20px;
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
}
.container h6 {
  font-size: 12px;
  color: #5c7597;
  align-items: center;
  display: flex;
  margin: 0px !important;
  padding: 0px !important;
}
.container span {
  font-size: 14px;
}
.bb {
  border-bottom: 1px solid #c9d9e5;
  margin-bottom: 5px;
}
.region {
  border-radius: 8px !important;
  border-color: #7993b1;
  text-align: center;
  width: 31%;
  color: #7993b1;
  font-size: 8px;
  padding: 5px 4px 5px 4px;
  align-content: center;
  display: flex;
  justify-content: center;
  float: left;
  margin: 2px;
}
select {
  padding: 8px;
  border: 1px solid #7993b1;
  border-radius: 10px;
  margin: 5px;
}
.dpr::-webkit-scrollbar {
  width: 2px;
}
.active {
  background: #238f53;
  color: #fff;
}
.center {
  height: 100vh;
  align-items: center;
  display: flex;
  width: 100vw;
  place-items: center;
  justify-content: center;
}

/* Track */
.dpr::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.dpr::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.dpr::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.search {
  width: 60%;
  border: none;
}
.bgmtt {
  width: 90%;
  margin: 0px auto;
}
.someExtraClass {
  background-color: aqua;
  padding: 10px;
  border: 1px solid #333;
  border-radius: 0 20px 20px 20px;
  box-shadow: 5px 3px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: auto !important;
  height: auto !important;
  margin: 0 !important;
}
.pin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: #00cae9;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
}
.pin:after {
  content: "";
  width: 14px;
  height: 14px;
  margin: 8px 0 0 8px;
  background: #e6e6e6;
  position: absolute;
  border-radius: 50%;
}

.bounce {
  animation-name: bounce;
  animation-fill-mode: both;
  animation-duration: 1s;
}

.pulse {
  background: #d6d4d4;
  border-radius: 50%;
  height: 14px;
  width: 14px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 11px 0px 0px -12px;
  transform: rotateX(55deg);
  z-index: -2;
}
.pulse:after {
  content: "";
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  margin: -13px 0 0 -13px;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
  box-shadow: 0 0 1px 2px #00cae9;
  animation-delay: 1.1s;
}

@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

@keyframes bounce {
  0% {
    opacity: 0;
    transform: translateY(-2000px) rotate(-45deg);
  }

  60% {
    opacity: 1;
    transform: translateY(30px) rotate(-45deg);
  }

  80% {
    transform: translateY(-10px) rotate(-45deg);
  }

  100% {
    transform: translateY(0) rotate(-45deg);
  }
}
.glow {
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
  box-shadow: 0 0 10px red, 0 0 3px red, 0 0 3px red, 0 0 3px red, 0 0 3px red;
}
@keyframes blink-animation {
  to {
    bax-shadow: 2px 3px 4px solid red;
    box-shadow: 0 0 20px red, 0 0 3px red, 0 0 3px red, 0 0 3px red, 0 0 3px red;
  }
}
@-webkit-keyframes blink-animation {
  to {
    bax-shadow: 2px 3px 4px solid red;
  }
}

.glow {
  position: relative;
  color: #fff;
  width: 30px;
  height: 30px;
  border: 0.02rem solid #9d1f1f;
  border-radius: 100%;
  font-size: 5em;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 20px #8fe70d0d, 0 0 3px #8fe70d0d, 0 0 3px #8fe70d0d, 0 0 3px #8fe70d0d,
    0 0 3px #8fe70d0d;
}

.modal {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.mdil div {
  display: flex !important;
  justify-content: space-between !important;
  padding: 3px !important;
  line-height: 35px;
  border: none !important;
  text-align: left !important;
}
.mdil div h6 {
  font-size: 12px;
  color: #7993b1;
}
.mdil div span {
  font-size: 12px;
}
.hl {
  position: relative;
  color: #fff;
  width: 30px;
  height: 30px;
  border: 0.02rem solid orange !important;
  border-radius: 100%;
  font-size: 5em;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 20px orange, 0 0 3px orange, 0 0 3px orange, 0 0 3px orange,
    0 0 3px orange;
}
.hl {
  animation: blinks-hl 1s steps(5, start) infinite;
  -webkit-animation: blinks-hl 1s steps(5, start) infinite;
  box-shadow: 0 0 10px orange, 0 0 3px orange, 0 0 3px orange, 0 0 3px orange,
    0 0 3px orange;
}
@keyframes blinks-hl {
  to {
    box-shadow: 0 0 20px orange, 0 0 3px orange, 0 0 3px orange, 0 0 3px orange,
      0 0 3px orange;
  }
}
@-webkit-keyframes blinks-hl {
  to {
    bax-shadow: 2px 3px 4px solid orange;
  }
}

.ml {
  position: relative;
  color: #fff;
  width: 30px;
  height: 30px;
  border: 0.02rem solid yellow !important;
  border-radius: 100%;
  font-size: 5em;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 20px yellow, 0 0 3px yellow, 0 0 3px yellow, 0 0 3px yellow,
    0 0 3px yellow;
}
.ml {
  animation: blinks-ml 1s steps(5, start) infinite;
  -webkit-animation: blinks-ml 1s steps(5, start) infinite;
  box-shadow: 0 0 10px yellow, 0 0 3px yellow, 0 0 3px yellow, 0 0 3px yellow,
    0 0 3px yellow;
}
@keyframes blinks-ml {
  to {
    box-shadow: 0 0 20px yellow, 0 0 3px yellow, 0 0 3px yellow, 0 0 3px yellow,
      0 0 3px yellow;
  }
}
@-webkit-keyframes blinks-ml {
  to {
    bax-shadow: 2px 3px 4px solid yellow;
  }
}
.ll {
  position: relative;
  color: #fff;
  width: 30px;
  height: 30px;
  border: 0.02rem solid #adf802 !important;
  border-radius: 100%;
  font-size: 5em;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 20px #adf802, 0 0 3px #adf802, 0 0 3px #adf802, 0 0 3px #adf802,
    0 0 3px #adf802;
}
.ll {
  animation: blinks-ll 1s steps(5, start) infinite;
  -webkit-animation: blinks-ll 1s steps(5, start) infinite;
  box-shadow: 0 0 10px #adf802, 0 0 3px #adf802, 0 0 3px #adf802, 0 0 3px #adf802,
    0 0 3px #adf802;
}
@keyframes blinks-ll {
  to {
    box-shadow: 0 0 20px #adf802, 0 0 3px #adf802, 0 0 3px #adf802, 0 0 3px #adf802,
      0 0 3px #adf802;
  }
}
@-webkit-keyframes blinks-ll {
  to {
    bax-shadow: 2px 3px 4px solid #adf802;
  }
}
.leaflet-container {
  height: 100% !important;
}
</style>
