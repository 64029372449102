<template>
  <div
    style="
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    "
  >
    <vue-loaders-ball-scale-multiple
      color="#238f53"
      scale="1"
    ></vue-loaders-ball-scale-multiple>
  </div>
</template>

<script>
export default {
  name: "Loader-Vue",
};
</script>
