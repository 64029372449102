<!---<template>
  <GMapMap :center="{ lat, lng }" :zoom="zoom" style="width: 100wv; height: 100vh">
    <GMapMarker :position="center" @click="changeZoom" />
    <GMapInfoWindow :opened="true"> </GMapInfoWindow>
  </GMapMap>
</template>
-->
<script>
export default {
  name: "MyMap",
  //  props: ["centers", "current"],
  /**
  data() {
    return {
      center: [],
      zoom: 19,
      current: [],
      lat: parseFloat(this.$route.params.lat),
      lng: parseFloat(this.$route.params.lng),
    };
  },

  methods: {
    changeZoom() {
      this.zoom = 20;
    },
  },
   */
  mounted() {
    const id = this.$route.params.id.replace(/hyphen/g, "_");
    console.log(id);
    window.location.href = `https://www.google.com/maps/search/?api=1&query=Google&query_place_id=${id}`;
    /*
    this.center = {
      lat: parseFloat(this.$route.params.lat),
      lng: parseFloat(this.$route.params.lng),
    };
    */

    console.log(this.center);
  },
};
</script>
