<template>
  <div class="w-100 app">
    <!-- Button trigger modal -->
    <div
      class="modal fade"
      id="exampleModalCenter"
      role="dialog"
      style="z-index: 2000; margin: 0px auto"
      aria-labelledby="exampleModalCenterTitle"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div
          class="modal-content"
          style="max-width: 350px; width: 100%; margin: 0px auto"
        >
          <div class="modal-header">
            <h5
              class="modal-title"
              id="exampleModalLongTitle"
              style="
                margin-top: 0px !important;
                margin-bottom: 0px !important;
                font-size: 14px;
              "
            >
              Incidence Details
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              id="closesmall"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="assignSubmit">
              <div class="form-group">
                <label for="exampleInputEmail1">Response Name</label>

                <div
                  class="btntab d-flex"
                  style="
                    border: 1px solid #f5f6f7;
                    justigy-content: left;
                    width: 100%;
                  "
                >
                  <input
                    type="text"
                    style="
                      width: 100%;
                      border: none !important;
                      outline: none;
                      color: #7993b1;
                      padding: 8px;
                      font-size: 12px;
                    "
                    placeholder="Enter a Team name"
                    v-model="searchres"
                    @focus="hidegetres"
                  />
                  <span
                    class="material-icons"
                    style="
                      display: flex;
                      align-self: center;
                      margin-right: 10px;
                      font-size: 14px;
                      color: #7993b1;
                    "
                    >search</span
                  >
                </div>

                <ul class="list-group mt-2 border-none" v-show="getres">
                  <!---  <li
                        class="list-group-item"
                        style="font-size: 12px"
                        v-for="item in filterName"
                        :key="item"
                        @click="getvalres(item)"
                      >
                        {{ item.firstName }} {{ item.lastName }}
                      </li>-->

                  <li
                    class="list-group-item border-none"
                    style="font-size: 12px; diplay: flex; align-items: center"
                    v-for="item in filterName"
                    :key="item._id"
                  >
                    <label class="classlist">
                      <input
                        class="form-check-input me-1"
                        type="checkbox"
                        :value="item._id"
                        aria-label="..."
                        v-model="respId"
                      />
                      <span class="checkmark"></span>

                      <label
                        class="form-check-label"
                        for="flexCheckCheckedDisabled"
                      >
                        {{ item.firstName }} {{ item.lastName }}
                      </label>
                    </label>
                  </li>
                </ul>
              </div>

              <div class="d-flex justify-content-between">
                <button
                  type="submit"
                  class="btn w-50 mr-2"
                  style="border: 2px solid #238f53; font-size: 12px"
                  data-toggle="modal"
                  data-target="#exampleModalLong"
                >
                  Go Back
                </button>
                <button
                  :disabled="isDisabled"
                  type="submit"
                  class="btn w-50 ml-2"
                  style="background: #238f53; color: #fff; font-size: 12px"
                >
                  Assign
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!--End Modal -->
    <!-- Modal -->
    <div
      @click="hideModalInfo"
      class="modal fade"
      id="exampleModal"
      role="dialog"
      aria-labelledby="exampleModalLabel"
    >
      <div
        class="modal-dialog"
        role="document"
        style="max-width: 460px !important; border-radius: 30px; z-index: 10"
      >
        <div
          class="modal-content"
          style="
            padding: 10px !important;
            border-radius: 10px;
            max-width: 400px;
          "
        >
          <div class="modal-header" style="border-bottom: none !important">
            <h5
              class="modal-title"
              id="exampleModalLabel"
              style="
                font-size: 12px;
                color: #334563;
                margin: 0px !important ;
                padding: 0px !important;
              "
            >
              Incident Details
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              id="closemebig"
            >
              <span aria-hidden="true" @click="hideModalInfo">&times;</span>
            </button>
          </div>
          <div
            class="modal-body"
            style="
              padding: 5px !important;
              border-top: none !important;
              border-bottom: none !important;
            "
          >
            <div class="d-flex justify-content-between mb-3">
              <div
                style="
                  background: #f2d5d5;
                  border-radius: 5px;
                  align-items: center;
                  display: flex;
                "
                :style="
                  selectedAlert.status == 'pending'
                    ? ' background: #f2d5d5 !important'
                    : ' background: #238f53 !important;color:#fff !important'
                "
                class="pr-2 pl-2 pt-2 pb-2"
              >
                <span
                  style="color: #9d1f1f; font-size: 12px"
                  v-if="selectedAlert.status == 'pending'"
                  >{{ selectedAlert.status }}</span
                >
                <span
                  style="color: hsl(0, 0%, 100%); font-size: 12px"
                  v-if="selectedAlert.status == 'resolved'"
                  >{{ selectedAlert.status }}</span
                >
              </div>
            </div>
            <div class="w-100" style="height: 180px">
              <Map :centers="center" :current="current" />
              <!--
                <l-map
                  :zoom="zoom"
                  :max-zoom="19"
                  :center="{ lat: lat, lng: lag }"
                  :bound="zoom"
                  style="width: 100%"
                >
                  <l-tile-layer :url="url" :attribution="attribution" />

                  <l-tile-layer :url="url" :attribution="attribution" />

                  <l-marker
                    :lat-lng="[
                      slectedcoordslat == 0 ? 0 : slectedcoordslat,
                      slectedcoordslag == 0 ? 0 : slectedcoordslag,
                    ]"
                    v-model="zoom"
                  >
                    <l-icon :icon-anchor="staticAnchor" class-name="someExtraClass">
                      <div class="glow" v-if="selectedlevel == 'CL'">
                        <div
                          style="
                            width: 10px;
                            height: 10px;
                            background: #9d1f1f;
                            border-radius: 100%;
                          "
                        ></div>
                      </div>
                      <div
                        class="hl"
                        v-if="selectedlevel == 'HL'"
                        style="border: 1px solid #ffcc99"
                      >
                        <div
                          style="
                            width: 10px;
                            height: 10px;
                            background: #ffcc99;
                            border-radius: 100%;
                          "
                        ></div>
                      </div>
                      <div
                        class="ml"
                        v-if="selectedlevel == 'ML'"
                        style="border: 1px solid #ffcc99"
                      >
                        <div
                          style="
                            width: 10px;
                            height: 10px;
                            background: yellow;
                            border-radius: 100%;
                          "
                        ></div>
                      </div>
                      <div
                        class="ll"
                        v-if="selectedlevel == 'LL'"
                        style="border: 1px solid #ffcc99"
                      >
                        <div
                          style="
                            width: 10px;
                            height: 10px;
                            background: yellow;
                            border-radius: 100%;
                          "
                        ></div>
                      </div>
                    </l-icon>
                  </l-marker>
                </l-map>
                -->
            </div>
            <div class="d-flex justify-content-between mt-3">
              <h5 style="color: #161f2e; font-size: 12px">
                <span v-for="(n, i) in selectedAlert?.alertTypes" :key="i">
                  {{ n.type }}
                  ({{ n.level }})
                </span>
              </h5>
              <span style="font-size: 12px; color: #e2b93b">{{
                selectedAlert.status
              }}</span>
            </div>
            <ul class="list-group mdil">
              <li
                class="list-group-item d-flex justify-content"
                style="align-items: center"
                data-toggle="tooltip"
                data-placement="top"
                :title="`STATE: ${selectedAlert.userState}`"
              >
                <h6>Sender</h6>
                <span v-for="(n, i) in selectedAlert?.user" :key="i">
                  {{ n.firstName }}
                  {{ n.lastName }}
                </span>
              </li>

              <li
                class="list-group-item d-flex justify-content"
                style="align-items: center"
              >
                <h6>Date/Time</h6>
                <span>{{
                  moment(selectedAlert.CreatedAt).format("DD-MM-YYYY h:s:a")
                }}</span>
              </li>

              <li
                class="list-group-item d-flex justify-content"
                style="align-items: center"
              >
                <h6>Address</h6>
                <span>{{ selectedAlert.address }}</span>
              </li>
              <li
                class="list-group-item d-flex justify-content"
                style="align-items: center"
              >
                <h6>ID</h6>
                <span>{{ selectedAlert._id }}</span>
              </li>
              <li
                class="list-group-item d-flex justify-content"
                style="align-items: center"
              >
                <h6>Description</h6>
                <span>No description</span>
              </li>
            </ul>
          </div>

          <div
            class="d-flex justify-content-between mt-2 mb-2"
            v-if="selectedAlert.Status == 'pending'"
          >
            <div class="d-grid" style="line-height: 15px">
              <h6
                style="
                  color: #c9d9e5;
                  font-size: 11px;
                  padding: 2px !important;
                  margin: 2px !important;
                "
              >
                Incident has not been assigned
              </h6>
              <span style="color: #161f2e; font-size: 12px"
                >Assign Response Team</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-100">
      <!-- <Header /> -->
      <main class="p-3 bg-home-main position-relative">
        <div class="w-100">
          <div class="w-100">
            <!-- Admin ALert-->

            <div
              id="content"
              ref="printcontent"
              style="
                position: absolute;
                box-sizing: border-box;

                overflow: hidden;
                right: 25px;
                left: 25px;
                padding: 10px;
                top: 30px;
              "
            >
              <div class="w-100 bg-white p-3 mt-4 conbg slide1" id="content">
                <div class="d-flex exptime">
                  <span class="font-weight:600">Today </span>
                  <span
                    class="material-icons"
                    style="font-size: 11px; align-items: center; display: flex"
                    >expand_more</span
                  >
                </div>
                <div class="d-flex justify-content-between mt-3">
                  <div class="container" style="background: #ebf6f3">
                    <span>Total no. of alerts</span>
                    <h5>
                      {{ parseInt(totalalerts) }}
                    </h5>
                  </div>
                  <div class="container" style="background: #f5f6f7">
                    <span>Total no. of unassigned</span>
                    <h5>{{ parseInt(totalpagepending) }}</h5>
                  </div>
                </div>
                <div class="d-flex justify-content-between">
                  <div class="container" style="background: #ebf3ff">
                    <span>Total no. of resolved</span>
                    <h5>{{ parseInt(totalpagereesolved) }}</h5>
                  </div>
                  <div class="container" style="background: #fdf6da">
                    <span>Total no. of unresolved</span>
                    <h5>{{ parseInt(totalpagepending) }}</h5>
                  </div>
                  <!--
                  <div class="container" style="background: #f2d5d5">
                    <span>Total no. of alert by category</span>
                    <h5>
                      {{
                        parseInt(pending) +
                        parseInt(cancelled) +
                        parseInt(resolved) +
                        parseInt(progress)
                      }}
                    </h5>
                  </div>
                  <div class="container" style="background: #e4dffe">
                    <span>Total no. of alert by type</span>
                    <h5>
                      {{
                        parseInt(pending) +
                        parseInt(cancelled) +
                        parseInt(resolved) +
                        parseInt(progress)
                      }}
                    </h5>
                  </div>
                  -->
                </div>
              </div>
              <div class="d-flex mt-4 justify-content-between p-1">
                <div class="conflex1">
                  <div style="flex: 1">
                    <div
                      style="
                        display: flex;
                        align-items: center;
                        font-size: 13px;
                        font-weight: 500;
                        margin-left: 10px;
                      "
                    >
                      Filter By:
                    </div>
                  </div>
                  <div
                    style="
                      flex: 9;

                      display: flex;
                      justify-content: left;
                    "
                  >
                    <div class="dropdown">
                      <button
                        class="btn dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Date
                      </button>
                      <div
                        class="dropdown-menu dtaedropdown"
                        aria-labelledby="dropdownMenuButton"
                        style="box-sizing: border-box"
                      >
                        <div class="float-left">
                          <label for="From" style="display: block">From</label>
                          <input
                            type="date"
                            style="width: 100%"
                            v-model="from"
                          />
                        </div>
                        <div class="float-right">
                          <label for="To" style="display: block">To</label>
                          <input
                            type="date"
                            style="width: 100%"
                            v-model="to"
                            @change="getto(selecteds)"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="dropdown">
                      <button
                        class="btn btn-secondary dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {{ choosenstate }}
                      </button>
                      <div
                        class="dropdown-menu dgggd"
                        aria-labelledby="dropdownMenuButton"
                        style="
                          height: 300px !important;
                          overflow: auto;
                          width: 100%;
                        "
                      >
                        <a
                          class="dropdown-item"
                          style="
                            width: 100%;

                            font-size: 12px;
                          "
                          href="#"
                          v-for="item in filterStates"
                          :key="item.id"
                          @click="getlgas(item.id, item.name, selected)"
                          >{{ item.name }}</a
                        >
                      </div>
                    </div>
                    <div class="dropdown">
                      <button
                        class="btn btn-secondary dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {{ choosenLga }}
                      </button>
                      <div
                        class="dropdown-menu dgggd"
                        aria-labelledby="dropdownMenuButton"
                        style="
                          height: 300px !important;
                          overflow: auto;
                          width: 100%;
                        "
                      >
                        <a
                          class="dropdown-item"
                          href="#"
                          v-for="item in selectedstates"
                          :key="item.id"
                          style="font-size: 12px"
                          @click="lgaget(item.name, selecteds)"
                        >
                          {{ item.name }}</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="conflex2">
                  <a
                    href="javascript:void()"
                    type="button"
                    class="float-right"
                    style="
                      color: #c02a2a;
                      font-weight: 600;
                      font-size: 14px;
                      line-height: 25.6px;
                      text-decoration: none;
                      margin-right: 10px;
                    "
                    @click="resetfilter(selecteds)"
                    >Reset Filter</a
                  >
                </div>
              </div>

              <div
                class="w-100 bg-white p-3 mt-1 conbg slide1 h-100 position-relative"
                style="height: 100% !important; min-height: 1400px"
              >
                <div class="w-100" style="height: 1100px !impotant">
                  <div class="float-left w-50">
                    <TabNav
                      :tabs="['Pending', 'Resolved']"
                      @selected="setSelected2"
                    >
                      <Tab :isselected="selecteds === 'Pending'">
                        <div
                          style="
                            position: absolute;
                            box-sizing: border-box;

                            overflow: hidden;
                            right: 25px;
                            left: 25px;
                            padding: 10px;
                          "
                        >
                          <table class="table mt-4">
                            <thead>
                              <tr>
                                <th scope="col">Alert Type</th>
                                <th scope="col">Severity</th>
                                <th scope="col">Address</th>
                                <th scope="col">Sender</th>
                                <th scope="col">Lat,Lng</th>

                                <th scope="col">Date/Time</th>
                                <th scope="col">Status</th>
                              </tr>
                            </thead>

                            <tbody v-if="notification.length > 0">
                              <tr
                                style="cursor: pointer"
                                @click="showModalInfo(item)"
                                :data-toggle="showModal ? 'modal' : ''"
                                :data-target="showModal ? '#exampleModal' : ''"
                                v-for="(item, index) in notification"
                                :key="index"
                              >
                                <td>
                                  <span
                                    v-for="(n, i) in item.alertTypes"
                                    :key="i"
                                  >
                                    {{ n.type }}
                                  </span>
                                </td>
                                <td>
                                  <span
                                    v-for="(n, i) in item.alertTypes"
                                    :key="i"
                                  >
                                    {{ n.level }}
                                  </span>
                                </td>
                                <td>{{ item.address }}</td>

                                <td>
                                  <span v-for="(n, i) in item.user" :key="i">
                                    {{ n.firstName }} {{ n.lastName }}
                                  </span>
                                </td>

                                <td>
                                  {{ item.location?.coordinates[0] }}
                                  {{ item.location?.coordinates[1] }}
                                </td>

                                <td>
                                  {{
                                    moment(item.createdAt).format(
                                      "DD-MM-YYYY h:ma"
                                    )
                                  }}
                                </td>
                                <td>
                                  <span
                                    style="
                                      background: #f2d5d5 !important;
                                      color: #9d1f1f !important;
                                    "
                                    class="status"
                                    >{{ item.status }}</span
                                  >
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div
                            style="
                              align-items: center;
                              display: grid;
                              height: 20vh;
                            "
                            v-if="notification.length == 0 && loading == false"
                          >
                            <h5 style="text-align: center; width: 100%">
                              No Pending Alert
                            </h5>
                          </div>
                          <div
                            style="
                              height: 200px;
                              width: 100%;
                              text-align: center;
                              justify-content: center;
                              align-items: center;
                            "
                            v-if="loading == true"
                          >
                            Getting Data....
                          </div>
                          <div
                            style="
                              width: 100%;
                              margin-top: 20px;
                              display: flex;
                              justify-content: space-between;
                              align-items: center;
                            "
                            v-if="notification.length > 0"
                          >
                            <span
                              >{{ page }}-
                              {{ parseInt(totalpagepending / 20) + 1 }} of
                              {{ totalpagepending }}</span
                            >
                            <nav aria-label="Page navigation example">
                              <ul class="pagination">
                                <li
                                  class="page-item"
                                  @click="previousPage"
                                  :class="page > 1 ? 'active' : null"
                                  :aria-disabled="page == 1 ? true : false"
                                >
                                  <a
                                    class="page-link"
                                    href="javascript:void()"
                                    aria-label="Previous"
                                  >
                                    <span aria-hidden="true">&laquo;</span>
                                    <span class="sr-only">Previous</span>
                                  </a>
                                </li>

                                <li
                                  class="page-item"
                                  @click="nextPage"
                                  :class="
                                    hasNextpagepending == true ? 'active' : ''
                                  "
                                  :aria-disabled="
                                    hasNextpagepending == true ? true : false
                                  "
                                >
                                  <a
                                    class="page-link"
                                    href="javascript:void()"
                                    aria-label="Next"
                                  >
                                    <span aria-hidden="true">&raquo;</span>
                                    <span class="sr-only">Next</span>
                                  </a>
                                </li>
                              </ul>
                            </nav>
                            <div class="d-flex" style="align-items: centet">
                              <label
                                style="
                                  font-size: 13px;
                                  font-weight: 500;
                                  margin-right: 10px;
                                "
                                >Rows per page</label
                              >
                              <select
                                v-model="eachpage"
                                @change="seteachpage(eachpage)"
                              >
                                <option :value="5">5</option>
                                <option :value="10">10</option>

                                <option :value="15">15</option>
                                <option :value="20" selected>20</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </Tab>
                      <Tab :isselected="selecteds === 'Resolved'">
                        <div
                          style="
                            position: absolute;
                            box-sizing: border-box;

                            overflow: hidden;
                            right: 25px;
                            left: 25px;
                            padding: 10px;
                          "
                        >
                          <table class="table mt-4">
                            <thead>
                              <tr>
                                <th scope="col">Alert Type</th>
                                <th scope="col">Severity</th>
                                <th scope="col">Address</th>
                                <th scope="col">Sender</th>
                                <th scope="col">Lat,Lng</th>

                                <th scope="col">Date/Time</th>
                                <th scope="col">Status</th>
                              </tr>
                            </thead>
                            <tbody v-if="notifications.length > 0">
                              <tr
                                style="cursor: pointer"
                                @click="showModalInfo(item)"
                                :data-toggle="showModal ? 'modal' : ''"
                                :data-target="showModal ? '#exampleModal' : ''"
                                v-for="(item, index) in notifications"
                                :key="index"
                              >
                                <td>
                                  <span
                                    v-for="(n, i) in item.alertTypes"
                                    :key="i"
                                  >
                                    {{ n.type }}
                                  </span>
                                </td>
                                <td>
                                  <span
                                    v-for="(n, i) in item.alertTypes"
                                    :key="i"
                                  >
                                    {{ n.level }}
                                  </span>
                                </td>
                                <td>{{ item.address }}</td>

                                <td>
                                  <span v-for="(n, i) in item.user" :key="i">
                                    {{ n.firstName }} {{ n.lastName }}
                                  </span>
                                </td>

                                <td>
                                  {{ item.location?.coordinates[0] }}
                                  {{ item.location?.coordinates[1] }}
                                </td>

                                <td>
                                  {{
                                    moment(item.createdAt).format(
                                      "DD-MM-YYYY h:ma"
                                    )
                                  }}
                                </td>
                                <td>
                                  <span
                                    style="
                                      background: #238f53 !important;
                                      color: #fff !important;
                                    "
                                    class="status"
                                    >{{ item.status }}</span
                                  >
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div
                            style="
                              align-items: center;
                              display: grid;
                              height: 20vh;
                            "
                            v-if="notifications.length == 0"
                          >
                            <h5 style="text-align: center; width: 100%">
                              No Resolved Alert
                            </h5>
                          </div>
                          <div
                            style="
                              width: 100%;
                              margin-top: 20px;
                              display: flex;
                              justify-content: space-between;
                              align-items: center;
                            "
                            v-if="notifications.length > 0"
                          >
                            <span
                              >{{ pager }}-
                              {{ parseInt(totalpagereesolved / 20) + 1 }} of
                              {{ totalpagereesolved }}</span
                            >
                            <nav aria-label="Page navigation example">
                              <ul class="pagination">
                                <li
                                  class="page-item"
                                  @click="previousPageResolved"
                                  :class="pager > 1 ? 'active' : ''"
                                  :aria-disabled="
                                    hasPrevpageresolved == false ? false : true
                                  "
                                >
                                  <a
                                    class="page-link"
                                    href="javascript:void()"
                                    aria-label="Previous"
                                  >
                                    <span aria-hidden="true">&laquo;</span>
                                    <span class="sr-only">Previous</span>
                                  </a>
                                </li>

                                <li
                                  class="page-item"
                                  @click="nextPageResolved"
                                  :class="
                                    hasNextpageresolved == true ? 'active' : ''
                                  "
                                  :aria-disabled="
                                    hasNextpageresolved == false ? false : true
                                  "
                                >
                                  <a
                                    class="page-link"
                                    href="javascript:void()"
                                    aria-label="Next"
                                  >
                                    <span aria-hidden="true">&raquo;</span>
                                    <span class="sr-only">Next</span>
                                  </a>
                                </li>
                              </ul>
                            </nav>
                            <div class="d-flex" style="align-items: centet">
                              <label
                                style="
                                  font-size: 13px;
                                  font-weight: 500;
                                  margin-right: 10px;
                                "
                                >Rows per page</label
                              >
                              <select
                                v-model="eachpage"
                                @change="seteachpager(eachpage)"
                              >
                                <option :value="5">5</option>
                                <option :value="10">10</option>

                                <option :value="15">15</option>
                                <option :value="20" selected>20</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </Tab>
                    </TabNav>
                  </div>
                </div>
              </div>
            </div>

            <!-- End -->
            <!--
            <div class="w-50" style="float: left">
              <TabNav
                :tabs="['Analytics', 'Alerts']"
                :selected="selected"
                @selected="setSelected"
              >
                <Tab :isselected="selected === 'Analytics'">
                  <div
                    style="
                      position: absolute;
                      box-sizing: border-box;
                      overflow: hidden;
                      right: 25px;
                      left: 25px;
                      padding: 10px;
                    "
                    ref="printcontent"
                  >
                    <div class="w-100 bg-white p-3 mt-4 conbg slide1" id="content">
                      <div class="d-flex exptime">
                        <span class="font-weight:600">Today </span>
                        <span
                          class="material-icons"
                          style="font-size: 11px; align-items: center; display: flex"
                          >expand_more</span
                        >
                      </div>
                      <div class="d-flex justify-content-between mt-3">
                        <div class="container" style="background: #ebf6f3">
                          <span>Total no. of alert</span>
                          <h5>
                            {{
                              parseInt(pending) +
                              parseInt(cancelled) +
                              parseInt(resolved) +
                              parseInt(progress)
                            }}
                          </h5>
                        </div>
                        <div class="container" style="background: #f5f6f7">
                          <span>Total no. of unassigned</span>
                          <h5>{{ parseInt(pending) }}</h5>
                        </div>
                        <div class="container" style="background: #fdf6da">
                          <span>Total no. of unresolved</span>
                          <h5>
                            {{ parseInt(pending) }}
                          </h5>
                        </div>
                      </div>
                      <div class="d-flex justify-content-between">
                        <div class="container" style="background: #ebf3ff">
                          <span>Total no. of resolved</span>
                          <h5>{{ parseInt(resolved) }}</h5>
                        </div>
                        <div class="container" style="background: #f2d5d5">
                          <span>Total no. of alert by category</span>
                          <h5>
                            {{
                              parseInt(pending) +
                              parseInt(cancelled) +
                              parseInt(resolved) +
                              parseInt(progress)
                            }}
                          </h5>
                        </div>
                        <div class="container" style="background: #e4dffe">
                          <span>Total no. of alert by type</span>
                          <h5>
                            {{
                              parseInt(pending) +
                              parseInt(cancelled) +
                              parseInt(resolved) +
                              parseInt(progress)
                            }}
                          </h5>
                        </div>
                      </div>
                    </div>

                    <div class="w-100 bg-white p-3 mt-1 conbg slide2">
                      <div v-if="loading === true" class="overflow-top-loading">
                        Getting data...
                      </div>

                      <div
                        v-else
                        style="
                          background: #f5f6f7;
                          height: 100%;
                          margin-left: 10px;
                          border-radius: 10px;
                        "
                      >
                        <div class="d-flex justify-content-between">
                          <div class="d-flex m-2">
                            <h5 style="font-size: 12px">Most Alert</h5>
                            <ul
                              class="d-flex justigy-content-between"
                              style="font-size: 12px"
                            >
                              <li
                                style="margin-right: 20px; cursor: pointer"
                                @click="getalertbyeach('current')"
                              >
                                Current Week
                              </li>
                              <li
                                style="margin-left: 20px; cursor: pointer"
                                @click="getalertbyeach('previous')"
                              >
                                Previous Week
                              </li>
                            </ul>
                          </div>
                          <div class="btn-group dropleft m-2 cursor-pointer">
                            <span
                              class="material-icons"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              style="cursor: pointer"
                              >more_vert</span
                            >
                            <div
                              class="dropdown-menu shadow-sm"
                              style="width: 100px !important"
                            >
                              <div
                                class="p-2 w-100 mm"
                                style="
                                  font-size: 12px;
                                  text-align: center;
                                  cursor: pointer;
                                "
                                @click="getalertbyeach('daily')"
                              >
                                Daily
                              </div>
                              <div
                                class="p-2 w-100 mm"
                                style="
                                  font-size: 12px;
                                  text-align: center;
                                  cursor: pointer;
                                "
                                @click="getalertbyeach('weekly')"
                              >
                                Weekly
                              </div>
                              <div
                                class="p-2 w-100 mm"
                                style="
                                  font-size: 12px;
                                  text-align: center;
                                  cursor: pointer;
                                "
                                @click="getalertbyeach('monthly')"
                              >
                                Monthly
                              </div>
                            </div>
                          </div>
                        </div>

                        <line-chart
                          :legend="false"
                          width="100%"
                          height="200px"
                          :data="data"
                          :curve="true"
                        />
                      </div>
                    </div>

                    <div
                      class="w-100 bg-white p-3 mt-1 conbg d-flex justify-content-between slide3"
                    >
                      <div
                        v-if="loading === true"
                        class="overflow-top-loading"
                        style="height: 350px"
                      >
                        Getting data...
                      </div>
                      <div
                        v-else
                        class="w-50 d-grid"
                        style="
                          background: #f5f6f7;

                          margin-right: 10px;
                          border-radius: 10px;
                        "
                      >
                        <h5 class="m-2" style="font-size: 14px">
                          Most Polarised Location
                        </h5>

                        <column-chart
                            :legend="false"
                            :stacked="true"
                            :grouped="true"
                            :dataset="{
                              radius: 100,
                              width: 5,
                              borderWidth: 1,
                              maxWidth: 4,
                              outerWidth: 5,
                              lineWidth: 10,
                            }"
                            :data="data"
                            :library="{
                              barThickness: 25,
                              legend: { labels: { fontSize: 10 } },
                            }"
                          ></column-chart>

                        <div
                          class="w-100"
                          style="box-sizing: border-box; overflow: hidden; height: 80%"
                        >
                          <AlertBar :mydata="groupzone" :myhighest="highestTotal" />
                        </div>
                      </div>
                      <div
                        v-if="loading === true"
                        class="overflow-top-loading"
                        style="height: 350px"
                      >
                        Getting data....
                      </div>
                      <div
                        v-else
                        class="w-50"
                        style="
                          background: #f5f6f7;
                          height: 100%;
                          margin-left: 10px;
                          border-radius: 10px;
                        "
                      >
                        <h5 class="m-2" style="font-size: 14px">
                          Avg time of resolution
                        </h5>
                        <pie-chart
                          legend="right"
                          :dataset="{
                            borderWidth: 1,
                            cutoutPercentage: 5,
                            innerWidth: 0,
                            radius: 100,
                            legendWidth: 5,
                            innerWidth: 5,
                            innerHeight: 100,
                            backgroundColor: [
                              '#C9D9E5',
                              '#C9D9E5',
                              '#B1E5AF',
                              '#A6CAF9',
                              '#F5E5B4',
                              '#F5B0B0',
                            ],
                          }"
                          :library="{
                            backgroundColor: 'blue',
                            cutout: 100,
                            fontSize: 5,
                          }"
                          :donut="true"
                          :data="data"
                        ></pie-chart>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab :isselected="selected === 'Alerts'">
                  <div
                    style="
                      position: absolute;
                      box-sizing: border-box;

                      overflow: hidden;
                      right: 25px;
                      left: 25px;
                      padding: 10px;
                    "
                  >
                    <div class="d-flex mt-4 justify-content-between p-1">
                      <div class="conflex1">
                        <div style="flex: 1">
                          <div
                            style="
                              display: flex;
                              align-items: center;
                              font-size: 13px;
                              font-weight: 500;
                              margin-left: 10px;
                            "
                          >
                            Filter By:
                          </div>
                        </div>
                        <div
                          style="
                            flex: 9;

                            display: flex;
                            justify-content: left;
                          "
                        >
                          <div class="dropdown">
                            <button
                              class="btn dropdown-toggle"
                              type="button"
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              Date
                            </button>
                            <div
                              class="dropdown-menu dtaedropdown"
                              aria-labelledby="dropdownMenuButton"
                              style="box-sizing: border-box"
                            >
                              <div class="float-left">
                                <label for="From" style="display: block">From</label>
                                <input type="date" style="width: 100%" v-model="from" />
                              </div>
                              <div class="float-right">
                                <label for="To" style="display: block">To</label>
                                <input
                                  type="date"
                                  style="width: 100%"
                                  v-model="to"
                                  @change="getto(selecteds)"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="dropdown">
                            <button
                              class="btn btn-secondary dropdown-toggle"
                              type="button"
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              {{ choosenstate }}
                            </button>
                            <div
                              class="dropdown-menu dgggd"
                              aria-labelledby="dropdownMenuButton"
                              style="
                                height: 300px !important;
                                overflow: auto;
                                width: 100%;
                              "
                            >
                              <a
                                class="dropdown-item"
                                style="
                                  width: 100%;

                                  font-size: 12px;
                                "
                                href="#"
                                v-for="item in filterStates"
                                :key="item.id"
                                @click="getlgas(item.id, item.name, selecteds)"
                                >{{ item.name }}</a
                              >
                            </div>
                          </div>
                          <div class="dropdown">
                            <button
                              class="btn btn-secondary dropdown-toggle"
                              type="button"
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              {{ choosenLga }}
                            </button>
                            <div
                              class="dropdown-menu dgggd"
                              aria-labelledby="dropdownMenuButton"
                              style="
                                height: 300px !important;
                                overflow: auto;
                                width: 100%;
                              "
                            >
                              <a
                                class="dropdown-item"
                                href="#"
                                v-for="item in selectedstates"
                                :key="item.id"
                                style="font-size: 12px"
                                @click="lgaget(item.name, selecteds)"
                              >
                                {{ item.name }}</a
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="conflex2">
                        <a
                          href="javascript:void()"
                          type="button"
                          class="float-right"
                          style="
                            color: #c02a2a;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 25.6px;
                            text-decoration: none;
                            margin-right: 10px;
                          "
                          @click="resetfilter(selecteds)"
                          >Reset Filter</a
                        >
                      </div>
                    </div>
                    <div
                      class="w-100 bg-white p-3 mt-1 conbg slide1 h-100 position-relative"
                      style="height: 100% !important; min-height: 1400px"
                    >
                      <div class="w-100" style="height: 1100px !impotant">
                        <div class="float-left w-50">
                          <TabNav
                            :tabs="['Pending', 'Resolved']"
                            @selected="setSelected2"
                          >
                            <Tab :isselected="selecteds === 'Pending'">
                              <div
                                style="
                                  position: absolute;
                                  box-sizing: border-box;

                                  overflow: hidden;
                                  right: 25px;
                                  left: 25px;
                                  padding: 10px;
                                "
                              >
                                <table class="table mt-4" id="content" ref="printcontent">
                                  <thead>
                                    <tr>
                                      <th scope="col">Alert Type</th>
                                      <th scope="col">Severity</th>
                                      <th scope="col">Address</th>
                                      <th scope="col">Sender</th>
                                      <th scope="col">Lag/Lat</th>
                                      <th scope="col">PU</th>
                                      <th scope="col">Date/Time</th>
                                      <th scope="col">Status</th>
                                    </tr>
                                  </thead>

                                  <tbody v-if="notification.length > 0">
                                    <tr
                                      style="cursor: pointer"
                                      @click="showModalInfo(item)"
                                      :data-toggle="showModal ? 'modal' : ''"
                                      :data-target="showModal ? '#exampleModal' : ''"
                                      v-for="(item, index) in notification"
                                      :key="index"
                                    >
                                      <td>
                                        {{ item.AlertType }}
                                      </td>
                                      <td>
                                        {{ item.Severity }}
                                      </td>
                                      <td>{{ item.Address }}</td>

                                      <td>
                                        {{ item.SenderName }}
                                      </td>

                                      <td>{{ item.Lat }} {{ item.Lng }}</td>
                                      <td>
                                        {{ item.PU }}
                                      </td>
                                      <td>
                                        {{
                                          moment(item.DateTime).format("DD-MM-YYYY h:ma")
                                        }}
                                      </td>
                                      <td>
                                        <span class="status">{{ item.Status }}</span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <div
                                  style="align-items: center; display: grid; height: 20vh"
                                  v-if="notification.length == 0"
                                >
                                  <h5 style="text-align: center; width: 100%">
                                    No Pending Alert
                                  </h5>
                                </div>
                                <div
                                  style="
                                    width: 100%;
                                    margin-top: 20px;
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                  "
                                  v-if="notification.length > 0"
                                >
                                  <span
                                    >{{ page }}-
                                    {{ Math.round(totalpagepending / eachpage) }} of
                                    {{ totalpagepending }}</span
                                  >
                                  <nav aria-label="Page navigation example">
                                    <ul class="pagination">
                                      <li
                                        class="page-item"
                                        @click="previousPage"
                                        :class="page > 1 ? 'active' : null"
                                        :aria-disabled="page == 1 ? true : false"
                                      >
                                        <a
                                          class="page-link"
                                          href="javascript:void()"
                                          aria-label="Previous"
                                        >
                                          <span aria-hidden="true">&laquo;</span>
                                          <span class="sr-only">Previous</span>
                                        </a>
                                      </li>

                                      <li
                                        class="page-item"
                                        @click="nextPage"
                                        :class="
                                          hasNextpagepending == true ? 'active' : ''
                                        "
                                        :aria-disabled="
                                          hasNextpagepending == true ? true : false
                                        "
                                      >
                                        <a
                                          class="page-link"
                                          href="javascript:void()"
                                          aria-label="Next"
                                        >
                                          <span aria-hidden="true">&raquo;</span>
                                          <span class="sr-only">Next</span>
                                        </a>
                                      </li>
                                    </ul>
                                  </nav>
                                  <div class="d-flex" style="align-items: centet">
                                    <label
                                      style="
                                        font-size: 13px;
                                        font-weight: 500;
                                        margin-right: 10px;
                                      "
                                      >Rows per page</label
                                    >
                                    <select
                                      v-model="eachpage"
                                      @change="seteachpage(eachpage)"
                                    >
                                      <option :value="5">5</option>
                                      <option :value="10">10</option>

                                      <option :value="15">15</option>
                                      <option :value="20" selected>20</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </Tab>
                            <Tab :isselected="selecteds === 'Resolved'">
                              <div
                                style="
                                  position: absolute;
                                  box-sizing: border-box;

                                  overflow: hidden;
                                  right: 25px;
                                  left: 25px;
                                  padding: 10px;
                                "
                              >
                                <table class="table mt-4">
                                  <thead>
                                    <tr>
                                      <th scope="col">Alert Type</th>
                                      <th scope="col">Severity</th>
                                      <th scope="col">Address</th>
                                      <th scope="col">Sender</th>
                                      <th scope="col">Lag/Lat</th>
                                      <th scope="col">PU</th>
                                      <th scope="col">Date/Time</th>
                                      <th scope="col">Status</th>
                                    </tr>
                                  </thead>
                                  <tbody v-if="notifications.length > 0">
                                    <tr
                                      style="cursor: pointer"
                                      @click="showModalInfo(item)"
                                      :data-toggle="showModal ? 'modal' : ''"
                                      :data-target="showModal ? '#exampleModal' : ''"
                                      v-for="(item, index) in notifications"
                                      :key="index"
                                    >
                                      <td>
                                        {{ item.AlertType }}
                                      </td>
                                      <td>
                                        {{ item.Severity }}
                                      </td>
                                      <td>{{ item.Address }}</td>

                                      <td>
                                        {{ item.SenderName }}
                                      </td>

                                      <td>{{ item.Lat }} {{ item.Lng }}</td>
                                      <td>
                                        {{ item.PU }}
                                      </td>
                                      <td>
                                        {{
                                          moment(item.DateTime).format("DD-MM-YYYY h:ma")
                                        }}
                                      </td>
                                      <td>
                                        <span
                                          class="status"
                                          style="
                                            background: #238f53 !important;
                                            color: #fff !important;
                                          "
                                          >{{ item.Status }}</span
                                        >
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <div
                                  style="align-items: center; display: grid; height: 20vh"
                                  v-if="notifications.length == 0"
                                >
                                  <h5 style="text-align: center; width: 100%">
                                    No Resolved Alert
                                  </h5>
                                </div>
                                <div
                                  style="
                                    width: 100%;
                                    margin-top: 20px;
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                  "
                                  v-if="notifications.length > 0"
                                >
                                  <span
                                    >{{ pager }}-
                                    {{ Math.round(totalpagereesolved / eachpage) }} of
                                    {{ totalpagereesolved }}</span
                                  >
                                  <nav aria-label="Page navigation example">
                                    <ul class="pagination">
                                      <li
                                        class="page-item"
                                        @click="previousPageResolved"
                                        :class="hasPrevpageresolved ? 'active' : null"
                                        :aria-disabled="
                                          hasPrevpageresolved == false ? false : true
                                        "
                                      >
                                        <a
                                          class="page-link"
                                          href="javascript:void()"
                                          aria-label="Previous"
                                        >
                                          <span aria-hidden="true">&laquo;</span>
                                          <span class="sr-only">Previous</span>
                                        </a>
                                      </li>

                                      <li
                                        class="page-item"
                                        @click="nextPageResolved"
                                        :class="hasNextpageresolved ? 'active' : null"
                                        :aria-disabled="
                                          hasNextpageresolved == false ? false : true
                                        "
                                      >
                                        <a
                                          class="page-link"
                                          href="javascript:void()"
                                          aria-label="Next"
                                        >
                                          <span aria-hidden="true">&raquo;</span>
                                          <span class="sr-only">Next</span>
                                        </a>
                                      </li>
                                    </ul>
                                  </nav>
                                  <div class="d-flex" style="align-items: centet">
                                    <label
                                      style="
                                        font-size: 13px;
                                        font-weight: 500;
                                        margin-right: 10px;
                                      "
                                      >Rows per page</label
                                    >
                                    <select
                                      v-model="eachpage"
                                      @change="seteachpager(eachpage)"
                                    >
                                      <option :value="5">5</option>
                                      <option :value="10">10</option>

                                      <option :value="15">15</option>
                                      <option :value="20" selected>20</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </Tab>
                          </TabNav>
                        </div>

                        <div class="float-right" style="width: 30% !important">
                          <div
                            class="btntab d-flex"
                            style="
                              border-bottom: 1px solid #f5f6f7;
                              justigy-content: left;
                              width: 100%;
                            "
                          >
                            <span
                              class="material-icons"
                              style="
                                display: flex;
                                align-self: center;
                                margin-right: 10px;
                                font-size: 14px;
                                color: #7993b1;
                              "
                              >search</span
                            ><select
                              type="text"
                              v-model="searchs"
                              @change="searchUser(selecteds)"
                              style="
                                width: 100%;
                                border: none !important;
                                outline: none;
                                color: #7993b1;
                              "
                              placeholder="Search"
                            >
                              <option value="">Search by type</option>
                              <option
                                :value="item._id"
                                v-for="item in altypes"
                                :key="item"
                              >
                                {{ item.type }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
              </TabNav>
            </div>
            -->
            <div style="float: right; width: 50%">
              <div class="d-flex" style="justify-content: end">
                <div class="btntab d-flex" style="padding-right: 25px">
                  <!---
                    <button class="btn btn1" @click="printThis">Export as png</button>
                    --->
                  <button class="btn btn2" @click="downloadCSV('csv')">
                    Export as csv
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>
<script>
//import Header from "../components/header.vue";
// import SideBar from "../components/sidebar.vue";
import TabNav from "@/components/TabNav.vue";
import Tab from "@/components/tab.vue";
//import * as XLSX from "xlsx/xlsx.mjs";
//import AlertBar from "@/components/alertbar.vue";
import axios from "axios";
import moment from "moment";
import "leaflet/dist/leaflet.css";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Map from "../components/Map.vue";
import { mapGetters } from "vuex";
//import { LMap, LTileLayer, LMarker, LIcon } from "@vue-leaflet/vue-leaflet";
import { latLng, icon } from "leaflet/dist/leaflet-src.esm";
import { publicPath } from "@/config";

export default {
  name: "Report-App2",
  components: {
    //  SideBar,
    //  Header,
    TabNav,
    Tab,
    Map,
    // LMap,
    // LTileLayer,
    // LMarker,
    // LIcon,
    // AlertBar,
  },
  data() {
    return {
      showModal: false,
      start: "",
      end: new Date(),
      states: [],
      alerts: [],
      groupzone: [],
      totalalertcommon: 0,
      datazone: [],
      slectedcoordslat: 0,
      slectedcoordslag: 0,
      perpage: 20,
      zonedatas: [],
      totalpagereesolved: 0,
      totalpage: 0,
      responseTeam: [],
      serachs: "",
      hasPrevPage: false,
      hasPrevpageresolved: false,
      getres: false,
      respId: [],
      highestTotal: 0,
      current: [],
      isDisabled: false,
      lat: 0,
      notifications: [],
      notificationss: [],
      totalpagepending: 0,
      lag: 0,
      pager: 1,
      setinterval: "",
      pending: 0,
      resolved: 0,
      progress: 0,
      cancelled: 0,

      hasNextpage: 0,
      selectedlevel: [],
      selectedAlert: [],
      selectedstates: [],
      notification: [],
      hasNextpageresolved: false,
      loading: true,
      search: "",
      totalalert: 0,
      responseTeamId: "",
      notificationresolved: 0,
      totalalerts: 0,
      eachpage: 20,
      totalalertresolved: 0,
      totalalertstatus: 0,
      searchres: "",
      page: 1,
      searchs: "",
      center: latLng(8.9669, 4.3874),
      hasNextpagepending: 0,
      to: "",
      from: "",
      selecteds: "Pending",
      rolepublic: "public",
      moment: moment,
      choosenstate: "States",
      choosenLga: "LGA",
      zoom: 9,
      adminrole: "",
      altypes: [],
      adminstate: "",
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",

      icon: icon({
        iconUrl: "static/images/baseball-marker.png",
        iconSize: [32, 37],
        iconAnchor: [16, 37],
      }),
      staticAnchor: [16, 37],
      customText: "Foobar",
      iconSize: 64,
      selected: "Analytics",
      options: {
        legend: {
          display: false,
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem) {
              return tooltipItem.yLabel;
            },
          },
        },
      },
      zones: [
        {
          name: "North Central",
          data: {
            Benue: 0,
            Kogi: 0,
            Kwara: 0,
            Nasarawa: 0,
            Niger: 0,
            Plateau: 0,
            FCT: 0,
          },
        },
        {
          name: "North East",
          data: {
            Adamawa: 0,
            Bauchi: 0,
            Borno: 0,
            Gombe: 0,
            Taraba: 0,
            Yobe: 0,
          },
        },
        {
          name: "North West",
          data: {
            Jigawa: 0,
            Kaduna: 0,
            Kano: 0,
            Katsina: 0,
            kebbi: 0,
            Sokoto: 0,
            Zamfara: 0,
          },
        },
        {
          name: "South East",
          data: {
            Abia: 0,
            Anambra: 0,
            Ebonyi: 0,
            Enugu: 0,
            Imo: 0,
          },
        },
        {
          name: "South South",
          data: {
            "Akwa Ibom": 0,
            Bayelsa: 0,
            "Cross River": 0,
            Delta: 0,
            Edo: 0,
            Rivers: 0,
          },
        },
        {
          name: "South South",
          data: {
            Ekiti: 0,
            Lagos: 0,
            Ogun: 0,
            Osun: 0,
            Oyo: 0,
            Ondo: 0,
          },
        },
      ],
      zonedata: [],
      data: [],
    };
  },
  methods: {
    async getalertbyeach(id) {
      const date = new Date(); // current date modify it to your date

      if (id == "current") {
        const dayOfWeek = date.getDay();
        const startOfWeek = new Date(
          date.getTime() - dayOfWeek * 24 * 60 * 60 * 1000
        );
        startOfWeek.setHours(0, 0, 0, 0);
        const endOfWeek = new Date(
          date.getTime() + (6 - dayOfWeek) * 24 * 60 * 60 * 1000
        );
        endOfWeek.setHours(23, 59, 59, 999);
        this.start = startOfWeek;
        this.end = endOfWeek;

        try {
          let resp = await axios.get(
            `v1/admin/alert_state_chart?startDate=${this.start}&endDate=${this.end}`
          );
          this.zonedata = resp.data.result.data.noOfAlertZone;
          const data = {
            "North West":
              this.zonedata[0] != undefined ? this.zonedata[0].count : 0,
            "North Central":
              this.zonedata[2] != undefined ? this.zonedata[2].count : 0,
            "South West":
              this.zonedata[1] != undefined ? this.zonedata[1].count : 0,
            "North East":
              this.zonedata[3] != undefined ? this.zonedata[3].count : 0,
            "South South":
              this.zonedata[5] != undefined ? this.this.zonedata[5].count : 0,
            "South East":
              this.zonedata[4] != undefined ? this.zonedata[4].count : 0,
          };

          this.data = data;
          const datas = resp.data.result.data.groupedZone;

          const keys = Object.keys(datas);
          const newObj = {};
          const total = {};
          for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            const value = datas[key];
            value.forEach((item) => {
              if (!newObj[key]) newObj[key] = {};
              newObj[key][item.state] = item.count;
              if (!total[key]) total[key] = 0;
              total[key] += item.count;
            });
          }
          Object.keys(total).map((zone) => {
            newObj[zone].totalCount = total[zone];
          });
          this.groupzone = newObj;

          this.highestTotal += Math.max(...Object.values(total));
        } catch (e) {
          this.$swal({
            text: e.message,

            width: "300px",
            confirmButtonColor: "#238f53",
          });
        }
      } else if (id == "previous") {
        const dayOfWeek = date.getDay();
        const startOfPreviousWeek = new Date(
          date.getTime() - (7 + dayOfWeek) * 24 * 60 * 60 * 1000
        );
        startOfPreviousWeek.setHours(0, 0, 0, 0);
        const endOfPreviousWeek = new Date(
          date.getTime() - (dayOfWeek + 1) * 24 * 60 * 60 * 1000
        );
        endOfPreviousWeek.setHours(23, 59, 59, 999);

        this.start = startOfPreviousWeek;
        this.end = endOfPreviousWeek;

        try {
          let resp = await axios.get(
            `v1/admin/alert_state_chart?startDate=${this.start}&endDate=${this.end}`
          );
          this.zonedata = resp.data.result.data.noOfAlertZone;
          const data = {
            "North West":
              this.zonedata[0] != undefined ? this.zonedata[0].count : 0,
            "North Central":
              this.zonedata[2] != undefined ? this.zonedata[2].count : 0,
            "South West":
              this.zonedata[1] != undefined ? this.zonedata[1].count : 0,
            "North East":
              this.zonedata[3] != undefined ? this.zonedata[3].count : 0,
            "South South":
              this.zonedata[5] != undefined ? this.this.zonedata[5].count : 0,
            "South East":
              this.zonedata[4] != undefined ? this.zonedata[4].count : 0,
          };

          this.data = data;
          const datas = resp.data.result.data.groupedZone;

          const keys = Object.keys(datas);
          const newObj = {};
          const total = {};
          for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            const value = datas[key];
            value.forEach((item) => {
              if (!newObj[key]) newObj[key] = {};
              newObj[key][item.state] = item.count;
              if (!total[key]) total[key] = 0;
              total[key] += item.count;
            });
          }
          Object.keys(total).map((zone) => {
            newObj[zone].totalCount = total[zone];
          });
          this.groupzone = newObj;

          this.highestTotal += Math.max(...Object.values(total));
        } catch (e) {
          this.$swal({
            text: e.message,

            width: "300px",
            confirmButtonColor: "#238f53",
          });
        }
      } else if (id == "monthly") {
        const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
        const endOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        endOfMonth.setHours(23, 59, 59, 999);
        (this.satrt = startOfMonth), (this.end = endOfMonth);
        try {
          let resp = await axios.get(
            `v1/admin/alert_state_chart?startDate=${this.start}&endDate=${this.end}`
          );
          this.zonedata = resp.data.result.data.noOfAlertZone;
          const data = {
            "North West":
              this.zonedata[0] != undefined ? this.zonedata[0].count : 0,
            "North Central":
              this.zonedata[2] != undefined ? this.zonedata[2].count : 0,
            "South West":
              this.zonedata[1] != undefined ? this.zonedata[1].count : 0,
            "North East":
              this.zonedata[3] != undefined ? this.zonedata[3].count : 0,
            "South South":
              this.zonedata[5] != undefined ? this.this.zonedata[5].count : 0,
            "South East":
              this.zonedata[4] != undefined ? this.zonedata[4].count : 0,
          };

          this.data = data;
          const datas = resp.data.result.data.groupedZone;

          const keys = Object.keys(datas);
          const newObj = {};
          const total = {};
          for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            const value = datas[key];
            value.forEach((item) => {
              if (!newObj[key]) newObj[key] = {};
              newObj[key][item.state] = item.count;
              if (!total[key]) total[key] = 0;
              total[key] += item.count;
            });
          }
          Object.keys(total).map((zone) => {
            newObj[zone].totalCount = total[zone];
          });
          this.groupzone = newObj;

          this.highestTotal += Math.max(...Object.values(total));
        } catch (e) {
          this.$swal({
            text: e.message,

            width: "300px",
            confirmButtonColor: "#238f53",
          });
        }
      } else if (id == "weekly") {
        const dayOfWeek = date.getDay();
        const startOfWeek = new Date(
          date.getTime() - dayOfWeek * 24 * 60 * 60 * 1000
        );
        startOfWeek.setHours(0, 0, 0, 0);
        const endOfWeek = new Date(
          date.getTime() + (6 - dayOfWeek) * 24 * 60 * 60 * 1000
        );
        endOfWeek.setHours(23, 59, 59, 999);
        this.start = startOfWeek;
        this.end = endOfWeek;
        try {
          let resp = await axios.get(
            `v1/admin/alert_state_chart?startDate=${this.start}&endDate=${this.end}`
          );
          this.zonedata = resp.data.result.data.noOfAlertZone;
          const data = {
            "North West":
              this.zonedata[0] != undefined ? this.zonedata[0].count : 0,
            "North Central":
              this.zonedata[2] != undefined ? this.zonedata[2].count : 0,
            "South West":
              this.zonedata[1] != undefined ? this.zonedata[1].count : 0,
            "North East":
              this.zonedata[3] != undefined ? this.zonedata[3].count : 0,
            "South South":
              this.zonedata[5] != undefined ? this.this.zonedata[5].count : 0,
            "South East":
              this.zonedata[4] != undefined ? this.zonedata[4].count : 0,
          };

          this.data = data;
          const datas = resp.data.result.data.groupedZone;

          const keys = Object.keys(datas);
          const newObj = {};
          const total = {};
          for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            const value = datas[key];
            value.forEach((item) => {
              if (!newObj[key]) newObj[key] = {};
              newObj[key][item.state] = item.count;
              if (!total[key]) total[key] = 0;
              total[key] += item.count;
            });
          }
          Object.keys(total).map((zone) => {
            newObj[zone].totalCount = total[zone];
          });
          this.groupzone = newObj;

          this.highestTotal += Math.max(...Object.values(total));
        } catch (e) {
          this.$swal({
            text: e.message,

            width: "300px",
            confirmButtonColor: "#238f53",
          });
        }
      } else if (id == "daily") {
        this.start = new Date() - 12 * 60 * 60;
        this.end = new Date();
        try {
          let resp = await axios.get(
            `v1/admin/alert_state_chart?startDate=${this.start}&endDate=${this.end}`
          );
          this.zonedata = resp.data.result.data.noOfAlertZone;
          const data = {
            "North West":
              this.zonedata[0] != undefined ? this.zonedata[0].count : 0,
            "North Central":
              this.zonedata[2] != undefined ? this.zonedata[2].count : 0,
            "South West":
              this.zonedata[1] != undefined ? this.zonedata[1].count : 0,
            "North East":
              this.zonedata[3] != undefined ? this.zonedata[3].count : 0,
            "South South":
              this.zonedata[5] != undefined ? this.this.zonedata[5].count : 0,
            "South East":
              this.zonedata[4] != undefined ? this.zonedata[4].count : 0,
          };

          this.data = data;
          const datas = resp.data.result.data.groupedZone;

          const keys = Object.keys(datas);
          const newObj = {};
          const total = {};
          for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            const value = datas[key];
            value.forEach((item) => {
              if (!newObj[key]) newObj[key] = {};
              newObj[key][item.state] = item.count;
              if (!total[key]) total[key] = 0;
              total[key] += item.count;
            });
          }
          Object.keys(total).map((zone) => {
            newObj[zone].totalCount = total[zone];
          });
          this.groupzone = newObj;

          this.highestTotal += Math.max(...Object.values(total));
        } catch (e) {
          this.$swal({
            text: e.message,

            width: "300px",
            confirmButtonColor: "#238f53",
          });
        }
      }
    },
    async searchUser(select) {
      if (select == "Pending") {
        try {
          let response = await axios.get(
            `/v1/public/get_all_alert?state?alertType=${this.searchs}&status=pending`
          );

          this.notification = response.data.result.data.data;
        } catch (e) {
          console.log(e);
        }
      } else {
        try {
          let response = await axios.get(
            `/v1/public/get_all_alert?alertType=${this.searchs}&status=resolved`
          );
          this.notifications = response.data.result.data.data;
        } catch (e) {
          console.log(e);
        }
      }
    },
    async resolvedalert() {
      try {
        const data = {
          alertId: this.alertid,
        };
        const res = await axios.post(`v1/admin/resolve_alert`, data);
        this.$swal({
          title:
            "<span style='font-size:13px !important;color:#238f53'>Success</span>",
          text: res.data.result.message,

          width: "300px",
          confirmButtonColor: "#238f53",
        }).then((res) => {
          if (res.isConfirmed) {
            this.$router.go();
          }
        });
      } catch (e) {
        if (e.response.status == 400) {
          this.$swal({
            title:
              "<span style='font-size:13px !important;color:red'>Failed</span>",
            text: "Alert has not been resolved",

            width: "300px",
            confirmButtonColor: "#238f53",
          }).then();
        } else if (e.response.status == 500) {
          this.$swal({
            title:
              "<span style='font-size:13px !important;color:red'>Failed</span>",
            text: "An error occur",

            width: "300px",
            confirmButtonColor: "#238f53",
          }).then();
        }
      }
    },

    async downloadCSV() {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const apiKey = urlParams.get("api_key");
        const config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${publicPath}v1/public/download_alerts`,
          headers: {
            "x-api-key": apiKey,
          },
        };

        const response = await axios(config);

        const blob = new Blob([response.data], { type: "text/csv" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "alerts.csv";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error("Error downloading CSV:", error);
      }
    },

    async printThis() {
      const el = this.$refs.printcontent;

      const options = {
        type: "dataURL",
      };
      const printCanvas = await html2canvas(el, options);

      const link = document.createElement("a");
      link.setAttribute("download", "Alert.png");
      link.setAttribute(
        "href",
        printCanvas
          .toDataURL("image/png")
          .replace("image/png", "image/octet-stream")
      );
      link.click();
    },
    download() {
      window.html2canvas = html2canvas;
      const doc = new jsPDF("p", "pt", "a2");
      doc.html(document.querySelector("#content"), {
        callback: function (pdf) {
          pdf.save("Alert.pdf");
        },
      });
    },
    async seteachpage(id) {
      this.eachpage = id;
      localStorage.setItem("page", id);

      try {
        let response = await axios.get(
          `/v1/public/get_all_alert?limit=${id}&status=pending${
            this.adminrole == "admin" ? `&state=${this.adminstate}` : ""
          }`
        );
        this.notification = response.data.result.data.data;
        this.totalpagepending = response.data.result.data.total;
        this.perpage = response.data.result.data.perPage;
        this.hasNextpagepending = response.data.result.data.hasNextPage;
        this.hasPrevpagepending = response.data.result.data.hasPrevPage;
        // this.loading = false;
      } catch (e) {
        console.log(e);
      }
    },

    async seteachpager(id) {
      this.eachpage = id;
      localStorage.setItem("page", id);

      try {
        let response = await axios.get(
          `/v1/public/get_all_alert?limit=${id}&status=resolved${
            this.adminrole == "admin" ? `&state=${this.adminstate}` : ""
          }`
        );
        this.notification = response.data.result.data.data;
        this.totalpagepending = response.data.result.data.total;
        this.perpage = response.data.result.data.perPage;
        this.hasNextpagepending = response.data.result.data.hasNextPage;
        this.hasPrevpagepending = response.data.result.data.hasPrevPage;
        //  this.loading = false;
      } catch (e) {
        console.log(e);
      }
    },
    async getto(select) {
      if (select == "Pending") {
        try {
          const response = await axios.get(
            `/v1/public/get_all_alert?status=pending&endDate=${new Date(
              this.to
            ).toISOString()}&startDate=${new Date(this.from).toISOString()}${
              this.adminrole == "admin" ? `&state=${this.adminstate}` : ""
            }`
          );
          this.notification = response.data.result.data.data;
        } catch (e) {
          console.log(e);
        }
      } else {
        try {
          const response = await axios.get(
            `/v1/public/get_all_alert?status=resolved&endDate=${new Date(
              this.to
            ).toISOString()}&startDate=${new Date(this.from).toISOString()}${
              this.adminrole == "admin" ? `&state=${this.adminstate}` : ""
            }`
          );
          this.notifications = response.data.result.data.data;
        } catch (e) {
          console.log(e);
        }
      }
    },
    async previousPage() {
      if (this.page > 1) {
        this.page--;

        try {
          let response = await axios.get(
            `/v1/public/get_all_alert?limit=${this.eachpage}&page=${this.page}&status=pending`
          );

          this.notification = response.data.result.data.data;
          this.totalpagepending = response.data.result.data.total;
          this.perpage = response.data.result.data.perPage;
        } catch (e) {
          console.log(e);
        }
      }
    },
    async previousPageResolved() {
      if (this.pager > 1) {
        this.pager--;

        try {
          let response = await axios.get(
            `/v1/public/get_all_alert?limit=${this.eachpage}&page=${this.pager}&status=resolved`
          );

          this.notifications = response.data.result.data.data;
          this.totalpagereesolved = response.data.result.data.total;
          this.perpage = response.data.result.data.perPage;
        } catch (e) {
          console.log(e);
        }
      }
    },
    async nextPage() {
      if (this.page <= this.totalpagepending / this.eachpage) {
        this.page++;

        try {
          let response = await axios.get(
            `/v1/public/get_all_alert?limit=${this.eachpage}&page=${this.page}&status=pending`
          );
          this.notification = response.data.result.data.data;
          this.totalpage = response.data.result.data.total;
          this.perpage = response.data.result.data.perPage;
        } catch (e) {
          console.log(e);
        }
      }
    },

    async nextPageResolved() {
      if (this.pager <= this.totalpagereesolved / this.eachpage) {
        this.pager++;

        try {
          let response = await axios.get(
            `/v1/public/get_all_alert?limit=${this.eachpage}&page=${this.pager}&status=resolved`
          );
          this.notifications = response.data.result.data.data;
          this.totalpagereesolved = response.data.result.data.total;
          this.perpage = response.data.result.data.perPage;
        } catch (e) {
          console.log(e);
        }
      }
    },
    async resetfilter(status) {
      this.choosenLga = "LGA";
      this.choosenstate = "State";
      this.to = "";
      this.from = "";

      if (status == "Pending") {
        try {
          let response = await axios.get(
            `/v1/public/get_all_alert?limit=20&status=pending${
              this.adminrole == "admin" ? `&state=${this.adminstate}` : ""
            }`
          );
          this.notification = response.data.result.data.data;
          this.totalpagepending = response.data.result.data.total;
          this.perpage = response.data.result.data.perPage;
          this.hasNextpagepending = response.data.result.data.hasNextPage;
          this.hasPrevpagepending = response.data.result.data.hasPrevPage;
          //  this.loading = false;
        } catch (e) {
          console.log(e);
        }
      } else {
        try {
          let response = await axios.get(
            `/v1/public/get_all_alert?limit=20&status=resolved${
              this.adminrole == "admin" ? `&state=${this.adminstate}` : ""
            }`
          );

          this.notifications = response.data.result.data.data;
          this.totalpagereesolved = response.data.result.data.total;
          this.perpage = response.data.result.data.perPage;
          this.hasNextpageresolved = response.data.result.data.hasNextPage;
          this.hasPrevpageresolved = response.data.result.data.hasPrevPage;
          // this.loading = false;
        } catch (e) {
          console.log(e);
        }
      }
    },
    async reloadpage() {
      try {
        let res = await axios.get("/v1/public/get_all_alert");

        this.notification = res.data.result.data;
      } catch (e) {
        console.log(e);
      }
    },
    async assignSubmit() {
      this.isDisabled = true;
      const data = {
        responseTeam: this.respId,
        alertId: this.alertid,
      };

      try {
        const response = await axios.post(
          "v1/admin/assign_response_team_alert",
          data
        );
        var button = document.getElementById("closemebig");
        button.click();
        var buttons = document.getElementById("closesmall");
        buttons.click();
        this.$swal({
          title:
            "<span style='font-size:13px !important;color:#238f53'>Success</span>",
          text: response.data.result.message,
          backdrop: false,
          visible: true,
          width: "300px",
          style: "z-index:5000",

          confirmButtonColor: "#238f53",
        }).then((res) => {
          if (res.isConfirmed) {
            this.$router.go();
          }
        });
      } catch (e) {
        if (e.response.status == 400) {
          var butt = document.getElementById("closemebig");
          butt.click();
          var butts = document.getElementById("closesmall");
          butts.click();

          this.$swal({
            title:
              "<span style='font-size:13px !important;color:red'>Failed</span>",
            text: e.response.data.errors[0].message,
            style: "z-index:5000",
            width: "300px",
            confirmButtonColor: "#238f53",
          }).then((this.isDisabled = false));
        } else if (e.response.status == 500) {
          var but = document.getElementById("closemebig");
          but.click();
          var buts = document.getElementById("closesmall");
          buts.click();
          this.$swal({
            title:
              "<span style='font-size:13px !important;color:red'>Failed</span>",
            text: e.response.data.errors[0].message,

            width: "300px",
            confirmButtonColor: "#238f53",
          }).then((this.isDisabled = false));
        }
      }
    },
    getvalres(item) {
      this.searchres = item.firstName + " " + item.lastName;
      this.respId = item._id;
      this.getres = false;
    },
    hidegetres() {
      this.getres = !this.getres;
    },
    async lgaget(name, select) {
      // console.log(select);
      if (select == "Pending") {
        this.choosenLga = name;
        try {
          const response = await axios.get(
            `/v1/public/get_all_alert?state=${this.choosenstate}&lga=${this.choosenLga}&status=pending`
          );
          //  console.log(response);
          this.notification = response.data.result.data.data;
        } catch (e) {
          console.log(e);
        }
      } else {
        this.choosenLga = name;
        try {
          const response = await axios.get(
            `/v1/public/get_all_alert?state=${this.choosenstate}&lga=${this.choosenLga}&status=resolved`
          );
          this.notification = response.data.result.data;
        } catch (e) {
          console.log(e);
        }
      }
    },
    async getlgas(state, name, select) {
      if (select == "Pending") {
        this.choosenstate = name;
        this.selectedstates = this.lga[state];
        try {
          const response = await axios.get(
            `/v1/public/get_all_alert?state=${this.choosenstate}&status=pending`
          );
          this.notification = response.data.result.data.data;
        } catch (e) {
          console.log(e);
        }
      } else {
        this.choosenstate = name;
        this.selectedstates = this.lga[state];
        try {
          const response = await axios.get(
            `/v1/public/get_all_alert?state=${this.choosenstate}&status=resolved`
          );
          this.notifications = response.data.result.data.data;
        } catch (e) {
          console.log(e);
        }
      }
    },
    showModalInfo(item) {
      this.showModal = true;
      this.selectedAlert = item;
      this.slectedcoordslat = item.location?.coordinates[1];
      this.slectedcoordslag = item.location?.coordinates[0];
      this.current = { lat: this.slectedcoordslat, lng: this.slectedcoordslag };
      //  console.log(this.current);

      this.selectedlevel = item.Severity;
      this.alertid = item._id;
    },
    hideModalInfo() {
      this.showModal = false;
    },
    selectedstate(stt) {
      this.state = stt;
    },
    setSelected(tab) {
      this.selected = tab;
    },
    setSelected2(tab) {
      this.selecteds = tab;
    },
  },
  mounted() {
    this.eachpage = localStorage.getItem("page");

    navigator.geolocation.getCurrentPosition(
      (position) => {
        this.lat = position.coords.latitude;
        this.lag = position.coords.longitude;
        this.center = latLng(this.lat, this.lag);
      },
      (error) => {
        console.log(error.message);
      }
    );
  },
  async created() {
    /*
    try {
      let response = await axios.get("v1/notification/get_all_alert_type");
      this.altypes = response.data.result.data.types;
    } catch (e) {
      console.log(e);
    }
    */
    /*
      try {
        let response = await axios.get("v1/user/get_user_profile");
        this.adminrole = response.data.result.data.userRole;
        this.adminstate = response.data.result.data.state;

        //this.notification = response.data.result.data.sos;
      } catch (e) {
        console.log(e);
      }
      */
    /*
      try {
        let resp = await axios.get(
          `v1/user/get_all_response_team${
            this.adminrole == "admin" ? `?state=${this.adminstate}` : ""
          }`
        );
        this.responseTeam = resp.data.result.data;
      } catch (e) {
        this.$swal({
          text: e.message,

          width: "300px",
          confirmButtonColor: "#238f53",
        });
      }
      */
    /*
    try {
      let resp = await axios.get(`v1/admin/alert_state_chart?endDate=${new Date()}`);
      this.zonedata = resp.data.result.data.noOfAlertZone;
      // console.log(this.zonedata);
      /*
         for (const [key, value] of Object.entries(this.zonedata)) {
          console.log(key, value);
          console.log(value.zone);
          const data = {
            value.zone
          }
          //  this.raname = value;
          //console.log(selectedlgas);

        }


      const data = {
        "North West": this.zonedata[1] != undefined ? this.zonedata[1].count : 0,
        "North Central": this.zonedata[0] != undefined ? this.zonedata[0].count : 0,
        "South West": this.zonedata[2] != undefined ? this.zonedata[2].count : 0,
        "North East": this.zonedata[3] != undefined ? this.zonedata[3].count : 0,
        "South South": this.zonedata[5] != undefined ? this.this.zonedata[5].count : 0,
        "South East": this.zonedata[4] != undefined ? this.zonedata[4].count : 0,
      };

      this.data = data;
      const datas = resp.data.result.data.groupedZone;

      const keys = Object.keys(datas);
      const newObj = {};
      const total = {};
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        const value = datas[key];
        value.forEach((item) => {
          if (!newObj[key]) newObj[key] = {};
          newObj[key][item.state] = item.count;
          if (!total[key]) total[key] = 0;
          total[key] += item.count;
        });
      }
      Object.keys(total).map((zone) => {
        newObj[zone].totalCount = total[zone];
      });
      this.groupzone = newObj;

      this.highestTotal += Math.max(...Object.values(total));
    } catch (e) {
      this.$swal({
        text: e.message,

        width: "300px",
        confirmButtonColor: "#238f53",
      });
    }
    */

    try {
      let res = await axios.get(`/v1/public/alert_analytics`);
      this.noofAlert = res.data.result.data.noOfAlert;
      this.noofalertlevel = res.data.result.data.noOfAlertLevel;

      this.cancelled =
        this.noofAlert[0] != undefined ? this.noofAlert[0].count : 0;
      this.pending =
        this.noofAlert[1] != undefined ? this.noofAlert[1].count : 0;
      this.progress =
        this.noofAlert[2] != undefined ? this.noofAlert[2].count : 0;
      this.resolved =
        this.noofAlert[3] != undefined ? this.noofAlert[3].count : 0;
    } catch (e) {
      console.log(e);
    }
    try {
      let response = await axios.get("v1/get_states");
      this.lga = response.data.result.data.lgas;
      this.states = response.data.result.data.state;
    } catch (e) {
      console.log(e);
    }
    try {
      let response = await axios.get(`/v1/public/get_all_alert`);

      this.totalalertcommon = response.data.result.data.data;
      //  console.log(response);
      this.totalalerts = response.data.result.data.total;
    } catch (e) {
      console.log(e);
    }
    try {
      let response = await axios.get(
        `/v1/public/get_all_alert?limit=${this.eachpage}&status=pending`
      );
      //console.log(response);
      this.notification = response.data.result.data.data;
      this.totalpagepending = response.data.result.data.total;
      this.perpage = response.data.result.data.perPage;
      this.hasNextpagepending = response.data.result.data.hasNextPage;
      this.hasPrevpagepending = response.data.result.data.hasPrevPage;
      //   this.totalalertpending = response.data.result.data.data.total

      // this.loading = false;
    } catch (e) {
      console.log(e);
    }

    try {
      let response = await axios.get(
        `/v1/public/get_all_alert?limit=20&status=resolved`
      );

      this.notifications = response.data.result.data.data.reverse();
      this.totalpagereesolved = response.data.result.data.total;
      this.perpage = response.data.result.data.perPage;
      this.hasNextpageresolved = response.data.result.data.hasNextPage;
      this.hasPrevpageresolved = response.data.result.data.hasPrevPage;
      //  this.totalalertresolved = response.data.result.data.data.total
    } catch (e) {
      console.log(e);
    }
    try {
      let response = await axios.get(
        `/v1/public/get_all_alert?status=resolved`
      );
      this.notificationresolved = response.data.result.data.data;
    } catch (e) {
      console.log(e);
    }

    try {
      let response = await axios.get(
        `/v1/public/get_all_alert?limit=20&status=in-progress`
      );

      this.notificationss = response.data.result.data.data;
      this.totalpage = response.data.result.data.total;
      this.perpage = response.data.result.data.perPage;
      this.hasNextpage = response.data.result.data.hasNextPage;
      this.hasPrevpage = response.data.result.data.hasPrevPage;
      this.loading = false;
    } catch (e) {
      console.log(e);
    }
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
    filterStates: function () {
      return this.states.filter((item) => {
        return item.name.match(this.search.toUpperCase());
      });
    },
    filterName: function () {
      return this.responseTeam.filter((item) => {
        return item.firstName.match(this.searchres);
      });
    },
  },
};
</script>
<style scoped>
.bg-home-main {
  background: #f5f6f7;
  min-height: calc(100vh - 50px);
  overflow: auto;
  position: relative;
}
.btn1 {
  border: 2px solid #238f53;
  color: #238f53;
  padding: 4px 7px 4px 7px !important;
  font-weight: 500;
  outline: none !important;
  font-size: 12px;
  margin-right: 5px;
}
.btn2 {
  border: 2px solid #238f53;
  color: #fff;
  font-size: 12px;
  background: #238f53;
  padding: 4px 7px 4px 7px !important;
  font-weight: 500;
  outline: none !important;
  margin-left: 5px;
}
.conbg {
  border-radius: 12px;
}
.exptime {
  font-size: 12px;
}
.container {
  width: 100%;
  margin: 5px 2.5px 5px 2.5px;
  padding: 12px;
  border-radius: 6px;
  text-align: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #455c7d;
}
.container h5 {
  font-size: 14px;
  align-content: center;
  display: flex;
  padding: 0px !important;
  margin: 0px !important;
}
.sweet-overlay {
  z-index: 1000;
}
.center {
  height: 100vh;
  align-items: center;
  display: flex;
  width: 100vw;
  place-items: center;
  justify-content: center;
}
.container span {
  font-size: 12px;
}
.reset {
  font-size: 13px;
  font-weight: 600;
  color: #238f53;
  float: right;
}
.filter {
  font-size: 13px;
  font-weight: 600;
  color: #238f53;

  align-items: center;
  display: flex;
  margin-left: -20px;
}
.page-link {
  background: transparent;
  border: 1px solid #dee2e6;
  color: #238f53;
}
.dropdown-toggle {
  background: #fff !important;

  font-size: 12px !important;
  width: 90%;
  padding-top: 5px !important ;
  padding-bottom: 5px !important;
  justify-content: space-between !important;
  display: flex !important;
  align-items: center;
  color: #455c7d;
  border-radius: 3px !important;
  border: 1px solid #c9d9e5 !important;
}
.dropdown {
  width: 100%;

  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.conflex1 {
  width: 80%;

  padding: 5px 0px 5px 0px;

  display: flex;
}
.conflex2 {
  width: 20%;

  padding: 5px 0px 5px 0px;
}
th {
  font-size: 12px !important;
  border: none !important;
  background: #f5f6f7;
  color: #090d14;
}
td {
  border: none !important;
  font-size: 12px !important;
}
tr:nth-child(even) {
  background: #f5f6f7;
  border-radius: 30px !important;
}
tr {
  border-radius: 30px !important;
  color: #5c7597;
  cursor: pointer;
}
.status {
  color: #9d1f1f;
  background: #f2d5d5;
  border-radius: 20px;
  padding-top: 1px !important ;
  padding-bottom: 2px !important ;
  padding-right: 10px !important;
  padding-left: 10px !important;
  display: inline-block;
  align-items: center;
  font-size: 12px !important;
}
.status2 {
  color: #fff;
  background: #238f53;
  border-radius: 20px;
  padding-top: 1px !important ;
  padding-bottom: 1px !important ;
  padding-right: 5px !important;
  padding-left: 5px !important;
  display: inline-block;
  align-items: center;
  font-size: 12px !important;
}
.active {
  color: #fff;
  background: #238f53 !important;
}

.modal-footer {
  border-top: none !important;
}
.mm:hover {
  background: #ebf7f3;
}
.mdil li {
  display: flex !important;
  justify-content: space-between !important;
  padding: 3px !important;
  line-height: 35px;
  border: none !important;
  text-align: left !important;
}
.mdil li h6 {
  font-size: 12px;
  color: #7993b1;
}
.page-item {
  color: #007bff;
  background: transparent !important;
}
.page-item {
  background: transparent !important;
}
.mdil li span {
  font-size: 12px;
}
.dtaedropdown {
  border-radius: 12px !important;
  padding: 10px;
  width: 400px;
}

.dgggd::-webkit-scrollbar {
  width: 2px;
}

/* Track */
.dgggd::-webkit-scrollbar-track {
  background: #aaa;
}

/* Handle */
.dgggd::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.dgggd::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.white {
  color: #fff !important;
}
.hl {
  position: relative;
  color: #fff;
  width: 30px;
  height: 30px;
  border: 0.02rem solid #ffcc99 !important;
  border-radius: 100%;
  font-size: 5em;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 20px #ffcc99, 0 0 3px #ffcc99, 0 0 3px #ffcc99,
    0 0 3px #ffcc99, 0 0 3px #ffcc99;
}
.hl {
  animation: blinks-hl 1s steps(5, start) infinite;
  -webkit-animation: blinks-hl 1s steps(5, start) infinite;
  box-shadow: 0 0 10px #ffcc99, 0 0 3px #ffcc99, 0 0 3px #ffcc99,
    0 0 3px #ffcc99, 0 0 3px #ffcc99;
}
@keyframes blinks-hl {
  to {
    box-shadow: 0 0 20px #ffcc99, 0 0 3px #ffcc99, 0 0 3px #ffcc99,
      0 0 3px #ffcc99, 0 0 3px #ffcc99;
  }
}
@-webkit-keyframes blinks-hl {
  to {
    bax-shadow: 2px 3px 4px solid #ffcc99;
  }
}

.ml {
  position: relative;
  color: #fff;
  width: 30px;
  height: 30px;
  border: 0.02rem solid yellow !important;
  border-radius: 100%;
  font-size: 5em;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 20px yellow, 0 0 3px yellow, 0 0 3px yellow, 0 0 3px yellow,
    0 0 3px yellow;
}
.ml {
  animation: blinks-ml 1s steps(5, start) infinite;
  -webkit-animation: blinks-ml 1s steps(5, start) infinite;
  box-shadow: 0 0 10px yellow, 0 0 3px yellow, 0 0 3px yellow, 0 0 3px yellow,
    0 0 3px yellow;
}
@keyframes blinks-ml {
  to {
    box-shadow: 0 0 20px yellow, 0 0 3px yellow, 0 0 3px yellow, 0 0 3px yellow,
      0 0 3px yellow;
  }
}
@-webkit-keyframes blinks-ml {
  to {
    bax-shadow: 2px 3px 4px solid yellow;
  }
}
.ll {
  position: relative;
  color: #fff;
  width: 30px;
  height: 30px;
  border: 0.02rem solid #e3ff00 !important;
  border-radius: 100%;
  font-size: 5em;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 20px #e3ff00, 0 0 3px #e3ff00, 0 0 3px #e3ff00,
    0 0 3px #e3ff00, 0 0 3px #e3ff00;
}
.ll {
  animation: blinks-ll 1s steps(5, start) infinite;
  -webkit-animation: blinks-ll 1s steps(5, start) infinite;
  box-shadow: 0 0 10px #e3ff00, 0 0 3px #e3ff00, 0 0 3px #e3ff00,
    0 0 3px #e3ff00, 0 0 3px #e3ff00;
}
@keyframes blinks-ll {
  to {
    box-shadow: 0 0 20px #e3ff00, 0 0 3px #e3ff00, 0 0 3px #e3ff00,
      0 0 3px #e3ff00, 0 0 3px #e3ff00;
  }
}
@-webkit-keyframes blinks-ll {
  to {
    bax-shadow: 2px 3px 4px solid #e3ff00;
  }
}
.classlist {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.classlist input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 7px;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.classlist:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.classlist input:checked ~ .checkmark {
  background-color: rgb(35, 143, 83);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.classlist input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.classlist .checkmark:after {
  left: 5px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.overflow-top-loading {
  width: 100%;
  height: 200px;
  background: rgb(245, 246, 247);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  border-radius: 12px;
}
</style>
