<template>
  <div>
    <router-view />
    <vue-progress-bar></vue-progress-bar>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>

<style lang="scss">
:root {
  --primary: #4705af;
  --sidebar-width: 250px;
  --menu-title: #3f4254;
}
.modal-content {
  max-width: 500px !important;
  margin: 0px auto;
}
.modal-dialog {
  max-width: 500%;
}
.app {
  display: flex;
  main {
    flex: 1 1 0;
    padding: 0.5rem;
    @media (max-width: 769px) {
      padding-left: 0rem;
      padding: 5px;
    }
  }
}
</style>
