<template>
  <div class="w-100 app">
    <!-- Button trigger modal -->

    <!-- Modal -->

    <SideBar />
    <div class="w-100">
      <Header />
      <main class="p-3 bg-home-main">
        <div class="w-100 d-flex justify-content-center mb-3">
          <div>
            <TabNav
              v-if="
                user.userRole === 'super_admin' ||
                user.userRole == 'global_admin'
              "
              :tabs="['Profiles', 'Alerts', 'SOS']"
              :selected="selected"
              @selected="setSelected"
            >
              <Tab :isselected="selected === 'Profiles'">
                <div
                  style="
                    position: absolute;

                    box-sizing: border-box;

                    overflow-x: hidden;
                    right: 25px;
                    left: 25px;
                    padding: 10px;
                  "
                >
                  <div class="d-flex">
                    <div style="flex: 1.5" class="mr-2">
                      <div
                        class="w-100 bg-white p-2"
                        style="
                          max-height: 110px;
                          align-items: center;
                          border-radius: 8px;
                        "
                      >
                        <div class="avater mb-2">
                          <img
                            :src="user.profileImage.url"
                            alt=""
                            width="100%"
                            height="100%"
                            style="
                              width: 100%;
                              height: 100%;
                              overflow: hidden;
                              border-radius: 100%;
                            "
                            v-if="user.profileImage.url != ''"
                          />
                          <img
                            src="../assets/images/avater.webp"
                            alt=""
                            width="100%"
                            height="100%"
                            style="
                              width: 100%;
                              height: 100%;
                              overflow: hidden;
                              border-radius: 100%;
                            "
                            v-else
                          />
                        </div>
                        <div style="display: flex; justify-content: center">
                          <button
                            v-if="
                              adminrole === 'super_admin' ||
                              adminrole === 'global_admin'
                            "
                            class="btn3"
                            style="margin: 10px auto"
                            @click="$refs.fileInput.click()"
                          >
                            Upload Profile picture
                          </button>
                          <input
                            type="file"
                            name=""
                            style="display: none"
                            accept="image/*"
                            ref="fileInput"
                            @change="onSelectedFile"
                          />
                        </div>
                      </div>
                    </div>
                    <div style="flex: 8.5" class="ml-2">
                      <div class="d-grid w-100">
                        <div
                          class="bg-white w-100 rounded-lg p-2 d-flex justify-content-between"
                        >
                          <div class="w-50 gbg">
                            <ul class="list-group">
                              <li class="list-group-item">
                                <span style="font-size: 12px; color: #7993b1"
                                  >First Name</span
                                >
                                <span
                                  style="font-weight: 500; font-size: 11px"
                                  >{{ user.firstName }}</span
                                >
                              </li>
                              <li class="list-group-item">
                                <span style="font-size: 12px; color: #7993b1"
                                  >Email</span
                                >
                                <span
                                  style="font-weight: 500; font-size: 11px"
                                  >{{ user.email }}</span
                                >
                              </li>
                              <li class="list-group-item">
                                <span style="font-size: 12px; color: #7993b1"
                                  >User Role</span
                                >
                                <span
                                  style="font-weight: 500; font-size: 11px"
                                  >{{ user.userRole }}</span
                                >
                              </li>
                            </ul>
                          </div>
                          <div class="w-50 gbg">
                            <ul class="list-group">
                              <li class="list-group-item">
                                <span style="font-size: 12px; color: #7993b1"
                                  >Lastname</span
                                >
                                <span
                                  style="font-weight: 500; font-size: 11px"
                                  >{{ user.lastName }}</span
                                >
                              </li>
                              <li class="list-group-item">
                                <span style="font-size: 12px; color: #7993b1"
                                  >Phone No.</span
                                >
                                <span
                                  style="font-weight: 500; font-size: 11px"
                                  >{{ user.phoneNumber }}</span
                                >
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="w-100 p-2 rounded-lg bg-white mt-2">
                          <div class="w-100">
                            <h6 style="font-size: 12px">Update Password</h6>
                            <div class="w-100">
                              <form @submit.prevent="updatePassword">
                                <div class="row">
                                  <div class="col">
                                    <label for="">Old Password</label>
                                    <input
                                      v-model="opassword"
                                      type="password"
                                      class="form-control"
                                      placeholder="***********"
                                      required
                                    />
                                    <div
                                      class="d-flex mt-1"
                                      style="align-items: center"
                                    >
                                      <span
                                        class="material-icons mr-1"
                                        style="color: #ffad0d; font-size: 10px"
                                        >warning</span
                                      >
                                      <span style="font-size: 10px"
                                        >Min 8 Alphanumeric characters</span
                                      >
                                    </div>
                                  </div>
                                  <div class="col">
                                    <label for="">New Password</label>
                                    <input
                                      v-model="npassword"
                                      type="password"
                                      class="form-control"
                                      :class="
                                        warning
                                          ? 'border border-warning !important'
                                          : null
                                      "
                                      placeholder="**********"
                                      required
                                      @blur="validateEmail"
                                    />
                                    <div
                                      class="d-flex mt-1"
                                      style="align-items: center"
                                    >
                                      <span
                                        class="material-icons mr-1"
                                        style="color: #ffad0d; font-size: 10px"
                                        >warning</span
                                      >
                                      <span style="font-size: 10px"
                                        >Min 8 Alphanumeric characters</span
                                      >
                                    </div>
                                  </div>
                                </div>
                                <button
                                  v-if="
                                    adminrole === 'super_admin' ||
                                    adminrole === 'global_admin'
                                  "
                                  type="submit"
                                  class="btn mt-2"
                                  style="background: #238f53; color: #fff"
                                >
                                  Save
                                </button>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab :isselected="selected === 'Alerts'">
                <div
                  style="
                    position: absolute;

                    box-sizing: border-box;

                    overflow-x: hidden;
                    right: 25px;
                    left: 25px;
                    padding: 10px;
                  "
                >
                  <div class="d-grid w-100 mt-2">
                    <div
                      class="bg-white w-100 p-2 pnp"
                      style="border-radius: 10px"
                    >
                      <h6>Alert Category</h6>
                      <div
                        style="
                          border-radius: 10px;
                          padding: 10px;
                          background: #f5f6f7;
                          max-height: 200px;
                          min-height: 200px;
                          height: 100%;
                        "
                      >
                        <div
                          class="w-100 d-flex justify-content-between"
                          style="align-items: center"
                        >
                          <h6
                            style="
                              margin: 0px !important;
                              padding: 0px !important;
                              font-size: 11px !important;
                            "
                          >
                            Critical Level
                          </h6>
                          <h6
                            style="
                              margin: 0px !important;
                              padding: 0px !important;
                              display: flex;
                              align-items: center;
                              color: #238f53;
                              font-size: 11px !important;
                              cursor: pointer;
                            "
                            data-toggle="modal"
                            data-target="#exampleModalCenter"
                            v-if="
                              adminrole === 'super_admin' ||
                              adminrole === 'global_admin'
                            "
                          >
                            Add New Type
                            <span class="material-icons">add</span>
                          </h6>
                          <!--- MOdal -->
                          <div
                            class="modal fade"
                            id="exampleModalCenter"
                            tabindex="-1"
                            role="dialog"
                            aria-labelledby="exampleModalCenterTitle"
                            aria-hidden="true"
                          >
                            <div
                              class="modal-dialog modal-dialog-centered"
                              role="document"
                            >
                              <div
                                class="modal-content"
                                style="
                                  max-width: 400px;
                                  width: 100%;
                                  margin: 0px auto;
                                "
                              >
                                <div
                                  class="modal-header mt-1 mb-1"
                                  style="
                                    padding-top: 5px !important;
                                    padding-bottom: 5px !important;
                                  "
                                >
                                  <h5
                                    class="modal-title"
                                    id="exampleModalLongTitle"
                                    style="font-size: 13px"
                                  >
                                    Add New Type
                                  </h5>
                                  <button
                                    type="button"
                                    class="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                  >
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                                <div class="modal-body">
                                  <form @submit.prevent="addAlert">
                                    <div
                                      v-for="(item, index) in items"
                                      :key="index"
                                    >
                                      <div
                                        class="form-row mt-2"
                                        style="
                                          background: #f5f6f7;
                                          border-radius: 10px;
                                        "
                                      >
                                        <div class="form-group col-md-4">
                                          <label for="inputEmail4"
                                            >Alert Type</label
                                          >
                                          <input
                                            type="text"
                                            class="form-control w-100"
                                            id="inputEmail4"
                                            v-model="item.type"
                                            placeholder="Type here"
                                            style="
                                              padding-bottom: 2px;
                                              padding-top: 2px;
                                            "
                                          />
                                        </div>
                                        <div class="form-group col-md-4">
                                          <label for="inputEmail4"
                                            >Response Time</label
                                          >
                                          <input
                                            type="time"
                                            class="form-control w-100"
                                            id="inputEmail4"
                                            placeholder="00:00"
                                            v-model="fff"
                                            style="
                                              padding-bottom: 2px;
                                              padding-top: 2px;
                                            "
                                          />
                                        </div>
                                        <div class="form-group col-md-4">
                                          <label for="inputEmail4"
                                            >Alert Level</label
                                          >
                                          <select
                                            type="email"
                                            class="form-control w-100"
                                            id="inputEmail4"
                                            placeholder="Email"
                                            style="
                                              padding-bottom: 2px;
                                              padding-top: 2px;
                                            "
                                            v-model="item.level"
                                          >
                                            <option value="CL">
                                              Critical Level
                                            </option>
                                            <option value="HL">
                                              High Level
                                            </option>
                                            <option value="ML">
                                              Medium Level
                                            </option>
                                            <option value="LL">
                                              Low Level
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      class="modal-footer d-flex justify-content-between mt-1"
                                    >
                                      <h6
                                        style="
                                          margin: 0px !important;
                                          padding: 0px !important;
                                          display: flex;
                                          align-items: center;
                                          color: #238f53;
                                          font-size: 11px !important;
                                          cursor: pointer;
                                        "
                                        @click="increntCon"
                                        v-if="
                                          adminrole === 'super_admin' ||
                                          adminrole === 'global_admin'
                                        "
                                      >
                                        Add New Type
                                        <span class="material-icons">add</span>
                                      </h6>
                                      <div
                                        style="
                                          width: 15px;
                                          height: 15px;
                                          border: 1px solid #f2d5d5;
                                          border-radius: 3px;
                                          background: #f2d5d5;
                                          display: flex;
                                          align-items: center;
                                          justify-content: center;
                                        "
                                      >
                                        <span
                                          class="material-icons"
                                          style="
                                            color: #f5b0b0;
                                            font-size: 10px;
                                          "
                                          @click="removeCon"
                                          >delete</span
                                        >
                                      </div>
                                    </div>
                                    <button
                                      v-if="adminrole === 'adminrole'"
                                      class="w-100 btn mt-1"
                                      style="background: #238f53; color: #fff"
                                    >
                                      Save
                                    </button>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="w-100">
                          <div
                            class="nucard shadow-sm pr-1 pl-1 pt-1 pb-1"
                            v-for="item in filterCL"
                            :key="item"
                          >
                            <span style="margin-right: 10px">
                              {{ item.type }}</span
                            >
                            <span style="margin-right: 10px">{{
                              item.level
                            }}</span>
                            <span
                              class="material-icons"
                              style="font-size: 11px; margin-left: 4px"
                              @click="removeAlert(item._id)"
                              >cancel</span
                            >
                          </div>

                          <!---Modal Ends -->
                        </div>
                      </div>

                      <!--HL-->

                      <div
                        style="
                          border-radius: 10px;
                          padding: 10px;
                          background: #f5f6f7;
                          max-height: 200px;
                          min-height: 200px;
                          margin-top: 10px;
                          height: 100%;
                        "
                      >
                        <div
                          class="w-100 d-flex justify-content-between"
                          style="align-items: center"
                        >
                          <h6
                            style="
                              margin: 0px !important;
                              padding: 0px !important;
                              font-size: 11px !important;
                            "
                          >
                            High Level
                          </h6>
                          <h6
                            style="
                              margin: 0px !important;
                              padding: 0px !important;
                              display: flex;
                              align-items: center;
                              color: #238f53;
                              font-size: 11px !important;
                              cursor: pointer;
                            "
                            data-toggle="modal"
                            data-target="#exampleModalCenter"
                            v-if="
                              adminrole === 'super_admin' ||
                              adminrole === 'global_admin'
                            "
                          >
                            Add New Type
                            <span class="material-icons">add</span>
                          </h6>
                        </div>
                        <div class="w-100">
                          <div
                            class="nucard shadow-sm pr-1 pl-1 pt-1 pb-1"
                            v-for="item in filterHL"
                            :key="item"
                          >
                            <span style="margin-right: 10px">
                              {{ item.type }}</span
                            >
                            <span style="margin-right: 10px">{{
                              item.level
                            }}</span>
                            <span
                              class="material-icons"
                              style="font-size: 11px; margin-left: 4px"
                              @click="removeAlert(item._id)"
                              >cancel</span
                            >
                          </div>
                        </div>
                      </div>

                      <!---ML -->
                      <div
                        style="
                          border-radius: 10px;
                          padding: 10px;
                          background: #f5f6f7;
                          max-height: 200px;
                          min-height: 200px;
                          margin-top: 10px;
                          height: 100%;
                        "
                      >
                        <div
                          class="w-100 d-flex justify-content-between"
                          style="align-items: center"
                        >
                          <h6
                            style="
                              margin: 0px !important;
                              padding: 0px !important;
                              font-size: 11px !important;
                            "
                          >
                            Middle Level
                          </h6>
                          <h6
                            style="
                              margin: 0px !important;
                              padding: 0px !important;
                              display: flex;
                              align-items: center;
                              color: #238f53;
                              font-size: 11px !important;
                              cursor: pointer;
                            "
                            data-toggle="modal"
                            data-target="#exampleModalCenter"
                            v-if="
                              adminrole === 'super_admin' ||
                              adminrole === 'global_admin'
                            "
                          >
                            Add New Type
                            <span class="material-icons">add</span>
                          </h6>
                        </div>
                        <div class="w-100">
                          <div
                            class="nucard shadow-sm pr-1 pl-1 pt-1 pb-1"
                            v-for="item in filterML"
                            :key="item"
                          >
                            <span style="margin-right: 10px">
                              {{ item.type }}</span
                            >
                            <span style="margin-right: 10px">{{
                              item.level
                            }}</span>
                            <span
                              class="material-icons"
                              style="font-size: 11px; margin-left: 4px"
                              @click="removeAlert(item._id)"
                              >cancel</span
                            >
                          </div>
                        </div>
                        <!---LL -->
                      </div>
                      <div
                        style="
                          border-radius: 10px;
                          padding: 10px;
                          background: #f5f6f7;
                          max-height: 200px;
                          min-height: 200px;
                          margin-top: 10px;
                          height: 100%;
                        "
                      >
                        <div
                          class="w-100 d-flex justify-content-between"
                          style="align-items: center"
                        >
                          <h6
                            style="
                              margin: 0px !important;
                              padding: 0px !important;
                              font-size: 11px !important;
                            "
                          >
                            Low Level
                          </h6>
                          <h6
                            style="
                              margin: 0px !important;
                              padding: 0px !important;
                              display: flex;
                              align-items: center;
                              color: #238f53;
                              font-size: 11px !important;
                              cursor: pointer;
                            "
                            data-toggle="modal"
                            data-target="#exampleModalCenter"
                            v-if="
                              adminrole === 'super_admin' ||
                              adminrole === 'global_admin'
                            "
                          >
                            Add New Type
                            <span class="material-icons">add</span>
                          </h6>
                        </div>
                        <div class="w-100">
                          <div
                            class="nucard shadow-sm pr-1 pl-1 pt-1 pb-1"
                            v-for="item in filterLL"
                            :key="item"
                          >
                            <span style="margin-right: 10px">
                              {{ item.type }}</span
                            >
                            <span style="margin-right: 10px">{{
                              item.level
                            }}</span>
                            <span
                              class="material-icons"
                              style="font-size: 11px; margin-left: 4px"
                              @click="removeAlert(item._id)"
                              >cancel</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab :isselected="selected === 'SOS'">
                <div
                  style="
                    position: absolute;

                    box-sizing: border-box;

                    overflow-x: hidden;
                    right: 25px;
                    left: 25px;
                    padding: 10px;
                  "
                >
                  <div class="d-grid w-100 mt-2">
                    <div
                      class="bg-white w-100 p-2 pnp"
                      style="border-radius: 10px"
                    >
                      <h6>SOS recipient</h6>
                      <div
                        style="
                          border-radius: 10px;
                          padding: 10px;
                          background: #f5f6f7;
                          max-height: 200px;
                          min-height: 200px;
                        "
                      >
                        <div
                          class="nucard shadow-sm pr-1 pl-1 pt-1 pb-1"
                          v-for="(item, index) in sosrecipient"
                          :key="item"
                        >
                          {{ item }}
                          <span
                            class="material-icons"
                            style="
                              font-size: 11px;
                              margin-left: 4px;
                              cursor: pointer;
                            "
                            @click="removerecipient(index)"
                            >cancel</span
                          >
                        </div>

                        <a
                          v-if="
                            adminrole === 'super_admin' ||
                            adminrole === 'global_admin'
                          "
                          type="button"
                          style="
                            color: #5c7597;
                            font-size: 12px;
                            align-items: center;
                            display: flex;
                            margin-top: -3px;
                            text-decoration: none;
                          "
                          class="p-2"
                          :data-toggle="show ? 'modal' : null"
                          :data-target="show ? '#exampleModal' : null"
                          @click="showme"
                        >
                          Type New Recipient
                        </a>

                        <!-- Modal -->

                        <div
                          v-show="show"
                          class="modal"
                          id="exampleModal"
                          tabindex="-1"
                          role="dialog"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true"
                        >
                          <div class="modal-dialog" role="document">
                            <div class="modal-content">
                              <div class="modal-header p-1 m-1">
                                <h5
                                  class="modal-title"
                                  id="exampleModalLabel"
                                  style="font-size: 13px"
                                >
                                  Add New Recipient
                                </h5>
                                <button
                                  v-if="
                                    adminrole === 'super_admin' ||
                                    adminrole === 'global_admin'
                                  "
                                  type="button"
                                  class="close"
                                  id="closeme"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                >
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <div class="modal-body">
                                <form @submit.prevent="addrecipient">
                                  <div class="form-group">
                                    <label for="formGroupExampleInput2"
                                      >Add New Recipient</label
                                    >
                                    <input
                                      type="text"
                                      class="form-control"
                                      id="formGroupExampleInput2"
                                      placeholder="New Recipient "
                                      v-model="number"
                                    />
                                  </div>
                                  <button
                                    v-if="
                                      adminrole === 'super_admin' ||
                                      adminrole === 'global_admin'
                                    "
                                    type="submit"
                                    class="btn"
                                    style="background: #238f53; color: #fff"
                                  >
                                    Submit
                                  </button>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="d-grid w-100 line-height:10px mt-2">
                    <div
                      class="bg-white w-100 p-2 pnp"
                      style="border-radius: 10px"
                    >
                      <h6>SOS Emergency Message</h6>
                      <div
                        style="
                          border-radius: 10px;
                          padding: 10px;
                          background: #f5f6f7;
                          max-height: 120px;
                          min-height: 100px;
                        "
                      >
                        <div class="form-group">
                          <textarea
                            class="form-control no-shadow"
                            id="exampleFormControlTextarea1"
                            style="
                              background: inherit !important;
                              border: none;
                              outline: none !important;
                            "
                            rows="3"
                            v-model="message"
                          >
                          </textarea>
                        </div>
                      </div>
                    </div>
                    <button
                      v-if="
                        adminrole === 'super_admin' ||
                        adminrole === 'global_admin'
                      "
                      :disabled="isDisabled"
                      type="submit"
                      class="btn mt-2"
                      style="background: #238f53; color: #fff"
                      @click="handleMessagerec()"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </Tab>
            </TabNav>
            <TabNav
              v-if="user.userRole === 'admin' || user.userRole === 'view_admin'"
              :tabs="['Profiles']"
              :selected="selected"
              @selected="setSelected"
            >
              <Tab :isselected="selected === 'Profiles'">
                <div
                  style="
                    position: absolute;

                    box-sizing: border-box;

                    overflow-x: hidden;
                    right: 25px;
                    left: 25px;
                    padding: 10px;
                  "
                >
                  <div class="d-flex">
                    <div style="flex: 1.5" class="mr-2">
                      <div
                        class="w-100 bg-white p-2"
                        style="
                          max-height: 110px;
                          align-items: center;
                          border-radius: 8px;
                        "
                      >
                        <div class="avater mb-2">
                          <img
                            :src="user.profileImage.url"
                            alt=""
                            width="100%"
                            height="100%"
                            style="
                              width: 100%;
                              height: 100%;
                              overflow: hidden;
                              border-radius: 100%;
                            "
                            v-if="user.profileImage.url != ''"
                          />
                          <img
                            src="../assets/images/avater.webp"
                            alt=""
                            width="100%"
                            height="100%"
                            style="
                              width: 100%;
                              height: 100%;
                              overflow: hidden;
                              border-radius: 100%;
                            "
                            v-else
                          />
                        </div>
                        <div style="display: flex; justify-content: center">
                          <button
                            v-if="
                              adminrole === 'super_admin' ||
                              adminrole === 'global_admin'
                            "
                            class="btn3"
                            style="margin: 10px auto"
                            @click="$refs.fileInput.click()"
                          >
                            Upload Profile picture
                          </button>
                          <input
                            type="file"
                            name=""
                            style="display: none"
                            accept="image/*"
                            ref="fileInput"
                            @change="onSelectedFile"
                          />
                        </div>
                      </div>
                    </div>
                    <div style="flex: 8.5" class="ml-2">
                      <div class="d-grid w-100">
                        <div
                          class="bg-white w-100 rounded-lg p-2 d-flex justify-content-between"
                        >
                          <div class="w-50 gbg">
                            <ul class="list-group">
                              <li class="list-group-item">
                                <span style="font-size: 12px; color: #7993b1"
                                  >First Name</span
                                >
                                <span
                                  style="font-weight: 500; font-size: 11px"
                                  >{{ user.firstName }}</span
                                >
                              </li>
                              <li class="list-group-item">
                                <span style="font-size: 12px; color: #7993b1"
                                  >Email</span
                                >
                                <span
                                  style="font-weight: 500; font-size: 11px"
                                  >{{ user.email }}</span
                                >
                              </li>
                              <li class="list-group-item">
                                <span style="font-size: 12px; color: #7993b1"
                                  >User Role</span
                                >
                                <span
                                  style="font-weight: 500; font-size: 11px"
                                  >{{ user.userRole }}</span
                                >
                              </li>
                            </ul>
                          </div>
                          <div class="w-50 gbg">
                            <ul class="list-group">
                              <li class="list-group-item">
                                <span style="font-size: 12px; color: #7993b1"
                                  >Lastname</span
                                >
                                <span
                                  style="font-weight: 500; font-size: 11px"
                                  >{{ user.lastName }}</span
                                >
                              </li>
                              <li class="list-group-item">
                                <span style="font-size: 12px; color: #7993b1"
                                  >Phone No.</span
                                >
                                <span
                                  style="font-weight: 500; font-size: 11px"
                                  >{{ user.phoneNumber }}</span
                                >
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="w-100 p-2 rounded-lg bg-white mt-2">
                          <div class="w-100">
                            <h6 style="font-size: 12px">Update Password</h6>
                            <div class="w-100">
                              <form @submit.prevent="updatePassword">
                                <div class="row">
                                  <div class="col">
                                    <label for="">Old Password</label>
                                    <input
                                      v-model="opassword"
                                      type="password"
                                      class="form-control"
                                      placeholder="***********"
                                      required
                                    />
                                    <div
                                      class="d-flex mt-1"
                                      style="align-items: center"
                                    >
                                      <span
                                        class="material-icons mr-1"
                                        style="color: #ffad0d; font-size: 10px"
                                        >warning</span
                                      >
                                      <span style="font-size: 10px"
                                        >Min 8 Alphanumeric characters</span
                                      >
                                    </div>
                                  </div>
                                  <div class="col">
                                    <label for="">New Password</label>
                                    <input
                                      v-model="npassword"
                                      type="password"
                                      class="form-control"
                                      :class="
                                        warning
                                          ? 'border border-warning !important'
                                          : null
                                      "
                                      placeholder="**********"
                                      required
                                      @blur="validateEmail"
                                    />
                                    <div
                                      class="d-flex mt-1"
                                      style="align-items: center"
                                    >
                                      <span
                                        class="material-icons mr-1"
                                        style="color: #ffad0d; font-size: 10px"
                                        >warning</span
                                      >
                                      <span style="font-size: 10px"
                                        >Min 8 Alphanumeric characters</span
                                      >
                                    </div>
                                  </div>
                                </div>
                                <button
                                  v-if="
                                    adminrole === 'super_admin' ||
                                    adminrole === 'global_admin'
                                  "
                                  type="submit"
                                  class="btn mt-2"
                                  style="background: #238f53; color: #fff"
                                >
                                  Save
                                </button>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
            </TabNav>
          </div>
        </div>
      </main>
    </div>
  </div>
  <!-- <div class="center" v-else>
    <vue-loaders-ball-scale-multiple
      color="#238f53"
      scale="1"
    ></vue-loaders-ball-scale-multiple>
  </div> -->
</template>
<script>
import Header from "../components/header.vue";
import SideBar from "../components/sidebar.vue";
import TabNav from "@/components/TabNav3.vue";
import Tab from "@/components/tab.vue";
import "leaflet/dist/leaflet.css";
import moment from "moment";
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "Settings-App",
  components: {
    SideBar,
    Header,
    TabNav,
    Tab,
  },
  data() {
    return {
      sosrecipient: [],
      adminrole: "",
      adminstate: "",
      showModal: false,
      isDisabled: false,
      opassword: "",
      npassword: "",
      message: "",
      moment: moment,
      number: "",
      hideme: false,
      altypes: [],
      levels: "",
      type: "",
      loading: true,
      time: 0,
      zoom: 9,
      warning: false,
      staticAnchor: [16, 37],
      customText: "Foobar",
      iconSize: 64,
      selected: "Profiles",
      count: 1,
      show: false,
      items: [
        {
          type: "",
          level: "",
          responseTime: "",
        },
      ],

      options: {
        legend: {
          display: false,
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem) {
              return tooltipItem.yLabel;
            },
          },
        },
      },
      datas: [
        {
          name: "Workout",
          data: {
            "North South  ": 3,
            "North East ": 4,
            "North West ": 5,
            "South Nouth  ": 2,
            "South East ": 1,
            "South South ": 6,
          },
        },
        {
          name: "Call parents",
          data: {
            "North South  ": 3,
            "North East ": 4,
            "North West ": 5,
            "South Nouth  ": 7,
            "South East ": 8,
            "South South ": 10,
          },
        },
        {
          name: "Call parents",
          data: {
            "North South  ": 3,
            "North East ": 6,
            "North West ": 8,
            "South Nouth  ": 1,
            "South East ": 4,
            "South South ": 1,
          },
        },
      ],
    };
  },
  methods: {
    showme() {
      this.show = !this.show;
    },
    async handleMessagerec() {
      this.isDisabled = true;
      const data = {
        recipients: this.sosrecipient,
        message: this.message,
      };
      try {
        const response = await axios.put("v1/admin/update_sos_settings", data);
        if (response.data.success == true) {
          this.$swal({
            title:
              "<span style='font-size:13px !important;color:#238f53'>Success</span>",
            text: response.data.result.message,

            width: "300px",
            confirmButtonColor: "#238f53",
          }).then((res) => {
            if (res.isConfirmed) {
              this.$router.go();
            }
          });
        }
      } catch (e) {
        this.$swal({
          title:
            "<span style='font-size:12px !important;color:red'>Failed</span>",
          text: "An Error Occured",
          width: "300px",
          confirmButtonColor: "#238f53",
        }).then(
          setTimeout(() => {
            this.$router.go();
          }, 3000)
        );
      }
    },
    async onSelectedFile(event) {
      const files = event.target.files;
      let filename = files[0].name;
      if (filename.lastIndexOf(".") <= 0) {
        alert("no");
      }
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        this.imageUrl = fileReader.result;
      });
      fileReader.readAsDataURL(files[0]);
      this.image = files[0];
      console.log(this.image);
      const formdata = new FormData();
      formdata.append("image", this.image, this.image.name);

      try {
        const response = await axios.post(
          `v1/user/upload_profile_picture`,
          formdata
        );
        if (response.data.success == true) {
          this.$swal({
            title:
              "<span style='font-size:13px !important;color:#238f53'>Success</span>",
            text: response.data.result.message,

            width: "300px",
            confirmButtonColor: "#238f53",
          }).then((res) => {
            if (res.isConfirmed) {
              this.$router.go();
            }
          });
        } else {
          this.$swal({
            title:
              "<span style='font-size:12px !important;color:red'>Failed</span>",
            text: "An Error Occured",
            width: "300px",
            confirmButtonColor: "#238f53",
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
    increntCon() {
      // this.count++;
      this.items.push({ type: "", level: "", responseTime: "" });
    },
    removeCon(index) {
      this.items.splice(index, 1);
    },
    async removeAlert(index) {
      this.$swal({
        title:
          "<span style='font-size:12px !important'>Are you sure you want to delete?</span>",
        confirmButtonColor: "#238f53",
        width: "300px",
        confirmButton: true,
        imageHeight: "30",
        imageWidth: "30",
        icon: "warning",
        fontSize: "12px",
        buttons: true,
        padding: "5px !important",
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete.isConfirmed) {
          try {
            const response = await axios.delete(
              `v1/admin/delete_alert_type/${index}`
            );
            if (response.status == 200 || response.status == 201) {
              this.$swal({
                text: response.data.result.message,
                icon: "success",
                width: "300px",

                confirmButtonColor: "#238f53",
              }).then((res) => {
                if (res.isConfirmed) {
                  this.$router.go();
                }
              });
            }
          } catch (e) {
            console.log(e);
          }
        }
      });
    },

    async addAlert() {
      const thisdata = {
        alertTypes: this.items,
      };
      try {
        const response = await axios.post(
          "v1/admin/create_alert_type",
          thisdata
        );
        if (response.data.success == true) {
          this.$swal({
            title:
              "<span style='font-size:13px !important;color:#238f53'>Success</span>",
            text: response.data.result.message,

            width: "300px",
            confirmButtonColor: "#238f53",
          }).then((res) => {
            if (res.isConfirmed) {
              this.$router.go();
            }
          });
        } else {
          this.$swal({
            title:
              "<span style='font-size:12px !important;color:red'>Failed</span>",
            text: "An Error Occured",
            width: "300px",
            confirmButtonColor: "#238f53",
          });
        }
      } catch (e) {
        this.$swal({
          title:
            "<span style='font-size:12px !important;color:red'>Failed</span>",
          text: "An Error Occured",
          width: "300px",
          confirmButtonColor: "#238f53",
        });
      }
    },
    removerecipient(index) {
      this.sosrecipient.splice(index, 1);
    },
    addrecipient() {
      if (this.number.length == 11) {
        this.sosrecipient.push(
          this.number.slice(0, 1) == "0"
            ? "234" + this.number.slice(1)
            : this.number
        );
        var button = document.getElementById("closeme");
        button.click();
      } else {
        this.$swal({
          title:
            "<span style='font-size:13px !important;color:crimson'>Failed</span>",
          text: "Recipient Number should be 11",

          width: "300px",
          confirmButtonColor: "#238f53",
        });
      }

      this.number = "";
    },
    showModalInfo() {
      this.showModal = true;
    },
    hideModalInfo() {
      this.showModal = false;
    },
    selectedstate(stt) {
      this.state = stt;
    },
    setSelected(tab) {
      this.selected = tab;
    },
    setSelected2(tab) {
      this.selecteds = tab;
    },
    async updatePassword() {
      const data = {
        oldPassword: this.opassword,
        newPassword: this.npassword,
      };
      try {
        const response = await axios.post("v1/user/update_password", data);

        if (response.data.success == true) {
          this.$swal({
            title:
              "<span style='font-size:13px !important;color:#238f53'>Success</span>",
            text: response.data.result.message,

            width: "300px",
            confirmButtonColor: "#238f53",
          }).then((res) => {
            if (res.isConfirmed) {
              this.$router.go();
            }
          });
        }
      } catch (e) {
        this.$swal({
          title:
            "<span style='font-size:12px !important;color:red'>Failed</span>",
          text: "An Error Occured",
          width: "300px",
          confirmButtonColor: "#238f53",
        });
      }
    },
  },
  async mounted() {
    try {
      let response = await axios.get("v1/user/get_user_profile");
      this.adminrole = response.data.result.data.userRole;
      this.adminstate = response.data.result.data.state;

      //this.notification = response.data.result.data.sos;
    } catch (e) {
      console.log(e);
    }
    navigator.geolocation.getCurrentPosition(
      (position) => {
        this.lat = position.coords.latitude;
        this.lag = position.coords.longitude;
      },
      (error) => {
        console.log(error.message);
      }
    );
    try {
      let response = await axios.get("v1/notification/get_all_alert_type");
      this.altypes = response.data.result.data.types;
    } catch (e) {
      console.log(e);
    }

    try {
      let response = await axios.get("v1/admin/get_sos_settings");
      console.log(response);
      this.sosrecipient = response.data.result.data.recipients;
      this.message = response.data.result.data.message;
      this.loading = false;
    } catch (e) {
      console.log(e);
    }
  },

  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),

    filterHL: function () {
      return this.altypes.filter((item) => {
        return item.level.match("HL");
      });
    },
    filterCL: function () {
      return this.altypes.filter((item) => {
        return item.level.match("CL");
      });
    },
    filterML: function () {
      return this.altypes.filter((item) => {
        return item.level.match("ML");
      });
    },
    filterLL: function () {
      return this.altypes.filter((item) => {
        return item.level.match("LL");
      });
    },
  },
};
</script>
<style scoped>
.bg-home-main {
  background: #f5f6f7;
  min-height: calc(100vh - 50px);
  overflow: auto;
  position: relative;
}
.btn1 {
  border: 2px solid #238f53;
  color: #238f53;
  padding: 4px 7px 4px 7px !important;
  font-weight: 500;
  outline: none !important;
  font-size: 12px;
  margin-right: 5px;
}
.btn2 {
  border: 2px solid #238f53;
  color: #fff;
  font-size: 12px;
  background: #238f53;
  padding: 4px 7px 4px 7px !important;
  font-weight: 500;
  outline: none !important;
  margin-left: 5px;
}
.conbg {
  border-radius: 12px;
}
.exptime {
  font-size: 12px;
}
.container {
  width: 32%;
  margin: 5px 2.5px 5px 2.5px;
  padding: 12px;
  border-radius: 6px;
  text-align: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #455c7d;
}
.container h5 {
  font-size: 14px;
  align-content: center;
  display: flex;
  padding: 0px !important;
  margin: 0px !important;
}
.container span {
  font-size: 12px;
}
@keyframes slide-in {
  from {
    transform: translateX(-100%);
    transition: 0.3s ease-out;
  }
  to {
    transform: translateX(0%);
    transition: 0.3s ease-out;
  }
}
.slide1 {
  animation: slide-in 1000ms;
  animation-timing-function: linear;
}
.slide2 {
  animation: slide-in 2000ms;
  animation-timing-function: linear;
}
.slide3 {
  animation: slide-in 3000ms;
  animation-timing-function: linear;
}
.reset {
  font-size: 13px;
  font-weight: 600;
  color: #238f53;
  float: right;
}
.filter {
  font-size: 13px;
  font-weight: 600;
  color: #238f53;

  align-items: center;
  display: flex;
  margin-left: -20px;
}
.dropdown-toggle {
  background: #fff !important;

  font-size: 12px !important;
  width: 90%;
  padding-top: 5px !important ;
  padding-bottom: 5px !important;
  justify-content: space-between !important;
  display: flex !important;
  align-items: center;
  color: #455c7d;
  border-radius: 3px !important;
  border: 1px solid #c9d9e5 !important;
}
.dropdown {
  width: 100%;

  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.conflex1 {
  width: 80%;

  padding: 5px 0px 5px 0px;

  display: flex;
}
.conflex2 {
  width: 20%;

  padding: 5px 0px 5px 0px;
}
th {
  font-size: 12px !important;
  border: none !important;
  background: #f5f6f7;
  color: #090d14;
}
td {
  border: none !important;
  font-size: 12px !important;
}
tbody tr:nth-child(even) {
  background: #f5f6f7;
  border-radius: 30px !important;
}
tr {
  border-radius: 30px !important;
  color: #5c7597;
}
.status {
  color: #9d1f1f;
  background: #f2d5d5;
  border-radius: 20px;
  padding-top: 1px !important ;
  padding-bottom: 1px !important ;
  padding-right: 5px !important;
  padding-left: 5px !important;
  display: inline-block;
  align-items: center;
  font-size: 12px !important;
}
.center {
  height: 100vh;
  align-items: center;
  display: flex;
  width: 100vw;
  place-items: center;
  justify-content: center;
}
.status2 {
  color: #fff;
  background: #238f53;
  border-radius: 20px;
  padding-top: 1px !important ;
  padding-bottom: 1px !important ;
  padding-right: 5px !important;
  padding-left: 5px !important;
  display: inline-block;
  align-items: center;
  font-size: 12px !important;
}

.modal-footer {
  border-top: none !important;
}
.mdil li {
  display: flex !important;
  justify-content: space-between !important;
  padding: 3px !important;
  line-height: 35px;
  border: none !important;
  text-align: left !important;
}
.mdil li h6 {
  font-size: 12px;
  color: #7993b1;
}
.mdil li span {
  font-size: 12px;
}
.dtaedropdown {
  border-radius: 12px !important;
  padding: 10px;
  width: 400px;
}
.btn1 {
  border: 1px solid #238f53;
  color: #238f53;
  padding: 4px 7px 4px 7px !important;
  font-weight: 500;
  outline: none !important;
  font-size: 10px;
  margin: 0px auto !important;
}
.btn3 {
  border: 1px solid #238f53;
  color: #238f53;
  padding: 4px 7px 4px 7px !important;
  font-weight: 500;
  outline: none !important;
  font-size: 10px;
}
.btn2 {
  border: 2px solid #238f53;
  color: #fff;
  font-size: 12px;
  background: #238f53;
  padding: 4px 7px 4px 7px !important;
  font-weight: 500;
  outline: none !important;
  margin-left: 5px;
}
.sideiconbuttob {
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  margin: 2px;
  padding: 0px !important;
}

.sideiconbuttob .material-icons {
  font-size: 9px !important;
  margin: 0px auto !important;
  display: inline-flex;
}
.butn1 {
  border: 1px solid #4cc27e !important;
  color: #4cc27e !important;
}
.butn2 {
  border: 1px solid #f5b0b0 !important;
  color: #f5b0b0 !important;
  background: #f2d5d5 !important;
}
.avater {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  border: 1px solid #aaa;
  margin: 0px auto;
}
.gbg ul li {
  padding-top: 2px !important ;
  padding-bottom: 2px !important;
  display: flex;
  justify-content: space-between;
  border: none !important;
  line-height: 20px;
}
.col label {
  display: block;
}
.pnp h6 {
  font-size: 12px;
  padding: 1rem;
}
.nucard {
  background: #fff;
  float: left;
  font-size: 11px;
  align-items: center;
  display: flex;
  border-radius: 5px;
  margin: 2px;
}
li {
  line-height: 30px !important;
}
</style>
