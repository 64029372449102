<template>
  <div class="w-100 app">
    <div
      style="
        width: 100vw;
        height: 100vh;
        background: red;
        position: absolute;
        z-index: 9999;
      "
      v-show="showiframe"
      id="iframHolder"
    >
      <iframe
        :src="`https://www.google.com/maps?q=${selectedMarker.address}`"
        name="iframenamr"
        frameborder="0"
      ></iframe>
    </div>
    <SideBar />
    <div class="w-100">
      <Header />
      <div
        class="modal"
        style="display: block !important; z-index: 1000000"
        v-show="status"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          class="modal-dialog"
          role="document"
          style="max-width: calc(100% - 200px); width:100%; !important; border-radius: 30px"
        >
          <div
            class="modal-content"
            style="
              padding: 10px !important;
              border-radius: 10px;
              max-width: 500px;
            "
          >
            <div
              class="modal-header"
              style="
                border-bottom: none !important ;
                display: flex;
                align-items: center;
              "
            >
              <h5
                class="modal-title"
                id="exampleModalLabel"
                style="
                  font-size: 12px;
                  color: #334563;
                  margin: 0px !important ;
                  padding: 0px !important;
                "
                v-if="selectedMarker.incidenceType"
              >
                Alert Details
              </h5>
              <h5
                class="modal-title"
                id="exampleModalLabel"
                style="
                  font-size: 12px;
                  color: #334563;
                  margin: 0px !important ;
                  padding: 0px !important;
                "
                v-else
              >
                SOS
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                id="closeme"
              >
                <span
                  aria-hidden="true"
                  @click="closeModalOpen"
                  style="font-size: 20px"
                  >&times;</span
                >
              </button>
            </div>
            <div
              class="modal-body"
              style="
                padding: 5px !important;
                border-top: none !important;
                border-bottom: none !important;
              "
            >
              <div class="d-flex justify-content-between mb-3">
                <div
                  style="
                    background: #f2d5d5;
                    border-radius: 5px;
                    align-items: center;
                    display: flex;
                  "
                  class="pr-2 pl-2 pt-2 pb-2"
                >
                  <span
                    style="
                      color: #9d1f1f;
                      font-size: 12px;
                      text-transform: uppercase;
                    "
                    >{{ selectedMarker.status }}</span
                  >
                </div>
                <button
                  v-show="
                    user.userRole === 'super_admin' ||
                    user.userRole == 'global_admin'
                  "
                  :disabled="isdiabled"
                  style="
                    background: #238f53;
                    border-radius: 5px;
                    align-items: center;
                    display: flex;
                    border: 1px solid #238f53;
                  "
                  class="pr-2 pl-2"
                  @click="MarkAsResolved"
                  v-if="selectedMarker.incidenceType"
                >
                  <span style="color: #ffff; font-size: 12px" id="myresolved"
                    >Mark as resolved</span
                  >
                </button>
                <button
                  :disabled="isdiabled"
                  v-show="user.userRole !== 'view_admin'"
                  style="
                    background: #238f53;
                    border-radius: 5px;
                    align-items: center;
                    display: flex;
                    border: 1px solid #238f53;
                  "
                  class="pr-2 pl-2"
                  @click="MarkAsResolvedSOS"
                  v-else
                >
                  <span style="color: #ffff; font-size: 12px" id="myresolvedsos"
                    >Mark as resolved</span
                  >
                </button>
              </div>
              <div class="w-100" style="height: 180px">
                <Map :centers="current" :current="center" />
              </div>

              <a
                target="_blank"
                :href="`https://www.google.com/maps/search/?api=1&query=Google&query_place_id=${selectedMarker?.placeId}

`"
                type="button"
                class="btn btn-primary mt-2"
              >
                View Route
              </a>

              <div class="d-flex justify-content-between mt-3">
                <h5
                  style="color: #161f2e; font-size: 12px"
                  v-for="itemme in selectedMarker.alertTypes"
                  :key="itemme"
                >
                  {{ itemme.type }} ({{ itemme.level }})
                </h5>
                <span
                  style="font-size: 12px; color: #e2b93b"
                  v-if="selectedMarker.incidenceType"
                  >{{ selectedMarker.status }}</span
                >
              </div>
              <div class="list-group mdil">
                <div
                  class="d-flex justify-content"
                  style="align-items: center"
                  data-toggle="tooltip"
                  data-placement="top"
                  v-for="name in selectedMarker.user"
                  :key="name"
                  :title="`STATE: ${name.state}; LGA: ${name.lga}; RA: ${name.ra}

                  `"
                >
                  <h6>Sender</h6>
                  <span> {{ name.firstName }} {{ name.lastName }}</span>
                </div>
                <div class="d-flex justify-content" style="align-items: center">
                  <h6>Sender</h6>
                  <span>{{ selectedMarker.senderPhoneNumber }}</span>
                </div>
                <div class="d-flex justify-content" style="align-items: center">
                  <h6>Date/Time</h6>
                  <span>{{
                    moment(selectedMarker.createdAt).format("DD-MM-YYYY h:ma")
                  }}</span>
                </div>

                <div
                  class="d-flex justify-content"
                  style="align-items: center"
                  v-if="selectedMarker.incidenceType"
                >
                  <h6>Polling Unit</h6>
                  <span>{{ selectedMarker.pollingUnit }}</span>
                </div>
                <div class="d-flex justify-content" style="align-items: center">
                  <h6>Address</h6>
                  <span>{{ selectedMarker.address }}</span>
                </div>
                <div class="d-flex justify-content" style="align-items: center">
                  <h6>Place ID</h6>
                  <span>{{ selectedMarker?.placeId }}</span>
                </div>
                <div class="d-flex justify-content" style="align-items: center">
                  <h6>ID</h6>
                  <span>{{ selectedMarker._id }}</span>
                </div>
                <div
                  class="d-flex justify-content"
                  style="align-items: center"
                  v-if="selectedMarker.incidenceType"
                >
                  <h6>Description</h6>
                  <span>No description</span>
                </div>
              </div>
              <div
                class="d-flex justify-content-between mt-2 mb-2"
                v-if="selectedMarker.incidenceType"
              >
                <div class="d-grid" style="line-height: 15px">
                  <h6
                    style="
                      color: #c9d9e5;
                      font-size: 11px;
                      padding: 2px !important;
                      margin: 2px !important;
                    "
                  >
                    Incident has not been assigned
                  </h6>
                  <span style="color: #161f2e; font-size: 12px"
                    >Assign Response Team</span
                  >
                </div>
                <button
                  type="button"
                  class="btn"
                  style="background: #238f53; color: #fff; font-size: 13px"
                  data-toggle="modal"
                  data-target="#exampleModalLong"
                  @click="closeModalOpen"
                  v-if="selectedMarker.incidenceType"
                >
                  Assign Response Team
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        style="z-index: 900000"
        id="exampleModalLong"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLongTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content" style="padding: 10px !important">
            <div
              class="modal-header"
              style="
                margin-top: 2px !important;
                margin-bottom: 2px !important;
                padding: 10px !important;
              "
            >
              <h5
                class="modal-title"
                id="exampleModalLongTitle"
                style="
                  margin-top: 0px !important;
                  margin-bottom: 0px !important;
                  font-size: 14px;
                "
              >
                Incidence Details
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                id="closeme5"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form @submit.prevent="assignSubmit">
                <div class="form-group">
                  <label for="exampleInputEmail1">Response Name</label>
                  <div
                    class="btntab d-flex"
                    style="
                      border: 1px solid #f5f6f7;
                      justify-content: left;
                      width: 100%;
                    "
                  >
                    <input
                      type="text"
                      style="
                        width: 100%;
                        border: none !important;
                        outline: none;
                        color: #7993b1;
                        padding: 8px;
                        font-size: 12px;
                      "
                      placeholder="Enter a Team name"
                      v-model="searchres"
                      @focus="hidegetres"
                    />
                    <span
                      class="material-icons"
                      style="
                        display: flex;
                        align-self: center;
                        margin-right: 10px;
                        font-size: 14px;
                        color: #7993b1;
                      "
                      >search</span
                    >
                  </div>
                  <ul class="list-group" v-show="getres">
                    <!---  <li
                      class="list-group-item"
                      style="font-size: 12px"
                      v-for="item in filterName"
                      :key="item"
                      @click="getvalres(item)"
                    >
                      {{ item.firstName }} {{ item.lastName }}
                    </li>-->

                    <li
                      class="list-group-item"
                      style="font-size: 12px; diplay: flex; align-items: center"
                      v-for="item in filterName"
                      :key="item"
                    >
                      <label class="classlist">
                        <input
                          class="form-check-input me-1"
                          type="checkbox"
                          :value="item._id"
                          aria-label="..."
                          v-model="respId"
                        />
                        <span class="checkmark"></span>
                        <label
                          class="form-check-label"
                          for="flexCheckCheckedDisabled"
                        >
                          {{ item.firstName }} {{ item.lastName }}
                        </label>
                      </label>
                    </li>
                  </ul>
                </div>

                <div class="d-flex justify-content-between">
                  <button
                    type="button"
                    class="btn w-50 mr-2"
                    style="border: 2px solid #238f53; font-size: 12px"
                    data-toggle="modal"
                    data-target="#exampleModalLong"
                  >
                    Go Back
                  </button>
                  <button
                    :disabled="isdisabled"
                    type="submit"
                    class="btn w-50 ml-2"
                    style="background: #238f53; color: #fff; font-size: 12px"
                  >
                    Assign
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!--- End Assign Modal -->

      <!--- Modal End -->

      <main class="d-flex">
        <div class="rowsm">
          <h5 style="color: #334563; font-size: 13px; line-height: 20px">
            Realtime flashpoint -
            {{
              parseFloat(
                parseFloat(sosAnalytics.totalSos) -
                  parseFloat(sosAnalytics.resolvedSos)
              ) + parseInt(filternopending != "" ? filternopending[0].count : 0)
            }}
          </h5>

          <ul class="list-group mt-0 mb-0 bg-none mylight">
            <li
              class="list-group-item pb-0 d-flex justify-content-between bg-none"
            >
              <div style="font-size: 12px; font-weight: 500; color: #adf802">
                Low
              </div>
              <span>{{ filternofLL != "" ? filternofLL[0].count : 0 }}</span>
            </li>
            <li
              class="list-group-item pb-0 d-flex justify-content-between bg-none"
            >
              <div
                class=""
                style="font-size: 12px; font-weight: 500; color: yellow"
              >
                Medium
              </div>
              <span>{{ filternofML != "" ? filternofML[0].count : 0 }}</span>
            </li>
            <li
              class="list-group-item pb-0 d-flex justify-content-between bg-none"
            >
              <div
                class=""
                style="font-size: 12px; font-weight: 500; color: orange"
              >
                High
              </div>
              <span>{{ filternofHL != "" ? filternofHL[0].count : 0 }}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between bg-none">
              <div style="font-size: 14px; font-weight: 400; color: red">
                Critical
              </div>
              <span>{{
                parseFloat(
                  filternofCL != "" &&
                    filternofCL[0] &&
                    !isNaN(filternofCL[0].count)
                    ? filternofCL[0].count
                    : 0
                ) +
                parseFloat(
                  !isNaN(sosAnalytics.totalSos) && sosAnalytics.totalSos !== ""
                    ? sosAnalytics.totalSos
                    : 0
                ) -
                parseFloat(
                  !isNaN(sosAnalytics.resolvedSos) &&
                    sosAnalytics.resolvedSos !== ""
                    ? sosAnalytics.resolvedSos
                    : 0
                )
              }}</span>
            </li>
          </ul>
          <h5 style="color: #334563; font-size: 12px; line-height: 30px">
            Analytics
          </h5>
          <div class="bb">
            <div class="containelgg" style="background: blue">
              <h6 style="color: #fff">Total no. of Alerts</h6>
              <span style="color: #fff">{{
                parseInt(filternopending != "" ? filternopending[0].count : 0) +
                parseInt(filternoresolve != "" ? filternoresolve[0].count : 0) +
                parseInt(
                  filternoprogress != "" ? filternoprogress[0].count : 0
                ) +
                parseInt(filterCancelled != "" ? filterCancelled[0].count : 0)
              }}</span>
            </div>

            <div class="containelgg" style="background: lightblue">
              <h6 style="color: #fff">Total no. of Assigned Alert</h6>
              <span style="color: #fff">{{
                filternoprogress != "" ? filternoprogress[0].count : 0
              }}</span>
            </div>
            <div class="containelgg" style="background: red">
              <h6 style="color: #fff">Total no. of Unresolved Alerts</h6>
              <span style="color: #fff">{{
                filternopending != "" ? filternopending[0].count : 0
              }}</span>
            </div>
            <div
              class="containelgg"
              style="background: green; margin-bottom: 30px"
            >
              <h6 style="color: #fff">Total no. of Resolved Alerts</h6>
              <span style="color: #fff">{{
                parseInt(filternoresolve != "" ? filternoresolve[0].count : 0) +
                parseInt(filterCancelled != "" ? filterCancelled[0].count : 0)
              }}</span>
            </div>

            <div
              class="containelgg"
              style="background: blue; margin-bottom: 10px"
            >
              <h6 style="color: #fff">Total no. of SOS</h6>
              <span style="color: #fff">{{ sosAnalytics.totalSos }}</span>
            </div>
            <div class="containelgg" style="background: red">
              <h6 style="color: #fff">Total no. of Unresolved SOS</h6>
              <span style="color: #fff">{{
                parseFloat(parseFloat(filterSOS.length))
              }}</span>
            </div>
            <div
              class="containelgg"
              style="background: green; margin-bottom: 30px"
            >
              <h6 style="color: #fff">Total no. of Resolved SOS</h6>
              <span style="color: #fff">{{ sosAnalytics.resolvedSos }}</span>
            </div>
            <div
              class="containelgg"
              style="background: blue; margin-bottom: 10px"
            >
              <h6 style="color: #fff">Total no. of USSD SOS</h6>
              <span style="color: #fff">{{ sosAnalytics.totalSosUSSD }}</span>
            </div>

            <div
              class="containelgg"
              style="background: green; margin-bottom: 10px"
            >
              <h6 style="color: #fff">Total no. of Resolved USSD SOS</h6>
              <span style="color: #fff">{{
                sosAnalytics.resolvedSosUSSD
              }}</span>
            </div>
            <div
              class="containelgg"
              style="background: red; cursor: pointer; margin-bottom: 10px"
              @click="this.$router.push('/alert/alert')"
            >
              <h6 style="color: #fff">Total no. of Unresolved USSD SOS</h6>
              <span style="color: #fff">{{ sosAnalytics.pendingSosUSSD }}</span>
            </div>
          </div>
          <div
            style="margin-bottom: 30px"
            v-show="
              user.userRole == 'super_admin' || user.userRole == 'global_admin'
            "
          >
            <h5
              class="mb-2"
              style="
                color: #334563;
                font-size: 12px;
                margin: 0px !important;
                padding: 0px !important;
                line-height: 40px;
              "
              v-if="
                user.userRole == 'super_admin' ||
                user.userRole == 'global_admin'
              "
            >
              Location with the most alerts
            </h5>

            <div
              class="col"
              v-for="(item, index) in moststate.slice(0, 9)"
              :key="item"
            >
              <div
                :class="index == clickindex ? 'active' : null"
                class="border rounded region"
                style="float: left; cursor: pointer"
                @click="updatestate(item, index)"
              >
                {{ item.state }}
              </div>
            </div>
            <button
              v-if="clickindex != null"
              class="btn w-100 active mt-2"
              @click="resetallalert"
              style="font-size: 12px; margin-bottom: 20px"
            >
              All State
            </button>
          </div>
        </div>
        <div class="rowsm2 p-2">
          <div class="w-100 p-2 d-flex justify-content-between">
            <h6
              style="
                margin: 0px !important ;
                padding: 0px !important;
                font-size: 14px;
                flex: 3;
                align-items: center;
                display: flex;
              "
            >
              Filter By:
            </h6>
            <div
              class="d-flex"
              style="flex: 7; justify-content: right"
              v-if="
                user.userRole == 'super_admin' ||
                user.userRole == 'global_admin'
              "
            >
              <div class="dropdown">
                <button
                  class="btn dropdown-toggle mr-2"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  style="background: #fff; font-size: 12px"
                >
                  {{ myalertstae == "" ? "State" : myalertstae }}
                </button>
                <div
                  class="dropdown-menu dpr overflow-auto position-absolute left-0 right-auto"
                  aria-labelledby="dropdownMenuButton"
                  style="max-height: 500px; min-height: 500px"
                >
                  <div class="w-100 d-flex justify-content-center bgmtt">
                    <input
                      type="search"
                      v-model="search"
                      class="search border-none p-1 searcg"
                      placeholder="Search State"
                    />
                    <span class="material-icons">search</span>
                  </div>
                  <a
                    class="dropdown-item"
                    href="#"
                    style="font-size: 12px"
                    type="button"
                    v-for="item in statess"
                    :key="item"
                    @click="getbystate(item)"
                    >{{ item }}
                  </a>
                </div>
              </div>
              <div
                class="btn-group"
                v-if="
                  user.userRole == 'super_admin' ||
                  user.userRole == 'global_admin'
                "
              >
                <button
                  type="button"
                  class="btn dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  style="background: #fff; font-size: 12px"
                >
                  {{ lg == "" ? " Sub locality" : lg }}
                </button>
                <div
                  class="dropdown-menu dropdown-menu-right"
                  style="max-height: 500px; min-height: 500px; overflow: auto"
                >
                  <button
                    class="dropdown-item"
                    type="button"
                    v-for="item in selectedstates"
                    :key="item.id"
                    style="font-size: 11px"
                    @click="getbylga(item)"
                  >
                    {{ item }}
                  </button>
                </div>
              </div>
            </div>
            <a
              href="javascript:void()"
              type="button"
              class="p-1 m-1"
              @click="resetDashboard"
              >Reset</a
            >
          </div>
          {{ filterSOS.length }}
          <div
            style="
              height: calc(100vh - 140px);
              width: 100%;
              box-sizing: border-box;
            "
            class="m-2 border rounded overflow-auto"
          >
            <Loader v-if="loading" />
            <l-map
              v-else
              :zoom="zoom"
              :max-zoom="maxzoom"
              :center="{ lat: lat, lng: lag }"
              :bounds="bound"
              style="height: 100% !important; width: 100%"
            >
              <l-control-layers position="topright"></l-control-layers>
              <l-tile-layer :url="url" />

              <l-control-scale
                position="topright"
                :imperial="true"
                :metric="false"
              ></l-control-scale>

              <!-- Use default icon -->

              <marker-cluster
                :options="{ showCoverageOnHover: false, chunkedLoading: true }"
              >
                <l-marker
                  v-for="item in filterSOS"
                  :key="item"
                  :lat-lng="[
                    item.location.coordinates[1],
                    item.location.coordinates[0],
                  ]"
                  v-model="zoom"
                  @click="showModalOpen(item, 1)"
                >
                  <div>
                    <l-icon
                      :icon-anchor="staticAnchor"
                      class-name="someExtraClass"
                      v-model="zoom"
                    >
                      <div class="glow">
                        <div
                          style="
                            width: 10px;
                            height: 10px;
                            background: #9d1f1f !important;
                            border-radius: 100%;
                          "
                        ></div>
                      </div>
                      <div
                        style="
                          font-size: 12px;
                          color: black;
                          font-weight: 600;
                          text-align: center;
                        "
                      >
                        sos
                      </div>
                    </l-icon>
                  </div>
                </l-marker>

                <!-- Alert Marker -->
                <div v-for="(notication, index) in filterAllAlert" :key="index">
                  <l-marker
                    :lat-lng="[
                      notication.location.coordinates[1],
                      notication.location.coordinates[0],
                    ]"
                    v-model="zoom"
                    @click="showModalOpen(notication, 2)"
                    v-if="notication.alertTypes.length > 0"
                  >
                    <div
                      v-for="(type, index) in notication.alertTypes"
                      :key="index"
                    >
                      <l-icon
                        :icon-anchor="staticAnchor"
                        class-name="someExtraClass"
                        v-if="type.level == 'CL'"
                      >
                        <div class="glow">
                          <div
                            style="
                              width: 10px;
                              height: 10px;
                              background: #9d1f1f;
                              border-radius: 100%;
                            "
                          ></div>
                        </div>
                      </l-icon>
                      <l-icon
                        :icon-anchor="staticAnchor"
                        class-name="someExtraClass"
                        v-if="type.level == 'HL'"
                      >
                        <div
                          class="hl"
                          v-if="type.level == 'HL'"
                          style="border: 1px solid orange"
                        >
                          <div
                            style="
                              width: 10px;
                              height: 10px;
                              background: orange;
                              border-radius: 100%;
                            "
                          ></div>
                        </div>
                      </l-icon>
                      <l-icon
                        :icon-anchor="staticAnchor"
                        class-name="someExtraClass"
                        v-if="type.level == 'ML'"
                      >
                        <div
                          class="ml"
                          v-if="type.level == 'ML'"
                          style="border: 1px solid orange"
                        >
                          <div
                            style="
                              width: 10px;
                              height: 10px;
                              background: yellow;
                              border-radius: 100%;
                            "
                          ></div>
                        </div>
                      </l-icon>
                      <l-icon
                        :icon-anchor="staticAnchor"
                        class-name="someExtraClass"
                        v-if="type.level == 'LL'"
                      >
                        <div
                          class="ll"
                          v-if="type.level == 'LL'"
                          style="border: 1px solid #adf802"
                        >
                          <div
                            style="
                              width: 10px;
                              height: 10px;
                              background: #adf802;
                              border-radius: 100%;
                            "
                          ></div>
                        </div>
                      </l-icon>
                    </div>
                  </l-marker>
                </div>
              </marker-cluster>
            </l-map>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>
<script>
import Header from "../components/header.vue";
import SideBar from "../components/sidebar.vue";
import Loader from "../components/loader.vue";
import Map from "../components/Map.vue";
import { mapGetters } from "vuex";
import { latLng } from "leaflet/dist/leaflet-src.esm";
import MarkerCluster from "../components/MarkerCluster.vue";

import {
  LMap,
  LTileLayer,
  LMarker,
  LIcon,
  LControlLayers,
  LControlScale,
} from "@vue-leaflet/vue-leaflet/src/lib";
import axios from "axios";
import "leaflet/dist/leaflet.css";
import moment from "moment";
import io from "socket.io-client";
let socket = io("wss://api.inec-sans.com", {
  transports: ["websocket"],
});

socket.on("connect", () => {
  console.log("Connected to WebSocket server");
});
socket.on("disconnect", () => {
  console.log("Disconnected from WebSocket server");
});

socket.on("error", (error) => {
  console.error("WebSocket error:", error);
});

export default {
  name: "Dashboard-App",
  components: {
    SideBar,
    Loader,
    Header,
    MarkerCluster,
    LMap,
    Map,
    LTileLayer,
    LMarker,
    LIcon,
    LControlLayers,
    LControlScale,
  },
  sockets: {
    connect: function (data) {
      console.log(data + "connection");
    },
    customEmit: function (data) {
      console.log(data);
    },
  },

  data() {
    return {
      states: [],
      alertid: "",
      selectedMarker: [],
      selectedstates: [],
      notification: [],
      responseTeam: [],
      myalertstae: "",
      loading: true,
      pending: 0,
      cancelled: 0,
      resolved: 0,
      lg: "",
      adminrole: "admin",
      adminstate: "",
      bound: 100,
      progress: 0,
      maxzoom: 18,
      clickindex: null,
      count: 0,
      cl: 0,
      hl: 0,
      ll: 0,
      ml: 0,
      alerts: [],
      analytics: [],
      sosAnalytics: [],
      exactstate: "",
      getres: false,
      slectedcoordslat: 0,
      isFiltering: false,
      showiframe: false,
      slectedcoordslag: 0,
      selectedlevel: "",
      choosenstate: "",
      searchres: "",
      status: false,
      current: [],
      alertState: [],
      newcountsos: 0,
      prevcountalert: 0,
      prevcountsos: 0,
      newcountalert: 0,
      search: "",
      lga: [],
      filteredState: [],
      stateData: [
        {
          id: 1,
          name: "ABIA",
          zone: "South East",
          coordinates: { lat: "5.45409529947607", lng: "7.5153071" },
        },
        {
          id: 2,
          name: "ADAMAWA",
          zone: "North East",
          coordinates: { lat: "9.512977199417053", lng: "12.388188699999999" },
        },
        {
          id: 3,
          name: "AKWA IBOM",
          zone: "South South",
          coordinates: { lat: "4.940863799511036", lng: "7.8412267" },
        },
        {
          id: 4,
          name: "ANAMBRA",
          zone: "South East",
          coordinates: { lat: "6.2183135994341985", lng: "6.9531842" },
        },
        {
          id: 5,
          name: "BAUCHI",
          zone: "North East",
          coordinates: { lat: "10.622828399473097", lng: "10.028775399999999" },
        },
        {
          id: 6,
          name: "BAYELSA",
          zone: "South South",
          coordinates: { lat: "4.762978599524332", lng: "6.028898" },
        },
        {
          id: 7,
          name: "BENUE",
          zone: "North Central",
          coordinates: { lat: "7.35057469939717", lng: "8.777287699999999" },
        },
        {
          id: 8,
          name: "BORNO",
          zone: "North East",
          coordinates: { lat: "12.18753919959776", lng: "13.308003399999999" },
        },
        {
          id: 9,
          name: "CROSS RIVER",
          zone: "South South",
          coordinates: { lat: "5.867196599451846", lng: "8.5204774" },
        },
        {
          id: 10,
          name: "DELTA",
          zone: "South South",
          coordinates: { lat: "5.527306099471514", lng: "6.178416699999999" },
        },
        {
          id: 11,
          name: "EBONYI",
          zone: "South East",
          coordinates: { lat: "6.199691799435065", lng: "8.0348906" },
        },
        {
          id: 12,
          name: "EDO",
          zone: "South South",
          coordinates: { lat: "6.607657499417959", lng: "5.9722713" },
        },
        {
          id: 13,
          name: "EKITI",
          zone: "South West",
          coordinates: { lat: "7.736890999391845", lng: "5.2738326" },
        },
        {
          id: 14,
          name: "ENUGU",
          zone: "South East",
          coordinates: { lat: "6.553609399419997", lng: "7.4143061" },
        },
        {
          id: 15,
          name: "FCT",
          zone: "North Central",
          coordinates: { lat: "8.831122799397733", lng: "7.172467300000001" },
        },
        {
          id: 16,
          name: "GOMBE",
          zone: "North East",
          coordinates: { lat: "10.383009999458512", lng: "11.206567" },
        },
        {
          id: 17,
          name: "IMO",
          zone: "South East",
          coordinates: { lat: "5.585945599467946", lng: "7.0669651" },
        },
        {
          id: 18,
          name: "JIGAWA",
          zone: "North West",
          coordinates: { lat: "12.325236199610885", lng: "7.0669651" },
        },
        {
          id: 19,
          name: "KADUNA",
          zone: "North West",
          coordinates: { lat: "10.382531799458487", lng: "7.853322600000001" },
        },
        {
          id: 20,
          name: "KANO",
          zone: "North West",
          coordinates: { lat: "11.894838899570903", lng: "8.5364136" },
        },
        {
          id: 21,
          name: "KATSINA",
          zone: "North West",
          coordinates: { lat: "12.563082499634245", lng: "4.107454499999999" },
        },
        {
          id: 22,
          name: "KEBBI",
          zone: "North West",
          coordinates: { lat: "11.416757399530335", lng: "7.0669651" },
        },
        {
          id: 23,
          name: "KOGI",
          zone: "North Central",
          coordinates: { lat: "7.79496019939138", lng: "6.686866899999999" },
        },
        {
          id: 24,
          name: "KWARA",
          zone: "North Central",
          coordinates: { lat: "8.836789099397848", lng: "4.668848699999999" },
        },
        {
          id: 25,
          name: "LAGOS",
          zone: "South West",
          coordinates: { lat: "6.526903299421034", lng: "3.5774005" },
        },
        {
          id: 26,
          name: "NASARAWA",
          zone: "North Central",
          coordinates: { lat: "8.438786799392046", lng: "8.238284899999998" },
        },
        {
          id: 27,
          name: "NIGER",
          zone: "North Central",
          coordinates: { lat: "9.932608299434758", lng: "5.651108799999999" },
        },
        {
          id: 28,
          name: "OGUN",
          zone: "South West",
          coordinates: { lat: "6.978858199405861", lng: "3.4389293" },
        },
        {
          id: 29,
          name: "ONDO",
          zone: "South West",
          coordinates: { lat: "7.0209685994047035", lng: "5.0567477" },
        },
        {
          id: 30,
          name: "OSUN",
          zone: "South West",
          coordinates: { lat: "7.548404699393967", lng: "4.497830700000001" },
        },
        {
          id: 31,
          name: "OYO",
          zone: "South West",
          coordinates: { lat: "8.215124899390593", lng: "3.5642896999999993" },
        },
        {
          id: 32,
          name: "PLATEAU",
          zone: "North Central",
          coordinates: { lat: "9.058344599402849", lng: "9.6826289" },
        },
        {
          id: 33,
          name: "RIVERS",
          zone: "South South",
          coordinates: { lat: "4.841602799518385", lng: "6.8604088" },
        },
        {
          id: 34,
          name: "SOKOTO",
          zone: "North West",
          coordinates: { lat: "13.061119499685718", lng: "5.3152203" },
        },
        {
          id: 35,
          name: "TARABA",
          zone: "North East",
          coordinates: { lat: "8.0141333993904", lng: "10.737633599999999" },
        },
        {
          id: 36,
          name: "YOBE",
          zone: "North East",
          coordinates: { lat: "12.123324199591742", lng: "11.506593699999998" },
        },
        {
          id: 37,
          name: "ZAMFARA",
          zone: "North West",
          coordinates: { lat: "12.007899799581104", lng: "6.4191432" },
        },
      ],
      lat: 9.0765,
      lag: 7.3986,
      exactlga: "",
      markers: [],
      statess: [],
      respId: [],
      moststate: [],
      noofAlert: [],
      noofalertlevel: [],
      mystate: "",
      minZoom: -2,
      moment: moment,
      isdisabled: false,
      isdiabled: false,

      zoom: 7,
      center: latLng(6.465422, 3.406448),
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",

      customText: "Foobar",
      iconSize: 64,
      socket: null,
      totalpage: 0,
      totalresolvedsos: 0,
      allUssdAlert: [],
      tileProviders: [
        {
          name: "OpenStreetMap",
          visible: true,
          attribution:
            '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
          url: "  https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        },
        {
          name: "  OpenTopoMap",
          visible: false,
          url: "https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png",
          attribution:
            'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
        },
      ],
    };
  },
  // watch: {
  //   alerts: {
  //     handler(newAlerts) {
  //       localStorage.setItem("alerts", JSON.stringify(newAlerts));
  //     },
  //     deep: true,
  //   },
  //   notification: {
  //     handler(newNotification) {
  //       localStorage.setItem("notification", JSON.stringify(newNotification));
  //     },
  //     deep: true,
  //   },
  // },

  methods: {
    DirectRouteIframe() {
      this.showiframe = true;
      //  var el = document.getElementById('iframHolder')
      //  el.innerHTML(`<iframe id="iframe" src="https://www.google.com/maps?q=${selectedMarker.address}" width="100%" height="100%"></iframe>`)
    },
    newSocket() {
      socket.on(
        `getAllSOS${this.adminrole === "admin" ? `_${this.adminstate}` : ""}`,
        (data) => {
          const filteredArray =
            this.adminrole === "admin"
              ? data.stateSos
              : data?.filter((item) => item.status === "pending");
          if (this.notification.length != filteredArray.length) {
            this.notification = filteredArray;
          }
          this.totalpage = data.length;
        }
      );
      socket.on(
        `alertsAnalytics${
          this.adminrole === "admin" ? `_${this.adminstate}` : ""
        }`,
        (resp) => {
          this.analytics = resp;

          this.moststate = this.analytics.alertsWithMostState;
          this.noofAlert = this.analytics.noOfAlert;
          this.noofalertlevel = this.analytics.noOfAlertLevel;
          this.pending =
            this.noofAlert[0] != undefined ? this.noofAlert[0].count : 0;
          this.cancelled =
            this.noofAlert[1] != undefined ? this.noofAlert[1].count : 0;
          this.progress =
            this.noofAlert[2] != undefined ? this.noofAlert[2].count : 0;
          this.resolved =
            this.noofAlert[3] != undefined ? this.noofAlert[3].count : 0;
        }
      );

      socket.on(
        `alerts${this.adminrole === "admin" ? `_${this.adminstate}` : ""}`,
        (response) => {
          const filteredArraya = response.data.filter(
            (item) => item.status !== "resolved"
          );
          if (this.alerts.length != filteredArraya.length) {
            this.alerts = filteredArraya;
          }
        }
      );
      const filter =
        this.adminrole === "admin" ? { state: this.adminstate } : {};

      socket.emit(
        `alertsAnalytics${
          this.adminrole === "admin" ? `_${this.adminstate}` : ""
        }`,
        (data) => {
          // console.log("mewData", data);
          // this.alerts = data.alerts.data;
          this.analytics = data.alertsAnalytics;
          this.moststate = this.analytics.alertsWithMostState;
          this.noofAlert = this.analytics.noOfAlert;
          this.noofalertlevel = this.analytics.noOfAlertLevel;
          this.pending =
            this.noofAlert[0] != undefined ? this.noofAlert[0].count : 0;
          this.cancelled =
            this.noofAlert[1] != undefined ? this.noofAlert[1].count : 0;
          this.progress =
            this.noofAlert[2] != undefined ? this.noofAlert[2].count : 0;
          this.resolved =
            this.noofAlert[3] != undefined ? this.noofAlert[3].count : 0;
        }
      );
      socket.emit("sosAnalytics", filter, (data) => {
        //  console.log(data);
        this.sosAnalytics = data;
      });

      socket.emit("getAllSOS", filter, (data) => {
        this.totalpage =
          this.adminrole === "admin"
            ? data.stateSos.length
            : data.allSos.length;
      });
      socket.on(
        `sosAnalytics${
          this.adminrole === "admin" ? `_${this.adminstate}` : ""
        }`,
        (resp) => {
          //   console.log(resp);
          this.sosAnalytics = resp;
        }
      );
    },

    async resetDashboard() {
      this.isFiltering = false;
      this.myalertstae = "";
      this.lg = "";

      (this.lat = 9.0765), (this.lag = 7.3986), (this.zoom = 7);
      try {
        let response = await axios.get("v1/user/get_user_profile");
        this.adminrole = response.data.result.data.userRole;
        this.adminstate = response.data.result.data.state;
      } catch (e) {
        console.log(e);
      }
      try {
        let response = await axios.get(
          `v1/admin/get_all_alert?status=pending${
            this.adminrole === "admin" ? `&state=${this.adminstate}` : ""
          } `
        );

        this.alerts = response.data.result.data.data;

        // this.prevcountalert = response.data.result.data.length;
      } catch (e) {
        console.log(e);
      }
      await axios
        .get(
          `v1/admin/sos_analytics?${
            this.adminrole === "admin" ? `&state=${this.adminstate}` : ""
          } `
        )
        .then((res) => {
          this.sosAnalytics = res.data.result.data;
        })
        .catch((e) => {
          console.log(e);
        });
      try {
        let response = await axios.get(
          `v1/admin/get_all_sos?status=pending${
            this.adminrole === "admin" ? `&state=${this.adminstate}` : ""
          } `
        );

        this.notification = response.data.result.data;
        // this.totalpage = response.data.result.data.total;
        this.prevcountsos = response.data.result.data.length;
      } catch (e) {
        console.log(e);
      }
      try {
        let response = await axios.get(
          `v1/admin/get_all_sos?${
            this.adminrole === "admin" ? `?state=${this.adminstate}` : ""
          } `
        );

        this.totalpage = response.data.result.data.length;
      } catch (e) {
        console.log(e);
      }

      try {
        let response = await axios.get(`v1/get_states`);
        this.lga = response.data.result.data.lgas;
        this.states = response.data.result.data.state;
        this.alertState = response.data.result.data.alertStates;

        for (const [key] of Object.entries(this.alertState)) {
          this.statess.push(key);
        }
      } catch (e) {
        console.log(e);
      }

      try {
        let resp = await axios.get(
          `v1/admin/alert_analytics${
            this.adminrole === "admin" ? `?state=${this.adminstate}` : ""
          } `
        );

        this.analytics = resp.data.result.data;
        this.moststate = this.analytics.alertsWithMostState;
        this.noofAlert = this.analytics.noOfAlert;
        //  this.noofalertlevel = this.analytics.noOfAlertLevel;
        this.pending =
          this.noofAlert[0] != undefined ? this.noofAlert[0].count : 0;
        this.cancelled =
          this.noofAlert[1] != undefined ? this.noofAlert[1].count : 0;
        this.progress =
          this.noofAlert[2] != undefined ? this.noofAlert[2].count : 0;
        this.resolved =
          this.noofAlert[3] != undefined ? this.noofAlert[3].count : 0;
      } catch (e) {
        console.log(e);
      }
      try {
        let resp = await axios.get(
          `v1/user/get_all_response_team${
            this.adminrole === "admin" ? `?state=${this.adminstate}` : ""
          }`
        );
        this.responseTeam = resp.data.result.data;
        this.loading = false;
      } catch (e) {
        this.$swal({
          text: e.message,

          width: "300px",
          confirmButtonColor: "#238f53",
        });
      }
      try {
        let response = await axios.get(`v1/get_states`);

        this.lga = response.data.result.data.lgas;
        this.states = response.data.result.data.state;
      } catch (e) {
        console.log(e);
      }
    },
    async getbystate(state) {
      this.isFiltering = true;

      this.selectedstates = this.alertState[state];
      this.myalertstae = state;
      try {
        let response = await axios.get(
          `v1/admin/get_all_alert?status=pending&state=${state}`
        );
        // console.log(response);
        //  console.log("All Allerts", response);
        this.filteredState = this.stateData.filter(
          (item) => item.name === state
        );
        this.lat = this.filteredState[0].coordinates.lat;
        this.lag = this.filteredState[0].coordinates.lng;
        //console.log(this.lat, this.lag);
        this.zoom = 10;
        this.alerts = response.data.result.data.data;

        //  this.prevcountalert = response.data.result.data.length;
      } catch (e) {
        console.log(e);
      }
      try {
        let response = await axios.get(
          `v1/admin/get_all_sos?status=pending&state=${state} `
        );
        // console.log("All SOS", response);

        this.notification = response.data.result.data;
        //this.prevcountsos = response.data.result.data.length;
      } catch (e) {
        console.log(e);
      }
      try {
        let response = await axios.get(`v1/admin/get_all_sos?state=${state} `);

        this.totalpage = response.data.result.data.length;
      } catch (e) {
        console.log(e);
      }

      try {
        let resp = await axios.get(`v1/admin/alert_analytics?state=${state}`);
        // console.log("Alert Anayltics", resp);
        this.analytics = resp.data.result.data;
        // this.moststate = this.analytics.alertsWithMostState;
        this.noofAlert = this.analytics.noOfAlert;
        this.noofalertlevel = this.analytics.noOfAlertLevel;
        this.pending =
          this.noofAlert[0] != undefined ? this.noofAlert[0].count : 0;
        this.cancelled =
          this.noofAlert[1] != undefined ? this.noofAlert[1].count : 0;
        this.progress =
          this.noofAlert[2] != undefined ? this.noofAlert[2].count : 0;
        this.resolved =
          this.noofAlert[3] != undefined ? this.noofAlert[3].count : 0;
      } catch (e) {
        console.log(e);
      }
      await axios
        .get(`v1/admin/sos_analytics?state=${state}`)
        .then((res) => {
          //  console.log(res);
          this.sosAnalytics = res.data.result.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async getbylga(lga) {
      // console.log("cliked");
      this.isFiltering = true;
      //this.lg = lga;

      try {
        let response = await axios.get(
          `v1/admin/get_all_alert?status=pending&state=${this.myalertstae}&lga=${lga}`
        );

        this.alerts = response.data.result.data.data;
        this.lat = this.alerts[0].location.coordinates[1];
        this.lag = this.alerts[0].location.coordinates[0];

        this.zoom = 8;

        //console.log(this.alerts);
        //  this.prevcountalert = response.data.result.data.length;
      } catch (e) {
        console.log(e);
      }

      try {
        let response = await axios.get(
          `v1/admin/get_all_sos?status=pending&state=${this.myalertstae}&lga=${lga} `
        );

        this.notification = response.data.result.data;
        //this.prevcountsos = response.data.result.data.length;
      } catch (e) {
        console.log(e);
      }
      await axios
        .get(`v1/admin/sos_analytics?state=${this.myalertstae}&lga=${lga}`)
        .then((res) => {
          this.sosAnalytics = res.data.result.data;
        })
        .catch((e) => {
          console.log(e);
        });

      try {
        let response = await axios.get(
          `v1/admin/get_all_sos?state=${this.myalertstae}&lga=${lga} `
        );

        this.totalpage = response.data.result.data.length;
      } catch (e) {
        console.log(e);
      }

      try {
        let resp = await axios.get(
          `v1/admin/alert_analytics?state=${this.myalertstae}&lga=${lga}`
        );
        //  console.log(resp);

        this.analytics = resp.data.result.data;
        //  console.log(this.analytics);
        // this.moststate = this.analytics.alertsWithMostState;
        this.noofAlert = this.analytics.noOfAlert;
        this.noofalertlevel = this.analytics.noOfAlertLevel;
        this.pending =
          this.noofAlert[0] != undefined ? this.noofAlert[0].count : 0;
        this.cancelled =
          this.noofAlert[1] != undefined ? this.noofAlert[1].count : 0;
        this.progress =
          this.noofAlert[2] != undefined ? this.noofAlert[2].count : 0;
        this.resolved =
          this.noofAlert[3] != undefined ? this.noofAlert[3].count : 0;
      } catch (e) {
        console.log(e);
      }
    },
    async resetallalert() {
      this.mystate = "";
      this.clickindex = null;
      try {
        let resp = await axios.get(`v1/admin/alert_analytics`);
        this.analytics = resp.data.result.data;
        this.moststate = this.analytics.alertsWithMostState;
        this.noofAlert = this.analytics.noOfAlert;
        this.noofalertlevel = this.analytics.noOfAlertLevel;
        this.lat = 9.0765;
        this.lag = 7.3986;
        this.zoom = 7;
        this.pending =
          this.noofAlert[0] != undefined ? this.noofAlert[0].count : 0;
        this.cancelled =
          this.noofAlert[1] != undefined ? this.noofAlert[1].count : 0;
        this.progress =
          this.noofAlert[2] != undefined ? this.noofAlert[2].count : 0;
        this.resolved =
          this.noofAlert[3] != undefined ? this.noofAlert[3].count : 0;
      } catch (e) {
        console.log(e);
      }
    },
    async updatestate(item, id) {
      this.isFiltering = true;
      try {
        let response = await axios.get(
          `v1/admin/get_all_sos?state=${item.state} `
        );

        this.totalpage = response.data.result.data.length;
      } catch (e) {
        console.log(e);
      }
      this.clickindex = id;

      this.choosenstate = item.state;
      this.mystate = item.state;
      //  console.log("All Allerts", response);
      this.filteredState = this.stateData.filter(
        (item) => item.name === this.mystate
      );
      this.lat = this.filteredState[0].coordinates.lat;
      this.lag = this.filteredState[0].coordinates.lng;
      //console.log(this.lat, this.lag);
      this.zoom = 10;
      /**
      for (var p = 0; p <= this.states.length; p++) {
        if (this.states[p] && this.states[p].name == item.state) {
          this.zoom = 8;

          this.lat = this.states[p].coordinates.lat;
          this.lag = this.states[p].coordinates.lng;
        }
      }
      */

      try {
        let resp = await axios.get(
          `v1/admin/alert_analytics?state=${item.state}`
        );
        this.analytics = resp.data.result.data;
        // this.moststate = this.analytics.alertsWithMostState;
        this.noofAlert = this.analytics.noOfAlert;
        this.noofalertlevel = this.analytics.noOfAlertLevel;

        this.pending =
          this.noofAlert[0] != undefined ? this.noofAlert[0].count : 0;
        this.cancelled =
          this.noofAlert[1] != undefined ? this.noofAlert[1].count : 0;
        this.progress =
          this.noofAlert[2] != undefined ? this.noofAlert[2].count : 0;
        this.resolved =
          this.noofAlert[3] != undefined ? this.noofAlert[3].count : 0;
      } catch (e) {
        console.log(e);
      }
    },
    async getmyalert(item) {
      this.exactlga = item.name;
      const response = await axios.get(
        `v1/admin/get_all_alert?state=${this.exactstate}&lga=${this.exactlga}`
      );
      this.alerts = response.data.result.data.alerts;
    },
    async MarkAsResolvedSOS() {
      this.isdiabled = true;
      document.getElementById("myresolvedsos").value = "Loading";

      try {
        const data = {
          sosId: this.alertid,
        };
        const res = await axios.post(`v1/admin/resolve_sos`, data);

        try {
          let response = await axios.get(
            `v1/admin/get_all_sos?status=pending${
              this.adminrole === "admin" ? `&state=${this.adminstate}` : ""
            }&limit="-1"`
          );
          this.notification = response.data.result.data;
        } catch (e) {
          console.log(e);
        }
        await axios
          .get(
            `v1/admin/sos_analytics${
              this.adminrole === "admin" ? `?state=${this.adminstate}` : ""
            }`
          )
          .then((res) => {
            this.sosAnalytics = res.data.result.data;
            var button = document.getElementById("closeme");
            button.click();
            this.status = false;
            this.isdiabled = false;
            document.getElementById("myresolvedsos").value = "Mark as resolved";
          })
          .catch((e) => {
            console.log(e);
          });
        this.$swal({
          title:
            "<span style='font-size:13px !important;color:#238f53'>Success</span>",
          text: res.data.result.message,

          width: "300px",
          confirmButtonColor: "#238f53",
        }).then(() => {});
      } catch (e) {
        if (e.response.status == 400) {
          this.isdiabled = false;
          document.getElementById("myresolvedsos").value = "Mark as resolved";

          this.$swal({
            title:
              "<span style='font-size:13px !important;color:red'>Failed</span>",
            text: "Alert has not been resolved",

            width: "300px",
            confirmButtonColor: "#238f53",
          }).then();
        } else if (e.response.status == 500) {
          this.isdiabled = false;
          document.getElementById("myresolvedsos").value = "Mark as resolved";

          this.$swal({
            title:
              "<span style='font-size:13px !important;color:red'>Failed</span>",
            text: "An error occur",

            width: "300px",
            confirmButtonColor: "#238f53",
          }).then();
        }
      }
    },
    async MarkAsResolved() {
      document.getElementById("myresolved").value = "Loading";
      this.isdiabled = true;
      try {
        const data = {
          alertId: this.alertid,
        };
        const res = await axios.post(`v1/admin/resolve_alert`, data);
        try {
          let resp = await axios.get(
            `v1/admin/alert_analytics${
              this.adminrole === "admin" ? `?state=${this.adminstate}` : ""
            }`
          );
          // console.log(resp);
          this.analytics = resp.data.result.data;
          this.moststate = this.analytics.alertsWithMostState;
          this.noofAlert = this.analytics.noOfAlert;
          //  this.noofalertlevel = this.analytics.noOfAlertLevel;
          this.pending =
            this.noofAlert[0] != undefined ? this.noofAlert[0].count : 0;
          this.cancelled =
            this.noofAlert[1] != undefined ? this.noofAlert[1].count : 0;
          this.progress =
            this.noofAlert[2] != undefined ? this.noofAlert[2].count : 0;
          this.resolved =
            this.noofAlert[3] != undefined ? this.noofAlert[3].count : 0;
        } catch (e) {
          console.log(e);
        }

        let response = await axios.get(
          `v1/admin/get_all_alert?status=pending${
            this.adminrole === "admin" ? `&state=${this.adminstate}` : ""
          } `
        );
        this.isdiabled = false;
        document.getElementById("myresolved").value = "Mark as resolved";

        this.alerts = response.data.result.data;
        var button = document.getElementById("closeme");
        button.click();
        this.status = false;
        this.$swal({
          title:
            "<span style='font-size:13px !important;color:#238f53'>Success</span>",
          text: res.data.result.message,

          width: "300px",
          confirmButtonColor: "#238f53",
        }).then(() => {
          var button = document.getElementById("closeme");
          button.click();
        });
      } catch (e) {
        if (e.response.status == 400) {
          this.isdiabled = false;
          document.getElementById("myresolved").value = "Mar as resolved";

          this.$swal({
            title:
              "<span style='font-size:13px !important;color:red'>Failed</span>",
            text: "Alert has not been resolved",

            width: "300px",
            confirmButtonColor: "#238f53",
          }).then();
        } else if (e.response.status == 500) {
          this.isdiabled = false;
          this.$swal({
            title:
              "<span style='font-size:13px !important;color:red'>Failed</span>",
            text: "An error occur",

            width: "300px",
            confirmButtonColor: "#238f53",
          }).then();
        }
      }
    },
    async assignSubmit() {
      this.isdisabled = true;
      const data = {
        responseTeam: this.respId,
        alertId: this.alertid,
      };
      try {
        const response = await axios.post(
          "v1/admin/assign_response_team_alert",
          data
        );

        try {
          let resp = await axios.get(
            `v1/admin/alert_analytics${
              this.adminrole === "admin" ? `?state=${this.adminstate}` : ""
            }`
          );
          // console.log(resp);
          this.analytics = resp.data.result.data;
          this.moststate = this.analytics.alertsWithMostState;
          this.noofAlert = this.analytics.noOfAlert;
          //  this.noofalertlevel = this.analytics.noOfAlertLevel;
          this.pending =
            this.noofAlert[0] != undefined ? this.noofAlert[0].count : 0;
          this.cancelled =
            this.noofAlert[1] != undefined ? this.noofAlert[1].count : 0;
          this.progress =
            this.noofAlert[2] != undefined ? this.noofAlert[2].count : 0;
          this.resolved =
            this.noofAlert[3] != undefined ? this.noofAlert[3].count : 0;
        } catch (e) {
          console.log(e);
        }
        try {
          let response = await axios.get(
            `v1/admin/get_all_alert?status=pending${
              this.adminrole === "admin" ? `&state=${this.adminstate}` : ""
            } `
          );
          this.alerts = response.data.result.data.data;
          this.status = false;
          var button = document.getElementById("closeme5");
          button.click();

          //  this.prevcountalert = response.data.result.data.length;
        } catch (e) {
          console.log(e);
        }
        this.$swal({
          title:
            "<span style='font-size:13px !important;color:#238f53'>Success</span>",
          text: response.data.result.message,

          width: "300px",
          confirmButtonColor: "#238f53",
        }).then(() => {});
      } catch (e) {
        if (e.response.status == 400) {
          var buttons = document.getElementById("closeme5");
          buttons.click();
          this.$swal({
            title:
              "<span style='font-size:13px !important;color:red'>Failed</span>",
            text: "User has been assigned for an alert",

            width: "300px",
            confirmButtonColor: "#238f53",
          });
          this.isdisabled = false;
        } else if (e.response.status == 500) {
          var buttonss = document.getElementById("closeme5");
          buttonss.click();
          this.$swal({
            title:
              "<span style='font-size:13px !important;color:red'>Failed</span>",
            text: "An error occur",

            width: "300px",
            confirmButtonColor: "#238f53",
          });
          this.isdisabled = false;
        }
      }
    },
    getvalres(item) {
      this.searchres = item.firstName + " " + item.lastName;

      this.getres = false;
    },
    hidegetres() {
      this.getres = !this.getres;
    },
    closeModalOpen() {
      this.status = false;
    },
    showModalOpen(item, id) {
      this.slectedcoordslat = item.location.coordinates[0];
      this.slectedcoordslag = item.location.coordinates[1];
      if (id == 2) {
        this.current = latLng(this.slectedcoordslat, this.slectedcoordslag);
        /*
        this.current = {
          lat: this.slectedcoordslat,
          lng: this.slectedcoordslag,
        };
        */
      } else {
        this.current = latLng(this.slectedcoordslag, this.slectedcoordslat);
        /*
        this.current = {
          lat: this.slectedcoordslag,
          lng: this.slectedcoordslat,
        };
        */
      }
      if (id == 2) {
        this.selectedlevel = item.alertTypes[0].level;
      }

      this.selectedMarker = item;
      this.status = true;
      this.alertid = item._id;
    },
    async getlgas(state) {
      //  (this.lat = state.coordinates.lat), (this.lag = state.coordinates.lng);
      this.selectedstates = this.alertState[state];
      // console.log(this.selectedstates);
      this.exactstate = state;
      this.choosenstate = state;
      this.mystate = state;

      try {
        let resp = await axios.get(
          `v1/admin/alert_analytics?state=${state.name}`
        );

        this.analytics = resp.data.result.data;
        this.moststate = this.analytics.alertsWithMostState;
        this.noofAlert = this.analytics.noOfAlert;
        this.noofalertlevel = this.analytics.noOfAlertLevel;
        this.pending =
          this.noofAlert[0] != undefined ? this.noofAlert[0].count : 0;
        this.cancelled =
          this.noofAlert[1] != undefined ? this.noofAlert[1].count : 0;
        this.progress =
          this.noofAlert[2] != undefined ? this.noofAlert[2].count : 0;
        this.resolved =
          this.noofAlert[3] != undefined ? this.noofAlert[3].count : 0;
      } catch (e) {
        console.log(e);
      }
    },
    selectedstate(stt) {
      this.state = stt;
    },
  },

  async mounted() {
    localStorage.setItem("page", 20);
    // const savedAlerts = localStorage.getItem("alerts");
    // const savedNotifcation = localStorage.getItem("notification");

    // if (savedAlerts.length > 0) {
    //   this.alerts = JSON.parse(savedAlerts);
    //   this.zoom = 7;
    // }
    // if (savedNotifcation.length > 0) {
    //   this.notification = JSON.parse(savedNotifcation);
    //   this.zoom = 7;
    // }
    try {
      let response = await axios.get("v1/user/get_user_profile");
      this.adminrole = response.data.result.data.userRole;
      this.adminstate = response.data.result.data.state;
      // console.log(this.adminstate);

      if (this.adminrole !== "super_admin") {
        if (this.alerts.length > 0) {
          const locationData = this.alerts[0].location;
          this.lat = locationData.coordinates[0];
          this.lag = locationData.coordinates[1];
          this.zoom = 9;
        } else if (this.notification.length > 0) {
          const locationData = this.notification[0].location;
          this.lat = locationData.coordinates[0];
          this.lag = locationData.coordinates[1];
          this.zoom = 9;
        } else {
          this.filteredState = this.stateData.filter(
            (item) => item.name === this.adminstate[0]
          );
          this.lat = this.filteredState[0].coordinates.lat;
          this.lag = this.filteredState[0].coordinates.lng;
          //console.log(this.lat, this.lag);
          this.zoom = 9;
        }
      }
      // console.log(this.adminstate);
    } catch (e) {
      console.log(e);
    }
    setInterval(() => {
      if (this.isFiltering == false) {
        this.newSocket();
      }
    }, 10000);
    await axios
      .get(
        `v1/admin/sos_analytics${
          this.adminrole === "admin" ? `?state=${this.adminstate}` : ""
        }`
      )
      .then((res) => {
        this.sosAnalytics = res.data.result.data;
      })
      .catch((e) => {
        console.log(e);
      });

    try {
      let response = await axios.get(
        `v1/admin/get_all_alert${
          this.adminrole === "admin" ? `?state=${this.adminstate}` : ""
        } `
      );
      this.alerts = response.data.result.data.data;
      // console.log(this.alerts);
      this.prevcountalert = response.data.result.data.length;
    } catch (e) {
      console.log(e);
    }
    try {
      let response = await axios.get(
        `/v1/admin/get_all_sos_ussd?status=pending${
          this.adminrole === "admin" ? `&state=${this.adminstate}` : ""
        } `
      );
      this.allUssdAlert = response.data.result.data.data;
      //  console.log("checkAllalerts", this.alerts);
      //  this.prevcountalert = response.data.result.data.length;
    } catch (e) {
      console.log(e);
    }
    try {
      let response = await axios.get(
        `v1/admin/get_all_sos?status=pending${
          this.adminrole === "admin" ? `&state=${this.adminstate}` : ""
        }&limit="-1"`
      );
      this.notification = response.data.result.data;
    } catch (e) {
      console.log(e);
    }

    try {
      let response = await axios.get(
        `v1/admin/get_all_sos${
          this.adminrole === "admin" ? `?state=${this.adminstate}` : ""
        } `
      );
      //  console.log(response);

      this.totalpage = response.data.result.data.length;
    } catch (e) {
      console.log(e);
    }
    try {
      let response = await axios.get(
        `v1/admin/get_all_sos?status=resolved${
          this.adminrole === "admin" ? `&state=${this.adminstate}` : ""
        } `
      );

      this.totalpage = response.data.result.data.length;
    } catch (e) {
      console.log(e);
    }

    try {
      let response = await axios.get(`v1/get_states`);
      this.lga = response.data.result.data.lgas;
      this.states = response.data.result.data.state;
      this.alertState = response.data.result.data.alertStates;

      for (const [key] of Object.entries(this.alertState)) {
        this.statess.push(key);
      }
    } catch (e) {
      console.log(e);
    }
    /**
    try {
      let response = await axios.get(
        `v1/admin/get_all_report${
          this.adminrole === "admin" ? `?state=${this.adminstate}` : ""
        }`
      );

      response.data.result.data.total;
    } catch (e) {
      console.log(e);
    }
    */
    try {
      let resp = await axios.get(
        `v1/admin/alert_analytics${
          this.adminrole === "admin" ? `?state=${this.adminstate}` : ""
        }`
      );
      // console.log(resp);
      this.analytics = resp.data.result.data;
      this.moststate = this.analytics.alertsWithMostState;
      this.noofAlert = this.analytics.noOfAlert;
      //  this.noofalertlevel = this.analytics.noOfAlertLevel;
      this.pending =
        this.noofAlert[0] != undefined ? this.noofAlert[0].count : 0;
      this.cancelled =
        this.noofAlert[1] != undefined ? this.noofAlert[1].count : 0;
      this.progress =
        this.noofAlert[2] != undefined ? this.noofAlert[2].count : 0;
      this.resolved =
        this.noofAlert[3] != undefined ? this.noofAlert[3].count : 0;
    } catch (e) {
      console.log(e);
    }
    try {
      await axios.get(
        `v1/admin/alert_analytics${
          this.adminrole === "admin"
            ? `?state=${this.adminstate}&status=pending`
            : "?status=pending"
        }`
      );
      //console.log("alertpendingana", resp);

      this.noofalertlevel = this.analytics.noOfAlertLevel;
    } catch (e) {
      console.log(e);
    }
    try {
      let resp = await axios.get(
        `v1/user/get_all_response_team${
          this.adminrole === "admin" ? `?state=${this.adminstate}` : ""
        }`
      );
      this.responseTeam = resp.data.result.data;
      this.loading = false;
    } catch (e) {
      this.$swal({
        text: e.message,

        width: "300px",
        confirmButtonColor: "#238f53",
      });
    }
    try {
      let response = await axios.get(`v1/get_states`);

      this.lga = response.data.result.data.lgas;
      this.states = response.data.result.data.state;
    } catch (e) {
      console.log(e);
    }
  },

  async created() {
    const response = await fetch(
      "https://rawgit.com/gregoiredavid/france-geojson/master/regions/pays-de-la-loire/communes-pays-de-la-loire.geojson"
    );
    this.geojson = await response.json();
    /*

    try {
      let response = await axios.get("v1/user/get_user_profile");
      this.adminrole = response.data.result.data.userRole;
      this.adminstate = response.data.result.data.state;
    } catch (e) {
      console.log(e);
    }
    /*

    this.$socket.emit("getAllSOS", (data) => {
      console.log(data);
      this.notification = data;
      this.totalpage = data.length;
    });

    this.$socket.emit(
      `alerts${this.adminrole === "admin" ? `_${this.adminstate}` : ""}`,
      (response) => {
        this.alerts = response;
      }
    );
    this.$socket.emit(
      `alertsAnalytics${this.adminrole === "admin" ? `_${this.adminstate}` : ""}`,
      (resp) => {
        console.log(resp);
        this.analytics = resp;

        this.moststate = this.analytics.alertsWithMostState;
        this.noofAlert = this.analytics.noOfAlert;
        this.noofalertlevel = this.analytics.noOfAlertLevel;
        this.pending = this.noofAlert[0] != undefined ? this.noofAlert[0].count : 0;
        this.cancelled = this.noofAlert[1] != undefined ? this.noofAlert[1].count : 0;
        this.progress = this.noofAlert[2] != undefined ? this.noofAlert[2].count : 0;
        this.resolved = this.noofAlert[3] != undefined ? this.noofAlert[3].count : 0;
      }
    );
    */
  },

  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),

    filterStates: function () {
      return this.states.filter((item) => {
        return item.name.match(this.search.toUpperCase());
      });
    },
    filterSOS: function () {
      return this.notification.filter((item) => {
        console.log(item);
        return item.state.match(this.mystate) && item.status !== "resolved";
      });
    },
    filternofHL: function () {
      return this.noofalertlevel.filter((item) => {
        return item.level.match("HL");
      });
    },
    filternofLL: function () {
      return this.noofalertlevel.filter((item) => {
        return item.level.match("LL");
      });
    },
    filternofML: function () {
      return this.noofalertlevel.filter((item) => {
        return item.level.match("ML");
      });
    },
    filternofCL: function () {
      return this.noofalertlevel.filter((item) => {
        console.log(item);
        return item.level.match("CL");
      });
    },

    filternoresolve: function () {
      return this.noofAlert.filter((item) => {
        return item.status.match("resolve");
      });
    },
    filternopending: function () {
      return this.noofAlert.filter((item) => {
        return item.status.match("pending");
      });
    },
    filternoprogress: function () {
      return this.noofAlert.filter((item) => {
        return item.status.match("in-progress");
      });
    },
    filterProgress: function () {
      return this.alerts.filter((item) => {
        return item.status.match("in-progress");
      });
    },
    filterResolved: function () {
      return this.alerts.filter((item) => {
        return item.status.match("resolved");
      });
    },
    filterCancelled: function () {
      return this.noofAlert.filter((item) => {
        return item.status.match("cancelled");
      });
    },
    filterAllAlert: function () {
      return this.alerts.filter((item) => {
        return item.state.match(this.mystate) && item.status !== "resolved";
      });
    },

    filterName: function () {
      return this.responseTeam.filter((item) => {
        return item.firstName.match(this.searchres);
      });
    },
  },
};
</script>
<style scoped>
main {
  background: #f5f6f7;
  height: calc(100vh - 50px);
  overflow: auto;
}
.rowsm {
  flex: 2;
  border-right: 1px solid #c9d9e5;
  padding: 15px;
}
.mylight li {
  line-height: 26px !important;
}
.rowsm2 {
  flex: 8;
}
ul {
  padding: 0px !important;
  border-bottom: 1px solid #c9d9e5;
}
ul li {
  background: none !important;
  border: none;
  font-weight: 500 !important;
  margin-left: -18px;
}
.containelgg {
  border-radius: 5px;
  padding: 10px;
  font-size: 14px;
  line-height: 20px;
  margin-top: 8px;
  /* margin-bottom: 10px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.containelgg h6 {
  font-size: 12px;
  color: #5c7597;
  align-items: center;
  display: flex;
  margin: 0px !important;
  padding: 0px !important;
}
.containelgg span {
  font-size: 14px;
}
.bb {
  border-bottom: 1px solid #c9d9e5;
  margin-bottom: 5px;
}
.region {
  border-radius: 8px !important;
  border-color: #7993b1;
  text-align: center;
  width: 31%;
  color: #7993b1;
  font-size: 8px;
  padding: 5px 4px 5px 4px;
  align-content: center;
  display: flex;
  justify-content: center;
  float: left;
  margin: 2px;
}
select {
  padding: 8px;
  border: 1px solid #7993b1;
  border-radius: 10px;
  margin: 5px;
}
.dpr::-webkit-scrollbar {
  width: 2px;
}
.active {
  background: #238f53;
  color: #fff;
}
.center {
  height: 100vh;
  align-items: center;
  display: flex;
  width: 100vw;
  place-items: center;
  justify-content: center;
}

/* Track */
.dpr::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.dpr::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.dpr::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.search {
  width: 60%;
  border: none;
}
.bgmtt {
  width: 90%;
  margin: 0px auto;
}
.someExtraClass {
  background-color: aqua;
  padding: 10px;
  border: 1px solid #333;
  border-radius: 0 20px 20px 20px;
  box-shadow: 5px 3px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: auto !important;
  height: auto !important;
  margin: 0 !important;
}
.pin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: #00cae9;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
}
.pin:after {
  content: "";
  width: 14px;
  height: 14px;
  margin: 8px 0 0 8px;
  background: #e6e6e6;
  position: absolute;
  border-radius: 50%;
}

.bounce {
  animation-name: bounce;
  animation-fill-mode: both;
  animation-duration: 1s;
}

.pulse {
  background: #d6d4d4;
  border-radius: 50%;
  height: 14px;
  width: 14px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 11px 0px 0px -12px;
  transform: rotateX(55deg);
  z-index: -2;
}
.pulse:after {
  content: "";
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  margin: -13px 0 0 -13px;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
  box-shadow: 0 0 1px 2px #00cae9;
  animation-delay: 1.1s;
}

@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

@keyframes bounce {
  0% {
    opacity: 0;
    transform: translateY(-2000px) rotate(-45deg);
  }

  60% {
    opacity: 1;
    transform: translateY(30px) rotate(-45deg);
  }

  80% {
    transform: translateY(-10px) rotate(-45deg);
  }

  100% {
    transform: translateY(0) rotate(-45deg);
  }
}
.glow {
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
  box-shadow: 0 0 10px red, 0 0 3px red, 0 0 3px red, 0 0 3px red, 0 0 3px red;
}
@keyframes blink-animation {
  to {
    bax-shadow: 2px 3px 4px solid red;
    box-shadow: 0 0 20px red, 0 0 3px red, 0 0 3px red, 0 0 3px red, 0 0 3px red;
  }
}
@-webkit-keyframes blink-animation {
  to {
    bax-shadow: 2px 3px 4px solid red;
  }
}

.glow {
  position: relative;
  color: #fff;
  width: 30px;
  height: 30px;
  border: 0.02rem solid #9d1f1f;
  border-radius: 100%;
  font-size: 5em;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 20px #8fe70d0d, 0 0 3px #8fe70d0d, 0 0 3px #8fe70d0d,
    0 0 3px #8fe70d0d, 0 0 3px #8fe70d0d;
}

.modal {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.mdil div {
  display: flex !important;
  justify-content: space-between !important;
  padding: 3px !important;
  line-height: 35px;
  border: none !important;
  text-align: left !important;
}
.mdil div h6 {
  font-size: 12px;
  color: #7993b1;
}
.mdil div span {
  font-size: 12px;
}
.hl {
  position: relative;
  color: #fff;
  width: 30px;
  height: 30px;
  border: 0.02rem solid orange !important;
  border-radius: 100%;
  font-size: 5em;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 20px orange, 0 0 3px orange, 0 0 3px orange, 0 0 3px orange,
    0 0 3px orange;
}
.hl {
  animation: blinks-hl 1s steps(5, start) infinite;
  -webkit-animation: blinks-hl 1s steps(5, start) infinite;
  box-shadow: 0 0 10px orange, 0 0 3px orange, 0 0 3px orange, 0 0 3px orange,
    0 0 3px orange;
}
@keyframes blinks-hl {
  to {
    box-shadow: 0 0 20px orange, 0 0 3px orange, 0 0 3px orange, 0 0 3px orange,
      0 0 3px orange;
  }
}
@-webkit-keyframes blinks-hl {
  to {
    bax-shadow: 2px 3px 4px solid orange;
  }
}

.ml {
  position: relative;
  color: #fff;
  width: 30px;
  height: 30px;
  border: 0.02rem solid yellow !important;
  border-radius: 100%;
  font-size: 5em;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 20px yellow, 0 0 3px yellow, 0 0 3px yellow, 0 0 3px yellow,
    0 0 3px yellow;
}
.ml {
  animation: blinks-ml 1s steps(5, start) infinite;
  -webkit-animation: blinks-ml 1s steps(5, start) infinite;
  box-shadow: 0 0 10px yellow, 0 0 3px yellow, 0 0 3px yellow, 0 0 3px yellow,
    0 0 3px yellow;
}
@keyframes blinks-ml {
  to {
    box-shadow: 0 0 20px yellow, 0 0 3px yellow, 0 0 3px yellow, 0 0 3px yellow,
      0 0 3px yellow;
  }
}
@-webkit-keyframes blinks-ml {
  to {
    bax-shadow: 2px 3px 4px solid yellow;
  }
}
.ll {
  position: relative;
  color: #fff;
  width: 30px;
  height: 30px;
  border: 0.02rem solid #adf802 !important;
  border-radius: 100%;
  font-size: 5em;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 20px #adf802, 0 0 3px #adf802, 0 0 3px #adf802,
    0 0 3px #adf802, 0 0 3px #adf802;
}
.ll {
  animation: blinks-ll 1s steps(5, start) infinite;
  -webkit-animation: blinks-ll 1s steps(5, start) infinite;
  box-shadow: 0 0 10px #adf802, 0 0 3px #adf802, 0 0 3px #adf802,
    0 0 3px #adf802, 0 0 3px #adf802;
}
@keyframes blinks-ll {
  to {
    box-shadow: 0 0 20px #adf802, 0 0 3px #adf802, 0 0 3px #adf802,
      0 0 3px #adf802, 0 0 3px #adf802;
  }
}
@-webkit-keyframes blinks-ll {
  to {
    bax-shadow: 2px 3px 4px solid #adf802;
  }
}
.leaflet-containelgg {
  height: 100% !important;
}
.classlist {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.classlist input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 7px;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.classlist:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.classlist input:checked ~ .checkmark {
  background-color: rgb(35, 143, 83);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.classlist input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.classlist .checkmark:after {
  left: 5px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.marker-cluster-small div {
  background: #fff !important;
  color: #000 !important;
}
.marker-cluster-large {
  background: #fff !important;
  color: #000 !important;
}
</style>
