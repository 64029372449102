<template>
  <div class="containerss border-left">
    <div class="bars">
      <div class="position-relative" style="width: 30px">
        <div class="bar one" style="height: 100%">
          <div
            class="w-100"
            style="position: absolute; bottom: 0"
            v-if="mydata['North Central']"
            :style="{
              height:
                (parseInt(mydata['North Central'].totalCount + 2) /
                  parseInt(myhighest + 5)) *
                  100 +
                '%',
            }"
          >
            <div
              style="background: #7acb78"
              :style="{
                height:
                  (parseInt(mydata['North Central'].KOGI) /
                    parseInt(mydata['North Central'].totalCount)) *
                    100 +
                  '%',
              }"
              :title="`KOGI ${mydata['North Central'].KOGI}`"
            ></div>
            <div
              style="background: #4fb24d"
              :style="{
                height:
                  (parseInt(mydata['North Central'].FCT) /
                    parseInt(mydata['North Central'].totalCount)) *
                    100 +
                  '%',
              }"
              :title="`FCT ${mydata['North Central'].FCT}`"
            ></div>

            <div
              :title="`Nasarawa ${mydata['North Central'].NASARAWA}`"
              style="background: #319830"
              :style="{
                height:
                  (parseInt(mydata['North Central'].NASARAWA) /
                    parseInt(mydata['North Central'].totalCount)) *
                    100 +
                  '%',
              }"
            ></div>
            <div
              :title="`BENUE ${mydata['North Central'].BENUE}`"
              style="background: #319831"
              :style="{
                height:
                  (parseInt(mydata['North Central'].BENUE) /
                    parseInt(mydata['North Central'].totalCount)) *
                    100 +
                  '%',
              }"
            ></div>

            <div
              style="background: #1d7e1c"
              :title="`Niger ${mydata['North Central'].NIGER}`"
              :style="{
                height:
                  (parseInt(mydata['North Central'].NIGER) /
                    parseInt(mydata['North Central'].totalCount)) *
                    100 +
                  '%',
              }"
            ></div>
            <div
              style="background: #10640f"
              :title="`Plateau ${mydata['North Central'].PLATEAU}`"
              :style="{
                height:
                  (parseInt(mydata['North Central'].PLATEAU) /
                    parseInt(mydata['North Central'].totalCount)) *
                    100 +
                  '%',
              }"
            ></div>

            <div
              style="background: #084b08"
              :title="`Kwara ${mydata['North Central'].KWARA}`"
              :style="{
                height:
                  (parseInt(mydata['North Central'].KWARA) /
                    parseInt(mydata['North Central'].totalCount)) *
                    100 +
                  '%',
              }"
            ></div>
          </div>
        </div>
        <div class="listme">North Central</div>
      </div>

      <div class="position-relative" style="width: 30px">
        <div class="bar one" style="height: 100%">
          <div
            style="position: absolute; bottom: 0"
            class="w-100"
            v-if="mydata['North East']"
            :style="{
              height:
                (parseInt(mydata['North East'].totalCount) / parseInt(myhighest + 5)) *
                  100 +
                '%',
            }"
          >
            <div
              style="background: #f5e5b4"
              :style="{
                height:
                  (parseInt(mydata['North East'].ADAMAWA) /
                    parseInt(mydata['North East'].totalCount)) *
                    100 +
                  '%',
              }"
              :title="`ADAMAWA ${mydata['North East'].ADAMAWA}`"
            ></div>

            <div
              style="background: #bd9d2f"
              :title="`GOMBE ${mydata['North East'].GOMBE}`"
              :style="{
                height:
                  (parseInt(mydata['North East'].GOMBE) /
                    parseInt(mydata['North East'].totalCount)) *
                    100 +
                  '%',
              }"
            ></div>
            <div
              style="background: #988026"
              :title="`TARABA ${mydata['North East'].TARABA}`"
              :style="{
                height:
                  (mydata['North East'].TARABA /
                    parseInt(mydata['North East'].totalCount)) *
                    100 +
                  '%',
              }"
            ></div>
            <div
              style="background: #988026"
              :title="`BAUCHI ${mydata['North East'].BAUCHI}`"
              :style="{
                height:
                  (mydata['North East'].BAUCHI /
                    parseInt(mydata['North East'].totalCount)) *
                    100 +
                  '%',
              }"
            ></div>
            <div
              style="background: #988026"
              :title="`BORNO${mydata['North East'].BORNO}`"
              :style="{
                height:
                  (mydata['North East'].BORNO /
                    parseInt(mydata['North East'].totalCount)) *
                    100 +
                  '%',
              }"
            ></div>
            <div
              style="background: #72621f"
              :title="`YOBE ${mydata['North East'].YOBE}`"
              :style="{
                height:
                  (parseInt(mydata['North East'].YOBE) /
                    parseInt(mydata['North East'].totalCount)) *
                    100 +
                  '%',
              }"
            ></div>
          </div>
        </div>

        <div class="listme">North East</div>
      </div>

      <div class="position-relative" style="width: 30px">
        <div class="bar one" style="height: 100%">
          <div
            style="position: absolute; bottom: 0"
            class="w-100"
            v-if="mydata['North West']"
            :style="{
              height:
                (parseInt(mydata['North West'].totalCount + 1) /
                  parseInt(myhighest + 5)) *
                  100 +
                '%',
            }"
          >
            <div
              style="background: #7acb78"
              :style="{
                height:
                  (parseInt(mydata['North West'].KANO) /
                    parseInt(mydata['North West'].totalCount)) *
                    100 +
                  '%',
              }"
              :title="`KANO ${mydata['North West'].KANO}`"
            ></div>
            <div
              style="background: #b1e5af"
              :style="{
                height:
                  (parseInt(mydata['North West'].SOKOTO) /
                    parseInt(mydata['North West'].totalCount)) *
                    100 +
                  '%',
              }"
              :title="`SOKOTO ${mydata['North West'].SOKOTO}`"
            ></div>

            <div
              style="background: #7acb78"
              :title="`KATSINA ${mydata['North West'].KATSINA}`"
              :style="{
                height:
                  (parseInt(mydata['North West'].KATSINA) /
                    parseInt(mydata['North West'].totalCount)) *
                    100 +
                  '%',
              }"
            ></div>

            <div
              style="background: #4fb24d"
              :title="`KADUNA ${mydata['North West'].KADUNA}`"
              :style="{
                height:
                  (parseInt(mydata['North West'].KADUNA) /
                    parseInt(mydata['North West'].totalCount)) *
                    100 +
                  '%',
              }"
            ></div>
            <div
              style="background: #319830"
              :title="`JIGAWA ${mydata['North West'].JIGAWA}`"
              :style="{
                height:
                  (mydata['North West'].JIGAWA /
                    parseInt(mydata['North West'].totalCount)) *
                    100 +
                  '%',
              }"
            ></div>
            <div
              style="background: #1d7e1c"
              :title="`KEBBI ${mydata['North West'].KEBBI}`"
              :style="{
                height:
                  (parseInt(mydata['North West'].KEBBI) /
                    parseInt(mydata['North West'].totalCount)) *
                    100 +
                  '%',
              }"
            ></div>
            <div
              style="background: #10640f"
              :title="`ZAMFARA ${mydata['North West'].ZAMFARA}`"
              :style="{
                height:
                  (parseInt(mydata['North West'].ZAMFARA) /
                    parseInt(mydata['North West'].totalCount)) *
                    100 +
                  '%',
              }"
            ></div>
          </div>
        </div>

        <div class="listme">North West</div>
      </div>

      <div class="position-relative" style="width: 30px">
        <div class="bar one" style="height: 100%">
          <div
            style="position: absolute; bottom: 0px"
            class="w-100"
            v-if="mydata['South West']"
            :style="{
              height:
                (parseInt(mydata['South West'].totalCount + 1) /
                  parseInt(myhighest + 5)) *
                  100 +
                '%',
            }"
          >
            <div
              style="background: #f2d5d5"
              :title="`EKITI ${mydata['South West'].EKITI}`"
              :style="{
                height:
                  (parseInt(mydata['South West'].EKITI) /
                    parseInt(mydata['South West'].totalCount)) *
                    100 +
                  '%',
              }"
            ></div>
            <div
              style="background: #f5b0b0"
              :title="`OSUN ${mydata['South West'].OSUN}`"
              :style="{
                height:
                  (parseInt(mydata['South West'].OSUN) /
                    parseInt(mydata['South West'].totalCount)) *
                    100 +
                  '%',
              }"
            ></div>
            <div
              style="background: #ec7171"
              :title="`OYO ${mydata['South West'].OYO}`"
              :style="{
                height:
                  (parseInt(mydata['South West'].OYO) /
                    parseInt(mydata['South West'].totalCount)) *
                    100 +
                  '%',
              }"
            ></div>
            <div
              style="background: #e23b3b"
              :title="`LAGOS ${mydata['South West'].LAGOS}`"
              :style="{
                height:
                  (parseInt(mydata['South West'].LAGOS) /
                    parseInt(mydata['South West'].totalCount)) *
                    100 +
                  '%',
              }"
            ></div>
            <div
              style="background: #9d1f1f"
              :title="`OGUN ${mydata['South West'].OGUN}`"
              :style="{
                height:
                  (parseInt(mydata['South West'].OGUN) /
                    parseInt(mydata['South West'].totalCount)) *
                    100 +
                  '%',
              }"
            ></div>

            <div
              style="background: #7b1919"
              :style="{
                height:
                  (parseInt(mydata['South West'].ONDO) /
                    parseInt(mydata['South West'].totalCount)) *
                    100 +
                  '%',
              }"
              :title="`ONDO ${mydata['South West'].ONDO}`"
            ></div>
          </div>
        </div>
        <div class="listme">South West</div>
      </div>

      <div class="position-relative" style="width: 30px">
        <div class="bar one" style="height: 100%">
          <div
            style="position: absolute; bottom: 0"
            class="w-100"
            v-if="mydata['South East']"
            :style="{
              height:
                (parseInt(mydata['South East'].totalCount + 1) /
                  parseInt(myhighest + 5)) *
                  100 +
                '%',
            }"
          >
            <div
              style="background: #f2d5d5"
              :style="{
                height:
                  (parseInt(mydata['South East'].ABIA) /
                    parseInt(mydata['South East'].totalCount)) *
                    100 +
                  '%',
              }"
              :title="`ABIA ${mydata['South East'].ABIA}`"
            ></div>

            <div
              style="background: #f5b0b0"
              :title="`ANAMBRA ${mydata['South East'].ANAMBRA}`"
              :style="{
                height:
                  (parseInt(mydata['South East'].ANAMBRA) /
                    parseInt(mydata['South East'].totalCount)) *
                    100 +
                  '%',
              }"
            ></div>

            <div
              style="background: #ec7171"
              :title="`EBONYI ${mydata['South East'].EBONYI}`"
              :style="{
                height:
                  (parseInt(mydata['South East'].EBONYI) /
                    parseInt(mydata['South East'].totalCount)) *
                    100 +
                  '%',
              }"
            ></div>

            <div
              style="background: #c02a2a"
              :title="`ENUGU ${mydata['South East'].ENUGU}`"
              :style="{
                height:
                  (parseInt(mydata['South East'].ENUGU) /
                    parseInt(mydata['South East'].totalCount)) *
                    100 +
                  '%',
              }"
            ></div>
            <div
              style="background: #9d1f1f"
              :title="`IMO ${mydata['South East'].IMO}`"
              :style="{
                height:
                  (parseInt(mydata['South East'].IMO) /
                    parseInt(mydata['South East'].totalCount)) *
                    100 +
                  '%',
              }"
            ></div>
          </div>
        </div>

        <div class="listme">South East</div>
      </div>

      <div class="position-relative" style="width: 30px">
        <div class="bar one" style="height: 100%">
          <div
            style="position: absolute; bottom: 0"
            class="w-100"
            v-if="mydata['South South']"
            :style="{
              height:
                Math.round(
                  (parseInt(mydata['South South'].totalCount + 1) /
                    parseInt(myhighest + 5)) *
                    100
                ) + '%',
            }"
          >
            <div class="h-100">
              <div
                style="background: #f5e5b4"
                :style="{
                  height:
                    Math.round(
                      (parseInt(mydata['South South'].BENIN) /
                        parseInt(mydata['South South'].totalCount)) *
                        100
                    ) + '%',
                }"
                :title="`BENIN ${mydata['South South'].BENIN}`"
              ></div>

              <div
                style="background: #f5e5b4"
                :style="{
                  height:
                    Math.round(
                      (parseInt(mydata['South South'].PORTHARCOURT) /
                        parseInt(mydata['South South'].totalCount)) *
                        100
                    ) + '%',
                }"
                :title="`PORTHARCOURT ${mydata['South South'].PORTHARCOURT}`"
              ></div>
              <div
                style="background: #ecce73"
                :style="{
                  height:
                    Math.round(
                      (parseInt(mydata['South South'].EDO) /
                        parseInt(mydata['South South'].totalCount)) *
                        100
                    ) + '%',
                }"
                :title="`EDO ${mydata['South South'].EDO}`"
              ></div>

              <div
                style="background: #e2b93b"
                :title="`DELTA ${mydata['South South'].DELTA}`"
                :style="{
                  height:
                    Math.round(
                      (parseInt(mydata['South South'].DELTA) /
                        parseInt(mydata['South South'].totalCount)) *
                        100
                    ) + '%',
                }"
              ></div>
              <div
                style="background: #bd9d2f"
                :title="`CROSSRIVER ${mydata['South South']['CROSS RIVER']}`"
                :style="{
                  height:
                    Math.round(
                      (mydata['South South']['CROSS RIVER'] /
                        parseInt(mydata['South South'].totalCount)) *
                        100
                    ) + '%',
                }"
              ></div>
              <div
                style="background: #bd9d2f"
                :title="`RIVERS ${mydata['South South'].RIVERS}`"
                :style="{
                  height:
                    Math.round(
                      (mydata['South South'].RIVERS /
                        parseInt(mydata['South South'].totalCount)) *
                        100
                    ) + '%',
                }"
              ></div>

              <div
                style="background: #988026"
                :title="`BAYELSA ${mydata['South South'].BAYELSA}`"
                :style="{
                  height:
                    Math.round(
                      (parseInt(mydata['South South'].BAYELSA) /
                        parseInt(mydata['South South'].totalCount)) *
                        100
                    ) + '%',
                }"
              ></div>
              <div
                style="background: #72621f"
                :title="`AKWAIBOM ${mydata['South South']['AKWA IBOM']}`"
                :style="{
                  height:
                    Math.round(
                      (parseInt(mydata['South South']['AKWA IBOM']) /
                        parseInt(mydata['South South'].totalCount)) *
                        100
                    ) + '%',
                }"
              ></div>
            </div>
          </div>
        </div>
        <div class="listme">South South</div>
      </div>
    </div>

    <ul class="v-meter">
      <li class="border-bottom">
        <div>{{ parseInt(myhighest + 5) }}</div>
      </li>
      <li class="border-bottom">
        <div>{{ Math.round((1 / 2) * parseInt(Math.round(myhighest + 5))) }}</div>
      </li>
      <li class="border-bottom">
        <div>{{ Math.round((1 / 4) * parseInt(myhighest + 5)) }}</div>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "alertBar",
  props: ["mydata", "myhighest"],
  data() {
    return {
      groupzone: [],
      newObj: [],
    };
  },
};
</script>
<style scoped>
.containerss {
  border-top: none !important;
  border-right: none !important;
  z-index: 0;
  margin: 10px;
  height: 220px;
  position: relative;
  margin: 30px;
}
.v-meter {
  position: relative;
  top: -10px;
  left: -15px;
  z-index: 100;
  box-sizing: border-box;
  height: 100%;
  width: 600px;
}
.v-meter li {
  position: relative;
  width: 100%;
  list-style: none;
  left: -30px;
  height: calc(100% / 3);
}
.v-meter li div {
  position: absolute;
  top: -10px;
  font-size: 12px;
  left: -15px;
  text-align: right;
}
.bar {
  width: 30px;
  background: #f5f6f7 transparent;
  z-index: 1000;
  font-size: 12px;
  color: #555;
  box-sizing: border-box;
  margin-bottom: 10px;
  display: grid;
  position: absolute;
  bottom: 0px;
}
.bars {
  position: absolute;
  width: 100%;
  z-index: 1000;
  height: 100%;
  display: flex;
  bottom: 0px;
  justify-content: space-around;
}
.listme {
  bottom: -20px;
  text-align: center;
  width: 90px;
  font-size: 11px;
  display: flex;
  justify-content: space-between;
  margin-left: -10px;
  margin-bottom: 0px;
  position: absolute;
}
</style>
