<template>
  <div class="w-100 app" v-if="loading == false">
    <div
      class="modal fade"
      id="exampleModalLong"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLongTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">Add Security Session</h5>
            <button
              type="button"
              class="close"
              id="myCheck"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="SubmitHandler">
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="inputEmail4">Expiry Date</label>
                  <input
                    type="date"
                    class="form-control"
                    id="inputEmail4"
                    placeholder="Date"
                    v-model="date"
                  />
                </div>
                <div class="form-group col-md-6">
                  <label for="inputPassword4">Expiry Time</label>
                  <input
                    type="time"
                    v-model="time"
                    class="form-control"
                    id="inputPassword4"
                    placeholder="Time"
                  />
                </div>
              </div>
              <div class="form-group">
                <label for="exampleInputPassword1">Maximum Users</label>
                <input
                  type="tel"
                  class="form-control"
                  id="exampleInputPassword1"
                  placeholder="Enter Maximum users"
                  v-model="maxuser"
                />
              </div>
              <div class="form-group">
                <label for="exampleInputPassword1">Token</label>
                <input
                  type="text"
                  class="form-control"
                  id="exampleInputPassword1"
                  placeholder="Enter Token"
                  v-model="token"
                />
              </div>

              <button
                :disabled="isdisable"
                type="submit"
                class="btn"
                style="background: rgb(35, 143, 83); color: #ffffff"
              >
                {{ loadingadd }}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade hidesession"
      id="exampleModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Add Session</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              id="myupdate"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="Update">
              <div class="form-group">
                <label for="exampleInputEmail1">Duration</label>
                <input
                  type="date"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  :value="date"
                  required
                />
              </div>
              <div class="form-group">
                <label for="exampleInputPassword1">Number of User</label>
                <input
                  type="text"
                  class="form-control"
                  id="exampleInputPassword1"
                  placeholder="Enter Number of User"
                  v-model="maxuser"
                  required
                />
              </div>

              <button type="submit" class="btn" style="background: #238f53; color: #fff">
                {{ loadingaddc }}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <SideBar />
    <div class="w-100">
      <Header />
      <main class="p-3 w-100">
        <div class="w-100">
          <div class="w-100" style="display: flex; flex-direction: column; gap: 16px">
            <div style="display: flex; justify-content: flex-end">
              <button
                type="submit"
                class="btn"
                style="
                  max-width: 190px;

                  border-radius: 8px;

                  border: none;
                  outline: none;
                  background: #238f53;
                  color: #ffffff;
                  padding: 16px 24px;
                "
                data-toggle="modal"
                data-target="#exampleModalLong"
              >
                Add Session
              </button>
            </div>
            <div class="w-100">
              <table class="table mt-4">
                <thead>
                  <tr>
                    <th scope="col">Maximum User</th>
                    <th scope="col">Expire Date</th>
                    <th scope="col">Code</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in codes" :key="index">
                    <td>{{ item?.maxUsers ? item.maxUsers : "No maximum users" }}</td>
                    <td>
                      {{
                        item?.expiryDate
                          ? new Date(item.expiryDate).toDateString()
                          : "No Expiry Date"
                      }}
                      {{
                        item?.expiryDate
                          ? new Date(item.expiryDate).toLocaleTimeString()
                          : ""
                      }}
                    </td>
                    <td>
                      {{ item.token }}
                      <input
                        type="hidden"
                        :value="item.token"
                        :id="`myInput_${item.token}`"
                      />
                    </td>
                    <td style="display: flex; align-items: center; gap: 4px">
                      <!---
                        <div
                          style="
                            height: 25px;
                            width: 25px;
                            border-radius: 4px;
                            background: rgb(35, 143, 83);
                            justify-content: center;
                            display: flex;
                            align-items: center;
                            cursor: pointer;
                          "
                          type="button"
                          data-toggle="modal"
                          data-target="#exampleModal"
                          @click="getSucrity(item)"
                        >
                          <span
                            class="material-icons"
                            style="color: #ffffff; font-size: 16px"
                            >edit</span
                          >
                        </div>
                        -->
                      <div
                        style="
                          height: 25px;
                          width: 25px;
                          border-radius: 4px;
                          background: crimson;
                          justify-content: center;
                          display: flex;
                          align-items: center;
                          cursor: pointer;
                        "
                        @click="DeleteSession(item._id)"
                      >
                        <span
                          class="material-icons"
                          style="color: #ffffff; font-size: 16px"
                          >delete</span
                        >
                      </div>
                      <div
                        style="
                          height: 25px;
                          width: 25px;
                          border-radius: 4px;
                          background: blue;
                          justify-content: center;
                          display: flex;
                          align-items: center;
                          cursor: pointer;
                        "
                        @click="copyClippord(item.token)"
                      >
                        <span
                          class="material-icons"
                          style="color: #ffffff; font-size: 16px"
                          >content_copy</span
                        >
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
  <div v-else class="center">
    <vue-loaders-ball-scale-multiple
      color="#238f53"
      scale="1"
    ></vue-loaders-ball-scale-multiple>
  </div>
</template>
<script>
import axios from "axios";
import Header from "../components/header.vue";
import SideBar from "../components/sidebar.vue";
export default {
  name: "DeleteRequest",
  components: {
    SideBar,
    Header,
  },
  data() {
    return {
      codes: [],
      date: new Date(),
      maxuser: 0,
      loadingadd: "Add Session",
      isdisable: false,
      id: "",
      loadingaddc: "Update",
      loading: true,
      time: new Date(),
      token: "",
    };
  },
  async created() {
    await axios
      .get(`/v1/user/request_for_delete`)
      .then((res) => {
        console.log(res);
        //this.codes = res.data.result.data.data;
        this.loading = false;
      })
      .catch((e) => {
        console.log(e);
      });
  },
  methods: {
    async copyClippord(value) {
      // Get the text field

      var copyText = document.getElementById(`myInput_${value}`);

      // Select the text field
      copyText.select();
      //copyText.setSelectionRange(0, 99999); // For mobile devices

      // Copy the text inside the text field
      await navigator.clipboard.writeText(copyText.value);

      // Alert the copied text
      alert("Copied the text: " + copyText.value);
    },
    async SubmitHandler() {
      this.loadingadd = "adding...";
      this.isdisable = true;
      const data = {
        expiryDate: new Date(`${this.date}T${this.time}`),
        maxUsers: this.maxuser,
        token: this.token,
      };
      // console.log(data);
      axios
        .post("/v1/admin/security/create", data)
        .then((resp) => {
          this.loadingadd = "Add Session";
          this.maxuser = "";
          this.date = "";
          this.isdisable = false;
          axios.get(`/v1/admin/security/all?limit=1000`).then((res) => {
            this.codes = res.data.result.data.data;
            document.getElementById("myCheck").click();
            this.$swal({
              text: `${resp.data.result.data.token} is generated successfully`,
              icon: "success",
              width: "300px",

              confirmButtonColor: "#238f53",
            }).then(() => {});
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getSucrity(item) {
      this.maxuser = item.maxUsers;
      this.date = item.expiryDate;
      this.id = item._id;
    },
    async Update() {
      this.loadingaddc = "Updating...";

      const data = {
        expiryDate: new Date(this.date),
        maxUsers: this.maxuser,
      };
      axios
        .put(`/v1/admin/security/${this.id}`, data)
        .then(() => {
          this.loadingaddc = "Update";
          this.maxuser = "";
          this.date = "";
          this.isdisable = false;
          axios.get(`/v1/admin/security/all?limit=1000`).then((res) => {
            this.codes = res.data.result.data.data;
            /*
              var x = document.getElementById("exampleModal");
              x.style.display = "none";
              x.style.opacity = "0";
              */
            document.getElementById("myupdate").click();
            this.$swal({
              text: res.data.result.message,
              icon: "success",
              width: "300px",

              confirmButtonColor: "#238f53",
            }).then(() => {});
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async DeleteSession(id) {
      this.$swal({
        title:
          "<span style='font-size:16px !important'>Are you sure you want to delete this session?</span>",
        confirmButtonColor: "#238f53",
        width: "300px",
        confirmButton: true,
        cancelButton: true,
        imageHeight: "30",
        imageWidth: "30",
        icon: "warning",
        fontSize: "12px",
        buttons: true,
        padding: "5px !important",
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete.isConfirmed) {
          try {
            const response = await axios.delete(`v1/admin/security/${id}`);
            await axios.get(`/v1/admin/security/all?limit=1000`).then((res) => {
              this.codes = res.data.result.data.data;
            });
            if (response.status == 200) {
              this.$swal({
                text: response.data.result.message,
                icon: "success",
                width: "300px",

                confirmButtonColor: "#238f53",
              }).then(() => {});
            }
          } catch (e) {
            console.log(e);
          }
        }
      });
    },
  },
};
</script>
