<template>
  <div class="w-100 app">
    <!-- Button trigger modal -->

    <div
      class="modal fade bd-example-modal-lg"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
      style="z-index: 10000"
    >
      <div class="modal-dialog modal-lg">
        <div
          class="modal-content"
          style="
            z-index: 10000;
            border-radius: 20px;
            margin: 0px auto;
            max-height: 200px;
            max-width: 200px;
          "
        >
          <img
            :src="`${selectedUser.profileImage.url}`"
            alt=""
            style="overflow: hidden; border-radius: 100%"
            class="centerss"
            v-if="selectedUser.profileImage"
          />
          <img
            src="../assets/images/avater-image.png"
            alt=""
            style="overflow: hidden; border-radius: 100%"
            class="centerss"
            v-else
          />
        </div>
      </div>
    </div>
    <!--User -->

    <div
      class="modal fade bd-example-modal-sm"
      tabindex="-1"
      role="dialog"
      aria-labelledby="mySmallModalLabel"
      aria-hidden="true"
      v-if="showmodaldetail === true"
    >
      <div
        class="modal-dialog"
        style="max-width: 550px; width: 100%; padding: 10px"
      >
        <div class="modal-content p-2" style="border-radius: 10px">
          <h3 style="font-size: 13px" class="p-2 m-2">User Details</h3>
          <div
            class="w-100 p-1 d-flex justify-content-center"
            style="margin: 0px auto"
          >
            <div
              style="
                width: 60px;
                height: 60px;
                border-radius: 100%;
                border: 1px solid #ccc;
                margin-top: 20px;
                margin-bottom: 20px;
              "
              data-toggle="modal"
              data-target=".bd-example-modal-lg"
            >
              <img
                :src="`${selectedUser.profileImage.url}`"
                alt=""
                style="
                  width: 100%;
                  height: 100%;
                  overflow: hidden;
                  border-radius: 100%;
                  cursor: pointer;
                "
                v-if="selectedUser.profileImage"
              />
              <img
                src="../assets/images/avater-image.png"
                alt=""
                style="
                  width: 100%;
                  height: 100%;
                  overflow: hidden;
                  border-radius: 100%;
                  cursor: pointer;
                "
                v-else
              />
            </div>
          </div>
          <div
            class="d-flex p-1 m-1"
            style="
              align-items: center;
              justify-content: space-between;
              line-height: 20px;
            "
          >
            <h5 style="font-size: 12px">First Name</h5>
            <span style="font-size: 12px"> {{ fname }} </span>
          </div>
          <div
            class="d-flex p-1 m-1"
            style="
              align-items: center;
              justify-content: space-between;
              line-height: 20px;
            "
          >
            <h5 style="font-size: 12px">Last Name</h5>
            <span style="font-size: 12px"> {{ lname }} </span>
          </div>
          <div
            class="d-flex p-1 m-1"
            style="
              align-items: center;
              justify-content: space-between;
              line-height: 20px;
            "
          >
            <h5 style="font-size: 12px">Email</h5>
            <span style="font-size: 12px"> {{ eemail }} </span>
          </div>
          <div
            class="d-flex p-1 m-1"
            style="justify-content: space-between; line-height: 20px"
          >
            <h5 style="font-size: 12px">State</h5>
            <div style="max-width: 320px; width: 100%; word-wrap: break-word">
              <span
                class="spanstate"
                style="font-size: 12px; text-align: right"
                v-for="state in selectedUser.state"
                :key="state"
              >
                {{ state }}
              </span>
            </div>
          </div>
          <div
            class="d-flex p-1 m-1"
            style="justify-content: space-between; line-height: 20px"
          >
            <h5 style="font-size: 12px">LG</h5>
            <div style="max-width: 320px; width: 100%; word-wrap: break-word">
              <span
                style="font-size: 12px; text-align: right"
                class="spanstate"
                v-for="lga in selectedUser.lga"
                :key="lga"
              >
                {{ lga }}
              </span>
            </div>
          </div>
          <div
            class="d-flex p-1 m-1"
            style="
              justify-content: space-between;
              line-height: 20px;
              overflow: hidden;
            "
          >
            <h5 style="font-size: 12px">Ra</h5>
            <div style="max-width: 320px; width: 100%; word-wrap: break-word">
              <span
                style="font-size: 12px; text-align: right"
                class="spanstate"
                v-for="ra in selectedUser.ra"
                :key="ra"
              >
                {{ ra }}
              </span>
            </div>
          </div>
          <div
            class="d-flex p-1 m-1"
            style="
              align-items: center;
              justify-content: space-between;
              line-height: 20px;
            "
          >
            <h5 style="font-size: 12px">Number</h5>
            <span style="font-size: 12px"> {{ phone }} </span>
          </div>
          <div
            class="d-flex p-1 m-1"
            style="
              align-items: center;
              justify-content: space-between;
              line-height: 20px;
            "
          >
            <h5 style="font-size: 12px">Role</h5>
            <span style="font-size: 12px"> {{ rrole }} </span>
          </div>
          <div
            class="d-flex p-1 m-1"
            style="
              align-items: center;
              justify-content: space-between;
              line-height: 20px;
            "
          >
            <h5 style="font-size: 12px">Phone</h5>
            <span style="font-size: 12px"> {{ phone }} </span>
          </div>
          <div
            class="d-flex p-1 m-1"
            style="
              align-items: center;
              justify-content: space-between;
              line-height: 20px;
            "
          >
            <h5 style="font-size: 12px">Id</h5>
            <span style="font-size: 12px"> {{ selectedUser._id }} </span>
          </div>
          <div
            class="d-flex p-1 m-1"
            style="
              align-items: center;
              justify-content: space-between;
              line-height: 20px;
            "
          >
            <h5 style="font-size: 12px">Date</h5>
            <span style="font-size: 12px">
              {{ moment(selectedUser.createdAt).format("DD-MM-YYYY h:ma") }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->

    <div
      class="modal fade"
      id="exampleModalLong"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLongTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div
          class="modal-content"
          style="
            max-width: 410px;
            width: 100%;
            margin: 0px auto;
            border-radius: 10px;
          "
        >
          <div class="modal-header">
            <h5
              class="modal-title"
              id="exampleModalLongTitle"
              style="font-size: 13px; padding: 5px"
            >
              Add Admin
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="addNewAdmin">
              <div class="form-row p-2">
                <div class="form-group col-md-6">
                  <label for="">First name</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Firstname"
                    v-model="firstname"
                    required
                  />
                </div>
                <div class="form-group col-md-6">
                  <label for="">Last name</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="lastname"
                    placeholder="Lastname"
                    required
                  />
                </div>
              </div>

              <div class="form-group">
                <label for="">Email Address</label>
                <input
                  type="email"
                  class="form-control"
                  placeholder="Email Address"
                  v-model="email"
                  required
                />
              </div>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="">Password</label>
                  <input
                    type="password"
                    class="form-control"
                    placeholder="password"
                    v-model="password"
                    required
                  />
                </div>
                <div class="form-group col-md-6">
                  <label for="">Phone Number</label>
                  <input
                    type="tel"
                    class="form-control"
                    placeholder="Phone Number"
                    v-model="phonenumber"
                    required
                  />
                </div>
              </div>
              <div class="form-group">
                <label for="">Role</label>

                <select
                  type="tel"
                  class="form-control"
                  placeholder="Choose Role"
                  v-model="myrole"
                >
                  <option value="admin">Admin</option>
                  <option value="super_admin">Super Admin</option>
                  <option value="view_admin">View Admin</option>
                </select>
              </div>

              <div class="form-group" v-if="myrole == 'admin'">
                <label for="">State</label>

                <select
                  type="tel"
                  class="form-control"
                  placeholder="248145938104"
                  v-model="state"
                  @change="getlga(state.state)"
                  required
                >
                  <option
                    :value="{ state: state.id, name: state.name }"
                    v-for="state in states"
                    :key="state.id"
                  >
                    {{ state.name }}
                  </option>
                </select>
              </div>

              <div class="form-group"></div>
              <button
                :disabled="isDisabledadmin"
                type="submit"
                class="btn w-100"
                style="background: #238f53; color: #fff"
              >
                Save Admin
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <!-- Modal -->
    <!-- Sample -->
    <div
      class="modal fade"
      id="exampleModalCenter"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      @click="this.showmodaldetail = true"
    >
      aria-hidden="true" >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div
          class="modal-content"
          style="max-width: 400px; width: 100%; margin: 0px auto"
        >
          <div class="modal-header" style="padding: 10px !important">
            <h5
              class="modal-title"
              id="exampleModalLongTitle"
              style="font-size: 14px"
            >
              Edit User
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <!-- form -->
            <form @submit.prevent="editUser">
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="">Firstname</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Email"
                    v-model="fname"
                  />
                </div>
                <div class="form-group col-md-6">
                  <label for="">Lastname</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="last name"
                    v-model="lname"
                  />
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="">Phone Number</label>
                  <input
                    type="tel"
                    class="form-control"
                    placeholder="Phone Number"
                    v-model="phone"
                  />
                </div>
                <div class="form-group col-md-6">
                  <label for="">Email Address</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Email Address"
                    v-model="eemail"
                  />
                </div>
                <div class="form-group">
                  <div v-if="isChecked">
                    <label for="">New Password</label>
                    <input
                      type="password"
                      class="form-control"
                      placeholder="password"
                      v-model="epassword"
                    />
                  </div>

                  <div>
                    <div
                      :style="{
                        display: 'flex',
                        alignItems: 'center',
                      }"
                    >
                      <label class="switch">
                        <input type="checkbox" @click="toggleSwitch" />

                        <div class="slider round"></div>
                      </label>
                      <p
                        :style="{
                          paddingLeft: '3px',
                          color: '#7993b1',
                          margin: '0',
                          fontSize: '12px',
                        }"
                      >
                        Edit Password
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group" v-if="selected === 'Admin'">
                <label for="inputAddress2" style="display: block">Role</label>
                <select class="form-control" v-model="rrole">
                  <option value="super_admin">Super Admin</option>
                  <option value="admin">Admin</option>
                  <option value="view_admin">View Admin</option>
                </select>
              </div>

              <div class="form-group" v-if="selected === 'User'">
                <label for="inputAddress2" style="display: block">Role</label>
                <select class="form-control" v-model="rrole">
                  <option value="Electoral Officer">Electoral Officer</option>
                  <option value="Assistant Presiding Officer">
                    Assistant Presiding Officer
                  </option>
                  <option value="presiding_officer">Presiding_officer</option>
                  <option value="Director of Department">
                    Presiding_officer
                  </option>
                  <option value="Admin Secretary">Admin Secretary</option>
                  <option value="REC">REC</option>

                  <option value="National Commissioner">
                    National Commissioner
                  </option>
                  <option value="Security Expert">Security Expert</option>
                  <option value="national_commissioner">
                    National Commissioner
                  </option>
                  <option value="security_expert">Security Expert</option>
                </select>
              </div>

              <button
                :disabled="isDisableduser"
                type="submit"
                class="btn w-100"
                style="background: #238f53; color: #fff; font-size: 13px"
              >
                Update User
              </button>
            </form>

            <!--End from -->
          </div>
        </div>
      </div>
    </div>

    <SideBar />
    <div class="w-100">
      <Header />
      <main class="p-3 bg-home-main">
        <div class="w-100">
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            <div style="display: flex; align-items: center; gap: 20px">
              <div class="d-flex mt-4 justify-content-between p-1">
                <div class="conflex1">
                  <div style="flex: 1">
                    <div
                      style="
                        display: flex;
                        align-items: center;
                        font-size: 13px;
                        font-weight: 500;
                        margin-left: 10px;
                        color: crimson;
                        min-width: 70px;
                        width: 100%;
                        cursor: pointer;
                      "
                      @click="resetAll"
                    >
                      Reset All
                    </div>
                  </div>
                  <div
                    style="
                      flex: 9;

                      display: flex;
                      justify-content: left;
                    "
                  >
                    <div class="dropdown" style="max-width: 600px">
                      <button
                        class="btn btn-secondary dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        style="
                          min-width: 256px;
                          width: 100%;
                          margin-right: 20px;
                        "
                      >
                        {{ choosenstate }}
                      </button>
                      <div
                        class="dropdown-menu dgggd"
                        aria-labelledby="dropdownMenuButton"
                        style="
                          height: 300px !important;
                          overflow: auto;
                          width: 100%;
                        "
                      >
                        <a
                          class="dropdown-item"
                          style="
                            width: 100%;

                            font-size: 12px;
                          "
                          href="#"
                          v-for="item in filterStatess"
                          :key="item.id"
                          @click="getlgas(item.id, item.name, selected)"
                          >{{ item.name }}</a
                        >
                      </div>
                    </div>
                    <div class="dropdown" v-if="selected === 'User'">
                      <button
                        class="btn btn-secondary dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        style="min-width: 256px; width: 100%"
                      >
                        {{ choosenLga }}
                      </button>
                      <div
                        class="dropdown-menu dgggd"
                        aria-labelledby="dropdownMenuButton"
                        style="
                          height: 300px !important;
                          overflow: auto;
                          width: 100%;
                        "
                      >
                        <a
                          class="dropdown-item"
                          href="#"
                          v-for="item in selectedstates"
                          :key="item.id"
                          style="font-size: 12px"
                          @click="lgaget(item.name)"
                        >
                          {{ item.name }}</a
                        >
                      </div>
                    </div>
                    <div class="dropdown" v-if="selected === 'User'">
                      <button
                        class="btn btn-secondary dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton3"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        style="min-width: 256px; width: 100%"
                      >
                        {{ choosenRole }}
                      </button>
                      <div
                        class="dropdown-menu dgggd"
                        aria-labelledby="dropdownMenuButton3"
                        style="
                          height: 300px !important;
                          overflow: auto;
                          width: 100%;
                        "
                      >
                        <a
                          class="dropdown-item"
                          href="#"
                          v-for="(item, index) in allRole"
                          :key="index"
                          style="font-size: 12px"
                          @click="findRole(item)"
                        >
                          {{ item }}</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex" style="justify-content: right !important">
              <div
                class="btntab d-flex"
                v-if="
                  adminrole === 'super_admin' || adminrole === 'global_admin'
                "
              >
                <button
                  class="btn btn2 d-flex mr-2"
                  style="align-items: center"
                  data-toggle="modal"
                  data-target="#exampleModalLong"
                >
                  <span class="material-icons" style="font-size: 14px"
                    >add</span
                  >
                  <span>New Admin</span>
                </button>

                <button
                  class="btn btn2 d-flex"
                  style="align-items: center"
                  data-toggle="modal"
                  data-target="#exampleModal"
                >
                  <span class="material-icons" style="font-size: 14px"
                    >add</span
                  >
                  <span>New User</span>
                </button>

                <button
                  v-if="adminrole === 'global_admin'"
                  class="btn btn2 d-flex"
                  style="
                    align-items: center;
                    background: crimson;
                    color: #fff;
                    border: none;
                  "
                  @click="DeleteAllUser"
                >
                  <span class="material-icons" style="font-size: 14px"
                    >delete</span
                  >
                  <span>Delete All Users</span>
                </button>
              </div>

              <!-- Button trigger modal -->

              <!-- Modal -->
              <div
                class="modal fade"
                id="exampleModal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog" role="document">
                  <div
                    class="modal-content"
                    style="
                      max-width: 400px;
                      margin: 0px auto;
                      border-radius: 10px !important;
                      background: #fff;
                    "
                  >
                    <div class="modal-header m-0" style="padding: 13px">
                      <div
                        class="modal-title"
                        id="exampleModalLabel"
                        style="
                          padding: 5px !important;
                          margin: 0px !important;
                          font-size: 13px;
                        "
                      >
                        Add New User
                      </div>
                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body" style="padding: 20px !important">
                      <form @submit.prevent="addNewUser">
                        <div class="form-row">
                          <div class="form-group col-md-6">
                            <label for="">Firstname</label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Firstname"
                              v-model="firstname"
                              required
                              autocomplete="off"
                            />
                          </div>
                          <div class="form-group col-md-6">
                            <label for="">Lastname</label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="lastname"
                              placeholder="Lastname"
                              required
                              autocomplete="off"
                            />
                          </div>
                        </div>

                        <div class="form-group">
                          <label for="">Email Address</label>
                          <input
                            type="email"
                            class="form-control"
                            placeholder="Email Address"
                            v-model="email"
                            required
                            autocomplete="off"
                          />
                        </div>

                        <div class="form-row">
                          <div class="form-group col-md-6">
                            <label for="">Password</label>
                            <input
                              type="password"
                              class="form-control"
                              placeholder="password"
                              v-model="password"
                              required
                              autocomplete="off"
                            />
                          </div>
                          <div class="form-group col-md-6">
                            <label for="">Phone Number</label>
                            <input
                              type="tel"
                              class="form-control"
                              placeholder="Phone Number"
                              v-model="phonenumber"
                              required
                              autocomplete="off"
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <label for="">User Role</label>
                          <select
                            type="tel"
                            class="form-control"
                            placeholder="248145938104"
                            v-model="userrole"
                            required
                          >
                            <option value="response_team">Respone Team</option>

                            <option value="notifying_user">
                              Notifying User
                            </option>
                          </select>
                        </div>

                        <div class="form-group">
                          <label for="">Assign Role</label>
                          <select
                            type="tel"
                            class="form-control"
                            placeholder="248145938104"
                            v-model="role"
                            required
                          >
                            <option
                              :value="item"
                              v-for="(item, index) in userrole ===
                              'response_team'
                                ? responseTeam
                                : notifiyingUser"
                              :key="index"
                            >
                              {{ item }}
                            </option>
                          </select>
                        </div>

                        <div class="form-row">
                          <div class="form-group col-md-6 position-relative">
                            <label for="">State</label>

                            <input
                              type="text"
                              class="form-control"
                              placeholder="Select State"
                              v-model="myselectedstate"
                              @focus="showlistme"
                              required
                              readonly
                            />
                            <ul
                              style="
                                list-style: none;
                                overflow-y: auto;
                                height: 300px;
                                z-index: 10000;
                                background: white;
                              "
                              v-show="hideshowme"
                              class="position-absolute shadow-sm w-100 p-2"
                            >
                              <li
                                style="
                                  text-align: right;
                                  cursor: pointer;
                                  color: rgb(35, 143, 83);
                                  font-size: 12px;
                                "
                                @click="showlistme"
                              >
                                Done
                              </li>

                              <input
                                placeholder="Search state"
                                type="search"
                                v-model="searchmystate"
                                style="
                                  border: 1px solid rgb(35, 143, 83) !important;
                                  border-radius: 8px;
                                  padding: 8px 4px 8px 4px;
                                  margin-top: 10px;
                                  margin-bottom: 10px;
                                "
                              />

                              <li>
                                <label class="form-check classlist">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    @change="selectAll($event, states)"
                                  />
                                  <span class="checkmark"></span>
                                  <label
                                    class="form-check-label"
                                    for="flexCheckCheckedDisabled"
                                    style="font-size: 12px"
                                  >
                                    Select All
                                  </label>
                                </label>
                              </li>
                              <!---
                              <li>
                                <label class="form-check classlist">
                                  <input
                                    class="form-check-input statecheck"
                                    type="checkbox"
                                    @click="selectallstate"
                                  />
                                  <span class="checkmark"></span>
                                  <label
                                    class="form-check-label"
                                    for="flexCheckCheckedDisabled"
                                    style="font-size: 12px"
                                  >
                                    Select All
                                  </label>
                                </label>
                              </li>
                              -->
                              <li
                                v-for="state in filterUserState"
                                :key="state.id"
                              >
                                <label class="form-check classlist">
                                  <input
                                    class="form-check-input statecheck"
                                    type="checkbox"
                                    v-model="mystateid"
                                    :value="state.id"
                                    @change="
                                      getlga(state.name, $event, state.id)
                                    "
                                  />
                                  <span class="checkmark"></span>
                                  <label
                                    class="form-check-label"
                                    for="flexCheckCheckedDisabled"
                                  >
                                    {{ state.name }}
                                  </label>
                                </label>
                              </li>
                            </ul>
                            <!--
                            <option
                              :value="{ state: state.id, name: state.name }"
                              v-for="state in states"
                              :key="state.id"
                            >

                            </option>
                            -->
                          </div>

                          <div class="form-group col-md-6">
                            <label for="">LGA</label>

                            <input
                              type="text"
                              class="form-control"
                              placeholder="Select LG"
                              v-model="selectedmelg"
                              @click="showlistmelg"
                              required
                              readonly
                            />
                            <ul
                              style="
                                list-style: none;
                                overflow-y: auto;
                                height: 300px;
                                z-index: 10000;
                                background: white;
                              "
                              v-show="hideshowmelg"
                              class="position-absolute shadow-sm w-100 p-2"
                            >
                              <li
                                style="
                                  text-align: right;
                                  cursor: pointer;
                                  color: rgb(35, 143, 83);
                                  font-size: 12px;
                                "
                                @click="showlistmelg"
                              >
                                Done
                              </li>
                              <input
                                placeholder="Search LGAs"
                                type="search"
                                v-model="searchmylgs"
                                style="
                                  border: 1px solid rgb(35, 143, 83) !important;
                                  border-radius: 8px;
                                  padding: 8px 4px 8px 4px;
                                  margin-top: 10px;
                                  margin-bottom: 10px;
                                "
                              />
                              <li>
                                <label class="form-check classlist">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    @click="selectAll2($event)"
                                  />
                                  <span class="checkmark"></span>
                                  <label
                                    class="form-check-label"
                                    for="flexCheckCheckedDisabled"
                                    style="font-size: 12px"
                                  >
                                    Select All
                                  </label>
                                </label>
                              </li>
                              <li v-for="item in filterUserLgs" :key="item">
                                <label class="form-check classlist">
                                  <input
                                    class="form-check-input lgacheck"
                                    type="checkbox"
                                    v-model="mylga"
                                    :value="item"
                                    @change="getras(item.name, $event)"
                                  />
                                  <span class="checkmark"></span>
                                  <label
                                    class="form-check-label"
                                    for="flexCheckCheckedDisabled"
                                  >
                                    {{ item.name }}
                                  </label>
                                </label>
                              </li>
                            </ul>
                            <!--
                            <select
                              type="tel"
                              class="form-control select"

                              placeholder="248145938104"
                              v-model="lga"
                              @change="getras(lga.item)"
                              required
                            >
                              <option
                                :value="{
                                  id: item.id,
                                  name: item.name,
                                  item: item,
                                }"
                                v-for="item in selectedstates"
                                :key="item.id"
                              >
                                {{ item.name }}
                              </option>
                            </select>
                            -->
                          </div>
                        </div>

                        <div class="form-group position-relative">
                          <label for="inputAddress2">RA</label>
                          <input
                            type="tel"
                            class="form-control"
                            placeholder="Select Ra"
                            v-model="myra"
                            @focus="showlistmeraopen"
                            required
                            readonly
                          />
                          <ul
                            style="
                              list-style: none;
                              overflow-y: auto;
                              height: 300px;
                              z-index: 10000;
                              background: white;
                            "
                            v-show="hideshowmera"
                            class="position-absolute shadow-sm w-100 p-2"
                          >
                            <li
                              style="
                                text-align: right;
                                cursor: pointer;
                                color: rgb(35, 143, 83);
                                font-size: 12px;
                              "
                              @click="showlistmera"
                            >
                              Done
                            </li>
                            <input
                              placeholder="Search RAs"
                              type="search"
                              v-model="searchmyras"
                              style="
                                border: 1px solid rgb(35, 143, 83) !important;
                                border-radius: 8px;
                                padding: 8px 4px 8px 4px;
                                margin-top: 10px;
                                margin-bottom: 10px;
                                width: 100%;
                              "
                            />
                            <li>
                              <label class="form-check classlist">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  @click="selectAll3($event)"
                                />
                                <span class="checkmark"></span>
                                <label
                                  class="form-check-label"
                                  for="flexCheckCheckedDisabled"
                                  style="font-size: 12px"
                                >
                                  Select All
                                </label>
                              </label>
                            </li>

                            <li v-for="item in filterUserRAS" :key="item">
                              <label class="form-check classlist">
                                <input
                                  class="form-check-input checkra"
                                  type="checkbox"
                                  v-model="myra"
                                  :value="item.name"
                                />
                                <span class="checkmark"></span>
                                <label
                                  class="form-check-label"
                                  for="flexCheckCheckedDisabled"
                                >
                                  {{ item.name }}
                                </label>
                              </label>
                            </li>
                          </ul>

                          <!---
                          <select
                            class="form-control select"

                            placeholder="248145938104"
                            v-model="ra"
                          >
                            <option
                              :value="{ id: item.id, name: item.name }"
                              v-for="item in selectedlgs.ras"
                              :key="item.id"
                              required
                            >
                              <input type="checkbox" />
                              {{ item.name }}
                            </option>
                          </select>
                          -->
                        </div>
                        <div class="form-group">
                          <label for="inputAddress2">PU</label>

                          <select class="form-control" v-model="pu">
                            <option
                              :value="item['POLLING UNIT']"
                              v-for="(item, index) in pollingUnit"
                              :key="index"
                            >
                              {{ item["POLLING UNIT"] }}
                            </option>
                          </select>
                        </div>
                        <div
                          class="form-group"
                          v-if="userrole !== 'notifying_user'"
                        >
                          <label for="inputAddress2">Alert Type</label>
                          <select
                            type="tel"
                            class="form-control"
                            placeholder="248145938104"
                            v-model="alerttype"
                            required
                          >
                            <option value="CL">Critical Level</option>
                            <option value="HL">High Level</option>
                            <option value="ML">Medium Level</option>
                            <option value="LL">Low Level</option>
                          </select>
                        </div>

                        <div class="form-group"></div>
                        <button
                          type="submit"
                          class="btn w-100"
                          style="background: #238f53; color: #fff"
                        >
                          Save User
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="w-100 bg-white rounded-lg p-2 mt-3"
            style="min-height: 1300px; overflow: hidden"
          >
            <div class="float-left">
              <TabNav
                :tabs="['User', 'Admin']"
                :selected="selected"
                @selected="setSelected"
              >
                <Tab :isselected="selected === setselectedtab">
                  <div
                    style="
                      position: absolute;

                      box-sizing: border-box;

                      overflow: hidden;
                      right: 25px;
                      left: 25px;
                      padding: 10px;
                      height: 100%;
                    "
                    class="border-top"
                  >
                    <table class="table" v-if="loading === false">
                      <thead>
                        <tr>
                          <th scope="col" @click="sorting('firstName')">
                            Name
                          </th>
                          <th scope="col" @click="sorting('phoneNumber')">
                            Phone No
                          </th>

                          <th scope="col" @click="sorting('created_At')">
                            Last Login
                          </th>
                          <th scope="col">Role</th>
                          <th scope="col">State</th>

                          <th
                            scope="col"
                            v-if="
                              adminrole === 'super_admin' ||
                              userRole == 'global_admin'
                            "
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody v-if="filterActive.length > 0">
                        <tr
                          v-for="item in filterActive
                            .slice(0, eachpage)
                            .sort((a, b) =>
                              a.firstName.toLowerCase() >
                              b.firstName.toLowerCase()
                                ? 1
                                : -1
                            )"
                          :key="item.id"
                          @click="eachgetUser(item, 'active')"
                          data-toggle="modal"
                          data-target=".bd-example-modal-sm"
                          style="cursor: pointer"
                        >
                          <td class="d-flex">
                            <div
                              style="
                                width: 30px;
                                height: 30px;
                                border-radius: 100%;
                                border: 1px solid #aaa;
                                margin-right: 10px;
                              "
                            >
                              <img
                                :src="`${item.profileImage.url}`"
                                alt=""
                                style="
                                  width: 100%;
                                  height: 100%;
                                  overflow: hidden;
                                  border-radius: 100%;
                                "
                                v-if="item.profileImage"
                              />
                              <img
                                src="../assets/images/avater-image.png"
                                alt=""
                                style="
                                  width: 100%;
                                  height: 100%;
                                  overflow: hidden;
                                  border-radius: 100%;
                                "
                                v-else
                              />
                            </div>
                            <div class="d-grid">
                              <h6 style="font-size: 12px" class="mt-0 mb-0">
                                {{ item.firstName }} {{ item.lastName }}
                              </h6>
                              <span style="font-size: 10px">{{
                                item.email
                              }}</span>
                            </div>
                          </td>
                          <td>{{ item.phoneNumber }}</td>

                          <td>
                            {{
                              moment(item.lastLogin).format("DD-MM-YYYY h:ma")
                            }}
                          </td>
                          <td>
                            {{
                              item.role !== undefined
                                ? item.role
                                : item.userRole
                            }}
                          </td>
                          <td>
                            {{ item.state[0] }}
                          </td>
                          <td
                            class="d-flex justify-content-cent"
                            v-if="
                              adminrole === 'super_admin' ||
                              adminrole === 'global_admin'
                            "
                          >
                            <button
                              class="sideiconbuttob butn1"
                              data-target="#exampleModalCenter"
                              data-toggle="modal"
                              @click="editusermodal()"
                            >
                              <span class="material-icons">edit</span>
                            </button>
                            <button
                              class="sideiconbuttob butn2 delete"
                              @click="deleteUser(item)"
                            >
                              <span class="material-icons">delete</span>
                            </button>
                            <!---- Modal --->

                            <!--End -->
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <div
                      style="
                        width: 100%;
                        margin-top: 20px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                      "
                      v-else
                    >
                      <Loader />
                    </div>

                    <div
                      class="w-100"
                      style="text-align: center"
                      v-if="filterActive.length == 0"
                    >
                      <h5 style="text-align: center" v-if="!loading">
                        No Active User found
                      </h5>
                    </div>
                    <div
                      style="
                        width: 100%;
                        margin-top: 20px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                      "
                      v-if="filterActive.length > 0"
                    >
                      <span
                        >{{ page }}- {{ parseInt(totalpage / 20) + 1 }} of
                        {{ totalpage }}</span
                      >
                      <nav aria-label="Page navigation example">
                        <ul class="pagination">
                          <li
                            class="page-item"
                            @click="previousPage"
                            :class="hasPrevpage ? 'active' : null"
                            :aria-disabled="hasPrevpage == false ? false : true"
                          >
                            <a
                              class="page-link"
                              href="javascript:void()"
                              aria-label="Previous"
                              :style="{
                                cursor: page <= 1 ? 'not-allowed' : 'pointer',
                              }"
                            >
                              <span aria-hidden="true">&laquo;</span>
                              <span class="sr-only">Previous</span>
                            </a>
                          </li>

                          <li
                            class="page-item"
                            @click="nextPage"
                            :class="hasNextpage ? 'active' : null"
                            :aria-disabled="!hasNextpage ? false : true"
                          >
                            <a
                              class="page-link"
                              href="javascript:void()"
                              aria-label="Next"
                              :style="{
                                cursor: !hasNextpage
                                  ? 'not-allowed'
                                  : 'pointer',
                              }"
                            >
                              <span aria-hidden="true">&raquo;</span>
                              <span class="sr-only">Next</span>
                            </a>
                          </li>
                        </ul>
                      </nav>
                      <div class="d-flex" style="align-items: centet">
                        <label
                          style="
                            font-size: 13px;
                            font-weight: 500;
                            margin-right: 10px;
                          "
                          >Rows per page</label
                        >
                        <select
                          v-model="eachpage"
                          @change="seteachpage(eachpage)"
                        >
                          <option value="5">5</option>
                          <option value="10">10</option>

                          <option value="15">15</option>
                          <option value="20">20</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab :isselected="selected === 'Admin'">
                  <div
                    style="
                      position: absolute;

                      box-sizing: border-box;

                      overflow: hidden;
                      right: 25px;
                      left: 25px;
                      padding: 10px;
                      height: 100%;
                    "
                    class="border-top"
                  >
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Phone No</th>

                          <th scope="col">Last Login</th>
                          <th scope="col">State</th>
                          <th scope="col">Role</th>
                          <th
                            scope="col"
                            v-if="
                              adminrole === 'super_admin' ||
                              userRole == 'global_admin'
                            "
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody v-if="filterPending.length > 0">
                        <tr
                          v-for="item in filterPending"
                          :key="item.id"
                          @click="eachgetUser(item)"
                        >
                          <td class="d-flex">
                            <div
                              style="
                                width: 30px;
                                height: 30px;
                                border-radius: 100%;
                                border: 1px solid #aaa;
                                margin-right: 10px;
                              "
                            >
                              <img
                                src="../assets/images/avater-image.png"
                                alt=""
                                style="
                                  width: 100%;
                                  height: 100%;
                                  overflow: hidden;
                                  border-radius: 100%;
                                "
                              />
                            </div>
                            <div class="d-grid">
                              <h6 style="font-size: 12px" class="mt-0 mb-0">
                                {{ item.firstName }} {{ item.lastName }}
                              </h6>
                              <span style="font-size: 10px">{{
                                item.email
                              }}</span>
                            </div>
                          </td>
                          <td>{{ item.phoneNumber }}</td>

                          <td>
                            {{
                              moment(item.lastLogin).format("DD-MM-YYYY h:ma")
                            }}
                          </td>
                          <td>{{ item.state[0] }}</td>
                          <td style="text-transform: capitalize">
                            {{ item.userRole }}
                          </td>
                          <td
                            class="d-flex justify-content-cent"
                            v-if="
                              adminrole === 'super_admin' ||
                              userRole == 'global_admin'
                            "
                          >
                            <!--
                            <button
                              class="sideiconbuttob butn1"
                              data-target="#exampleModalCenter"
                              data-toggle="modal"
                              @click="editusermodal()"
                            >
                              <span class="material-icons">edit</span>
                            </button>

                            -->
                            <button
                              class="sideiconbuttob butn2 delete"
                              @click="deleteUser(item)"
                              style="margin-left: 15px"
                            >
                              <span class="material-icons">delete</span>
                            </button>
                            <!---- Modal --->

                            <!--End -->
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div
                      class="w-100"
                      style="text-align: center"
                      v-if="filterPending.length == 0"
                    >
                      <h5 style="text-align: center">No Pending User found</h5>
                    </div>
                    <div
                      style="
                        width: 100%;
                        margin-top: 20px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                      "
                      v-if="filterPending.length > 0"
                    >
                      <span
                        >{{ page }}- {{ parseInt(totalpageadmin / 20) + 1 }} of
                        {{ totalpageadmin }}</span
                      >
                      <nav aria-label="Page navigation example">
                        <ul class="pagination">
                          <li
                            class="page-item"
                            @click="previousPageAdmin"
                            :class="hasPrevpageadmin ? 'active' : null"
                            :aria-disabled="
                              hasPrevpageadmin == false ? false : true
                            "
                          >
                            <a
                              class="page-link"
                              href="javascript:void()"
                              aria-label="Previous"
                              :style="{
                                cursor: page <= 1 ? 'not-allowed' : 'pointer',
                              }"
                            >
                              <span aria-hidden="true">&laquo;</span>
                              <span class="sr-only">Previous</span>
                            </a>
                          </li>

                          <li
                            class="page-item"
                            @click="nextPageAdmin"
                            :class="hasNextpageadmin ? 'active' : null"
                            :aria-disabled="
                              hasNextpageadmin == false ? false : true
                            "
                          >
                            <a
                              class="page-link"
                              href="javascript:void()"
                              aria-label="Next"
                              :style="{
                                cursor: !hasNextpageadmin
                                  ? 'not-allowed'
                                  : 'pointer',
                              }"
                            >
                              <span aria-hidden="true">&raquo;</span>
                              <span class="sr-only">Next</span>
                            </a>
                          </li>
                        </ul>
                      </nav>
                      <div class="d-flex" style="align-items: centet">
                        <label
                          style="
                            font-size: 13px;
                            font-weight: 500;
                            margin-right: 10px;
                          "
                          >Rows per page</label
                        >
                        <select
                          v-model="eachpage"
                          @change="seteachpage(eachpage)"
                        >
                          <option value="5">5</option>
                          <option value="10">10</option>

                          <option value="15">15</option>
                          <option value="20">20</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab :isselected="selected === 'Revoked'">
                  <div
                    style="
                      position: absolute;

                      box-sizing: border-box;

                      overflow: hidden;
                      right: 25px;
                      left: 25px;
                      padding: 10px;
                      height: 100%;
                    "
                    class="border-top"
                  >
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col" @click="sorting('firstName')">
                            Name
                          </th>
                          <th scope="col">Phone No</th>

                          <th scope="col">Date Added</th>
                          <th scope="col">Role</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody v-if="filterRevoke.length > 0">
                        <tr
                          v-for="item in filterRevoke"
                          :key="item.id"
                          @click="eachgetUser(item)"
                        >
                          <td class="d-flex">
                            <div
                              style="
                                width: 30px;
                                height: 30px;
                                border-radius: 100%;
                                border: 1px solid #aaa;
                                margin-right: 10px;
                              "
                            >
                              <img
                                src="../assets/images/avater-image.png"
                                alt=""
                                style="
                                  width: 100%;
                                  height: 100%;
                                  overflow: hidden;
                                  border-radius: 100%;
                                "
                              />
                            </div>
                            <div class="d-grid">
                              <h6 style="font-size: 12px" class="mt-0 mb-0">
                                {{ item.firstName }} {{ item.lastName }}
                              </h6>
                              <span style="font-size: 10px">{{
                                item.email
                              }}</span>
                            </div>
                          </td>
                          <td>{{ item.phoneNumber }}</td>

                          <td>
                            {{
                              moment(item.updatedAt).format("DD-MM-YYYY h:ma")
                            }}
                          </td>
                          <td>{{ item.role }}</td>
                          <td class="d-flex justify-content-cent">
                            <button
                              class="sideiconbuttob butn1"
                              data-target="#exampleModalCenter"
                              data-toggle="modal"
                              @click="this.showmodaldetail = false"
                            >
                              <span class="material-icons">edit</span>
                            </button>
                            <button
                              class="sideiconbuttob butn2"
                              @click="deleteUser(item)"
                            >
                              <span class="material-icons">delete</span>
                            </button>

                            <!---- Modal --->

                            <!--End -->
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div
                      class="w-100"
                      style="text-align: center"
                      v-if="filterRevoke == 0"
                    >
                      <h5 style="text-align: center">No Revoke User found</h5>
                    </div>
                    <div
                      style="
                        width: 100%;
                        margin-top: 20px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                      "
                      v-if="filterActive > 0"
                    >
                      <span
                        >{{ page }}- {{ parseInt(totalpage / 20) + 1 }} of
                        {{ totalpage }}</span
                      >
                      <nav aria-label="Page navigation example">
                        <ul class="pagination">
                          <li
                            class="page-item"
                            @click="previousPage"
                            :class="hasPrevpage ? 'active' : null"
                            :aria-disabled="hasPrevpage == false ? false : true"
                          >
                            <a
                              class="page-link"
                              href="javascript:void()"
                              aria-label="Previous"
                              :style="{
                                cursor: page <= 1 ? 'not-allowed' : 'pointer',
                              }"
                            >
                              <span aria-hidden="true">&laquo;</span>
                              <span class="sr-only">Previous</span>
                            </a>
                          </li>

                          <li
                            class="page-item"
                            @click="nextPage"
                            :class="hasNextpage ? 'active' : null"
                            :aria-disabled="hasNextpage == false ? false : true"
                          >
                            <a
                              class="page-link"
                              href="javascript:void()"
                              aria-label="Next"
                              :style="{
                                cursor: !hasNextpage
                                  ? 'not-allowed'
                                  : 'pointer',
                              }"
                            >
                              <span aria-hidden="true">&raquo;</span>
                              <span class="sr-only">Next</span>
                            </a>
                          </li>
                        </ul>
                      </nav>
                      <div class="d-flex" style="align-items: centet">
                        <label
                          style="
                            font-size: 13px;
                            font-weight: 500;
                            margin-right: 10px;
                          "
                          >Rows per page</label
                        >
                        <select
                          v-model="eachpage"
                          @change="seteachpage(eachpage)"
                        >
                          <option value="5">5</option>
                          <option value="10">10</option>

                          <option value="15">15</option>
                          <option value="20">20</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </Tab>
              </TabNav>
            </div>
            <div class="float-right w-50">
              <div
                class="form-group has-search w-100 shadow-none"
                style="border: none !important; outline: none !important"
              >
                <span
                  class="material-icons form-control-feedback"
                  style="font-size: 13px"
                >
                  search</span
                >
                <input
                  type="text"
                  v-model="searchs"
                  @keyup="searchUser"
                  class="form-control no-border no-outline shadow-none"
                  placeholder="Search"
                  autocomplete="off"
                  id="inputEmail3"
                  style="
                    width: 100%;
                    font-size: 13px;
                    outline-border: none;
                    border: 0px !important;
                    border-top: 0px !important;
                    border-bottom: 0px !important;
                    border-right: 0px !important;
                    border-left: 0px !important;
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>
<script>
import Header from "../components/header.vue";
import SideBar from "../components/sidebar.vue";
import TabNav from "@/components/TabNav3.vue";
import Tab from "@/components/tab.vue";
import Loader from "@/components/loader.vue";
import "leaflet/dist/leaflet.css";
import { mapActions } from "vuex";
import moment from "moment";
import axios from "axios";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

export default {
  name: "Management-App",
  components: {
    SideBar,
    Header,
    TabNav,
    Tab,
    Loader,
  },
  data() {
    return {
      showModal: false,
      isChecked: false,
      email: "",
      password: "",
      searchmylgs: "",
      states: [],
      lga: [],
      searchmyras: "",
      showmodaldetail: true,
      hasNextpage: false,
      hasPrevpage: false,
      myselectedra: [],
      hasNextpageadmin: false,
      hasPrevpageadmin: false,
      allUser: [],
      state: "",
      moment: moment,
      selectedUser: [],
      username: "",
      role: "",
      firstname: "",
      alllga: [],
      sss: [],
      selectedstates: [],

      totalpageadmin: 0,
      perpageadmin: 0,
      allUserAdmin: [],
      ppp: [],
      allRole: [
        "Select Role",
        "RESIDENT ELECTORAL COMMISSIONER.(REC)",
        "HEADs OF DEPARTMENTs (HODs)",
        "ADMINISTRATIVE SECRETARY (AS)",
        "ELECTORAL OFFICER (EO)",
        "ICCES (SECURITY EXPERTs)",
        "DESK OFFICERs (DOs)",
        "DIRECTOR",
        "ASSISTANT ELECTORAL OFFICER (AEO)",
        "RA SUPERVISOR",
        "LGA SUPERVISOR",
        "STATE SUPERVISOR",
        "CONSTITUENCY SUPERVISOR",
        "SPOS",
        "RA TECH SUPPORT",
        "LGA TECH SUPPORT",
        "CONSTITUENCY TECH SUPPORT",
        "MONITOR",
        "ASSISTANT PRESIDING OFFICER",
        "PRESIDING OFFICER",
        "RA COLLATION OFFICER",
        "LGA COLLATION OFFICER",
        "STATE COLLATION OFFICER",
        "CONSTITUENCY COLLATION OFFICER",
        "LOGISTICS SUPPORT OFFICER",
        "CSRVS (RA)",
        "CSRVS (LGA)",
        "CSRVS(CONSTITUENCY)",
        "CSRVS (SENATORIAL)",
        "CSRVS (STATE)",
      ],
      /* eslint-disable */
      responseTeam: [
        "Select Role",
        "RESIDENT ELECTORAL COMMISSIONER.(REC)",
        "HEADs OF DEPARTMENTs (HODs)",
        "ADMINISTRATIVE SECRETARY (AS)",
        "ELECTORAL OFFICER (EO)",
        "ICCES (SECURITY EXPERTs)",
        "DESK OFFICERs (DOs)",
        "DIRECTOR",
        "ASSISTANT ELECTORAL OFFICER (AEO)",
        "RA SUPERVISOR",
        "LGA SUPERVISOR",
        "STATE SUPERVISOR",
        "CONSTITUENCY SUPERVISOR",
        "SPOS",
        "RA TECH SUPPORT",
        "LGA TECH SUPPORT",
        "CONSTITUENCY TECH SUPPORT",
        "MONITOR",
        "ASSISTANT PRESIDING OFFICER",
        "PRESIDING OFFICER",
        "RA COLLATION OFFICER",
        "LGA COLLATION OFFICER",
        "STATE COLLATION OFFICER",
        "CONSTITUENCY COLLATION OFFICER",
        "LOGISTICS SUPPORT OFFICER",
        "CSRVS (RA)",
        "CSRVS (LGA)",
        "CSRVS(CONSTITUENCY)",
        "CSRVS (SENATORIAL)",
        "CSRVS (STATE)",
      ],
      notifiyingUser: [
        "Select Role",
        "RA SUPERVISOR",
        "LGA SUPERVISOR",
        "STATE SUPERVISOR",
        "CONSTITUENCY SUPERVISOR",
        "SPOS",
        "RA TECH SUPPORT",
        "LGA TECH SUPPORT",
        "CONSTITUENCY TECH SUPPORT",
        "MONITOR",
        "ASSISTANT PRESIDING OFFICER",
        "PRESIDING OFFICER",
        "RA COLLATION OFFICER",
        "LGA COLLATION OFFICER",
        "STATE COLLATION OFFICER",
        "CONSTITUENCY COLLATION OFFICER",
        "LOGISTICS SUPPORT OFFICER",
        "CSRVS (RA)",
        "CSRVS (LGA)",
        "CSRVS(CONSTITUENCY)",
        "CSRVS (SENATORIAL)",
        "CSRVS (STATE)",
      ],
      searchs: "",
      page: 1,
      myCallback:
        "http://inecsans-env.eba-ermh8jnu.eu-west-1.elasticbeanstalk.com/v1/admin/get_all_users",
      lastname: "",
      phonenumber: "",
      lga: "",
      ra: "",
      alerts: [],
      mystateid: [],
      selectedmelg: [],
      myselectedstate: [],
      raname: "",
      hideshowme: false,
      hideshowmelg: false,
      setselectedtab: "User",

      hideshowmera: false,
      setOrder: "ASC",
      selectedstates: [],
      alerttype: "",
      eachpage: 20,
      per_page: 12,
      myrole: "",
      zoom: 9,
      letmysate: [],
      pollingUnit: [],
      editusershow: false,
      altypes: [],
      adminrole: "",
      search: "",
      adminstate: "",
      selectedidforstate: [],
      loading: true,
      fname: "",
      mylga: [],
      lname: "",
      isDisableduser: false,
      isDisabledadmin: false,
      pu: [],
      uname: "",
      eemail: "",
      epassword: "",
      rrole: "",
      check2: [],
      item: "",
      phone: "",
      check: [],
      totalpage: 0,
      perPage: 0,
      choosenRole: "Select Role",
      lgs: [],
      rs: [],
      searchmystate: "",
      myra: [],
      choosenstate: "Select State",
      choosenLga: "Select LGA",
      staticAnchor: [16, 37],
      selectedlgs: [],
      customText: "Foobar",

      iconSize: 64,
      selected: "User",
      states: [],
      userrole: "",
      myscondpush: [],

      options: {
        legend: {
          display: false,
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem) {
              return tooltipItem.yLabel;
            },
          },
        },
      },
    };
  },
  methods: {
    async resetAll() {
      this.choosenLga = "Select Lga";
      this.choosenstate = "Select State";
      this.choosenRole = "Select Role";
      try {
        let response = await axios.get(
          `v1/admin/get_all_users?isPaginate=true&limit=${this.eachpage}${
            this.adminrole == "admin" ? `&state=${this.adminstate}` : ""
          }&isAdmin=false`
        );
        this.allUser = response.data.result.data.data;
        this.totalpage = response.data.result.data.total;
        this.perpage = response.data.result.data.perPage;
        this.hasNextpage = response.data.result.data.hasNextPage;
        this.hasPrevpage = response.data.result.data.hasPrevPage;
      } catch (e) {
        console.log(e);
      }
      try {
        let response = await axios.get(
          `v1/admin/get_all_users?isPaginate=true&limit=${this.eachpage}${
            this.adminrole == "admin" ? `&state=${this.adminstate}` : ""
          }&isAdmin=true`
        );
        this.allUserAdmin = response.data.result.data.data;
        this.totalpageadmin = response.data.result.data.total;
        this.perpageadmin = response.data.result.data.perPage;
        this.hasNextpageadmin = response.data.result.data.hasNextPage;
        this.hasPrevpageadmin = response.data.result.data.hasPrevPage;
      } catch (e) {
        console.log(e);
      }
    },
    async getlgas(state, name, select) {
      if (this.selected == "User") {
        this.choosenstate = name;
        this.selectedstates = this.lga[state];
        try {
          let response = await axios.get(
            `v1/admin/get_all_users?isPaginate=true&limit=${this.eachpage}${
              this.adminrole == "admin" ? `&state=${this.adminstate}` : ""
            }&isAdmin=false&state=${this.choosenstate}`
          );
          this.allUser = response.data.result.data.data;
          this.totalpage = response.data.result.data.total;
          this.perpage = response.data.result.data.perPage;
          this.hasNextpage = response.data.result.data.hasNextPage;
          this.hasPrevpage = response.data.result.data.hasPrevPage;
        } catch (e) {
          console.log(e);
        }
      } else {
        this.choosenstate = name;
        this.selectedstates = this.lga[state];
        try {
          let response = await axios.get(
            `v1/admin/get_all_users?isPaginate=true&limit=${this.eachpage}${
              this.adminrole == "admin" ? `&state=${this.adminstate}` : ""
            }&isAdmin=true&state=${this.choosenstate}`
          );
          this.allUserAdmin = response.data.result.data.data;
          this.totalpageadmin = response.data.result.data.total;
          this.perpageadmin = response.data.result.data.perPage;
          this.hasNextpageadmin = response.data.result.data.hasNextPage;
          this.hasPrevpageadmin = response.data.result.data.hasPrevPage;
        } catch (e) {
          console.log(e);
        }
      }
    },
    async lgaget(name, select) {
      if (this.selected == "User") {
        this.choosenLga = name;
        try {
          let response = await axios.get(
            `v1/admin/get_all_users?isPaginate=true&limit=${this.eachpage}${
              this.adminrole == "admin" ? `&state=${this.adminstate}` : ""
            }&isAdmin=false&lga=${this.choosenLga}&state=${this.choosenstate}`
          );
          this.allUser = response.data.result.data.data;
          this.totalpage = response.data.result.data.total;
          this.perpage = response.data.result.data.perPage;
          this.hasNextpage = response.data.result.data.hasNextPage;
          this.hasPrevpage = response.data.result.data.hasPrevPage;
        } catch (e) {
          console.log(e);
        }
      } else {
        this.choosenLga = name;
        try {
          let response = await axios.get(
            `v1/admin/get_all_users?isPaginate=true&limit=${this.eachpage}${
              this.adminrole == "admin" ? `&state=${this.adminstate}` : ""
            }&isAdmin=true&state=${this.choosenstate}&lga=${name}`
          );
          this.allUserAdmin = response.data.result.data.data;
          this.totalpageadmin = response.data.result.data.total;
          this.perpageadmin = response.data.result.data.perPage;
          this.hasNextpageadmin = response.data.result.data.hasNextPage;
          this.hasPrevpageadmin = response.data.result.data.hasPrevPage;
        } catch (e) {
          console.log(e);
        }
      }
    },
    async findRole(name) {
      if (this.selected == "User") {
        this.choosenRole = name;
        try {
          let response = await axios.get(
            `v1/admin/get_all_users?isPaginate=true&limit=${this.eachpage}${
              this.adminrole == "admin" ? `&state=${this.adminstate}` : ""
            }&isAdmin=false&role=${name}`
          );
          this.allUser = response.data.result.data.data;
          this.totalpage = response.data.result.data.total;
          this.perpage = response.data.result.data.perPage;
          this.hasNextpage = response.data.result.data.hasNextPage;
          this.hasPrevpage = response.data.result.data.hasPrevPage;
        } catch (e) {
          console.log(e);
        }
      }
    },
    sorting(col) {
      if (this.setOrder === "ASC") {
        const sorted = [...this.filterActive].sort((a, b) =>
          a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
        );
        this.allUser = sorted;
        this.setOrder = "DSC";
      }
      if (this.setOrder === "DSC") {
        const sorted = [...this.filterActive].sort((a, b) =>
          a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
        );
        this.allUser = sorted;
        this.setOrder = "DSC";
      }
    },
    DeleteAllUser() {
      this.$swal({
        title:
          "<span style='font-size:12px !important'>Are you sure you want to all users?</span>",
        confirmButtonColor: "#238f53",
        width: "300px",
        confirmButton: true,
        imageHeight: "30",
        imageWidth: "30",
        icon: "warning",
        fontSize: "12px",
        buttons: true,
        padding: "5px !important",
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete.isConfirmed) {
          try {
            const response = await axios.delete(`v1/admin/delete_all_users`);
            if (response.status == 200) {
              this.$swal({
                text: response.data.result.message,
                icon: "success",
                width: "300px",

                confirmButtonColor: "#238f53",
              }).then(() => {
                this.$router.go();
              });
            }
          } catch (e) {
            console.log(e);
          }
        }
      });
    },
    selectAll3(event) {
      this.check2 = document.getElementsByClassName("checkra");
      var arr3 = Array.from(this.check2);
      var myra = [];
      if (event.target.checked) {
        arr3.forEach(function (checkbox) {
          checkbox.checked = true;
          myra.push(checkbox.value);
        });
        this.myra = myra;
        /*
        for (let mycheck2 = 0; mycheck2 < this.check2.length; mycheck2++) {
          this.check2[mycheck2].checked = true;

          this.myra.push(this.check2[mycheck2].value);
        }
        */
      } else {
        arr3.forEach(function (checkbox) {
          checkbox.checked = false;
        });
        this.myra = [];
      }
    },
    selectAll2(event) {
      this.check3 = document.getElementsByClassName("lgacheck");
      var arr2 = Array.from(this.check3);

      if (event.target.checked) {
        arr2.forEach(function (checkboxes) {
          checkboxes.checked = true;
        });

        for (const [key, value] of Object.entries(this.lgs)) {
          this.eee = key;
          this.sss.push(...value);
        }

        for (var j = 0; j < this.sss.length; j++) {
          this.selectedlgs.push(...this.sss[j].ras);
          this.selectedmelg.push(this.sss[j].name);
        }
      } else {
        arr2.forEach(function (checkboxes) {
          checkboxes.checked = false;
        });
        this.selectedmelg = [];
        this.selectedlgs = [];
        this.sss = [];
      }
    },
    selectAll(event, states) {
      // var mystateid = [];
      this.check = document.getElementsByClassName("statecheck");
      const arr = Array.from(this.check);
      if (event.target.checked) {
        arr.forEach(function (checkbox) {
          checkbox.checked = true;
        });

        for (var state = 0; state < states.length; state++) {
          this.myselectedstate.push(states[state].name);
          this.mystateid.push(states[state].id);
        }

        var mychoosenstate = [];

        for (const [key, value] of Object.entries(this.mystateid)) {
          this.eee = key;
          mychoosenstate.push(this.lgs[value]);
        }
        for (const [key, value] of Object.entries(mychoosenstate)) {
          this.eee = key;
          this.selectedstates.push(...value);
        }
      } else {
        arr.forEach(function (checkbox) {
          checkbox.checked = false;
        });
        this.myselectedstate = [];
        this.selectedstates = [];
        this.mystateid = [];
      }
    },
    getra(name) {
      this.myselectedra.push(name);
      this.myselectedra.slice(
        this.myselectedra.findIndex((item) => item.field === name)
      );
    },
    showlistme() {
      this.hideshowme = !this.hideshowme;
    },
    showlistmelg() {
      this.hideshowmelg = !this.hideshowmelg;
    },
    showlistmeraopen() {
      this.hideshowmera = !this.hideshowmera;
    },
    async showlistmera() {
      this.hideshowmera = !this.hideshowmera;
      await axios
        .get(
          `v1/get_polling_unit?STATE=${this.myselectedstate[0]}&LGA=${this.selectedmelg[0]}&REGISTRATION AREA=${this.myra[0]}`
        )
        .then((res) => {
          this.pollingUnit = res.data.result.data;
          //console.log(res);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    addNewAdmin() {
      this.isDisabledadmin = true;
      const data = {
        email: this.email,
        password: this.password,
        state: this.myrole == "admin" ? [this.state.name] : [],
        userRole: this.myrole,

        firstName: this.firstname,
        lastName: this.lastname,
        phoneNumber:
          this.phonenumber.slice(0, 1) == "0"
            ? "234" + this.phonenumber.slice(1)
            : this.phonenumber,
      };
      // console.log(data);
      this.signAdmin(data)
        .then(() => {
          this.$swal({
            title:
              "<span style='font-size:13px !important;color:green'>Success</span>",
            text: "New Admin created succesfully",
            width: "300px",
            confirmButtonColor: "#238f53",
          }).then((res) => {
            if (res.isConfirmed) {
              this.$router.go();
            }
          });
        })
        .catch((e) => {
          console.log(e);
          if (e.response.status == 400) {
            this.disabled = false;
            this.$swal({
              title:
                "<span style='font-size:13px !important;color:crimson'>Failed</span>",
              text: e.response.data.errors[0].message,
              width: "300px",
              confirmButtonColor: "#238f53",
            }).then((this.isDisabledadmin = false));
          } else {
            this.disabled = false;
            this.$swal({
              title:
                "<span style='font-size:13px !important;color:crimson'>Failed</span>",
              text: e.response.data.errors[0].message,
              width: "300px",
              confirmButtonColor: "#238f53",
            }).then((this.isDisabledadmin = false));
          }

          {
            this.disabled = false;
            this.error = true;
            this.errMessage = "Invalid Crendentials";
            setTimeout(() => {
              (this.error = false), (this.disabled = false);
            }, 2000);
          }
        });
    },

    seteachpage(id) {
      this.eachpage = id;
    },
    async previousPage() {
      if (this.page >= 1) {
        this.page--;

        try {
          let response = await axios.get(
            `v1/admin/get_all_users?isPaginate=true&limit=${this.eachpage}&page=${this.page}`
          );
          this.allUser = response.data.result.data.data;
          this.totalpage = response.data.result.data.total;
          this.perpage = response.data.result.data.perPage;
        } catch (e) {
          console.log(e);
        }
      }
    },
    async nextPage() {
      if (this.page <= this.totalpage / 20) {
        this.page++;

        try {
          let response = await axios.get(
            `v1/admin/get_all_users?isPaginate=true&limit=${this.eachpage}&page=${this.page}`
          );
          this.allUser = response.data.result.data.data;
          this.totalpage = response.data.result.data.total;
          this.perpage = response.data.result.data.perPage;
        } catch (e) {
          console.log(e);
        }
      }
    },
    async previousPageAdmin() {
      if (this.page >= 1) {
        this.page--;

        try {
          let response = await axios.get(
            `v1/admin/get_all_users?isPaginate=true&limit=${this.eachpage}&page=${this.page}&isAdmin=true`
          );
          this.allUserAdmin = response.data.result.data.data;
          this.totalpageadmin = response.data.result.data.total;
          this.perpageadmin = response.data.result.data.perPage;
        } catch (e) {
          console.log(e);
        }
      }
    },
    async nextPageAdmin() {
      if (this.page <= this.totalpage / 20 && this.hasNextpageadmin == true) {
        this.page++;

        try {
          let response = await axios.get(
            `v1/admin/get_all_users?isPaginate=true&limit=${this.eachpage}&page=${this.page}&isAdmin=true`
          );
          this.allUserAdmin = response.data.result.data.data;
          this.totalpageadmin = response.data.result.data.total;
          this.perpageadmin = response.data.result.data.perPage;
        } catch (e) {
          console.log(e);
        }
      }
    },
    async searchUser() {
      try {
        let response = await axios.get(
          `v1/admin/get_all_users?isPaginate=true&search=${this.searchs}`
        );
        this.allUser = response.data.result.data.data;
      } catch (e) {
        console.log(e);
      }
    },

    async editUser() {
      const data = {
        firstName: this.fname,
        lastName: this.lname,
        email: this.eemail,
        password: this.epassword ?? null,
        phoneNumber:
          this.phone.slice(0, 1) == "0"
            ? "234" + this.phone.slice(1)
            : this.phone,
        role: this.rrole,

        fcmToken: "iamadmin",
      };
      // console.log(data);
      try {
        const response = await axios.put(
          `v1/admin/update_user/${this.selectedUser._id}`,
          data
        );
        this.$swal({
          title:
            "<span style='font-size:13px !important;color:green'>Success</span>",
          text: response.data.result.data.message,
          width: "300px",
          confirmButtonColor: "#238f53",
        }).then((res) => {
          if (res.isConfirmed) {
            this.$router.go();
          }
        });
      } catch (e) {
        if (e.response.status == 500) {
          this.$swal({
            title:
              "<span style='font-size:13px !important;color:crimson'>Failed</span>",
            text: "An error occured",
            width: "300px",
            confirmButtonColor: "#238f53",
          });
        } else {
          this.$swal({
            title:
              "<span style='font-size:13px !important;color:crimson'>Failed</span>",
            text: "Failed to update user",
            width: "300px",
            confirmButtonColor: "#238f53",
          });
        }
      }
    },
    deleteUser(item) {
      this.showmodaldetail = false;
      this.$swal({
        title:
          "<span style='font-size:12px !important'>Are you sure you want to delete?</span>",
        confirmButtonColor: "#238f53",
        width: "300px",
        confirmButton: true,
        imageHeight: "30",
        imageWidth: "30",
        icon: "warning",
        fontSize: "12px",
        buttons: true,
        padding: "5px !important",
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete.isConfirmed) {
          try {
            const response = await axios.delete(
              `v1/admin/delete_user/${item._id}`
            );
            if (response.status == 200) {
              this.$swal({
                text: response.data.result.message,
                icon: "success",
                width: "300px",

                confirmButtonColor: "#238f53",
              }).then((res) => {
                if (res.isConfirmed) {
                  this.$router.go();
                }
              });
            }
          } catch (e) {
            console.log(e);
            this.showmodaldetail = true;
          }
        } else {
          this.showmodaldetail = true;
        }
      });
    },
    editusermodal(value) {
      this.showmodaldetail = false;
    },
    eachgetUser(item) {
      //  console.log(item);

      this.selectedUser = item;
      this.fname = item.firstName;
      this.lname = item.lastName;
      this.eemail = item.email;
      this.rrole = item.role;
      this.phone = item.phoneNumber;
    },
    getras(name, event) {
      if (event.target.checked) {
        this.selectedmelg.push(name);
        // alert(id);
        //var selectedlgas = [];

        for (const [key, value] of Object.entries(this.mylga)) {
          this.eee = key;
          this.sss = value.ras;
          //  this.raname = value;
          //console.log(selectedlgas);
        }

        for (var j = 0; j < this.sss.length; j++) {
          this.selectedlgs.push(this.sss[j]);
        }
      } else {
        this.selectedlgs = [];
        for (const [key, value] of Object.entries(this.mylga)) {
          this.ee = key;

          this.ppp = value.ras;
          //  this.raname = value;
          //console.log(selectedlgas);
        }

        for (var k = 0; k < this.ppp.length; k++) {
          this.selectedlgs.push(this.ppp[k]);
        }
        for (var p = this.selectedmelg.length - 1; p >= 0; --p) {
          if (this.selectedmelg[p] == name) {
            this.selectedmelg.splice(p, 1);
          }
        }
      }
    },
    getlga(name, event) {
      if (event.target.checked) {
        this.myselectedstate.push(name);
        var mychoosenstate = [];

        for (const [key, value] of Object.entries(this.mystateid)) {
          console.log(key, value);
          mychoosenstate = this.lgs[value];
        }

        for (var i = 0; i < mychoosenstate.length; i++) {
          this.selectedstates.push(mychoosenstate[i]);
        }
      } else {
        var thisstate = [];
        this.selectedstates = [];

        for (const [key, value] of Object.entries(this.mystateid)) {
          console.log(key, value);
          thisstate = this.lgs[value];
        }

        for (var j = 0; j < thisstate.length; j++) {
          this.selectedstates.push(thisstate[j]);
        }

        for (var p = this.myselectedstate.length - 1; p >= 0; --p) {
          if (this.myselectedstate[p] == name) {
            this.myselectedstate.splice(p, 1);
          }
        }
      }
    },
    ...mapActions({
      signUp: "auth/signUp",
      signAdmin: "auth/signAdmin",
    }),

    addNewUser() {
      this.isDisableduser = true;
      const data = {
        email: this.email,

        password: this.password,
        state: this.myselectedstate,
        username: this.username,
        role: this.role,
        firstName: this.firstname,
        lastName: this.lastname,
        phoneNumber:
          this.phonenumber.slice(0, 1) == "0"
            ? "234" + this.phonenumber.slice(1)
            : this.phonenumber,
        lga: this.selectedmelg,
        ra: this.myra,
        pollingUnit: [this.pu],
        alertType: this.alerttype,
        fcmToken: "iamnecadmin",
        userRole: this.userrole,
      };

      this.signUp(data)
        .then(() => {
          this.$swal({
            title:
              "<span style='font-size:13px !important;color:green'>Success</span>",
            text: "New User Added succesfully",
            width: "300px",
            confirmButtonColor: "#238f53",
          }).then((res) => {
            if (res.isConfirmed) {
              this.$router.go();
            }
          });
        })
        .catch((e) => {
          console.log(e);
          if (e.response.status == 400) {
            this.disabled = false;
            this.$swal({
              title:
                "<span style='font-size:13px !important;color:crimson'>Failed</span>",
              text: e.response.data.errors[0].message,
              width: "300px",
              confirmButtonColor: "#238f53",
            }).then((this.isDisableduser = false));
          } else {
            this.disabled = false;
            this.$swal({
              title:
                "<span style='font-size:13px !important;color:crimson'>Failed</span>",
              text: e.response.data.errors[0].message,
              width: "300px",
              confirmButtonColor: "#238f53",
            }).then((this.isDisableduser = false));
          }

          {
            this.disabled = false;
            this.error = true;
            this.errMessage = "Invalid Crendentials";
            setTimeout(() => {
              (this.error = false), (this.disabled = false);
            }, 2000);
          }
        });
    },
    showModalInfo() {
      this.showModal = true;
    },
    hideModalInfo() {
      this.showModal = false;
      // console.log(this.showModal);
    },
    selectedstate(stt) {
      this.state = stt;
    },
    setSelected(tab) {
      this.selected = tab;
      this.choosenstate = "Select State";
      this.choosenLga = "Select Lga";
      this.choosenRole = "Select Role";
      this.resetAll();
    },
    setSelected2(tab) {
      this.selecteds = tab;
    },

    toggleSwitch() {
      this.isChecked = !this.isChecked;
    },
  },
  /*
  mounted() {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        this.lat = position.coords.latitude;
        this.lag = position.coords.longitude;
      },
      (error) => {
        console.log(error.message);
      }
    );
  },
  */
  computed: {
    filterStates: function () {
      return this.allUser.filter((item) => {
        return item.firstname.match(this.search);
      });
    },
    filterStatess: function () {
      return this.states.filter((item) => {
        return item.name.match(this.search.toUpperCase());
      });
    },
    filterUserState: function () {
      return this.states.filter((item) => {
        return item.name.match(this.searchmystate.toUpperCase());
      });
    },
    filterUserLgs: function () {
      return this.selectedstates.filter((item) => {
        return item.name.match(this.searchmylgs.toUpperCase());
      });
    },
    filterUserRAS: function () {
      return this.selectedlgs.filter((item) => {
        return item.name.match(this.searchmyras.toUpperCase());
      });
    },

    filterActive: function () {
      return this.allUser.filter((item) => {
        return item.status.match("active");
      });
    },
    filterRevoke: function () {
      return this.allUser.filter((item) => {
        return item.status.match("delected");
      });
    },
    filterPending: function () {
      return this.allUserAdmin.filter((item) => {
        return item.status.match("");
      });
    },
  },
  async created() {
    try {
      let response = await axios.get("v1/get_states");
      this.lga = response.data.result.data.lgas;
      this.states = response.data.result.data.state;
    } catch (e) {
      console.log(e);
    }
    try {
      let response = await axios.get("v1/user/get_user_profile");
      this.adminrole = response.data.result.data.userRole;
      this.adminstate = response.data.result.data.state;

      //this.notification = response.data.result.data.sos;
      this.alerts = response.data.result.data;
    } catch (e) {
      console.log(e);
    }
    try {
      let response = await axios.get("v1/get_states");
      this.lgs = response.data.result.data.lgas;
      // this.rs =
      this.states = response.data.result.data.state;
      for (const [key, value] of Object.entries(this.lgs)) {
        this.eee = key;

        this.alllga.push(...value);
      }
    } catch (e) {
      console.log(e);
    }
    try {
      let response = await axios.get(
        `v1/admin/get_all_users?isPaginate=true&limit=${this.eachpage}${
          this.adminrole == "admin" ? `&state=${this.adminstate}` : ""
        }&isAdmin=false`
      );
      this.allUser = response.data.result.data.data;
      this.totalpage = response.data.result.data.total;
      this.perpage = response.data.result.data.perPage;
      this.hasNextpage = response.data.result.data.hasNextPage;
      this.hasPrevpage = response.data.result.data.hasPrevPage;
    } catch (e) {
      console.log(e);
    }
    try {
      let response = await axios.get(
        `v1/admin/get_all_users?isPaginate=true&limit=${this.eachpage}${
          this.adminrole == "admin" ? `&state=${this.adminstate}` : ""
        }&isAdmin=true`
      );
      this.allUserAdmin = response.data.result.data.data;
      this.totalpageadmin = response.data.result.data.total;
      this.perpageadmin = response.data.result.data.perPage;
      this.hasNextpageadmin = response.data.result.data.hasNextPage;
      this.hasPrevpageadmin = response.data.result.data.hasPrevPage;
    } catch (e) {
      console.log(e);
    }
    try {
      let response = await axios.get(
        `v1/notification/get_all_alert_type${
          this.adminrole == "admin" ? `?state=${this.adminstate}` : ""
        }`
      );
      this.altypes = response.data.result.data.types;

      this.loading = false;
    } catch (e) {
      console.log(e);
    }
  },
};
</script>
<style scoped>
.bg-home-main {
  background: #f5f6f7;

  overflow: hidden;
  position: relative;
}
.form-control {
  font-size: 12px !important;
}
.btn1 {
  border: 2px solid #238f53;
  color: #238f53;
  padding: 4px 7px 4px 7px !important;
  font-weight: 500;
  outline: none !important;
  font-size: 12px;
  margin-right: 5px;
}
.btn2 {
  border: 2px solid #238f53;
  color: #fff;
  font-size: 12px;
  background: #238f53;
  padding: 4px 7px 4px 7px !important;
  font-weight: 500;
  outline: none !important;
  margin-left: 5px;
}
.conbg {
  border-radius: 12px;
}
.exptime {
  font-size: 12px;
}
.container {
  width: 32%;
  margin: 5px 2.5px 5px 2.5px;
  padding: 12px;
  border-radius: 6px;
  text-align: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #455c7d;
}
.center {
  height: 100vh;
  align-items: center;
  display: flex;
  width: 100vw;
  place-items: center;
  justify-content: center;
}
.container h5 {
  font-size: 14px;
  align-content: center;
  display: flex;
  padding: 0px !important;
  margin: 0px !important;
}
.container span {
  font-size: 12px;
}
@keyframes slide-in {
  from {
    transform: translateX(-100%);
    transition: 0.3s ease-out;
  }
  to {
    transform: translateX(0%);
    transition: 0.3s ease-out;
  }
}
.slide1 {
  animation: slide-in 1000ms;
  animation-timing-function: linear;
}
.slide2 {
  animation: slide-in 2000ms;
  animation-timing-function: linear;
}
.slide3 {
  animation: slide-in 3000ms;
  animation-timing-function: linear;
}
.reset {
  font-size: 13px;
  font-weight: 600;
  color: #238f53;
  float: right;
}
.filter {
  font-size: 13px;
  font-weight: 600;
  color: #238f53;

  align-items: center;
  display: flex;
  margin-left: -20px;
}
.active {
  background-color: #238f53 !important;
}
.dropdown-toggle {
  background: #fff !important;

  font-size: 12px !important;
  width: 90%;
  padding-top: 5px !important ;
  padding-bottom: 5px !important;
  justify-content: space-between !important;
  display: flex !important;
  align-items: center;
  color: #455c7d;
  border-radius: 3px !important;
  border: 1px solid #c9d9e5 !important;
}
.dropdown {
  width: 100%;

  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.conflex1 {
  width: 80%;

  padding: 5px 0px 5px 0px;

  display: flex;
}
.conflex2 {
  width: 20%;

  padding: 5px 0px 5px 0px;
}
th {
  font-size: 12px !important;
  border: none !important;
  background: #f5f6f7;
  color: #090d14;
}
td {
  border: none !important;
  font-size: 12px !important;
}
tbody tr:nth-child(even) {
  background: #f5f6f7;
  border-radius: 30px !important;
}
tr {
  border-radius: 30px !important;
  color: #5c7597;
}
.status {
  color: #9d1f1f;
  background: #f2d5d5;
  border-radius: 20px;
  padding-top: 1px !important ;
  padding-bottom: 1px !important ;
  padding-right: 5px !important;
  padding-left: 5px !important;
  display: inline-block;
  align-items: center;
  font-size: 12px !important;
}
.status2 {
  color: #fff;
  background: #238f53;
  border-radius: 20px;
  padding-top: 1px !important ;
  padding-bottom: 1px !important ;
  padding-right: 5px !important;
  padding-left: 5px !important;
  display: inline-block;
  align-items: center;
  font-size: 12px !important;
}

.modal-footer {
  border-top: none !important;
}
.mdil li {
  display: flex !important;
  justify-content: space-between !important;
  padding: 3px !important;
  line-height: 35px;
  border: none !important;
  text-align: left !important;
}
.mdil li h6 {
  font-size: 12px;
  color: #7993b1;
}
.mdil li span {
  font-size: 12px;
}
.dtaedropdown {
  border-radius: 12px !important;
  padding: 10px;
  width: 400px;
}
.btn1 {
  border: 2px solid #238f53;
  color: #238f53;
  padding: 4px 7px 4px 7px !important;
  font-weight: 500;
  outline: none !important;
  font-size: 12px;
  margin-right: 5px;
}
.btn2 {
  border: 2px solid #238f53;
  color: #fff;
  font-size: 12px;
  background: #238f53;
  padding: 4px 7px 4px 7px !important;
  font-weight: 500;
  outline: none !important;
  margin-left: 5px;
}
.sideiconbuttob {
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  margin: 2px;
  padding: 0px !important;
}

.sideiconbuttob .material-icons {
  font-size: 9px !important;
  margin: 0px auto !important;
  display: inline-flex;
}
.butn1 {
  border: 1px solid #4cc27e !important;
  color: #4cc27e !important;
}
.butn2 {
  border: 1px solid #f5b0b0 !important;
  color: #f5b0b0 !important;
  background: #f2d5d5 !important;
}
.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}
.swal {
  max-width: 200px;
  width: 100%;
}

.multiselect {
  width: 200px;
}
.overSelect {
  position: absolute;
  left: 0;
  right: 0px;
  top: 0;
  bottom: 0;
}
.selectBox {
  position: relative;
}
.selectBox select {
  width: 100%;
  font-weight: normal;
}
#checkboxes {
  display: none;
}
#checkboxes label {
  display: block;
}

.classlist {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.classlist input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 7px;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.classlist:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.classlist input:checked ~ .checkmark {
  background-color: rgb(35, 143, 83);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.classlist input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.classlist .checkmark:after {
  left: 5px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
select:focus,
.uneditable-input:focus {
  border-color: rgb(35, 143, 83);
  box-shadow: 0 0 8px rgb(35, 143, 83);
  outline: 0 none;
}
.centerss {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
th {
  cursor: pointer;
}
.sort_icon-con {
  display: flex;
  flex-direction: column;
  margin-top: -20px;
}
.up-arrwo {
  width: 6px;
  height: 6px;
}
.down-arrow {
  width: 6px;
  height: 6px;
}
.speb {
  display: flex;
  align-items: center;
}
.spanstate::after {
  content: " ";
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
  margin-top: 10px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #238f53;
}

input:focus + .slider {
  box-shadow: 0 0 1px #238f53;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
