<template>
  <div class="w-100 app">
    <!-- Button trigger modal -->

    <!-- Modal -->
    <div
      @click="hideModalInfo"
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div
        class="modal-dialog"
        role="document"
        style="max-width: calc(100% - 200px); width:100%;!important; border-radius: 30px; margin:40px auto"
      >
        <div
          class="modal-content"
          style="
            padding: 10px !important;
            border-radius: 10px;
            max-width: 500px;
            margin: 0px auto;
          "
        >
          <div class="modal-header" style="border-bottom: none !important">
            <h5
              class="modal-title"
              id="exampleModalLabel"
              style="
                font-size: 12px;
                color: #334563;
                margin: 0px !important ;
                padding: 0px !important;
              "
            >
              Incident Details
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" @click="hideModalInfo">&times;</span>
            </button>
          </div>
          <div
            class="modal-body"
            style="
              padding: 5px !important;
              border-top: none !important;
              border-bottom: none !important;
            "
          >
            <div class="d-flex justify-content-between mb-3">
              <div
                style="
                  background: #f2d5d5;
                  border-radius: 5px;
                  align-items: center;
                  display: flex;
                "
                :style="
                  selectedAlert.status == 'pending'
                    ? ' background: #f2d5d5 !important'
                    : ' background: #238f53 !important;color:#fff !important'
                "
                class="pr-2 pl-2 pt-2 pb-2"
                :class="selectedAlert.status == 'resolved' ? 'white' : null"
              >
                <span
                  style="
                    color: #9d1f1f;
                    font-size: 12px;
                    text-transform: uppercase;
                  "
                  >{{ selectedAlert.status }}</span
                >
              </div>
              <div
                v-show="
                  user.userRole == 'super_admin' ||
                  user.userRole == 'global_admin'
                "
                style="
                  background: #238f53;
                  border-radius: 5px;
                  align-items: center;
                  display: flex;
                  cursor: pointer;
                "
                class="pr-2 pl-2"
                v-if="selectedAlert.status == 'pending'"
                @click="resolvedalert"
              >
                <span style="color: #ffff; font-size: 12px"
                  >Mark as resolved</span
                >
              </div>
            </div>

            <div class="w-100" style="height: 180px">
              <Map :centers="current" :current="center" />

              <!--
              <l-map
                :zoom="zoom"
                :max-zoom="19"
                :center="{ lat: lat, lng: lag }"
                :bound="zoom"
                style="width: 100%"
              >
                <l-tile-layer :url="url" :attribution="attribution" />

                <l-tile-layer :url="url" :attribution="attribution" />

                <l-marker
                  :lat-lng="[
                    slectedcoordslat == 0 ? 0 : slectedcoordslat,
                    slectedcoordslag == 0 ? 0 : slectedcoordslag,
                  ]"
                  v-model="zoom"
                >
                  <l-icon :icon-anchor="staticAnchor" class-name="someExtraClass">
                    <div class="glow" v-if="selectedlevel == 'Critical Level'">
                      <div
                        style="
                          width: 10px;
                          height: 10px;
                          background: #9d1f1f;
                          border-radius: 100%;
                        "
                      ></div>
                    </div>
                    <div
                      class="hl"
                      v-if="selectedlevel == 'High Level'"
                      style="border: 1px solid #ffcc99"
                    >
                      <div
                        style="
                          width: 10px;
                          height: 10px;
                          background: #ffcc99;
                          border-radius: 100%;
                        "
                      ></div>
                    </div>
                    <div
                      class="ml"
                      v-if="selectedlevel == 'Medium Level'"
                      style="border: 1px solid #ffcc99"
                    >
                      <div
                        style="
                          width: 10px;
                          height: 10px;
                          background: yellow;
                          border-radius: 100%;
                        "
                      ></div>
                    </div>
                    <div
                      class="ll"
                      v-if="selectedlevel == 'Low Level' || selectedlevel == 'low'"
                      style="border: 1px solid #ffcc99"
                    >
                      <div
                        style="
                          width: 10px;
                          height: 10px;
                          background: yellow;
                          border-radius: 100%;
                        "
                      ></div>
                    </div>
                  </l-icon>
                </l-marker>
              </l-map>
              -->
            </div>
            <a
              :href="`https://www.google.com/maps/search/?api=1&query=Google&query_place_id=${selectedAlert?.placeId}`"
              type="button"
              target="_blank"
              class="btn btn-primary mt-2"
            >
              View Route
            </a>
            <div class="d-flex justify-content-between mt-3">
              <h5 style="color: #161f2e; font-size: 12px">
                {{ selectedAlert.name }} ({{ selectedlevel }})
              </h5>
              <span style="font-size: 12px; color: #e2b93b">{{
                selectedAlert.status
              }}</span>
            </div>
            <ul class="list-group mdil">
              <li
                class="list-group-item d-flex justify-content"
                style="align-items: center"
                v-for="n in selectedAlert.user"
                :key="n"
                :title="`STATE: ${n.state};LGA: ${n.lga}; RA: ${n.ra}`"
              >
                <h6>Sender</h6>
                <span>{{ n.firstName }}{{ n.lastName }}</span>
              </li>
              <li
                class="list-group-item d-flex justify-content"
                style="align-items: center"
              >
                <h6>Date/Time</h6>
                <span>{{
                  moment(selectedAlert.createdAt).format("DD-MM-YYYY h:s:a")
                }}</span>
              </li>
              <li
                class="list-group-item d-flex justify-content"
                style="align-items: center"
              >
                <h6>Polling Unit</h6>
                <span>{{ selectedAlert.pollingUnit }}</span>
              </li>
              <li
                class="list-group-item d-flex justify-content"
                style="align-items: center"
              >
                <h6>Address</h6>
                <span>{{ selectedAlert.address }}</span>
              </li>
              <li
                class="list-group-item d-flex justify-content"
                style="align-items: center"
              >
                <h6>Place Id</h6>
                <span>{{ selectedAlert?.placeId }}</span>
              </li>
              <li
                class="list-group-item d-flex justify-content"
                style="align-items: center"
              >
                <h6>ID</h6>
                <span>{{ selectedAlert._id }}</span>
              </li>
              <li
                class="list-group-item d-flex justify-content"
                style="align-items: center"
              >
                <h6>Description</h6>
                <span>{{ selectedAlert.description }}</span>
              </li>
            </ul>

            <div class="w-100" style="background: #f5f6f7">
              <div
                style="margin: 10px; width: 100%; background: #f5f6f7"
                class="col"
                v-for="n in selectedAlert.assets"
                :key="n"
              >
                <img
                  :src="`${n.url}`"
                  alt=""
                  style="
                    height: 70px;
                    width: 70px;
                    margin: 5px;
                    float: left;
                    border: 1px solid #aaa;
                    border-radius: 10px;
                    margin-bottom: 10px;
                  "
                />
              </div>
            </div>
          </div>

          <div
            class="d-flex justify-content-between mt-2 mb-2"
            v-if="selectedAlert.status == 'pending'"
          >
            <div
              @click="hideModalInfo"
              class="modal fade"
              id="exampleModal"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div
                class="modal-dialog"
                role="document"
                style="max-width: calc(100% - 200px); width:100%; !important;  border-radius: 30px;margin:40px auto;"
              >
                <div
                  class="modal-content"
                  style="
                    padding: 10px !important;
                    border-radius: 10px;
                    max-width: 500px;
                    margin: 0px auto;
                  "
                >
                  <div
                    class="modal-header"
                    style="border-bottom: none !important"
                  >
                    <h5
                      class="modal-title"
                      id="exampleModalLabel"
                      style="
                        font-size: 12px;
                        color: #334563;
                        margin: 0px !important ;
                        padding: 0px !important;
                      "
                    >
                      Incident Details
                    </h5>
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true" @click="hideModalInfo"
                        >&times;</span
                      >
                    </button>
                  </div>
                  <div
                    class="modal-body"
                    style="
                      padding: 5px !important;
                      border-top: none !important;
                      border-bottom: none !important;
                    "
                  >
                    <div class="d-flex justify-content-between mb-3">
                      <div
                        style="
                          background: #f2d5d5;
                          border-radius: 5px;
                          align-items: center;
                          display: flex;
                        "
                        class="pr-2 pl-2 pt-2 pb-2"
                      >
                        <span style="color: #9d1f1f; font-size: 12px">{{
                          selectedAlert.status
                        }}</span>
                      </div>
                      <div
                        style="
                          background: #238f53;
                          border-radius: 5px;
                          align-items: center;
                          display: flex;
                        "
                        class="pr-2 pl-2"
                      >
                        <span
                          style="color: #ffff; font-size: 12px; cursor: pointer"
                          >Mark as resolved</span
                        >
                      </div>
                    </div>
                    <div class="w-100" style="height: 140px">
                      <l-map :zoom="zoom" :center="center" style="width: 100%">
                        <l-tile-layer :url="url" :attribution="attribution" />
                        <!-- Use default icon -->
                        <l-tile-layer :url="url" :attribution="attribution" />
                        <!-- Use default icon -->
                        <l-marker
                          :lat-lng="[
                            selectedAlert.location.coordinates[1],
                            selectedAlert.location.coordinates[0],
                          ]"
                          v-model="zoom"
                          data-toggle="modal"
                          data-target="#exampleModal"
                        >
                          <l-icon
                            :icon-anchor="staticAnchor"
                            class-name="someExtraClass"
                          >
                            <div class="glow">
                              <div
                                style="
                                  width: 10px;
                                  height: 10px;
                                  background: #9d1f1f;
                                  border-radius: 100%;
                                "
                              ></div>
                            </div>
                          </l-icon>
                        </l-marker>
                      </l-map>
                    </div>
                    <a
                      :href="`https://www.google.com/maps/search/?api=1&query=Google&query_place_id=${selectedAlert?.placeId}`"
                      type="button"
                      target="_blank"
                      class="btn btn-primary mt-2"
                    >
                      View Route
                    </a>
                    <div class="d-flex justify-content-between mt-3">
                      <h5 style="color: #161f2e; font-size: 12px">
                        Kidnapping (CL)
                      </h5>
                      <span style="font-size: 12px; color: #e2b93b">{{
                        selectedAlert.status
                      }}</span>
                    </div>
                    <ul class="list-group mdil">
                      <li
                        class="list-group-item d-flex justify-content"
                        style="align-items: center"
                      >
                        <h6>Sender</h6>
                        <span>{{ selectedAlert.userId }}</span>
                      </li>
                      <li
                        class="list-group-item d-flex justify-content"
                        style="align-items: center"
                      >
                        <h6>Date/Time</h6>
                        <span>{{
                          moment(selectedAlert.createdAt).format(
                            "DD-MM-YYYY h:s:a"
                          )
                        }}</span>
                      </li>
                      <li
                        class="list-group-item d-flex justify-content"
                        style="align-items: center"
                      >
                        <h6>Polling Unit</h6>
                        <span>{{ selectedAlert.pollingUnit }}</span>
                      </li>
                      <li
                        class="list-group-item d-flex justify-content"
                        style="align-items: center"
                      >
                        <h6>Address</h6>
                        <span>{{ selectedAlert.address }}</span>
                      </li>
                      <li
                        class="list-group-item d-flex justify-content"
                        style="align-items: center"
                      >
                        <h6>Place Id</h6>
                        <span>{{ selectedAlert?.placeId }}</span>
                      </li>
                      <li
                        class="list-group-item d-flex justify-content"
                        style="align-items: center"
                      >
                        <h6>ID</h6>
                        <span>{{ selectedAlert.incidenceType }}</span>
                      </li>
                      <li
                        class="list-group-item d-flex justify-content"
                        style="align-items: center"
                      >
                        <h6>Description</h6>
                        <span>No description</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <SideBar />
    <div class="w-100">
      <Header />
      <main class="p-3 bg-home-main position-relative">
        <div class="w-100">
          <div class="w-100">
            <!-- Admin Report -->
            <div
              v-if="user.userRole == 'admin'"
              id="content"
              ref="printcontent"
              style="
                position: absolute;
                box-sizing: border-box;

                overflow: hidden;
                right: 25px;
                left: 25px;
                padding: 10px;
                top: 30px;
              "
            >
              <div class="w-100 mt-2" id="content">
                <div class="w-100 bg-white p-3 mt-4 conbg slide1">
                  <div class="d-flex exptime">
                    <span class="font-weight:600">Today </span>
                    <span
                      class="material-icons"
                      style="
                        font-size: 11px;
                        align-items: center;
                        display: flex;
                      "
                      >expand_more</span
                    >
                  </div>
                  <div class="d-flex mt-3" style="gap: 20px">
                    <div class="container" style="background: #ebf6f3">
                      <span>Total no. of reports</span>
                      <h5>
                        {{ parseInt(totalreports) }}
                      </h5>
                    </div>
                    <div class="container" style="background: #f5f6f7">
                      <span>Total no. of unassigned</span>
                      <h5>
                        {{ parseInt(totalpending) }}
                      </h5>
                    </div>
                  </div>
                  <div class="d-flex" style="gap: 20px">
                    <div class="container" style="background: #ebf3ff">
                      <span>Total no. of resolved</span>
                      <h5>{{ parseInt(totalresolved) }}</h5>
                    </div>
                    <div class="container" style="background: #fdf6da">
                      <span>Total no. of unresolved</span>
                      <h5>{{ parseInt(totalpending) }}</h5>
                    </div>
                    <!--
                    <div class="container" style="background: #f2d5d5">
                      <span>Total no. of report by category</span>
                      <h5>
                        {{
                          parseInt(pending) +
                          parseInt(cancelled) +
                          parseInt(resolved) +
                          parseInt(progress)
                        }}
                      </h5>
                    </div>
                    -->
                    <!--
                    <div class="container" style="background: #e4dffe">
                      <span>Total no. of report by type</span>
                      <h5>
                        {{
                          parseInt(pending) +
                          parseInt(cancelled) +
                          parseInt(resolved) +
                          parseInt(progress)
                        }}
                      </h5>
                    </div>
                    -->
                  </div>
                </div>
              </div>
              <div class="d-flex mt-4 justify-content-between p-1">
                <div class="conflex1">
                  <div style="flex: 1">
                    <div
                      style="
                        display: flex;
                        align-items: center;
                        font-size: 13px;
                        font-weight: 500;
                        margin-left: 10px;
                      "
                    >
                      Filter By:
                    </div>
                  </div>
                  <div
                    style="
                      flex: 9;

                      display: flex;
                      justify-content: left;
                    "
                  >
                    <div class="dropdown">
                      <button
                        class="btn dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Date
                      </button>
                      <div
                        class="dropdown-menu dtaedropdown"
                        aria-labelledby="dropdownMenuButton"
                        style="box-sizing: border-box"
                      >
                        <div class="float-left">
                          <label for="From" style="display: block">From</label>
                          <input
                            type="date"
                            style="width: 100%"
                            v-model="from"
                          />
                        </div>
                        <div class="float-right">
                          <label for="To" style="display: block">To</label>
                          <input
                            type="date"
                            style="width: 100%"
                            v-model="to"
                            @change="getto(selecteds)"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="dropdown" v-if="user.userRole != 'admin'">
                      <button
                        class="btn btn-secondary dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {{ choosenstate }}
                      </button>
                      <div
                        class="dropdown-menu dgggd"
                        aria-labelledby="dropdownMenuButton"
                        style="
                          height: 300px !important;
                          overflow: auto;
                          width: 100%;
                        "
                      >
                        <a
                          class="dropdown-item"
                          style="
                            width: 100%;

                            font-size: 12px;
                          "
                          href="#"
                          v-for="item in filterStates"
                          :key="item.id"
                          @click="getlgas(item.id, item.name, selecteds)"
                          >{{ item.name }}</a
                        >
                      </div>
                    </div>
                    <div class="dropdown" v-if="user.userRole != 'admin'">
                      <button
                        class="btn btn-secondary dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {{ choosenLga }}
                      </button>
                      <div
                        class="dropdown-menu dgggd"
                        aria-labelledby="dropdownMenuButton"
                        style="
                          height: 300px !important;
                          overflow: auto;
                          width: 100%;
                        "
                      >
                        <a
                          class="dropdown-item"
                          href="#"
                          v-for="item in selectedstates"
                          :key="item.id"
                          style="font-size: 12px"
                          @click="lgaget(item.name, selecteds)"
                        >
                          {{ item.name }}</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="conflex2">
                  <a
                    href="javascript:void()"
                    type="button"
                    class="float-right"
                    style="
                      color: #c02a2a;
                      font-weight: 600;
                      font-size: 14px;
                      line-height: 25.6px;
                      text-decoration: none;
                      margin-right: 10px;
                    "
                    @click="resetfilter(selecteds)"
                    >Reset Filter</a
                  >
                </div>
              </div>
              <div
                class="bg-white p-3 mt-1 conbg slide1 position-relative"
                style="
                  height: 100% !important;
                  min-height: 700px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
                v-if="loadingdata === true"
              >
                <vue-loaders-ball-scale-multiple
                  color="#238f53"
                  scale="1"
                ></vue-loaders-ball-scale-multiple>
              </div>
              <div
                class="w-100 bg-white p-3 mt-1 conbg slide1 h-100"
                style="
                  max-height: 1500px !important;
                  height: 100% !important;
                  min-height: 1500px;
                "
              >
                <div class="w-100" style="height: 1500px !impotant">
                  <div class="float-left w-50">
                    <TabNav2
                      :tabs="['Pending', 'Resolved']"
                      @selected="setSelected2"
                    >
                      <Tab :isselected="selecteds === 'Pending'">
                        <div
                          style="
                            position: absolute;
                            box-sizing: border-box;

                            overflow: hidden;
                            right: 25px;
                            left: 25px;
                            padding: 10px;
                            height: 100%;
                          "
                        >
                          <table
                            class="table mt-4"
                            v-if="notification.length > 0"
                          >
                            <thead>
                              <tr>
                                <th scope="col">Incidence Type</th>
                                <th scope="col">Severity</th>
                                <th scope="col">Address</th>
                                <th scope="col">Sender</th>
                                <th scope="col">Date/Time</th>
                                <th scope="col">Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                style="cursor: pointer"
                                @click="showModalInfo(item)"
                                :data-toggle="showModal ? 'modal' : ''"
                                :data-target="showModal ? '#exampleModal' : ''"
                                v-for="item in notification"
                                :key="item"
                              >
                                <td>{{ item.name }}</td>
                                <td>{{ item.severity }}</td>
                                <td>{{ item.address }}</td>
                                <td>{{ item.senderName }}</td>
                                <td>
                                  {{
                                    moment(item.createdAt).format(
                                      "DD-MM-YYYY h:ma"
                                    )
                                  }}
                                </td>
                                <td>
                                  <span class="status">{{ item.status }}</span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div
                            style="
                              align-items: center;
                              display: grid;
                              height: 20vh;
                            "
                            v-if="notification.length == 0"
                          >
                            <h5 style="text-align: center; width: 100%">
                              No Pending Report
                            </h5>
                            <button
                              class="btn d-flex"
                              style="
                                background: #238f53;
                                color: #fff;
                                width: 80px;
                                margin: 0px auto;
                                font-size: 12px;
                                align-items: center;
                              "
                              @click="reloadpage"
                            >
                              <div>Reload</div>
                              <span
                                class="material-icons"
                                style="font-size: 12px"
                                >refresh</span
                              >
                            </button>
                          </div>
                          <div
                            style="
                              width: 100%;
                              margin-top: 20px;
                              display: flex;
                              justify-content: space-between;
                              align-items: center;
                            "
                            v-if="notification.length > 0"
                          >
                            <span
                              >{{ page }}- {{ parseInt(totalpage / 20) + 1 }} of
                              {{ totalpage }}</span
                            >
                            <nav aria-label="Page navigation example">
                              <ul class="pagination">
                                <li
                                  class="page-item"
                                  @click="previousPage"
                                  :class="hasPrevpage == true ? 'active' : ''"
                                  :aria-disabled="
                                    hasPrevpage == true ? true : false
                                  "
                                >
                                  <a
                                    class="page-link"
                                    href="javascript:void()"
                                    aria-label="Previous"
                                  >
                                    <span aria-hidden="true">&laquo;</span>
                                    <span class="sr-only">Previous</span>
                                  </a>
                                </li>

                                <li
                                  class="page-item"
                                  @click="nextPage"
                                  :class="hasNextpage ? 'active' : ''"
                                  :aria-disabled="
                                    hasNextpage == true ? true : false
                                  "
                                >
                                  <a
                                    class="page-link"
                                    href="javascript:void()"
                                    aria-label="Next"
                                    :style="{
                                      cursor: !hasNextpage
                                        ? 'not-allowed'
                                        : 'pointer',
                                    }"
                                  >
                                    <span aria-hidden="true">&raquo;</span>
                                    <span class="sr-only">Next</span>
                                  </a>
                                </li>
                              </ul>
                            </nav>
                            <div class="d-flex" style="align-items: centet">
                              <label
                                style="
                                  font-size: 13px;
                                  font-weight: 500;
                                  margin-right: 10px;
                                "
                                >Rows per page</label
                              >
                              <select
                                v-model="eachpage"
                                @change="seteachpage(eachpage)"
                              >
                                <option value="5">5</option>
                                <option value="10">10</option>

                                <option value="15">15</option>
                                <option value="20">20</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </Tab>
                      <Tab :isselected="selecteds === 'Resolved'">
                        <div
                          style="
                            position: absolute;
                            box-sizing: border-box;

                            overflow: hidden;
                            right: 25px;
                            left: 25px;
                            padding: 10px;
                            height: 100%;
                          "
                        >
                          <table
                            id="content"
                            class="table mt-4"
                            ref="printcontent"
                            v-if="notificationr.length > 0"
                          >
                            <thead>
                              <tr>
                                <th scope="col">Alert Type</th>
                                <th scope="col">Severity</th>
                                <th scope="col">Address</th>
                                <th scope="col">Sender</th>
                                <th scope="col">Date/Time</th>
                                <th scope="col">Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                style="cursor: pointer"
                                @click="showModalInfo(item)"
                                :data-toggle="showModal ? 'modal' : ''"
                                :data-target="showModal ? '#exampleModal' : ''"
                                v-for="item in notificationr"
                                :key="item"
                              >
                                <td>{{ item.name }}</td>
                                <td>{{ item.severity }}</td>
                                <td>{{ item.address }}</td>
                                <td>{{ item.senderName }}</td>

                                <td>
                                  {{
                                    moment(item.createdAt).format(
                                      "DD-MM-YYYY h:ma"
                                    )
                                  }}
                                </td>
                                <td>
                                  <span
                                    class="status"
                                    style="
                                      background: #238f53 !important;
                                      color: #fff !important;
                                    "
                                    >{{ item.status }}</span
                                  >
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div
                            style="
                              align-items: center;
                              display: grid;
                              height: 20vh;
                            "
                            v-if="notificationr.length == 0"
                          >
                            <h5 style="text-align: center; width: 100%">
                              No Resolved Alert
                            </h5>
                            <button
                              class="btn d-flex"
                              style="
                                background: #238f53;
                                color: #fff;
                                width: 80px;
                                margin: 0px auto;
                                font-size: 12px;
                                align-items: center;
                              "
                              @click="reloadpage"
                            >
                              <div>Reload</div>
                              <span
                                class="material-icons"
                                style="font-size: 12px"
                                >refresh</span
                              >
                            </button>
                          </div>
                          <div
                            style="
                              width: 100%;
                              margin-top: 20px;
                              display: flex;
                              justify-content: space-between;
                              align-items: center;
                            "
                            v-if="notificationr.length > 0"
                          >
                            <span
                              >{{ page }}-
                              {{ parseInt(totalpager / 20) + 1 }} of
                              {{ totalpager }}</span
                            >
                            <nav aria-label="Page navigation example">
                              <ul class="pagination">
                                <li
                                  class="page-item"
                                  @click="previousPager"
                                  :class="hasPrevpage ? 'active' : null"
                                  :aria-disabled="
                                    hasPrevpage == false ? false : true
                                  "
                                >
                                  <a
                                    class="page-link"
                                    href="javascript:void()"
                                    aria-label="Previous"
                                  >
                                    <span aria-hidden="true">&laquo;</span>
                                    <span class="sr-only">Previous</span>
                                  </a>
                                </li>

                                <li
                                  class="page-item"
                                  @click="nextPager"
                                  :class="hasNextpage ? 'active' : null"
                                  :aria-disabled="
                                    hasNextpage == false ? false : true
                                  "
                                >
                                  <a
                                    class="page-link"
                                    href="javascript:void()"
                                    aria-label="Next"
                                  >
                                    <span aria-hidden="true">&raquo;</span>
                                    <span class="sr-only">Next</span>
                                  </a>
                                </li>
                              </ul>
                            </nav>
                            <div class="d-flex" style="align-items: centet">
                              <label
                                style="
                                  font-size: 13px;
                                  font-weight: 500;
                                  margin-right: 10px;
                                "
                                >Rows per page</label
                              >
                              <select
                                v-model="eachpage"
                                @change="seteachpager(eachpage)"
                              >
                                <option value="5">5</option>
                                <option value="10">10</option>

                                <option value="15">15</option>
                                <option value="20" selected>20</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </Tab>
                    </TabNav2>
                  </div>

                  <!--- search -->
                  <div class="float-right" style="width: 30% !important">
                    <div
                      class="btntab d-flex"
                      style="
                        border-bottom: 1px solid #f5f6f7;
                        justigy-content: left;
                        width: 100%;
                      "
                    >
                      <span
                        class="material-icons"
                        style="
                          display: flex;
                          align-self: center;
                          margin-right: 10px;
                          font-size: 14px;
                          color: #7993b1;
                        "
                        >search</span
                      ><input
                        @keyup="searchUser(selecteds)"
                        type="text"
                        v-model="searchs"
                        style="
                          width: 100%;
                          border: none !important;
                          outline: none;
                          color: #7993b1;
                        "
                        placeholder="Search"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- End Admin report -->
            <div
              class="w-50"
              style="float: left"
              v-if="user.userRole != 'admin'"
            >
              <TabNav
                :tabs="['Analytics', 'Report']"
                :selected="selected"
                @selected="setSelected"
              >
                <Tab :isselected="selected === 'Analytics'">
                  <div
                    style="
                      position: absolute;
                      box-sizing: border-box;
                      overflow: hidden;
                      right: 25px;
                      left: 25px;
                      padding: 10px;
                    "
                    ref="printcontent"
                  >
                    <div class="w-100" id="content">
                      <div class="w-100 bg-white p-3 mt-4 conbg slide1">
                        <div class="d-flex exptime">
                          <span class="font-weight:600">Today </span>
                          <span
                            class="material-icons"
                            style="
                              font-size: 11px;
                              align-items: center;
                              display: flex;
                            "
                            >expand_more</span
                          >
                        </div>
                        <div class="d-flex mt-3" style="gap: 20px">
                          <div class="container" style="background: #ebf6f3">
                            <span>Total no. of reports</span>
                            <h5>
                              {{ parseInt(totalreports) }}
                            </h5>
                          </div>
                          <div class="container" style="background: #f5f6f7">
                            <span>Total no. of unassigned</span>
                            <h5>
                              {{ parseInt(totalpending) }}
                            </h5>
                          </div>
                        </div>
                        <div class="d-flex" style="gap: 20px">
                          <div class="container" style="background: #ebf3ff">
                            <span>Total no. of resolved</span>
                            <h5>{{ parseInt(totalresolved) }}</h5>
                          </div>
                          <div class="container" style="background: #fdf6da">
                            <span>Total no. of unresolved</span>
                            <h5>{{ parseInt(totalpending) }}</h5>
                          </div>
                          <!---
                          <div class="container" style="background: #f2d5d5">
                            <span>Total no. of alert by category</span>
                            <h5>
                              {{
                                parseInt(pending) +
                                parseInt(cancelled) +
                                parseInt(resolved) +
                                parseInt(progress)
                              }}
                            </h5>
                          </div>
                          -->
                          <!---
                          <div class="container" style="background: #e4dffe">
                            <span>Total no. of alert by type</span>
                            <h5>
                              {{
                                parseInt(pending) +
                                parseInt(cancelled) +
                                parseInt(resolved) +
                                parseInt(progress)
                              }}
                            </h5>
                          </div>
                          -->
                        </div>
                      </div>
                      <div class="w-100 bg-white p-3 mt-1 conbg slide2">
                        <Loader v-if="loading" />
                        <div
                          v-else
                          style="
                            background: #f5f6f7;
                            height: 100%;
                            margin-left: 10px;
                            border-radius: 10px;
                          "
                        >
                          <div class="d-flex justify-content-between">
                            <div class="d-flex m-2">
                              <h5 style="font-size: 12px">Most report</h5>
                              <ul
                                class="d-flex justigy-content-between"
                                style="font-size: 12px"
                              >
                                <li
                                  style="margin-right: 20px; cursor: pointer"
                                  @click="getalertbyeach('current')"
                                >
                                  Current Week
                                </li>
                                <li
                                  style="margin-left: 20px; cursor: pointer"
                                  @click="getalertbyeach('previous')"
                                >
                                  Previous Week
                                </li>
                              </ul>
                            </div>
                            <div class="btn-group dropleft m-2 cursor-pointer">
                              <span
                                class="material-icons cursor-pointer"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                style="cursor: pointer"
                                >more_vert</span
                              >
                              <div
                                class="dropdown-menu shadow-sm"
                                style="width: 100px !important"
                              >
                                <div
                                  class="p-2 w-100 mm"
                                  style="
                                    font-size: 12px;
                                    text-align: center;
                                    cursor: pointer;
                                  "
                                  @click="getalertbyeach('daily')"
                                >
                                  Daily
                                </div>
                                <div
                                  class="p-2 w-100 mm"
                                  style="
                                    font-size: 12px;
                                    text-align: center;
                                    cursor: pointer;
                                  "
                                  @click="getalertbyeach('weekly')"
                                >
                                  Weekly
                                </div>
                                <div
                                  class="p-2 w-100 mm"
                                  style="
                                    font-size: 12px;
                                    text-align: center;
                                    cursor: pointer;
                                  "
                                  @click="getalertbyeach('monthly')"
                                >
                                  Monthly
                                </div>
                              </div>
                            </div>
                          </div>
                          <line-chart
                            :legend="false"
                            width="100%"
                            height="200px"
                            :data="data"
                            :curve="true"
                          />
                        </div>
                      </div>

                      <div
                        class="w-100 bg-white p-3 mt-1 conbg d-flex justify-content-between slide3"
                      >
                        <!-- <Loader v-if="loading" /> -->
                        <div
                          v-if="!loading"
                          class="w-50"
                          style="
                            background: #f5f6f7;

                            margin-right: 10px;
                            border-radius: 10px;
                          "
                        >
                          <h5 class="m-2" style="font-size: 14px">
                            Most Polarised Location
                          </h5>

                          <!--  <column-chart
                            :legend="false"
                            :stacked="true"
                            :grouped="true"
                            :dataset="{
                              radius: 100,
                              width: 5,
                              borderWidth: 1,
                              maxWidth: 4,
                              outerWidth: 5,
                              lineWidth: 10,
                            }"
                            :data="data"
                            :library="{
                              barThickness: 25,
                              legend: { labels: { fontSize: 10 } },
                            }"
                          ></column-chart>
                          -->
                          <div
                            class="w-100"
                            style="
                              box-sizing: border-box;
                              overflow: hidden;
                              height: 80%;
                            "
                          >
                            <AlertBar
                              :mydata="groupzone"
                              :myhighest="highestTotal"
                            />
                          </div>
                        </div>
                        <!-- <Loader v-if="loading" /> -->
                        <div
                          v-if="!loading"
                          class="w-50"
                          style="
                            background: #f5f6f7;
                            height: 100%;
                            margin-left: 10px;
                            border-radius: 10px;
                          "
                        >
                          <h5 class="m-2" style="font-size: 14px">
                            Avg time of resolution
                          </h5>
                          <pie-chart
                            legend="right"
                            :dataset="{
                              borderWidth: 1,
                              cutoutPercentage: 5,
                              innerWidth: 0,
                              radius: 100,
                              legendWidth: 5,
                              innerWidth: 5,
                              innerHeight: 100,
                              backgroundColor: [
                                '#C9D9E5',
                                '#C9D9E5',
                                '#B1E5AF',
                                '#A6CAF9',
                                '#F5E5B4',
                                '#F5B0B0',
                              ],
                            }"
                            :library="{
                              backgroundColor: 'blue',
                              cutout: 100,
                              fontSize: 5,
                            }"
                            :donut="true"
                            :data="data"
                          ></pie-chart>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab :isselected="selected === 'Report'">
                  <div
                    style="
                      position: absolute;
                      box-sizing: border-box;

                      overflow: hidden;
                      right: 25px;
                      left: 25px;
                      padding: 10px;
                    "
                  >
                    <div class="d-flex mt-4 justify-content-between p-1">
                      <div class="conflex1">
                        <div style="flex: 1">
                          <div
                            style="
                              display: flex;
                              align-items: center;
                              font-size: 13px;
                              font-weight: 500;
                              margin-left: 10px;
                            "
                          >
                            Filter By:
                          </div>
                        </div>
                        <div
                          style="
                            flex: 9;

                            display: flex;
                            justify-content: left;
                          "
                        >
                          <div class="dropdown">
                            <button
                              class="btn dropdown-toggle"
                              type="button"
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              Date
                            </button>
                            <div
                              class="dropdown-menu dtaedropdown"
                              aria-labelledby="dropdownMenuButton"
                              style="box-sizing: border-box"
                            >
                              <div class="float-left">
                                <label for="From" style="display: block"
                                  >From</label
                                >
                                <input
                                  type="date"
                                  style="width: 100%"
                                  v-model="from"
                                />
                              </div>
                              <div class="float-right">
                                <label for="To" style="display: block"
                                  >To</label
                                >
                                <input
                                  type="date"
                                  style="width: 100%"
                                  v-model="to"
                                  @change="getto(selecteds)"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="dropdown">
                            <button
                              class="btn btn-secondary dropdown-toggle"
                              type="button"
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              {{ choosenstate }}
                            </button>
                            <div
                              class="dropdown-menu dgggd"
                              aria-labelledby="dropdownMenuButton"
                              style="
                                height: 300px !important;
                                overflow: auto;
                                width: 100%;
                              "
                            >
                              <a
                                class="dropdown-item"
                                style="
                                  width: 100%;

                                  font-size: 12px;
                                "
                                href="#"
                                v-for="item in filterStates"
                                :key="item.id"
                                @click="getlgas(item.id, item.name, selecteds)"
                                >{{ item.name }}</a
                              >
                            </div>
                          </div>
                          <div class="dropdown">
                            <button
                              class="btn btn-secondary dropdown-toggle"
                              type="button"
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              {{ choosenLga }}
                            </button>
                            <div
                              class="dropdown-menu dgggd"
                              aria-labelledby="dropdownMenuButton"
                              style="
                                height: 300px !important;
                                overflow: auto;
                                width: 100%;
                              "
                            >
                              <a
                                class="dropdown-item"
                                href="#"
                                v-for="item in selectedstates"
                                :key="item.id"
                                style="font-size: 12px"
                                @click="lgaget(item.name, selecteds)"
                              >
                                {{ item.name }}</a
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="conflex2">
                        <a
                          href="javascript:void()"
                          type="button"
                          class="float-right"
                          style="
                            color: #c02a2a;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 25.6px;
                            text-decoration: none;
                            margin-right: 10px;
                          "
                          @click="resetfilter(selecteds)"
                          >Reset Filter</a
                        >
                      </div>
                    </div>
                    <div
                      class="bg-white p-3 mt-1 conbg slide1 position-relative"
                      style="
                        height: 100% !important;
                        min-height: 700px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      "
                      v-if="loadingdata === true"
                    >
                      <vue-loaders-ball-scale-multiple
                        color="#238f53"
                        scale="1"
                      ></vue-loaders-ball-scale-multiple>
                    </div>
                    <div
                      class="w-100 bg-white p-3 mt-1 conbg slide1 h-100"
                      style="
                        max-height: 1500px !important;
                        height: 100% !important;
                        min-height: 1500px;
                      "
                    >
                      <div class="w-100" style="height: 1200px !important">
                        <div class="float-left w-50">
                          <TabNav2
                            :tabs="['Pending', 'Resolved']"
                            @selected="setSelected2"
                          >
                            <Tab :isselected="selecteds === 'Pending'">
                              <div
                                style="
                                  position: absolute;
                                  box-sizing: border-box;

                                  overflow: hidden;
                                  right: 25px;
                                  left: 25px;
                                  padding: 10px;
                                  height: 100%;
                                "
                              >
                                <table
                                  class="table mt-4"
                                  v-if="notification.length > 0"
                                  id="content"
                                  ref="printcontent"
                                >
                                  <thead>
                                    <tr>
                                      <th scope="col">Incidence Type</th>
                                      <th scope="col">Severity</th>
                                      <th scope="col">Address</th>
                                      <th scope="col">Sender</th>
                                      <th scope="col">Date/Time</th>
                                      <th scope="col">Status</th>
                                      <th scope="col">Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      style="cursor: pointer"
                                      @click="showModalInfo(item)"
                                      :data-toggle="showModal ? 'modal' : ''"
                                      :data-target="
                                        showModal ? '#exampleModal' : ''
                                      "
                                      v-for="item in notification"
                                      :key="item"
                                    >
                                      <td>{{ item.name }}</td>
                                      <td>{{ item.severity }}</td>
                                      <td>{{ item.address }}</td>
                                      <td>{{ item.senderName }}</td>

                                      <td>
                                        {{
                                          moment(item.createdAt).format(
                                            "DD-MM-YYYY h:ma"
                                          )
                                        }}
                                      </td>
                                      <td>
                                        <span class="status">{{
                                          item.status
                                        }}</span>
                                      </td>
                                      <td
                                        class="d-flex justify-content-cent"
                                        v-if="
                                          adminrole === 'super_admin' ||
                                          adminrole === 'global_admin'
                                        "
                                      >
                                        <button
                                          class="sideiconbuttob butn2 delete"
                                          @click="deleteReport(item)"
                                        >
                                          <span
                                            :style="{ fontSize: '16px' }"
                                            class="material-icons"
                                            >delete</span
                                          >
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <div
                                  style="
                                    align-items: center;
                                    display: grid;
                                    height: 20vh;
                                  "
                                  v-if="notification.length == 0"
                                >
                                  <h5 style="text-align: center; width: 100%">
                                    No Pending Report
                                  </h5>
                                  <button
                                    class="btn d-flex"
                                    style="
                                      background: #238f53;
                                      color: #fff;
                                      width: 80px;
                                      margin: 0px auto;
                                      font-size: 12px;
                                      align-items: center;
                                    "
                                    @click="reloadpage"
                                  >
                                    <div>Reload</div>
                                    <span
                                      class="material-icons"
                                      style="font-size: 12px"
                                      >refresh</span
                                    >
                                  </button>
                                </div>
                                <div
                                  style="
                                    width: 100%;
                                    margin-top: 20px;
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                  "
                                  v-if="notification.length > 0"
                                >
                                  <span
                                    >{{ page }}-
                                    {{ parseInt(totalpage / 20) + 1 }} of
                                    {{ totalpage }}</span
                                  >
                                  <nav aria-label="Page navigation example">
                                    <ul class="pagination">
                                      <li
                                        class="page-item"
                                        @click="previousPage"
                                        :class="
                                          hasPrevpage == true ? 'active' : ''
                                        "
                                        :aria-disabled="
                                          hasPrevpage == true ? true : false
                                        "
                                      >
                                        <a
                                          class="page-link"
                                          href="javascript:void()"
                                          aria-label="Previous"
                                          :style="{
                                            cursor:
                                              page === 1
                                                ? 'not-allowed'
                                                : 'pointer',
                                          }"
                                        >
                                          <span aria-hidden="true"
                                            >&laquo;</span
                                          >
                                          <span class="sr-only">Previous</span>
                                        </a>
                                      </li>

                                      <li
                                        class="page-item"
                                        @click="nextPage"
                                        :class="hasNextpage ? 'active' : ''"
                                        :aria-disabled="
                                          hasNextpage == true ? true : false
                                        "
                                      >
                                        <a
                                          class="page-link"
                                          href="javascript:void()"
                                          aria-label="Next"
                                          :style="{
                                            cursor: !hasNextpage
                                              ? 'not-allowed'
                                              : 'pointer',
                                          }"
                                        >
                                          <span aria-hidden="true"
                                            >&raquo;</span
                                          >
                                          <span class="sr-only">Next</span>
                                        </a>
                                      </li>
                                    </ul>
                                  </nav>
                                  <div
                                    class="d-flex"
                                    style="align-items: centet"
                                  >
                                    <label
                                      style="
                                        font-size: 13px;
                                        font-weight: 500;
                                        margin-right: 10px;
                                      "
                                      >Rows per page</label
                                    >
                                    <select
                                      v-model="eachpage"
                                      @change="seteachpage(eachpage)"
                                    >
                                      <option value="5">5</option>
                                      <option value="10">10</option>

                                      <option value="15">15</option>
                                      <option value="20">20</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </Tab>
                            <Tab :isselected="selecteds === 'Resolved'">
                              <div
                                style="
                                  position: absolute;
                                  box-sizing: border-box;

                                  overflow: hidden;
                                  right: 25px;
                                  left: 25px;
                                  padding: 10px;
                                  height: 100%;
                                "
                              >
                                <table
                                  id="content"
                                  class="table mt-4"
                                  ref="printcontent"
                                  v-if="notificationr.length > 0"
                                >
                                  <thead>
                                    <tr>
                                      <th scope="col">Alert Type</th>
                                      <th scope="col">Severity</th>
                                      <th scope="col">Address</th>
                                      <th scope="col">Sender</th>
                                      <th scope="col">Date/Time</th>
                                      <th scope="col">Status</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      style="cursor: pointer"
                                      @click="showModalInfo(item)"
                                      :data-toggle="showModal ? 'modal' : ''"
                                      :data-target="
                                        showModal ? '#exampleModal' : ''
                                      "
                                      v-for="item in notificationr"
                                      :key="item"
                                    >
                                      <td>{{ item.name }}</td>
                                      <td>{{ item.severity }}</td>
                                      <td>{{ item.address }}</td>
                                      <td>{{ item.senderName }}</td>

                                      <td>
                                        {{
                                          moment(item.createdAt).format(
                                            "DD-MM-YYYY h:ma"
                                          )
                                        }}
                                      </td>
                                      <td>
                                        <span
                                          class="status"
                                          style="
                                            background: #238f53 !important;
                                            color: #fff !important;
                                          "
                                          >{{ item.status }}</span
                                        >
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <div
                                  style="
                                    align-items: center;
                                    display: grid;
                                    height: 20vh;
                                  "
                                  v-if="notificationr.length == 0"
                                >
                                  <h5 style="text-align: center; width: 100%">
                                    No Resolved Alert
                                  </h5>
                                  <button
                                    class="btn d-flex"
                                    style="
                                      background: #238f53;
                                      color: #fff;
                                      width: 80px;
                                      margin: 0px auto;
                                      font-size: 12px;
                                      align-items: center;
                                    "
                                    @click="reloadpage"
                                  >
                                    <div>Reload</div>
                                    <span
                                      class="material-icons"
                                      style="font-size: 12px"
                                      >refresh</span
                                    >
                                  </button>
                                </div>
                                <div
                                  style="
                                    width: 100%;
                                    margin-top: 20px;
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                  "
                                  v-if="notificationr.length > 0"
                                >
                                  <span
                                    >{{ page }}-
                                    {{ parseInt(totalpager / 20) + 1 }}
                                    of {{ totalpager }}</span
                                  >
                                  <nav aria-label="Page navigation example">
                                    <ul class="pagination">
                                      <li
                                        class="page-item"
                                        @click="previousPager"
                                        :class="hasPrevpage ? 'active' : null"
                                        :aria-disabled="
                                          hasPrevpage == false ? false : true
                                        "
                                      >
                                        <a
                                          class="page-link"
                                          href="javascript:void()"
                                          aria-label="Previous"
                                        >
                                          <span aria-hidden="true"
                                            >&laquo;</span
                                          >
                                          <span class="sr-only">Previous</span>
                                        </a>
                                      </li>

                                      <li
                                        class="page-item"
                                        @click="nextPager"
                                        :class="hasNextpage ? 'active' : null"
                                        :aria-disabled="
                                          hasNextpage == false ? false : true
                                        "
                                      >
                                        <a
                                          class="page-link"
                                          href="javascript:void()"
                                          aria-label="Next"
                                        >
                                          <span aria-hidden="true"
                                            >&raquo;</span
                                          >
                                          <span class="sr-only">Next</span>
                                        </a>
                                      </li>
                                    </ul>
                                  </nav>
                                  <div
                                    class="d-flex"
                                    style="align-items: centet"
                                  >
                                    <label
                                      style="
                                        font-size: 13px;
                                        font-weight: 500;
                                        margin-right: 10px;
                                      "
                                      >Rows per page</label
                                    >
                                    <select
                                      v-model="eachpage"
                                      @change="seteachpager(eachpage)"
                                    >
                                      <option value="5">5</option>
                                      <option value="10">10</option>

                                      <option value="15">15</option>
                                      <option value="20">20</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </Tab>
                          </TabNav2>
                        </div>

                        <!--- search -->
                        <!--
                        <div
                          class="float-right"
                          style="width: 30% !important"
                          v-if="user.userRole == 'super_admin'"
                        >
                          <div
                            class="btntab d-flex"
                            style="
                              border-bottom: 1px solid #f5f6f7;
                              justigy-content: left;
                              width: 100%;
                            "
                          >
                            <span
                              class="material-icons"
                              style="
                                display: flex;
                                align-self: center;
                                margin-right: 10px;
                                font-size: 14px;
                                color: #7993b1;
                              "
                              >search</span
                            ><input
                              @keyup="searchUser(selecteds)"
                              type="text"
                              v-model="searchs"
                              style="
                                width: 100%;
                                border: none !important;
                                outline: none;
                                color: #7993b1;
                              "
                              placeholder="Search"
                            />
                          </div>

                        </div>
                          -->
                      </div>
                    </div>
                  </div>
                </Tab>
              </TabNav>
            </div>
            <div style="float: right; width: 50%">
              <div class="d-flex" style="justify-content: end">
                <div class="btntab d-flex">
                  <!--
                  <button class="btn btn1" @click="printThis">Export as png</button>
                  -->
                  <button class="btn btn2" @click="downloadCSV('csv')">
                    Export as csv
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>
<script>
import Header from "../components/header.vue";
import SideBar from "../components/sidebar.vue";
import Loader from "../components/loader.vue";
import TabNav from "@/components/TabNav.vue";
import Tab from "@/components/tab.vue";
import TabNav2 from "@/components/TabNav2.vue";
//import * as XLSX from "xlsx/xlsx.mjs";
import Map from "../components/Map.vue";
import { mapGetters } from "vuex";
import axios from "axios";
import moment from "moment";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import "leaflet/dist/leaflet.css";
import AlertBar from "@/components/alertbar.vue";

//import { latLng } from "leaflet/dist/leaflet-src.esm";

//import { LMap, LTileLayer, LMarker, LIcon } from "@vue-leaflet/vue-leaflet/src/lib";
import { latLng } from "leaflet/dist/leaflet-src.esm";
import { publicPath } from "@/config";
export default {
  name: "Report-App",
  components: {
    SideBar,
    Loader,
    Header,
    TabNav,
    Tab,
    Map,
    TabNav2,
    // LMap,
    // LTileLayer,
    //LMarker,
    // LIcon,
    AlertBar,
  },
  data() {
    return {
      adminrole: "",
      adminstate: "",
      showModal: false,
      states: [],
      alerts: [],
      searchs: "",
      selectedAlert: [],
      selectedstates: [],
      loading: true,
      notificationr: [],
      slectedcoordslat: 0,
      slectedcoordslag: 0,
      current: [],
      notification: [],
      totalalert: 0,
      noofAlert: [],
      totalalertstatusp: 0,
      totalalertstatus: 0,
      totalalertresolved: 0,
      eachpage: 20,
      notificationss: [],
      page: 1,
      totalpager: 0,
      to: "",
      from: "",
      selectedlevel: "",
      totalpage: 0,
      highestTotal: 0,
      alertid: "",
      perpage: 0,
      hasNextpage: false,
      hasPrevpage: false,
      search: "",
      selecteds: "Pending",
      pending: 0,
      cancelled: 0,
      totalreports: 0,
      progress: 0,
      loadingdata: true,
      resolved: 0,
      moment: moment,
      totalresolved: 0,
      pager: 1,
      choosenstate: "States",
      choosenLga: "LGA",
      zoom: 9,
      totalpending: 0,
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",

      staticAnchor: [16, 37],
      customText: "Foobar",
      iconSize: 64,
      selected: "Analytics",
      options: {
        legend: {
          display: false,
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem) {
              return tooltipItem.yLabel;
            },
          },
        },
      },
      data: [],
      datas: [
        {
          name: "North Central",
          data: [],
        },
        {
          name: "Call parents",
          data: {
            "North South  ": 3,
            "North East ": 4,
            "North West ": 5,
            "South Nouth  ": 7,
            "South East ": 8,
            "South South ": 10,
          },
        },
        {
          name: "Call parents",
          data: {
            "North South  ": 3,
            "North East ": 6,
            "North West ": 8,
            "South Nouth  ": 1,
            "South East ": 4,
            "South South ": 1,
          },
        },
        {
          name: "Call parents",
          data: {
            "North South  ": 3,
            "North East ": 6,
            "North West ": 8,
            "South Nouth  ": 1,
            "South East ": 4,
            "South South ": 1,
          },
        },
        {
          name: "Call parents",
          data: {
            "North South  ": 3,
            "North East ": 6,
            "North West ": 8,
            "South Nouth  ": 1,
            "South East ": 4,
            "South South ": 1,
          },
        },
        {
          name: "Call parents",
          data: {
            "North South  ": 3,
            "North East ": 6,
            "North West ": 8,
            "South Nouth  ": 1,
            "South East ": 4,
            "South South ": 1,
          },
        },
      ],
    };
  },
  methods: {
    async resetfilter(status) {
      this.choosenLga = "LGA";
      this.choosenstate = "State";
      this.to = "";
      this.from = "";

      if (status == "Pending") {
        try {
          let response = await axios.get(
            `v1/admin/get_all_report_v2?status=pending&limit=20${
              this.adminrole == "admin" ? `&state=${this.adminstate}` : ""
            }`
          );

          this.notification = response.data.result.data;
          this.totalpage = response.data.result.data.total;
          this.perpage = response.data.result.data.perPage;
          this.hasNextpage = response.data.result.data.hasNextPage;
          this.hasPrevpage = response.data.result.data.hasPrevPage;
        } catch (e) {
          console.log(e);
        }
      } else {
        try {
          let response = await axios.get(
            `v1/admin/get_all_report_v2?status=resolved&limit=20${
              this.adminrole == "admin" ? `&state=${this.adminstate}` : ""
            }`
          );

          this.notificationr = response.data.result.data;
          this.totalpager = response.data.result.data.total;
          this.perpage = response.data.result.data.perPage;
          this.hasNextpage = response.data.result.data.hasNextPage;
          this.hasPrevpage = response.data.result.data.hasPrevPage;
        } catch (e) {
          console.log(e);
        }
      }
    },
    async searchUser(select) {
      if (select == "Pending") {
        try {
          let response = await axios.get(
            `v1/admin/get_all_report_v2?status=pending&search=${this.searchs}${
              this.adminrole == "admin" ? `&state=${this.adminstate}` : ""
            }`
          );
          this.notification = response.data.result.data.data;

          this.totalpage = response.data.result.data.total;
          this.perpage = response.data.result.data.perPage;
          this.hasNextpage = response.data.result.data.hasNextPage;
          this.hasPrevpage = response.data.result.data.hasPrevPage;
        } catch (e) {
          console.log(e);
        }
      } else {
        try {
          let response = await axios.get(
            `v1/admin/get_all_report_v2?status=resolved&search=${this.searchs}${
              this.adminrole == "admin" ? `&state=${this.adminstate}` : ""
            }`
          );

          this.notifications = response.data.result.data.data;

          this.totalpager = response.data.result.data.total;
          this.perpager = response.data.result.data.perPage;
          this.hasNextpager = response.data.result.data.hasNextPage;
          this.hasPrevpager = response.data.result.data.hasPrevPage;
        } catch (e) {
          console.log(e);
        }
      }
    },
    async getalertbyeach(id) {
      const date = new Date(); // current date modify it to your date

      if (id == "current") {
        const dayOfWeek = date.getDay();
        const startOfWeek = new Date(
          date.getTime() - dayOfWeek * 24 * 60 * 60 * 1000
        );
        startOfWeek.setHours(0, 0, 0, 0);
        const endOfWeek = new Date(
          date.getTime() + (6 - dayOfWeek) * 24 * 60 * 60 * 1000
        );
        endOfWeek.setHours(23, 59, 59, 999);
        this.start = startOfWeek;
        this.end = endOfWeek;

        try {
          let resp = await axios.get(
            `v1/admin/report_state_chart?startDate=${this.start}&endDate=${this.end}`
          );
          this.zonedata = resp.data.result.data.noOfReportZone;
          const data = {
            "North West":
              this.zonedata[1] != undefined ? this.zonedata[1].count : 0,
            "North Central":
              this.zonedata[0] != undefined ? this.zonedata[0].count : 0,
            "South West":
              this.zonedata[2] != undefined ? this.zonedata[2].count : 0,
            "North East":
              this.zonedata[4] != undefined ? this.zonedata[4].count : 0,
            "South South":
              this.zonedata[5] != undefined ? this.zonedata[5].count : 0,
            "South East":
              this.zonedata[3] != undefined ? this.zonedata[3].count : 0,
          };

          this.data = data;
          const datas = resp.data.result.data.groupedZone;

          const keys = Object.keys(datas);
          const newObj = {};
          const total = {};
          for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            const value = datas[key];
            value.forEach((item) => {
              if (!newObj[key]) newObj[key] = {};
              newObj[key][item.state] = item.count;
              if (!total[key]) total[key] = 0;
              total[key] += item.count;
            });
          }
          Object.keys(total).map((zone) => {
            newObj[zone].totalCount = total[zone];
          });
          this.groupzone = newObj;

          this.highestTotal += Math.max(...Object.values(total));
        } catch (e) {
          this.$swal({
            text: e.message,

            width: "300px",
            confirmButtonColor: "#238f53",
          });
        }
      } else if (id == "previous") {
        const dayOfWeek = date.getDay();
        const startOfPreviousWeek = new Date(
          date.getTime() - (7 + dayOfWeek) * 24 * 60 * 60 * 1000
        );
        startOfPreviousWeek.setHours(0, 0, 0, 0);
        const endOfPreviousWeek = new Date(
          date.getTime() - (dayOfWeek + 1) * 24 * 60 * 60 * 1000
        );
        endOfPreviousWeek.setHours(23, 59, 59, 999);

        this.start = startOfPreviousWeek;
        this.end = endOfPreviousWeek;

        try {
          let resp = await axios.get(
            `v1/admin/report_state_chart?startDate=${this.start}&endDate=${this.end}`
          );
          this.zonedata = resp.data.result.data.noOfReportZone;
          const data = {
            "North West": this.zonedata[1] ? this.zonedata[1].count : 0,
            "North Central": this.zonedata[0] ? this.zonedata[0].count : 0,
            "South West": this.zonedata[2] ? this.zonedata[2].count : 0,
            "North East": this.zonedata[4] ? this.zonedata[4].count : 0,
            "South South": this.zonedata[5] ? this.zonedata[5].count : 0,
            "South East": this.zonedata[3] ? this.zonedata[3].count : 0,
          };

          this.data = data;
          const datas = resp.data.result.data.groupedZone;

          const keys = Object.keys(datas);
          const newObj = {};
          const total = {};
          for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            const value = datas[key];
            value.forEach((item) => {
              if (!newObj[key]) newObj[key] = {};
              newObj[key][item.state] = item.count;
              if (!total[key]) total[key] = 0;
              total[key] += item.count;
            });
          }
          Object.keys(total).map((zone) => {
            newObj[zone].totalCount = total[zone];
          });
          this.groupzone = newObj;

          this.highestTotal += Math.max(...Object.values(total));
        } catch (e) {
          this.$swal({
            text: e.message,

            width: "300px",
            confirmButtonColor: "#238f53",
          });
        }
      } else if (id == "monthly") {
        const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
        const endOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        endOfMonth.setHours(23, 59, 59, 999);
        (this.satrt = startOfMonth), (this.end = endOfMonth);
        try {
          let resp = await axios.get(
            `v1/admin/report_state_chart?startDate=${this.start}&endDate=${this.end}`
          );
          this.zonedata = resp.data.result.data.noOfReportZone;
          const data = {
            "North West": this.zonedata[1] ? this.zonedata[1].count : 0,
            "North Central": this.zonedata[0] ? this.zonedata[0].count : 0,
            "South West": this.zonedata[2] ? this.zonedata[2].count : 0,
            "North East": this.zonedata[4] ? this.zonedata[4].count : 0,
            "South South": this.zonedata[5] ? this.zonedata[5].count : 0,
            "South East": this.zonedata[3] ? this.zonedata[3].count : 0,
          };

          this.data = data;
          const datas = resp.data.result.data.groupedZone;

          const keys = Object.keys(datas);
          const newObj = {};
          const total = {};
          for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            const value = datas[key];
            value.forEach((item) => {
              if (!newObj[key]) newObj[key] = {};
              newObj[key][item.state] = item.count;
              if (!total[key]) total[key] = 0;
              total[key] += item.count;
            });
          }
          Object.keys(total).map((zone) => {
            newObj[zone].totalCount = total[zone];
          });
          this.groupzone = newObj;

          this.highestTotal += Math.max(...Object.values(total));
        } catch (e) {
          this.$swal({
            text: e.message,

            width: "300px",
            confirmButtonColor: "#238f53",
          });
        }
      } else if (id == "weekly") {
        const dayOfWeek = date.getDay();
        const startOfWeek = new Date(
          date.getTime() - dayOfWeek * 24 * 60 * 60 * 1000
        );
        startOfWeek.setHours(0, 0, 0, 0);
        const endOfWeek = new Date(
          date.getTime() + (6 - dayOfWeek) * 24 * 60 * 60 * 1000
        );
        endOfWeek.setHours(23, 59, 59, 999);
        this.start = startOfWeek;
        this.end = endOfWeek;
        try {
          let resp = await axios.get(
            `v1/admin/report_state_chart?startDate=${this.start}&endDate=${this.end}`
          );
          this.zonedata = resp.data.result.data.noOfReportZone;
          const data = {
            "North West": this.zonedata[1] ? this.zonedata[1].count : 0,
            "North Central": this.zonedata[0] ? this.zonedata[0].count : 0,
            "South West": this.zonedata[2] ? this.zonedata[2].count : 0,
            "North East": this.zonedata[4] ? this.zonedata[4].count : 0,
            "South South": this.zonedata[5] ? this.zonedata[5].count : 0,
            "South East": this.zonedata[3] ? this.zonedata[3].count : 0,
          };

          this.data = data;
          const datas = resp.data.result.data.groupedZone;

          const keys = Object.keys(datas);
          const newObj = {};
          const total = {};
          for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            const value = datas[key];
            value.forEach((item) => {
              if (!newObj[key]) newObj[key] = {};
              newObj[key][item.state] = item.count;
              if (!total[key]) total[key] = 0;
              total[key] += item.count;
            });
          }
          Object.keys(total).map((zone) => {
            newObj[zone].totalCount = total[zone];
          });
          this.groupzone = newObj;

          this.highestTotal += Math.max(...Object.values(total));
        } catch (e) {
          this.$swal({
            text: e.message,

            width: "300px",
            confirmButtonColor: "#238f53",
          });
        }
      } else if (id == "daily") {
        this.start = new Date() - 12 * 60 * 60;
        this.end = new Date();
        try {
          let resp = await axios.get(
            `v1/admin/report_state_chart?startDate=${this.start}&endDate=${this.end}`
          );
          this.zonedata = resp.data.result.data.noOfReportZone;
          const data = {
            "North West": this.zonedata[1] ? this.zonedata[1].count : 0,
            "North Central": this.zonedata[0] ? this.zonedata[0].count : 0,
            "South West": this.zonedata[2] ? this.zonedata[2].count : 0,
            "North East": this.zonedata[4] ? this.zonedata[4].count : 0,
            "South South": this.zonedata[5] ? this.zonedata[5].count : 0,
            "South East": this.zonedata[3] ? this.zonedata[3].count : 0,
          };

          this.data = data;
          const datas = resp.data.result.data.groupedZone;

          const keys = Object.keys(datas);
          const newObj = {};
          const total = {};
          for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            const value = datas[key];
            value.forEach((item) => {
              if (!newObj[key]) newObj[key] = {};
              newObj[key][item.state] = item.count;
              if (!total[key]) total[key] = 0;
              total[key] += item.count;
            });
          }
          Object.keys(total).map((zone) => {
            newObj[zone].totalCount = total[zone];
          });
          this.groupzone = newObj;

          this.highestTotal += Math.max(...Object.values(total));
        } catch (e) {
          this.$swal({
            text: e.message,

            width: "300px",
            confirmButtonColor: "#238f53",
          });
        }
      }
    },
    download() {
      window.html2canvas = html2canvas;
      const doc = new jsPDF("p", "pt", "a2");
      doc.html(document.querySelector("#content"), {
        callback: function (pdf) {
          pdf.save("Alert.pdf");
        },
      });
    },
    async downloadCSV() {
      try {
        const response = await fetch(
          `${publicPath}v1/admin/download_all_report`,
          {
            headers: new Headers({
              Authorization: "Bearer " + localStorage.getItem("token"),
            }),
          }
        );
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = "report.csv";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error(error);
      }
    },

    async printThis() {
      const el = this.$refs.printcontent;

      const options = {
        type: "dataURL",
      };
      const printCanvas = await html2canvas(el, options);

      const link = document.createElement("a");
      link.setAttribute("download", "Report.png");
      link.setAttribute(
        "href",
        printCanvas
          .toDataURL("image/png")
          .replace("image/png", "image/octet-stream")
      );
      link.click();
    },
    async getto(select) {
      if (select == "Pending") {
        try {
          const response = await axios.get(
            `v1/admin/get_all_report_v2?status=pending&endDate=${new Date(
              this.to
            ).toISOString()}&startDate=${new Date(this.from).toISOString()}${
              this.adminrole == "admin" ? `&state=${this.adminstate}` : ""
            }`
          );
          this.notification = response.data.result.data;
        } catch (e) {
          console.log(e);
        }
      } else {
        try {
          const response = await axios.get(
            `v1/admin/get_all_report_v2?status=resolved&endDate=${new Date(
              this.to
            ).toISOString()}&startDate=${new Date(this.from).toISOString()}${
              this.adminrole == "admin" ? `&state=${this.adminstate}` : ""
            }`
          );
          this.notifications = response.data.result.data;
        } catch (e) {
          console.log(e);
        }
      }
    },
    async resolvedalert() {
      try {
        const data = {
          reportId: this.alertid,
        };
        const res = await axios.post(`v1/admin/resolve_report`, data);
        this.$swal({
          title:
            "<span style='font-size:13px !important;color:#238f53'>Success</span>",
          text: res.data.result.message,

          width: "300px",
          confirmButtonColor: "#238f53",
        }).then((res) => {
          if (res.isConfirmed) {
            this.$router.go();
          }
        });
      } catch (e) {
        if (e.response.status == 400) {
          this.$swal({
            title:
              "<span style='font-size:13px !important;color:red'>Failed</span>",
            text: "Alert has not been resolved",

            width: "300px",
            confirmButtonColor: "#238f53",
          }).then();
        } else if (e.response.status == 500) {
          this.$swal({
            title:
              "<span style='font-size:13px !important;color:red'>Failed</span>",
            text: "An error occur",

            width: "300px",
            confirmButtonColor: "#238f53",
          }).then();
        }
      }
    },
    async seteachpage(id) {
      this.eachpage = id;
      try {
        let response = await axios.get(
          `v1/admin/get_all_report_v2?status=pending&limit=${id}${
            this.adminrole == "admin" ? `&state=${this.adminstate}` : ""
          }`
        );

        this.notification = response.data.result.data.data;
        this.totalpage = response.data.result.meta.total;
        this.perpage = response.data.result.meta.perPage;
        this.hasNextpage = response.data.result.meta.hasNextPage;
        this.hasPrevpage = response.data.result.meta.hasPrevPage;
      } catch (e) {
        console.log(e);
      }
    },

    async seteachpager(id) {
      this.eachpage = id;
      try {
        let response = await axios.get(
          `v1/admin/get_all_report_v2?status=resolved&limit=${id}${
            this.adminrole == "admin" ? `&state=${this.adminstate}` : ""
          }`
        );

        this.notification = response.data.result.data.data;
        this.totalpage = response.data.result.meta.total;
        this.perpage = response.data.result.meta.perPage;
        this.hasNextpage = response.data.result.meta.hasNextPage;
        this.hasPrevpage = response.data.result.meta.hasPrevPage;
      } catch (e) {
        console.log(e);
      }
    },
    async previousPage() {
      if (this.page > 1) {
        this.page--;

        try {
          let response = await axios.get(
            `v1/admin/get_all_report_v2?limit=${
              this.eachpage
            }&status=pending&page=${this.page}${
              this.adminrole == "admin" ? `&state=${this.adminstate}` : ""
            }`
          );
          this.notification = response.data.result.data.data;
          this.totalpage = response.data.result.meta.total;
          this.perpage = response.data.result.meta.perPage;
        } catch (e) {
          console.log(e);
        }
      }
    },
    async previousPager() {
      if (this.pager > 1) {
        this.pager--;

        try {
          let response = await axios.get(
            `v1/admin/get_all_report_v2?limit=${
              this.eachpage
            }&status=resolved&page=${this.pager}${
              this.adminrole == "admin" ? `&state=${this.adminstate}` : ""
            }`
          );
          this.notifications = response.data.result.data.data;
          this.totalpage = response.data.result.meta.total;
          this.perpage = response.data.result.meta.perPage;
        } catch (e) {
          console.log(e);
        }
      }
    },
    async nextPage() {
      if (this.page <= parseInt(this.totalpage) / 20) {
        this.page++;

        try {
          let response = await axios.get(
            `v1/admin/get_all_report_v2?limit=${
              this.eachpage
            }&status=pending&page=${this.page}${
              this.adminrole == "admin" ? `&state=${this.adminstate}` : ""
            }`
          );
          this.notification = response.data.result.data;
          this.totalpage = response.data.result.meta.total;
          this.perpage = response.data.result.meta.perPage;
        } catch (e) {
          console.log(e);
        }
      }
    },
    async nextPager() {
      if (this.pager <= parseInt(this.totalpager) / 20) {
        this.pager++;

        try {
          let response = await axios.get(
            `v1/admin/get_all_report_v2?limit=${
              this.eachpage
            }&status=resolved&page=${this.pager}${
              this.adminrole == "admin" ? `&state=${this.adminstate}` : ""
            }`
          );
          this.notifications = response.data.result.data;
          this.totalpage = response.data.result.meta.total;
          this.perpage = response.data.result.meta.perPage;
        } catch (e) {
          console.log(e);
        }
      }
    },
    async lgaget(name, select) {
      if (select == "Pending") {
        this.choosenLga = name;
        try {
          const response = await axios.get(
            `v1/admin/get_all_report_v2?state=${this.choosenstate}&lga=${this.choosenLga}&status=pending`
          );
          this.notification = response.data.result.data;
        } catch (e) {
          console.log(e);
        }
      } else {
        try {
          const response = await axios.get(
            `v1/admin/get_all_report_v2?state=${this.choosenstate}&lga=${this.choosenLga}&status=resolved`
          );
          this.notifications = response.data.result.data;
        } catch (e) {
          console.log(e);
        }
      }
    },
    async getlgas(state, name, select) {
      // console.log(name);
      if (select == "Pending") {
        this.choosenstate = name;
        this.selectedstates = this.lga[state];
        try {
          const response = await axios.get(
            `v1/admin/get_all_report_v2?state=${name}&status=pending`
          );
          // console.log(response);
          this.notification = response.data.result.data;
        } catch (e) {
          console.log(e);
        }
      } else {
        this.choosenstate = name;
        try {
          const response = await axios.get(
            `v1/admin/get_all_report_v2?state=${name}&status=resolved`
          );
          this.notifications = response.data.result.data;
        } catch (e) {
          console.log(e);
        }
      }
    },

    showModalInfo(item) {
      this.showModal = true;
      this.selectedAlert = item;
      this.slectedcoordslat = item.location.coordinates[1];
      this.slectedcoordslag = item.location.coordinates[0];
      // this.current = { lat: this.slectedcoordslat, lng: this.slectedcoordslag };
      this.current = latLng(this.slectedcoordslat, this.slectedcoordslag);

      this.selectedlevel = item.severity;
      this.alertid = item._id;
    },
    hideModalInfo() {
      this.showModal = false;
    },
    selectedstate(stt) {
      this.state = stt;
    },
    setSelected(tab) {
      this.selected = tab;
      (this.choosenLga = "LGA"), (this.choosenstate = "State");
    },
    setSelected2(tab) {
      this.selecteds = tab;
      this.choosenstate = "State";
      this.choosenLga = "LGA";
    },
    deleteReport(item) {
      console.log(item);
      this.showModal = false;
      this.showmodaldetail = false;
      this.$swal({
        title:
          "<span style='font-size:12px !important'>Are you sure you want to delete?</span>",
        confirmButtonColor: "#238f53",
        width: "300px",
        confirmButton: true,
        imageHeight: "30",
        imageWidth: "30",
        icon: "warning",
        fontSize: "12px",
        buttons: true,
        padding: "5px !important",
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete.isConfirmed) {
          try {
            const response = await axios.delete(
              `v1/notification/cancel_report/${item._id}`
            );
            if (response.status == 200) {
              this.$swal({
                text: response.data.result.message,
                icon: "success",
                width: "300px",

                confirmButtonColor: "#238f53",
              }).then((res) => {
                if (res.isConfirmed) {
                  this.$router.go();
                }
              });
            }
          } catch (e) {
            console.log(e);
            this.showmodaldetail = true;
          }
        } else {
          this.showmodaldetail = true;
        }
      });
    },
  },
  mounted() {
    this.eachpage = localStorage.getItem("page");

    navigator.geolocation.getCurrentPosition(
      (position) => {
        this.lat = position.coords.latitude;
        this.lag = position.coords.longitude;
        this.center = latLng(this.lat, this.lag);
      },
      (error) => {
        console.log(error.message);
      }
    );
  },
  async created() {
    try {
      let response = await axios.get("v1/user/get_user_profile");
      this.adminrole = response.data.result.data.userRole;
      this.adminstate = response.data.result.data.state;
      //this.notification = response.data.result.data.sos;
    } catch (e) {
      console.log(e);
    }

    try {
      let resp = await axios.get(
        `v1/admin/report_state_chart?endDate=${new Date()}`
      );
      this.zonedata = resp.data.result.data.noOfReportZone;
      const data = {
        "North West": this.zonedata[3]?.count,
        "North Central": this.zonedata[1]?.count,
        "South West": this.zonedata[0]?.count,
        "North East": this.zonedata[5]?.count,
        "South South": this.zonedata[4]?.count,
        "South East": this.zonedata[2]?.count,
      };
      this.data = data;
      const datas = resp.data.result.data.groupedZone;

      const keys = Object.keys(datas);
      const newObj = {};
      const total = {};
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        const value = datas[key];
        value.forEach((item) => {
          if (!newObj[key]) newObj[key] = {};
          newObj[key][item.state] = item.count;
          if (!total[key]) total[key] = 0;
          total[key] += item.count;
        });
      }
      Object.keys(total).map((zone) => {
        newObj[zone].totalCount = total[zone];
      });
      this.groupzone = newObj;
      this.highestTotal += Math.max(...Object.values(total));
    } catch (e) {
      console.log(e);
      this.$swal({
        text: e.message,

        width: "300px",
        confirmButtonColor: "#238f53",
      });
    }

    try {
      let res = await axios.get("v1/admin/report_analytics");
      this.noofAlert = res.data.result.data.noOfReport;
      this.cancelled =
        this.noofAlert[0] != undefined ? this.noofAlert[0].count : 0;
      this.pending =
        this.noofAlert[1] != undefined ? this.noofAlert[1].count : 0;
      this.progress =
        this.noofAlert[2] != undefined ? this.noofAlert[2].count : 0;
      this.resolved =
        this.noofAlert[3] != undefined ? this.noofAlert[3].count : 0;
    } catch (e) {
      console.log(e);
    }

    try {
      let response = await axios.get("v1/get_states");
      this.lga = response.data.result.data.lgas;
      this.states = response.data.result.data.state;
    } catch (e) {
      console.log(e);
    }

    try {
      let response = await axios.get(
        `v1/admin/get_all_report_v2?status=pending&limit=20${
          this.adminrole == "admin" ? `&state=${this.adminstate}` : ""
        }`
      );
      //console.log(response);
      this.totalpending = response.data.result.meta.total;

      this.notification = response.data.result.data;
      this.totalpage = response.data.result.meta.total;
      this.perpage = response.data.result.meta.perPage;
      this.hasNextpage = response.data.result.meta.hasNextPage;
      this.hasPrevpage = response.data.result.meta.hasPrevPage;
    } catch (e) {
      console.log(e);
    }
    try {
      let response = await axios.get(
        `v1/admin/get_all_report_v2?${
          this.adminrole == "admin" ? `&state=${this.adminstate}` : ""
        }`
      );
      //  console.log(response);
      this.totalreports = response.data.result.meta.total;
      // this.t = response.data.result.meta.total;
    } catch (e) {
      console.log(e);
    }

    try {
      let response = await axios.get(
        `v1/admin/get_all_report_v2?status=resolved&limit=20${
          this.adminrole == "admin" ? `&state=${this.adminstate}` : ""
        }`
      );
      // console.log(response);
      this.totalresolved = response.data.result.meta.total;
      this.notificationr = response.data.result.data;
      this.totalpager = response.data.result.meta.total;
      this.perpage = response.data.result.meta.perPage;
      this.hasNextpage = response.data.result.meta.hasNextPage;
      this.hasPrevpage = response.data.result.meta.hasPrevPage;
    } catch (e) {
      console.log(e);
    }

    try {
      let response = await axios.get("v1/admin/get_all_report_v2");
      this.notificationss = response.data.result.data;
      this.loadingdata = false;
      this.loading = false;
    } catch (e) {
      console.log(e);
    }
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
    filterStates: function () {
      return this.states.filter((item) => {
        return item.name.match(this.search.toUpperCase());
      });
    },
  },
};
</script>

<style scoped>
.bg-home-main {
  background: #f5f6f7;
  min-height: calc(100vh - 50px);
  overflow: auto;
  position: relative;
}
.btn1 {
  border: 2px solid #238f53;
  color: #238f53;
  padding: 4px 7px 4px 7px !important;
  font-weight: 500;
  outline: none !important;
  font-size: 12px;
  margin-right: 5px;
}
.btn2 {
  border: 2px solid #238f53;
  color: #fff;
  font-size: 12px;
  background: #238f53;
  padding: 4px 7px 4px 7px !important;
  font-weight: 500;
  outline: none !important;
  margin-left: 5px;
}
.conbg {
  border-radius: 12px;
}
.exptime {
  font-size: 12px;
}
.container {
  width: 100%;
  margin: 5px 2.5px 5px 2.5px;
  padding: 12px;
  border-radius: 6px;
  text-align: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #455c7d;
}
.container h5 {
  font-size: 14px;
  align-content: center;
  display: flex;
  padding: 0px !important;
  margin: 0px !important;
}
.container span {
  font-size: 12px;
}

.reset {
  font-size: 13px;
  font-weight: 600;
  color: #238f53;
  float: right;
}
.filter {
  font-size: 13px;
  font-weight: 600;
  color: #238f53;

  align-items: center;
  display: flex;
  margin-left: -20px;
}
.dropdown-toggle {
  background: #fff !important;

  font-size: 12px !important;
  width: 90%;
  padding-top: 5px !important ;
  padding-bottom: 5px !important;
  justify-content: space-between !important;
  display: flex !important;
  align-items: center;
  color: #455c7d;
  border-radius: 3px !important;
  border: 1px solid #c9d9e5 !important;
}
.dropdown {
  width: 100%;

  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.conflex1 {
  width: 80%;

  padding: 5px 0px 5px 0px;

  display: flex;
}
tr {
  cursor: pointer;
}
.conflex2 {
  width: 20%;

  padding: 5px 0px 5px 0px;
}
.center {
  height: 100vh;
  align-items: center;
  display: flex;
  width: 100vw;
  place-items: center;
  justify-content: center;
}
th {
  font-size: 12px !important;
  border: none !important;
  background: #f5f6f7;
  color: #090d14;
}
td {
  border: none !important;
  font-size: 12px !important;
}
tr:nth-child(even) {
  background: #f5f6f7;
  border-radius: 30px !important;
}
tr {
  border-radius: 30px !important;
  color: #5c7597;
}
.status {
  color: #9d1f1f;
  background: #f2d5d5;
  border-radius: 20px;
  padding-top: 1px !important ;
  padding-bottom: 2px !important ;
  padding-right: 10px !important;
  padding-left: 10px !important;
  display: inline-block;
  align-items: center;
  font-size: 12px !important;
}
.status2 {
  color: #fff;
  background: #238f53;
  border-radius: 20px;
  padding-top: 1px !important ;
  padding-bottom: 1px !important ;
  padding-right: 5px !important;
  padding-left: 5px !important;
  display: inline-block;
  align-items: center;
  font-size: 12px !important;
}
.mm:hover {
  background: #ebf7f3;
}

.modal-footer {
  border-top: none !important;
}
.mdil li {
  display: flex !important;
  justify-content: space-between !important;
  padding: 3px !important;
  line-height: 35px;
  border: none !important;
  text-align: left !important;
}
.mdil li h6 {
  font-size: 12px;
  color: #7993b1;
}
.mdil li span {
  font-size: 12px;
}
.dtaedropdown {
  border-radius: 12px !important;
  padding: 10px;
  width: 400px;
}

.dgggd::-webkit-scrollbar {
  width: 2px;
}

/* Track */
.dgggd::-webkit-scrollbar-track {
  background: #aaa;
}

/* Handle */
.dgggd::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.dgggd::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.white {
  color: #fff !important;
}
.hl {
  position: relative;
  color: #fff;
  width: 30px;
  height: 30px;
  border: 0.02rem solid #ffcc99 !important;
  border-radius: 100%;
  font-size: 5em;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 20px #ffcc99, 0 0 3px #ffcc99, 0 0 3px #ffcc99,
    0 0 3px #ffcc99, 0 0 3px #ffcc99;
}
.hl {
  animation: blinks-hl 1s steps(5, start) infinite;
  -webkit-animation: blinks-hl 1s steps(5, start) infinite;
  box-shadow: 0 0 10px #ffcc99, 0 0 3px #ffcc99, 0 0 3px #ffcc99,
    0 0 3px #ffcc99, 0 0 3px #ffcc99;
}
@keyframes blinks-hl {
  to {
    box-shadow: 0 0 20px #ffcc99, 0 0 3px #ffcc99, 0 0 3px #ffcc99,
      0 0 3px #ffcc99, 0 0 3px #ffcc99;
  }
}
@-webkit-keyframes blinks-hl {
  to {
    bax-shadow: 2px 3px 4px solid #ffcc99;
  }
}

.ml {
  position: relative;
  color: #fff;
  width: 30px;
  height: 30px;
  border: 0.02rem solid yellow !important;
  border-radius: 100%;
  font-size: 5em;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 20px yellow, 0 0 3px yellow, 0 0 3px yellow, 0 0 3px yellow,
    0 0 3px yellow;
}
.ml {
  animation: blinks-ml 1s steps(5, start) infinite;
  -webkit-animation: blinks-ml 1s steps(5, start) infinite;
  box-shadow: 0 0 10px yellow, 0 0 3px yellow, 0 0 3px yellow, 0 0 3px yellow,
    0 0 3px yellow;
}
@keyframes blinks-ml {
  to {
    box-shadow: 0 0 20px yellow, 0 0 3px yellow, 0 0 3px yellow, 0 0 3px yellow,
      0 0 3px yellow;
  }
}
@-webkit-keyframes blinks-ml {
  to {
    bax-shadow: 2px 3px 4px solid yellow;
  }
}
.ll {
  position: relative;
  color: #fff;
  width: 30px;
  height: 30px;
  border: 0.02rem solid #e3ff00 !important;
  border-radius: 100%;
  font-size: 5em;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 20px #e3ff00, 0 0 3px #e3ff00, 0 0 3px #e3ff00,
    0 0 3px #e3ff00, 0 0 3px #e3ff00;
}
.ll {
  animation: blinks-ll 1s steps(5, start) infinite;
  -webkit-animation: blinks-ll 1s steps(5, start) infinite;
  box-shadow: 0 0 10px #e3ff00, 0 0 3px #e3ff00, 0 0 3px #e3ff00,
    0 0 3px #e3ff00, 0 0 3px #e3ff00;
}
@keyframes blinks-ll {
  to {
    box-shadow: 0 0 20px #e3ff00, 0 0 3px #e3ff00, 0 0 3px #e3ff00,
      0 0 3px #e3ff00, 0 0 3px #e3ff00;
  }
}
@-webkit-keyframes blinks-ll {
  to {
    bax-shadow: 2px 3px 4px solid #e3ff00;
  }
}
.overflow-top-loading {
  width: 100%;
  height: 200px;
  background: rgb(245, 246, 247);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  border-radius: 12px;
}
.butn2 {
  border: 1px solid #f5b0b0 !important;
  color: #f5b0b0 !important;
  background: #f2d5d5 !important;
}
.sideiconbuttob {
  display: flex;
  align-items: center;
  border-radius: 5px;

  padding: 0px !important;
}
</style>
