<template>
  <div style="width: 100%; margin: 0px">
    <div
      style="
        max-width: 900px;
        width: 100%;
        margin: 0px auto;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      "
    >
      <div style="padding: 10px">
        <h2>Accessibility Apps Privacy Policy</h2>
        <p>
          Think Hat Services Limited, Inc. (“Think Hat”, “we”, or “us”) is honored to
          provide the INEC SANS Mobil App (the “App”), which offers users (“you”) a secure
          and reliable method for INEC personnel and ad-hoc staff to send SOS alerts,
          ensuring the safety of staff members during electoral events. When using the
          App, you may consent to us collecting some of your personal information as well
          as using certain features of your device to carry out key functionality for the
          app.
        </p>
        <p>
          The purpose of this Privacy Policy is to provide you with a clear understanding
          of what personal information we have access to when you use the App, as well as
          what personal information may be collected when you allow us to collect usage
          data, how your personal information is used, and the choices you have to control
          the collection and/or use of your personal information.
        </p>
      </div>
      <div style="padding: 10px">
        <h2 style="border-bottom: 1px solid">Contact Information</h2>
        <p>
          If you have any questions, comments, or concerns about your personal
          information, this Privacy Policy, your privacy, or any of our privacy practices,
          please contact our Data Protection Officer by email at hello @thinkhatfirm.com
          or by mailing correspondence to Data Protection Officer at Think Hat Services
          Limited, 42 Montgomery Road, Yaba, Lagos, Nigeria.
        </p>
      </div>
      <div style="padding: 10px">
        <h2>Overview</h2>
        <p>
          This Privacy Policy explains how Think Hat Services collects, stores, uses,
          transfers and shares your personal information in connection with the App, which
          is available for download from the Google Play(TM) store.
        </p>
        <p>
          We may and reserve the right to update this Privacy Policy to reflect changes in
          and to keep you informed about our privacy practices. Please periodically review
          the App or the relevant Google Play(TM) store page for the latest information on
          our privacy practices.
        </p>
        <p>
          If we make any changes, we will notify you by contacting you through the App, by
          posting a notice on the App, by posting a notice on our Google Play(TM) store
          page for the App, or by some combination of these methods. If you continue to
          use the App after the Privacy Policy has been updated, this continued use will
          indicate your acceptance of the Privacy Policy as modified. In some cases, you
          will have to explicitly accept changes to the Privacy Policy. If you do not
          accept the terms of the Privacy Policy, please discontinue using the App
          immediately.
        </p>
      </div>
      <div style="padding: 10px">
        <h2>Personal Information We Collect About You</h2>
        <p>Local Data</p>
        <p>
          When you install the App, a randomly generated unique identifier (“Generic ID”)
          is used to distinguish your installation of the App from installations of the
          App by other users.
        </p>
        <p>
          Upon installation, you will be explicitly asked to grant the App permission to
          access your device’s accessibility services and data (the “Accessible Data”),
          which are used to facilitate the offline transmission of SOS alerts via SMS and
          USSD. The Accessible Data does not include information that is used to
          personally identify you.
        </p>
        <p>
          However, we only collect the data listed in the Collected Data section below and
          we never store or index any of the Collected Data in association with your
          personal identity.
        </p>
        <p>
          The App locally processes the Accessible Data to provide various features and
          functionality to you. You are entirely free to decide whether or not to grant
          data access to the App, and can revoke this permission at any time, although
          doing so may make it infeasible for the App or a feature of the App to function
          properly.
        </p>
      </div>
      <div style="padding: 10px">
        <h2>Collected Data</h2>
        <p>
          The App extracts the below-listed Collected data from the Accessible Data and
          will ask for your explicit consent to transmit the Collected Data to INEC SANS
          servers, such as:
        </p>
        <ul>
          <li>your IP address;</li>
          <li>the Generic ID;</li>
          <li>your device type;</li>
          <li>your device location;</li>
          <li>your browser version;</li>
          <li>mobile network data; and</li>
          <li>usage data (collectively the “Collected Data”).</li>
        </ul>
        <p>
          You are entirely free to decide whether or not to share the Collected Data.
          However, some features of the App rely on the Collected Data to work, and if you
          decline to share the Collected Data, those features may not be available to you.
        </p>
        <p>
          Upon receiving any Collected Data, our policy is to delete your IP address from
          our logs within hours of receipt, unless an error is being debugged, in which
          case it is deleted upon resolution of the error.
        </p>
        <p>
          If you uninstall the App, any Collected Data is permanently disassociated with
          that installation of the App and is at that point anonymized and cannot be used
          to re-identify you.
        </p>
      </div>
      <div style="padding: 10px">
        <h2>Special Category or Sensitive Personal Data</h2>
        <p>
          We never receive or process any of your sensitive personal information, such
          information regarding your sexual orientation, ethnicity, religious affiliation,
          refugee status, health, or finances.
        </p>
      </div>
      <div style="padding: 10px">
        <h2>How We Use Your Personal Information</h2>
        <h3>Basis for Processing</h3>
        <p>
          We may process your personal information for the purposes described in this
          Privacy Policy, with your consent; when we have assessed it is necessary for the
          purposes of the legitimate interests pursued by INEC SANS or a third party to
          whom it may be necessary to disclose information; or to comply with a legal
          obligation. If you have questions about these legal bases you can contact our
          Data Protection Officer.
        </p>
      </div>
      <div style="padding: 10px">
        <h2>Purposes of Processing</h2>
        <p>We may use your personal information for the following purposes:</p>
        <ul>
          <li>to operate, maintain, debug, and improve the App;</li>
          <li>
            to send you technical notices, updates, security alerts and support and
            administrative messages;
          </li>
          <li>
            to respond to your comments, questions and requests and provide customer
            service;
          </li>
          <li>to monitor and analyze trends and usage of the App;</li>
          <li>for any other purposes indicated in this Privacy Policy.</li>
        </ul>
      </div>
      <div style="padding: 10px">
        <h2>Data Minimization and Purpose Limitation</h2>
        <p>
          We will not process your personal information in a way that is incompatible with
          the purposes for which it has been collected or collect any personal information
          that is not needed for these purposes.
        </p>
      </div>
      <div style="padding: 10px">
        <h2>No Sale of Personal Information</h2>
        <p>
          We do not and will never sell your personal information to advertising
          platforms, data brokers, or information resellers. We will also never sell your
          personal information as may be defined by applicable laws.
        </p>
      </div>
      <div style="padding: 10px">
        <h2>No Sale of Personal Information</h2>
        <p>
          We may aggregate anonymized technical information (Anonymized Information) from
          the Collected Data of the users of the App. We may use the Anonymized
          Information to generate and validate the data and insights about app ecosystems
          that we provide on our website at inec-sans.com.
        </p>
        <p>
          We may share Anonymized Information with our service providers that perform
          services on our behalf to help us provide, understand, or improve the App.
        </p>
        <p>
          For example, we may use service providers or other partners to help host our
          services and databases, perform analyses or research, send communications on our
          behalf, or measure the effectiveness of our advertising. Service providers can
          only use the Anonymized Information shared with them for the limited purpose
          under which it was shared, and cannot ever sell the Anonymized Information or
          use the Anonymized Information to personally identify you.
        </p>
      </div>
      <div style="padding: 10px">
        <h2>Use of Contact Information</h2>
        <p>
          In situations where you consensually provide us with contact information for the
          sole purpose of communicating with us, we may use your information to respond to
          your communications and send you information you request.
        </p>
      </div>
      <div style="padding: 10px">
        <h2>Special Circumstances</h2>
        <p>
          From time to time, a situation may arise where we may use personal information
          to enforce our terms of service; prevent unlawful activities and misuse of the
          App; protect the legal rights, property, safety, and security of INEC SANS, our
          affiliates, and our users; and resolve disputes involving you relating to the
          Services; or and comply with applicable laws and assist law enforcement.
          Regardless, we do not and will not share your personal information with
          third-parties except in the following limited circumstances:
        </p>
        <ul>
          <li>
            Third-Parties When Required By Law or as Necessary to Protect the Services. We
            may access, use, preserve, and share your information with third parties when
            we have a good faith belief that it is necessary to: detect, prevent and
            address fraud and other illegal activity; protect ourselves, you and others,
            including as part of investigations; or prevent death or imminent bodily harm.
            We may also share such information if we believe that you have abused your
            rights to use the Services or violated an applicable law, or in connection
            with any dispute between you and us with respect to the Services.
          </li>
          <li>
            In Response to a Legal Request. We may also access, use, preserve, and share
            your information,in response to a legal request if we believe in good faith
            that the response is required by law in that jurisdiction, applies to users in
            that jurisdiction, and is consistent with internationally recognized
            standards. Information we receive about you may be accessed, processed, and
            retained for an extended period of time when it is the subject of a legal
            request or obligation, government investigation, investigation concerning
            possible violations of our terms or policies, or otherwise to prevent harm.
          </li>
          <li>
            In Connection With a Transfer of Assets. If we sell all or part of our
            business, make a sale or transfer of assets, are otherwise involved in a
            merger or business transfer, or in the event of bankruptcy, we may
            confidentially disclose and transfer your personal information to one or more
            third parties as part of that transaction.
          </li>
          <li>
            Other Disclosures. We also may disclose personal information as described to
            you at the point of collection or pursuant to your consent.
          </li>
        </ul>
      </div>
      <h2>Retention of your personal information</h2>
      <div style="padding: 10px">
        <h2>When you use the App</h2>
        <p>
          The personal information we collect from you, as described above, is
          pseudonymized when collected and is retained for as long as needed to accomplish
          the purposes provided in this Privacy Policy. At any time, you can request that
          we erase any personal information that we have collected as outlined in the
          “Your Rights” section of this Privacy Policy.
        </p>
      </div>
      <div style="padding: 10px">
        <h2>When you delete the App</h2>
        <p>
          If you delete the App from your device, your Generic ID is permanently
          disassociated with that instance of the App and any of your personal information
          will become Anonymized Information. You should be aware that we may retain any
          such Anonymized Information after you have deleted the App. We reserve the right
          to use the Anonymized Information after you have deleted the App.
        </p>
      </div>
      <div style="padding: 10px">
        <h2>Your Rights</h2>
        <p>
          With us, you have rights and control over your personal information regardless
          of the country you live in. Many of these important rights are afforded by
          Nigeria’s General Data Protection Act (NGDPR), which include:
        </p>
        <ol>
          <li>
            The right to be informed about the collection and use of your personal
            information, which this Privacy Policy provides.
          </li>
          <li>
            The right to access your personal information (called a “subject access
            request” under the GDPR), which you can do by sending an email to our Data
            Protection Officer using the contact information provided above. Please note
            that since we pseudonymize data collected from you, we may need some
            additional information from you in order to re-identify your personal
            information. Also, if you have uninstalled the App, any data collected from
            you will have been anonymized and will not be re-identifiable.
          </li>
          <li>
            The right to have any data that is inaccurate or incomplete rectified by
            correcting or completing it.
          </li>
          <li>
            The right to erasure (a.k.a., “be forgotten”) by sending an email to our Data
            Protection Officer using the contact information provided above and requesting
            that we delete your personal information. Please note that since we
            pseudonymize data collected from you, we may need some additional information
            from you in order to locate and delete your personal information. Also, if you
            have uninstalled the App, any data collected from you will have been
            anonymized and we will be unable to locate it.
          </li>
          <li>
            The right to restrict the processing of your personal information under
            certain circumstances provided by Article 18 of the GDPR.
          </li>
          <li>
            The right to obtain and reuse a copy of your personal information for your own
            purposes.
          </li>
          <li>
            The right to object to processing of your personal information under certain
            circumstances provided by Article 21 of the GDPR.
          </li>
        </ol>
      </div>
      <div style="padding: 10px">
        <h2>How to exercise your privacy rights</h2>
        <p>
          Contact our Data Protection Officer using the contact information provided above
          to exercise any of your privacy rights. We commit to grant them within 30 days
          after receipt or less.
        </p>
        <p>
          Please keep in mind that in the case of a vague request we may engage the
          individual in a dialogue so as to better understand the motivation and content
          of the request. We may also refuse manifestly unfounded and excessive
          (repetitive) requests.
        </p>
        <p>
          We might also require you to prove your identity in some cases. This is made to
          ensure that no rights of third parties are violated by your request. Other
          items.
        </p>
        <p>
          Notification requirements. We commit to notify you, when it is needed under the
          law, within a reasonable period of time and your data protection authority
          within the timeframe specified in applicable law about data breaches related to
          your personal information.
        </p>
        <p>
          Data Protection Authorities. Subject to applicable laws, you may have the right
          to lodge a complaint with your local data protection authority about any of our
          activities that you deem are not compliant with applicable law.
        </p>
      </div>
      <div style="padding: 10px">
        <h2>Why We Collect Your Personal Information</h2>
        <p>
          We use identifiers, commercial information, and Internet or other electronic
          network activity information in order:
        </p>
        <ul>
          <li>to operate, maintain, debug, and improve the App;</li>
          <li>
            to send you technical notices, updates, security alerts and support and
            administrative messages;
          </li>
          <li>
            to respond to your comments, questions and requests and provide customer
            service;
          </li>
          <li>to monitor and analyze trends and usage of the App;</li>
          <li>to monitor and analyze ads;</li>
          <li>to power INEC SANS</li>
          <li>to use in training of neural networks and artificial intelligence;</li>
          <li>for scientific and academic research purposes; and</li>
          <li>to ensure security.</li>
        </ul>
      </div>
      <div style="padding: 10px">
        <h2>How We Collect Your Personal Information</h2>
        <p>
          As further described in the Collected Data section above, we collect identifiers
          and information directly from you and we collect Internet or other electronic
          network activity from your usage of the App and its services.
        </p>
        <p>
          We do not use cookies or web beacons to collect personal information from you or
          track your activity.
        </p>
      </div>
      <div style="padding: 10px">
        <h2>With Whom We Share and Sell Your Personal Information</h2>
        <p>We do not sell your personal information.</p>
        <ul>
          <li>
            Service Providers: We will share personal information with third parties who
            perform services on our behalf. For example, we share personal information
            with our vendors (e.g., IT services providers, cloud providers, online
            platforms, debugging platforms, support providers, etc.), partners and
            consultants. We may also share personal information with the Independent
            National Electoral Commission for effective deployment and implementation of
            the INEC SANS App.
          </li>
          <li>
            Comply with the Law: We will share personal information if we think we have to
            in order to comply with the law or to protect ourselves such as responding to
            court orders or subpoenas. We may also share your personal information if a
            government agency or investigatory body requests. We may also use your
            personal information when we are investigating potential fraud or other areas
            of concern or if we believe in good faith that disclosure is legally required
            or otherwise necessary to protect our rights and property, or the rights,
            property or safety of others.
          </li>
          <li>
            Information We Share With Your Consent: We will share your personal
            information in the manner that you authorize us to.
          </li>
          <li>
            We may share personal information for other reasons we may describe to you or
            as permitted by law.
          </li>
        </ul>
      </div>
      <div style="padding: 10px">
        <h2>Your Right to Request Deletion</h2>
        <p>
          You have the right to request that we delete any personal information about you
          that we have collected from you. Please note that there are exceptions where we
          do not have to fulfill a request to delete information, such as when the
          deletion of information would create problems with the completion of a
          transaction or compliance with a legal obligation.
        </p>
      </div>
      <div style="padding: 10px">
        <h2>Submitting a Request, Question, or Feedback</h2>
        <p>
          If you would like to exercise your right to opt out, right to delete, or right
          to request disclosure, or if you have any questions or would like to provide
          feedback on this Privacy Policy or our privacy practices, please email us using
          the following information and our privacy team will examine your request,
          question, or feedback and respond to you as quickly as possible. Data Protection
          Officer hello@thinkhatfirm.com
        </p>
      </div>
      <div style="padding: 10px">
        <h2>“Do Not Track” Requests</h2>
        <p>
          We adhere to the standards set out in this Privacy Notice and do not monitor or
          respond to Do Not Track browser requests.
        </p>
      </div>
      <div style="padding: 10px">
        <h2>Security of your personal information</h2>
        <p>
          We maintain reasonable and appropriate safeguards to protect all collected
          personal information against loss, misuse, theft, unauthorized access,
          disclosure, alteration, or destruction. While we strive to protect your
          information and privacy, no data transmission or storage can be guaranteed to be
          100% secure.
        </p>
        <p>
          We cannot guarantee or warrant the security of information you disclose or
          transmit to us and cannot be responsible for the theft, destruction, misuse,
          unauthorized access, alteration, or inadvertent disclosure of your information.
        </p>
        <p>
          If we become aware of a security systems breach, we will attempt to inform you
          by posting a notice, publishing a press release, notifying you via the App, or
          other appropriate means, and will take appropriate steps to remedy the breach in
          compliance with any applicable laws and as specified in this Privacy Policy.
        </p>
      </div>

      <div style="padding: 10px">
        <h2>Third-party Data Processors</h2>
        <p>
          We use data processors that perform particular operations with your personal
          information for us.
        </p>
        <p>
          Data processors (or just processors) are companies that help us, the Data
          Controller, run the App, support our communication with you or perform other
          App-related activities. They may process certain personal information on our
          behalf to accomplish the goals related to the App functions and associated
          activities. Data processors act only in accordance with instructions and process
          only the personal information that we instruct them to process. We are liable
          for any acts or omissions of our data processors and engage with them under data
          processing agreements to the extent required by applicable law.
        </p>
      </div>
      <div style="padding: 10px">
        <h2>Changes to this Policy</h2>
        <p>
          We keep our Privacy Policy under regular review and reserve the right to make
          changes as needed. This version was published on November 2, 2023.
        </p>
      </div>
      <div style="padding: 10px">
        <h2>Third-party Links</h2>
        <p>
          This Privacy Policy may include links to third-party websites, plug-ins and
          applications. Clicking on those links or enabling those connections may allow
          third parties to collect or share data about you. We do not control these
          third-party websites and are not responsible for their privacy statements. When
          you leave our website, we encourage you to read the privacy policy of every
          website you visit.
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PrivacyAccessibility",
};
</script>
<style scoped>
h2 {
  font-weight: 500;
  font-size: 1.5rem;
}
p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
ul,
ol {
  display: grid;
  gap: 20px;
}
</style>
