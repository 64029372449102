<template>
  <div
    class="bg-white p-3 mt-1 conbg slide1 position-relative"
    style="
      height: 100% !important;
      min-height: 700px;
      display: flex;
      justify-content: center;
      align-items: center;
    "
    v-if="isLoading"
  >
    <vue-loaders-ball-scale-multiple
      color="#238f53"
      scale="1"
    ></vue-loaders-ball-scale-multiple>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "redirectMap",

  data() {
    return {
      isLoading: false,
    };
  },
  mounted() {
    const id = this.$route.params.id.replace(/hyphen/g, "_");
    this.redirectUrl(id);
  },

  methods: {
    async redirectUrl(id) {
      console.log(id);
      this.isLoading = true;
      try {
        let resp = await axios.get(`/sms/${id}`);
        let redirectData = resp?.data?.data?.originalURL;
        window.location.href = redirectData;
      } catch (e) {
        console.error("error:", e);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
