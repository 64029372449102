<template>
  <GMapMap
    :center="centers"
    :zoom="zoom"
    style="width: 100%; height: 180px; transition: width 5s"
    :streetViewControl="true"
    :options="{
      zoomControl: true,
      mapTypeControl: true,
      scaleControl: true,
      streetViewControl: true,
      rotateControl: true,
      fullscreenControl: true,
      disableDefaultUi: false,
      bounds: 2,
    }"
    map-type-id="terrain"
  >
    <GMapMarker :position="centers" @click="changeZoom" :animate="2" />

    <GMapInfoWindow :opened="true">
      <div>I am in info window</div>
    </GMapInfoWindow>
  </GMapMap>
</template>
<script>
export default {
  name: "App",
  props: ["centers", "current"],
  data() {
    return {
      center: [],
      zoom: 1,
    };
  },
  methods: {
    changeZoom() {
      if (this.zoom >= 10) {
        this.zoom = this.zoom + 1;
      } else {
        this.zoom = 10;
      }
    },
  },
  created() {
    //  this.zoom = 1;
    if (this.zoom > 1) {
      this.zoom = 1;
    }
  },
  mounted() {
    this.$getLocation({
      enableHighAccuracy: true, //defaults to false
      timeout: Infinity, //defaults to Infinity
      maximumAge: 1, //defaults to 0
    }).then(() => {
      this.zoom = 7;
    });
  },
};
</script>
