<!-- eslint-disable vue/multi-word-component-names -->
// eslint-disable-next-line vue/multi-word-component-names
<template>
  <aside :class="is_expandable ? 'is-expanded' : ''" class="shadow">
    <div class="logo d-flex">
      <img src="@/assets/images/logo.svg" alt="" />
      <div
        class="text ml-2"
        style="text-align: left; font-size: 12px !important"
      >
        <span style="text-align: center; color: #b8ebcd">Security Alert</span>
        <br />
        <span style="text-align: center; color: #b8ebcd"
          >Notification System</span
        >
      </div>
    </div>
    <div class="menu-toggle-wrap">
      <button
        class="menu-toggle"
        @click="ToggleMenu"
        style="border: none; background: none"
      >
        <span class="material-icons">keyboard_double_arrow_right</span>
      </button>
    </div>
    <div class="menu" style="display: grid">
      <router-link to="/admin/dashboard" class="button">
        <span class="material-icons" title="Dashboard">dashboard</span>
        <span class="text">Dashboard</span>
      </router-link>

      <router-link to="/emergency/report" class="button">
        <span class="material-icons" title="Transaction">receipt_long</span>
        <span class="text">Emergency Report</span>
      </router-link>
      <router-link to="/alert/alert" class="button">
        <span class="material-icons">error</span>
        <span class="text">Alert</span>
      </router-link>
      <router-link
        to="/user/management"
        class="button"
        v-if="
          user.userRole === 'global_admin' || user.userRole === 'super_admin'
        "
      >
        <span class="material-icons">manage_accounts</span>
        <span class="text">User Management</span>
      </router-link>
      <router-link to="/settings/settings" class="button">
        <span class="material-icons">settings</span>
        <span class="text">Settings</span>
      </router-link>
      <router-link
        to="/code"
        class="button"
        v-if="
          user.userRole === 'global_admin' || user.userRole === 'super_admin'
        "
      >
        <span class="material-icons" title="Transaction">code</span>
        <span class="text">Session</span>
      </router-link>
      <!---
      <router-link to="/delete-request" class="button">
        <span class="material-icons" title="Transaction">delete</span>
        <span class="text">Delete Request</span>
      </router-link>
      -->
    </div>

    <div class="menu" style="display: grid" @click="logout">
      <a class="button" href="javascript:void()">
        <span class="material-icons">logout</span>
        <span class="text">Logout</span>
      </a>
    </div>
    <div class="menu" style="display: grid">
      <router-link to="/faq/faq" class="button" style="align-item: center">
        <span class="material-icons" style="font-size: 14px"
          >question_mark</span
        >
        <span class="text">FAQs</span>
      </router-link>
    </div>
  </aside>
</template>
<script setup>
import { ref } from "vue";
const is_expandable = ref(localStorage.getItem("is-expanded") === "true");
const ToggleMenu = () => {
  is_expandable.value = !is_expandable.value;
  localStorage.setItem("is-expanded", is_expandable.value);
};
</script>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  methods: {
    ...mapActions({
      signOut: "auth/signOut",
    }),
    logout() {
      this.signOut().then(() => {
        this.$router.push("/");
      });
    },
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
  },
};
</script>

<style scoped lang="scss">
aside {
  display: flex;
  flex-direction: column;
  width: calc(2rem + 32px);
  min-height: 100vh;
  overflow: hidden;
  padding: 1rem;
  transition: 0.6s ease-out;
  background-color: #238f53;
  a {
    line-height: 2.2rem;
    color: #fff !important;
    text-decoration: none;
  }
  .services {
    line-height: 2rem;
  }
  .flex {
    flex: 1;
  }
  .logo {
    margin-left: -5px;

    img {
      width: 45px;
    }
  }
  .menu-toggle-wrap {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
    position: relative;
    top: 0;
    transition: 0.2s ease-out;
    z-index: 10000;
    .menu-toggle {
      transition: 0.2s ease-out;
      .material-icons {
        font-size: 1rem !important;
        color: var(--menu-title);
        color: white;
      }
      &:hover {
        .material-icons {
          transform: translateX(0.5rem);
          transition: 0.2s ease-out;
        }
      }
    }
  }
  h3,
  .button .text {
    opacity: 0;
    transition: 0.3s ease-out;
  }

  .menu {
    margin: 0 -0.5rem;
    .button {
      display: flex;
      align-items: center;
      text-decoration: none;
      padding: 0.5rem 1rem;
      transition: 0.2s ease-out;

      .material-icons {
        color: var(--menu-title);
        transition: 0.2s ease-out;
        color: white !important;
        font-size: 12px !important;
      }
      .text {
        color: var(--menu-title);
        transition: 0.2s ease-out;
        color: #fff;
        font-size: 12px !important;
      }
      &:hover {
        background: #ebf6f31a;
        transition: 0.2s ease-out;
      }
      &.router-link-exact-active {
        .material-icons,
        .text {
          color: white;
        }
      }
      &.router-link-exact-active {
        background: #ebf6f31a;
      }
    }
    .dropdown-menu {
      transition: 0.2s ease-in;
    }
  }
  &.is-expanded {
    width: var(--sidebar-width);
    .menu-toggle-wrap {
      top: -3rem;
      .menu-toggle {
        transform: rotate(-180deg);
      }
    }
    .button {
      .material-icons {
        font-size: 1.4rem;
      }
    }
    h3,
    .button .text {
      opacity: 1;
      transition: 0.3s ease-out;
    }

    .button {
      .material-icons {
        margin-right: 0.8rem;
      }
    }
    .logo {
      img {
        width: 45px;
      }
    }
    .dropdown-menu {
      transition: 0.2s ease-in-out;
    }
  }
}
</style>
