import { createApp } from "vue";
import App from "./App.vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap/dist/js/bootstrap.js";
import "vue-loaders/dist/vue-loaders.css";
import VueLoaders from "vue-loaders";
import axios from "axios";
import router from "./router/index.js";
import Chartkick from "vue-chartkick";
import chart from "chart.js/auto";
import "chartjs-adapter-moment";
import VueApexCharts from "vue3-apexcharts";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import VueGeolocation from "vue3-geolocation";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

require("@/store/subscriber");
import store from "./store";
Chartkick.options = {
  library: {
    cutoutPercentage: 0,
  },
};

store.dispatch("auth/attempt", localStorage.getItem("token")).then(() => {
  createApp(App)
    .use(VueLoaders, axios)
    .use(router)
    .use(VueApexCharts)
    .use(store)
    .use(Chartkick.use(chart))
    .use(VueSweetalert2)
    .use(VueGeolocation)
    .use(VueGoogleMaps, {
      load: {
        key: "AIzaSyANCcAa09J_M6N_tRRd6_K8cF8mIthUPBw",
        libraries: "visualization",
      },
    })
    .mount("#app");
});
