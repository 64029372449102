<template>
  <div
    class="container bg-white shadow-sm"
    style="margin: 30px auto; max-width: 800px; width: 100%"
  >
    <h6 style="padding: 10px">Background</h6>
    <p style="word-spacing: 1">
      We are ThinkHat Service Limited and operate under the name THinkHat We are
      registered with the Corporate Affairs Commission with the number RC1733970.

    </p>
    <p style="word-spacing: 1">
      This policy describes what information we collect about you, how we collect and use
      the information you share with us, and with whom we share that information. You
      don’t have to share any information with us, but to use our services, we will need
      some information from you. This policy also contains information about when we share
      your personal information with third parties such as cloud infrastructural providers.
    </p>

    <h6 style="padding: 10px">What information do we collect?</h6>
    <p style="word-spacing: 1">
        We collect and use any information you supply when you interact with any of our touch points. When you open an account with us, you share details like your name, BVN, ID and address.
        When sending securit alert while interacting with the mobile application you share with us details of your current location when you send either an incident alert , emergency report or SOS report.
        <ul>
            <li>Also, if you choose to delete your ThinkHat account, we will delete any data we have previously collected from you.</li>
        </ul>
    </p>
    <h6 style="padding: 10px">Information you give us when you contact us</h6>
    <p style="word-spacing: 1">
        If you contact us via means other than the in-app chat, we collect the following information so we can answer your questions or take action.     
           <ul>
            <li>The phone number you’re calling from and information you give us during the call</li>
            <li>The email address you use and the contents of your email (and any attachments).</li>
            <li>Public details from your social media profile (like Facebook, Instagram or Twitter) if you reach out to us via these platforms, and the contents of your messages or posts to us.</li>
            <li>The content of your message or posts to us</li>
        </ul>
    </p>

    <h6 style="padding: 10px">Information we collect when you use INEC SANS</h6>
    <p style="word-spacing: 1">
        If you contact us via means other than the in-app chat, we collect the following information so we can answer your questions or take action.     
           <ul>
            <li>The mobile network and operating system you use,</li>
            <li>Your IP address and device ID.</li>
        </ul>
    </p>

    <h6 style="padding: 10px">Information we get from third parties</h6>
    <p style="word-spacing: 1">
        As part of our KYC (Know Your Customer) process, we run checks on the personal and corporate ID information you supply during signup. Also, we run further checks with the credit bureaus to check if we can offer you our credit facility services.
    </p>

    <h6 style="padding: 10px">How do we use your information?</h6>
    <p style="word-spacing: 1">
        The Nigerian Data Protection Regulation says we need to have a lawful basis for using your  information you share with us. At least one of the following must apply: contractual or legal duty, legitimate interest, public interest, vital individual interest or consent.    </p>

  <h6 style="padding: 10px">Contractual Obligation</h6>
    <p style="word-spacing: 1">
        We collect certain data from you to fulfil the contract we have with you, or to enter into a contract with you., as well as the contract we have with independent National Electoral Commision. We use this data to:
        

        <ul>
            <li>Give you the services we agreed to in line with the featurs and functions of the INEC SANS Android Application</li>
            <li>Send you messages about your account and other services you use if you get in touch, or we need to tell you about something.</li>
            <li>Exercise our rights under contracts we’ve entered with you, by virtue of signing up  to use the INEC SANS Mobile App; the scope of this contract includes allowing you to send election specific security alerts to the independent National Electoral  Commission</li>
            <li>Investigate and resolve complaints and other issues.</li>
        </ul>
    </p>



    <h6 style="padding: 10px">Legal Duty</h6>
    <p style="word-spacing: 1">
        We have to ensure we are not breaking any laws or allowing you to use the mobile application to constitute nuisance to the Commission. To do ths, we need your data to:
        

        <ul>
            <li>Confirm your identity when you sign up </li>
            <li>Keep records of information we hold about you in line with the legal requirements</li>
            <li>Legitimate Interest.</li>
            <li>In some Instances, we need to use your datayou supply us for legitimate interest. This means  we are using your datain a way  that might expect us to, for a reason which is in your interest and  does not override your privacy , or fundamental rights and freedoms</li>
        </ul>
    </p>

<!--- 
    <h6 style="padding: 10px">Legitimate Interest</h6>
    <p style="word-spacing: 1">
        In some instances, we need to use the data you supply us for our legitimate interests. This means we are using your data in a way that you might expect us to, for a reason which is in your interest and does not override your privacy, interests or fundamental rights and freedoms.
        </p>
        -->

        <h6 style="padding: 10px">Consent</h6>
    <p style="word-spacing: 1">
        For certain information, we will ask for your consent. We will ask for your consent to:
        <ul>
            <li>Market and communicate our products and services or those of related third parties. You can always unsubscribe from receiving these if you choose to.</li>
        </ul>

    </p>

    <h6 style="padding: 10px">Who do we share your information with?
</h6>
    <p style="word-spacing: 1">
        In some instances, we will share the information provided with some third parties that we do business with, like cloud server infrastructure providers; the Independent national Electoral Commission , or with law enforecement agencies with the agencies when required .       

    </p>


    <h6 style="padding: 10px">How long do we keep your data?</h6>
    <p style="word-spacing: 1">
        We keep your information as long as you are an INEC SANS registered user and for any additional period as required under applicable law or regulations. These retention requirements supersede any right to erasure requests under applicable data protection laws. We cannot therefore erase your data in relation to your INEC SANS account until after this time period, however we can assure you that your data will be held safely and securely whilst under our supervision.

    </p>


    <h6 style="padding: 10px">When do we delete your data?</h6>
    <p style="word-spacing: 1">
        We are basically storing and processing your data as gathered through the mobile application as long as it is necessary to perform our obligations under the agreement with you or as long as the law requires us to store it. 

If you choose to delete your INEC SANS account, we will delete any data you have previously given us.

    </p>

    <h6 style="padding: 10px">What are your rights?</h6>
    <p style="word-spacing: 1">
        You have the right to access and request the information that we have for you in our records. You may also ask us to delete our record of you, restrict the way in which we use your personal information, request a copy of the information we hold about you, ask that we update the personal information we hold about you or correct such personal information which you think is incorrect or incomplete, and we will grant this request as long as we are legally allowed to. 
    </p>
    <p style="padding:10px">
        You also have the right to object to us using your information for our marketing purposes or any additional services we may be offering you. Lastly, you may also withdraw any consent you have previously given us.


    </p>
    <h6 style="padding: 10px">How to make a complaint</h6>
    <p style="word-spacing: 1">
        At Think Hat Service Limited we’re extremely committed to respecting and protecting your information. If you have any worries or reservations about your personal information, please contact our Data Protection Officer by:


        <ul>
            <li>Sending a message via the app</li>
            <li>Emailing us at  <a href="mailto:Developer@thinkhatfirm.com" style="color:black">Developer@thinkhatfirm.com</a></li>
            <li>Writing to us at  <a href="mailto:Developer@thinkhatfirm.com" style="color:black">Developer@thinkhatfirm.com</a>
            </li>
        </ul>
    </p>
    </div>
    </template>
<script>
export default {
  name: "myPrivacy",
};
</script>
<style scoped>
    h6{
        line-height: 30px;

    }
    p{
        line-height: 40px;
        line-break: auto;
        text-align: left;
        padding:10px ;
    }
    li{
        line-height:40px
    }
</style>
