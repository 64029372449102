<template>
  <div></div>
</template>
<script>
export default {
  name: "downloadApps",
  mounted() {
    window.location.href = "https://inecsans.s3.eu-west-1.amazonaws.com/app-release.apk";
  },
};
</script>
<style>
.center {
  height: 100vh;
  align-items: center;

  display: flex;
  width: 100vw;
  place-items: center;
  justify-content: center;
}
</style>
