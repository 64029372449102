<template>
  <div class="w-100 app">
    <SideBar />
    <div class="w-100">
      <Header />

      <div class="container p-12 bg-home-main">
        <h2>Frequently Asked Questions</h2>
        <div class="accordion">
          <div class="accordion-item">
            <button id="accordion-button-1" aria-expanded="false">
              <span class="accordion-title">What is INEC SANS?</span
              ><span class="icon" aria-hidden="true"></span>
            </button>
            <div class="accordion-content">
              <p>
                INEC SANS stands for INEC Security Alert and Notification
                System. This is a software developed by the Independent National
                Electoral Commission to aid with managing security incidents and
                its effect on the Commission’s assets and personnel.
              </p>
            </div>
          </div>

          <div class="accordion-item">
            <button id="accordion-button-1" aria-expanded="false">
              <span class="accordion-title">What is SOS?</span
              ><span class="icon" aria-hidden="true"></span>
            </button>
            <div class="accordion-content">
              <p>
                SOS is short for “Save Our Soul”. It is embedded in the
                INEC-SANS mobile application. The button when clicked, sends an
                emergency message to the preregistered numbers for quick
                response. The button will also ask you to enter the phone
                numbers for your allocated EO and SPO, so the emergency text
                message can be shared with them as well.
              </p>
            </div>
          </div>
          <div class="accordion-item">
            <button id="accordion-button-1" aria-expanded="false">
              <span class="accordion-title">Who are the Notifying users?</span
              ><span class="icon" aria-hidden="true"></span>
            </button>
            <div class="accordion-content">
              <p>
                Notifying Users are field assets eg SPOs, POs etc. They are
                responsible for sending SOS Alerts, Alert Notifications and
                Emergency Reports from the mobile app.
              </p>
            </div>
          </div>
          <div class="accordion-item">
            <button id="accordion-button-1" aria-expanded="false">
              <span class="accordion-title"
                >Who are the response team users?</span
              ><span class="icon" aria-hidden="true"></span>
            </button>
            <div class="accordion-content">
              <p>
                The response team users are the people appointed to respond to
                alert notifications, SOS alerts and emergency reports.
              </p>
            </div>
          </div>
          <div class="accordion-item">
            <button id="accordion-button-1" aria-expanded="false">
              <span class="accordion-title">Who can Login?</span
              ><span class="icon" aria-hidden="true"></span>
            </button>
            <div class="accordion-content">
              <p>
                The only people allowed to login into the application are people
                authorised by the commission.
              </p>
            </div>
          </div>

          <div class="accordion-item">
            <button id="accordion-button-1" aria-expanded="false">
              <span class="accordion-title"
                >What do I do if I don’t get early/any response?</span
              ><span class="icon" aria-hidden="true"></span>
            </button>
            <div class="accordion-content">
              <p>
                Rest assured the INEC SANS team is committed to providing timely
                intervention when you send an alert. However, if you don’t get
                an early response or any response, please reach out to the
                nearest security personnel for immediate assistance.
              </p>
            </div>
          </div>
          <div class="accordion-item">
            <button id="accordion-button-1" aria-expanded="false">
              <span class="accordion-title">
                What are the alert categories?</span
              ><span class="icon" aria-hidden="true"></span>
            </button>
            <div class="accordion-content">
              <p>
                There are 4 alert categories on INEC SANS – Critical, High,
                Medium and Low
              </p>
            </div>
          </div>
          <div class="accordion-item">
            <button id="accordion-button-1" aria-expanded="false">
              <span class="accordion-title">How do I login</span
              ><span class="icon" aria-hidden="true"></span>
            </button>
            <div class="accordion-content">
              <p>
                Kindly login to INEC SANS application using the email and
                password you registered
              </p>
            </div>
          </div>
          <div class="accordion-item">
            <button id="accordion-button-2" aria-expanded="false">
              <span class="accordion-title"
                >I am unable to login to the INEC SANS application</span
              ><span class="icon" aria-hidden="true"></span>
            </button>
            <div class="accordion-content">
              <p>
                Please that you have inputted the correct email and password you
                registered with
              </p>
            </div>
          </div>
          <div class="accordion-item">
            <button id="accordion-button-3" aria-expanded="false">
              <span class="accordion-title">
                I cannot Remember my password </span
              ><span class="icon" aria-hidden="true"></span>
            </button>
            <div class="accordion-content">
              <p>
                Kindly click on the forgotten password button and reset your
                password
              </p>
            </div>
          </div>
          <div class="accordion-item">
            <button id="accordion-button-4" aria-expanded="false">
              <span class="accordion-title">How do i change my password?</span
              ><span class="icon" aria-hidden="true"></span>
            </button>
            <div class="accordion-content">
              <p>Click on the profile page and change your password.</p>
            </div>
          </div>
          <div class="accordion-item">
            <button id="accordion-button-5" aria-expanded="false">
              <span class="accordion-title">How do i send SOS alert?</span
              ><span class="icon" aria-hidden="true"></span>
            </button>
            <div class="accordion-content">
              <p>
                When you open your application, you will see the SOS button,
                click on it and register two numbers and send. Note that
                registration of two numbers is optional
              </p>
            </div>
          </div>
          <div class="accordion-item">
            <button id="accordion-button-5" aria-expanded="false">
              <span class="accordion-title">Can I send SOS without Data?</span
              ><span class="icon" aria-hidden="true"></span>
            </button>
            <div class="accordion-content">
              <p>
                With or Without Network,clicking on the SOS button will
                automatically send an SOS
              </p>
            </div>
          </div>

          <div class="accordion-item">
            <button id="accordion-button-5" aria-expanded="false">
              <span class="accordion-title"
                >How do i update my profile picture?</span
              ><span class="icon" aria-hidden="true"></span>
            </button>
            <div class="accordion-content">
              <p>
                Click on the profile button and update your profile picture.
              </p>
            </div>
          </div>

          <div class="accordion-item">
            <button id="accordion-button-5" aria-expanded="false">
              <span class="accordion-title">How do i send alert?</span
              ><span class="icon" aria-hidden="true"></span>
            </button>
            <div class="accordion-content">
              <p>
                Click on the alert button, you will see a plus sign at the top
                right of the alert page, click on it and fill the text field to
                send alert.
              </p>
            </div>
          </div>
          <div class="accordion-item">
            <button id="accordion-button-5" aria-expanded="false">
              <span class="accordion-title"
                >How do i fill the Emergency report form?</span
              ><span class="icon" aria-hidden="true"></span>
            </button>
            <div class="accordion-content">
              <p>
                Click on the alert button, you will see a plus sign at the top
                right of the alert page, click on it and fill the form and send.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "../components/header.vue";
import SideBar from "../components/sidebar.vue";
export default {
  name: "myFaq",
  components: {
    Header,
    SideBar,
  },
  methods: {},

  mounted() {
    const items = document.querySelectorAll(".accordion button");

    function toggleAccordion() {
      const itemToggle = this.getAttribute("aria-expanded");

      for (var i = 0; i < items.length; i++) {
        items[i].setAttribute("aria-expanded", "false");
      }

      if (itemToggle == "false") {
        this.setAttribute("aria-expanded", "true");
      }
    }

    items.forEach((item) => item.addEventListener("click", toggleAccordion));
  },
};
</script>
<style scoped lang="scss">
$bg: #ffffff;
$text: rgb(35, 143, 83);
$gray: #4d5974;
$lightgray: #e5e5e5;
$blue: rgb(35, 143, 83);

* {
  box-sizing: border-box;
  &::before,
  &::after {
    box-sizing: border-box;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: "Hind", sans-serif;
  background: $bg;
  color: $gray;
  display: flex;
  min-height: 100vh;
}
.bg-home-main {
  background: #f5f6f7;
  max-height: 100dvh;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.container {
  margin: 0 auto;
  padding: 4rem;
  width: 90%;
}

.accordion {
  .accordion-item {
    border-bottom: 1px solid $lightgray;
    button[aria-expanded="true"] {
      border-bottom: 1px solid $blue;
    }
  }
  button {
    position: relative;
    display: block;
    text-align: left;
    width: 100%;
    padding: 1em 0;
    color: $text;
    font-size: 1.15rem;
    font-weight: 400;
    border: none;
    background: none;
    outline: none;
    &:hover,
    &:focus {
      cursor: pointer;
      color: rgb(35, 143, 83);
      &::after {
        cursor: pointer;
        color: rgb(35, 143, 83);
        border: 1px solid rgb(35, 143, 83);
      }
    }
    .accordion-title {
      padding: 1em 1.5em 1em 0;
    }
    .icon {
      display: inline-block;
      position: absolute;
      top: 18px;
      right: 0;
      width: 22px;
      height: 22px;
      border: 1px solid;
      border-radius: 22px;
      &::before {
        display: block;
        position: absolute;
        content: "";
        top: 9px;
        left: 5px;
        width: 10px;
        height: 2px;
        background: currentColor;
      }
      &::after {
        display: block;
        position: absolute;
        content: "";
        top: 5px;
        left: 9px;
        width: 2px;
        height: 10px;
        background: currentColor;
      }
    }
  }
  button[aria-expanded="true"] {
    color: $blue;
    .icon {
      &::after {
        width: 0;
      }
    }
    + .accordion-content {
      opacity: 1;
      max-height: 9em;
      transition: all 200ms linear;
      will-change: opacity, max-height;
    }
  }
  .accordion-content {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: opacity 200ms linear, max-height 200ms linear;
    will-change: opacity, max-height;
    p {
      font-size: 18px;
      font-weight: 300;
      margin: 2em 0;
    }
  }
}
</style>
